<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon
        class="pl-3"
        v-on="on"
        v-if="daysTillExpired > 7 && daysTillExpired <= 31"
        color="amber lighten-2"
      >
        mdi-alert
      </v-icon>
      <v-icon
        v-on="on"
        class="pl-3"
        v-else-if="daysTillExpired <= 7 && daysTillExpired >= 1"
        color="red"
      >
        mdi-alert-circle-outline
      </v-icon>
      <v-icon
        v-on="on"
        class="pl-3"
        v-else-if="daysTillExpired < 1"
        color="red"
      >
        mdi-alert-circle
      </v-icon>
    </template>
    <span> {{ warningMessage }} </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    daysTillExpired: {
      type: Number,
      required: true
    },
    warningMessage: {
      type: String,
      required: true
    }
  }
}
</script>
