<template>
  <v-col
    cols="12"
    :class="
      $vuetify.breakpoint.smAndUp ? 'py-0 pr-15 d-flex justify-end' : 'py-0'
    "
  >
    <ChipTransition
      v-for="(data, i) in chipData"
      :key="i"
      :condition="data.condition"
      :name="data.name"
      :icon="data.icon"
      :close="data.close"
      :onClick="toggleDrawer"
    />
  </v-col>
</template>

<script>
import Store from '../../../store/computed/storeHelpers'
import GetNamesFromId from '../../../services/mixins/getNamesFromId'
import FormatDates from '@/services/mixins/formatDates'

import ChipTransition from './ChipTransition'
import FilterHelpers from '../shared/mixins/filterHelpers'

export default {
  mixins: [GetNamesFromId, FormatDates, FilterHelpers],
  components: {
    ChipTransition
  },
  props: {
    toggleDrawer: {
      type: Function,
      required: false
    },
    lineView: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    users: Store.getSet({ store: 'users', prop: 'usersLite' }),
    contractors: Store.getSet({
      store: 'contractors',
      prop: 'contractorsLite'
    }),
    drivers: Store.getSet({ store: 'drivers', prop: 'driversLite' }),
    vehicles: Store.getSet({ store: 'vehicles', prop: 'vehiclesLite' }),
    vehicleTypes: Store.getSet({ store: 'vehicleTypes', prop: 'vehicleTypes' }),
    itemTypes: Store.getSet({ store: 'itemTypes', prop: 'itemTypesLite' }),
    projects: Store.getSet({ store: 'projects', prop: 'projectsLite' }),
    sources: Store.getSet({ store: 'sources', prop: 'sourcesLite' }),
    destinations: Store.getSet({
      store: 'destinations',
      prop: 'destinationsLite'
    }),
    reports: Store.getSet({ store: 'reports', prop: 'reports' }),
    dateString() {
      let string = ''
      if (this.dateRangeStart) string = this.makeShortDate(this.dateRangeStart)
      if (this.dateRangeEnd)
        string += ` - ${this.makeShortDate(this.dateRangeEnd)}`
      return string
    },
    chipData() {
      return [
        {
          condition: this.showBillingPeriod,
          name: this.selectedBillingPeriod,
          icon: 'mdi-calendar-blank',
          close: () => (this.selectedBillingPeriod = null)
        },
        {
          condition: this.showProjects,
          name: this.projectName,
          icon: this.$store.state.global.pages.project.icon,
          close: () => (this.selectedProjectId = null)
        },
        {
          condition: this.showSources,
          name: this.sourceName,
          icon: this.$store.state.global.pages.source.icon,
          close: () => (this.selectedSourceId = null)
        },
        {
          condition: this.showDestinations,
          name: this.destinationName,
          icon: this.$store.state.global.pages.destination.icon,
          close: () => (this.selectedDestinationId = null)
        },
        {
          condition: this.showContractors,
          name: this.contractorName,
          icon: this.$store.state.global.pages.driver.icon,
          close: () => (this.selectedContractorId = null)
        },
        {
          condition: this.showDrivers,
          name: this.driverName,
          icon: this.$store.state.global.pages.driver.icon,
          close: () => (this.selectedDriverId = null)
        },
        {
          condition: this.showVehicles,
          name: this.vehicleName,
          icon: this.$store.state.global.pages.vehicle.icon,
          close: () => (this.selectedVehicleId = null)
        },
        {
          condition: this.showVehicleTypes,
          name: this.vehicleTypeName,
          icon: this.$store.state.global.pages.vehicle.icon,
          close: () => (this.selectedVehicleTypeId = null)
        },
        {
          condition: this.showItemTypes,
          name: this.itemTypeName,
          icon: this.$store.state.global.pages.itemType.icon,
          close: () => (this.selectedItemTypeId = null)
        },
        {
          condition: this.showUsers,
          name: this.userName,
          icon: this.$store.state.global.pages.user.icon,
          close: () => (this.selectedUserId = null)
        },
        {
          condition: this.showShowClosed,
          name: 'Closed',
          icon: 'close-circle-outline',
          close: () => (this.showClosed = false)
        },
        {
          condition: this.showShowVoid,
          name: 'Void',
          icon: 'close-circle-outline',
          close: () => (this.showVoid = false)
        },
        {
          condition: this.showDeliveryStatus,
          name: this.selectedDeliveryStatus,
          icon: 'mdi-truck',
          close: () => (this.selectedDeliveryStatus = null)
        },
        {
          condition: this.showDateRange,
          name: this.dateString,
          icon: 'mdi-calendar-range',
          close: this.toggleDrawer
        },
        {
          condition: this.showPodType,
          name: this.selectedPodType ? this.selectedPodType.join(' | ') : '',
          icon: this.$store.state.global.pages.pod.icon,
          close: () => (this.selectedPodType = [])
        },
        {
          condition: this.showReportId,
          name: this.reportName,
          icon: 'mdi-file-document',
          close: () => (this.selectedReportId = null)
        }
      ]
    }
  }
}
</script>
