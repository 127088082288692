export default {
  isEmail: val => {
    if (
      !val ||
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        val
      )
    ) {
      return true
    } else {
      return 'Must be a valid email'
    }
  },
  isRequired: val => {
    if (val) {
      return true
    } else {
      return 'Field is required'
    }
  },
  isRequiredWithZero: val => {
    if (val) {
      return true
    } else {
      if (val === 0) {
        return true
      } else {
        return 'Field is required (0 is allowed)'
      }
    }
  },
  minLength: (val, length) => {
    if (!val || val.toString().length >= length) {
      return true
    } else {
      return `Must be atleast ${length} characters long`
    }
  },

  maxLength: (val, length) => {
    if (!val || val.toString().length <= length) {
      return true
    } else {
      return `Must be less than ${Number(length) + 1} characters long`
    }
  },

  isPhone: val => {
    if (
      !val ||
      (/^[0-9\s]+$/.test(val) &&
        val.toString().length <= 15 &&
        val.toString().length >= 5)
    ) {
      return true
    } else {
      return `Must be a valid phone number`
    }
  },

  isNumber: val => {
    if (!val || typeof val === 'number') {
      return true
    } else {
      return 'Must be a number'
    }
  },

  isBoolean: val => {
    if (typeof val === 'boolean') {
      return true
    } else {
      return 'Must be a boolean'
    }
  },

  isMaxNumber: (val, max) => {
    if (!val || val < max) {
      return true
    } else {
      return `Must be less than ${max.toString()}`
    }
  },

  isPassword: val => {
    if (
      !val ||
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        val
      )
    ) {
      return true
    } else {
      return 'Password must contain at least one Uppercase letter, one lower case letter, one number, one special character and be at least 8 characters long'
    }
  },
  isInValidDateRange: val => {
    val = new Date(val)
    val.setHours(val.getHours() - 10)
    var endDate = new Date()
    var startDate = new Date(2009, 11)

    if (
      val.getTime() > startDate.getTime() &&
      val.getTime() <= endDate.getTime()
    ) {
      return true
    } else {
      return 'Date must be between Jan, 2010 and today'
    }
  }
}
