<template>
  <v-row class="pt-5">
    <v-col cols="9" class="d-flex align-center">
      Accounting system
    </v-col>
    <v-col cols="3" class="d-flex align-center">
      <v-autocomplete
        :disabled="!userAuthClass"
        v-model="clonedAccountingSettings.accountingSystem"
        clearable
        hide-details
        class="mt-0"
        :items="['Xero', 'Quickbooks', 'MYOB']"
      ></v-autocomplete>
    </v-col>

    <v-col cols="9" class="d-flex align-center">
      Sales Account
    </v-col>
    <v-col cols="3" class="d-flex align-center">
      <v-text-field
        :disabled="!userAuthClass"
        v-model="clonedAccountingSettings.salesAccount"
        hide-details
        class="mt-0"
      ></v-text-field>
    </v-col>

    <v-col cols="9" class="d-flex align-center">
      Purchases Account
    </v-col>
    <v-col cols="3" class="d-flex align-center">
      <v-text-field
        :disabled="!userAuthClass"
        v-model="clonedAccountingSettings.purchasesAccount"
        hide-details
        class="mt-0"
      ></v-text-field>
    </v-col>

    <v-col
      cols="12"
      class="d-flex justify-end"
      v-if="clonedAccountingSettings.accountingSystem"
    >
      <v-btn @click="connectAccounting"
        >Connect {{ clonedAccountingSettings.accountingSystem }}</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import Store from '../../store/computed/storeHelpers'
import { updateAccounting } from '../../services/requests/settings'
import {
  connectXero,
  connectQuickbooks,
  connectMyob
} from '../../services/requests/accounting'

export default {
  props: {
    accountingSettings: {
      type: Object,
      required: true
    }
  },
  watch: {
    clonedAccountingSettings: {
      deep: true,
      handler() {
        this.saveSettings()
      }
    }
  },
  data() {
    return {
      clonedAccountingSettings: Object.assign({}, this.accountingSettings),
      loading: false
    }
  },
  computed: {
    settings: Store.getSet({ store: 'settings', prop: 'settings' }),
    connect() {
      if (this.clonedAccountingSettings.accountingSystem == 'Xero') {
        return connectXero
      }
      if (this.clonedAccountingSettings.accountingSystem == 'Quickbooks') {
        return connectQuickbooks
      }
      if (this.clonedAccountingSettings.accountingSystem == 'MYOB') {
        return connectMyob
      }
      return null
    }
  },
  methods: {
    async saveSettings() {
      this.loadingSettings = true
      const payload = {
        accountingSystem: this.clonedAccountingSettings.accountingSystem,
        purchasesAccount: this.clonedAccountingSettings.purchasesAccount,
        salesAccount: this.clonedAccountingSettings.salesAccount
      }
      try {
        this.loading = true
        const result = await updateAccounting(
          this.clonedAccountingSettings.id,
          payload
        )
        this.settings = result.data.result
        this.snack({ text: 'Setting updated', color: 'green' })
        this.loadingSettings = false
        this.loading = false
      } catch (err) {
        this.loading = false
        this.loadingSettings = false
        console.log(err)
      }
    },
    async connectAccounting() {
      try {
        if (!this.connect) return
        const result = await this.connect()
        window.open(result.data.result, '_blank')
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
