import { updateAllocation } from '../../../services/requests/allocations'
import Allocation from '../../../services/models/allocation'

export default {
  data: () => ({
    value: '',
    dragEvent: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null
  }),
  methods: {
    startDrag({ event, timed }) {
      if (
        event &&
        timed &&
        this.userAuthClass &&
        !this.dragEvent?.allocation.closed &&
        !this.dragEvent?.allocation.docketId
      ) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms)
      if (
        this.dragEvent &&
        this.dragTime === null &&
        !this.dragEvent?.allocation.closed &&
        !this.dragEvent?.allocation.docketId
      ) {
        const start = this.dragEvent.start
        this.dragTime = mouse - start
      }
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms)
      if (
        this.dragEvent &&
        this.dragTime !== null &&
        !this.dragEvent?.allocation.closed &&
        !this.dragEvent?.allocation.docketId
      ) {
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
      }
    },
    async endDrag(e) {
      if (
        !this.dragEvent?.allocation?.closed &&
        !this.dragEvent?.allocation.docketId
      ) {
        await this.editAllocationDate({
          date: e.date,
          allocation: this.dragEvent?.allocation
        })
      }
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    async editAllocationDate({ date, allocation }) {
      if (!allocation || date == allocation.date) return
      try {
        this.loading = true
        const payload = {
          date: date,
          driverId: allocation.driverId,
          sourceId: allocation.sourceId,
          vehicleId: allocation.vehicleId
        }
        const result = await updateAllocation(allocation.id, payload)
        if (result) {
          this.$store.commit('allocations/updateArrayStateById', {
            prop: 'allocations',
            editedValue: new Allocation(result.data.result)
          })
          if (this.events) this.getEvents()
          this.snackUpdated()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime()
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)]
    }
  }
}
