<template>
  <v-sheet outlined :color="color" rounded class="card-outlined">
    <v-card flat>
      <v-card-text>
        <slot name="header"></slot>
        <v-row class="pb-3">
          <v-col
            cols="12"
            sm="3"
            class="py-0"
            v-if="filterCols && onFilterColChange"
          >
            <FilterSelect
              :label="filterLabel"
              :filterCols="filterCols"
              :initialFilterCol="filterCol"
              :onChanged="onFilterColChange"
              :clearFilterBy="() => (currentFilterBy = null)"
            />
          </v-col>
          <v-col cols="12" sm="3" class="py-0" v-if="onFilterByChange">
            <v-text-field
              :class="$vuetify.breakpoint.smAndUp ? '' : 'py-0'"
              prepend-icon="mdi-filter-variant"
              label="Filter by..."
              v-model="currentFilterBy"
              clearable
            ></v-text-field>
          </v-col>
          <ChipDisplay
            :toggleDrawer="toggleDrawer"
            v-if="toggleDrawer"
            :lineView="lineView"
          />
        </v-row>
      </v-card-text>
      <OverlapFab
        v-if="userAuthClass && toggleAdd"
        :condition="addDialog"
        :action="toggleAdd"
      />
    </v-card>
  </v-sheet>
</template>

<script>
import FilterSelect from '../fields/FilterSelect'
import OverlapFab from '../OverlapFab'
import ChipDisplay from '../chips/ChipDisplay'
export default {
  components: {
    FilterSelect,
    OverlapFab,
    ChipDisplay
  },
  props: {
    addDialog: {
      type: Boolean,
      required: false,
      default: false
    },
    toggleAdd: {
      type: Function,
      required: false
    },
    toggleDrawer: {
      type: Function,
      required: false
    },
    color: {
      type: String,
      required: false
    },
    filterLabel: {
      type: String,
      required: false
    },
    filterCols: {
      type: Array,
      required: false
    },
    filterCol: {
      type: String,
      required: false
    },
    filterBy: {
      type: String,
      required: false
    },
    lineView: {
      type: Boolean,
      required: false
    },
    onFilterColChange: {
      type: Function,
      required: false
    },
    onFilterByChange: {
      type: Function,
      required: false
    }
  },
  computed: {
    currentFilterBy: {
      get() {
        return this.filterBy
      },
      set(val) {
        this.onFilterByChange(val)
      }
    }
  }
}
</script>
