<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="d-flex justify-space-between">
      <div class="font-weight-medium grey--text text--darken-2">
        <v-icon color="primary" class="mr-2">
          {{ icon }}
        </v-icon>
        {{ title }}
      </div>
      <span class="text-truncate text-right" style="width:50%">
        {{ subtitle }}
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-text-field v-model="search" placeholder="Search"></v-text-field>
      <v-list dense>
        <v-list-item-group v-model="selected" multiple>
          <v-list-item
            v-for="item in listItems"
            :key="item.id"
            :value="item.id"
            v-show="!item.disabled"
            fluid
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  class="mt-0"
                  hide-details
                  :input-value="active"
                  color="primary"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <!-- <v-checkbox
            v-model="selected"
            :label="item.name"
            :value="item.id"
            v-show="!item.disabled"
            @change="() => select(selected)"
          ></v-checkbox> -->
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    select: {
      type: Function,
      required: true
    },
    value: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      selected: [],
      search: null,
      listItems: [...this.items]
    }
  },
  watch: {
    value() {
      this.selected = this.value
    },
    selected() {
      this.select(this.selected)
    },
    search(val) {
      for (const item of this.listItems) {
        if (item.name.toLowerCase().search(val.toLowerCase()) > -1)
          item.disabled = false
        else item.disabled = true
      }
    }
  }
}
</script>
