import Store from '@/store/computed/storeHelpers'
export default {
  computed: {
    defaultHeaders: Store.getSet({
      store: 'users',
      prop: 'userAuthClassDefaultHeaders'
    })
  },
  data() {
    return {
      allPossibleHeaders: [
        {
          text: 'Name',
          align: 'center',
          value: 'name',
          type: 'text',
          show: false
        },
        {
          text: 'User Read',
          align: 'center',
          value: 'userRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: 'User Write',
          align: 'center',
          value: 'userWrite',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: 'User Auth Class Read',
          align: 'center',
          value: 'userAuthClassRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: 'User Auth Class Write',
          align: 'center',
          value: 'userAuthClassWrite',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: 'Rate Read',
          align: 'center',
          value: 'rateRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: 'Rate Write',
          align: 'center',
          value: 'rateWrite',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.project.titleSingular} Read`,
          align: 'center',
          value: 'projectRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.project.titleSingular} Write`,
          align: 'center',
          value: 'projectWrite',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.source.titleSingular} Read`,
          align: 'center',
          value: 'sourceRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.source.titleSingular} Write`,
          align: 'center',
          value: 'sourceWrite',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.contractor.titleSingular} Read`,
          align: 'center',
          value: 'contractorRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.contractor.titleSingular} Write`,
          align: 'center',
          value: 'contractorWrite',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.vehicle.titleSingular} Read`,
          align: 'center',
          value: 'vehicleRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.vehicle.titleSingular} Write`,
          align: 'center',
          value: 'vehicleWrite',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.vehicleType.titleSingular} Read`,
          align: 'center',
          value: 'vehicleTypeRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.vehicleType.titleSingular} Write`,
          align: 'center',
          value: 'vehicleTypeWrite',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.driver.titleSingular} Read`,
          align: 'center',
          value: 'driverRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.driver.titleSingular} Write`,
          align: 'center',
          value: 'driverWrite',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.destination.titleSingular} Read`,
          align: 'center',
          value: 'destinationRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.destination.titleSingular} Write`,
          align: 'center',
          value: 'destinationWrite',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.itemType.titleSingular} Read`,
          align: 'center',
          value: 'itemTypeRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.itemType.titleSingular} Write`,
          align: 'center',
          value: 'itemTypeWrite',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: 'Audit Read',
          align: 'center',
          value: 'auditRead',
          type: 'bool',
          group: 'transaction',
          show: false
        },
        {
          text: 'Settings Read',
          align: 'center',
          value: 'settingsRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `Settings Write`,
          align: 'center',
          value: 'settingsWrite',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.policy.titleSingular} Read`,
          align: 'center',
          value: 'policyRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.policy.titleSingular} Write`,
          align: 'center',
          value: 'policyWrite',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.compliance.titleSingular} Read`,
          align: 'center',
          value: 'complianceRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.compliance.titleSingular} Write`,
          align: 'center',
          value: 'complianceWrite',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `Map Read`,
          align: 'center',
          value: 'mapRead',
          type: 'bool',
          group: 'master',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.allocation.titleSingular} Read`,
          align: 'center',
          value: 'allocationRead',
          type: 'bool',
          group: 'transaction',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.allocation.titleSingular} Write`,
          align: 'center',
          value: 'allocationWrite',
          type: 'bool',
          group: 'transaction',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.plan.titleSingular} Read`,
          align: 'center',
          value: 'planRead',
          type: 'bool',
          group: 'transaction',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.plan.titleSingular} Write`,
          align: 'center',
          value: 'planWrite',
          type: 'bool',
          group: 'transaction',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.docket.titleSingular} Read`,
          align: 'center',
          value: 'docketRead',
          type: 'bool',
          group: 'transaction',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.docket.titleSingular} Write`,
          align: 'center',
          value: 'docketWrite',
          type: 'bool',
          group: 'transaction',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.rcti.titleSingular} Read`,
          align: 'center',
          value: 'rctiRead',
          type: 'bool',
          group: 'transaction',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.rcti.titleSingular} Write`,
          align: 'center',
          value: 'rctiWrite',
          type: 'bool',
          group: 'transaction',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.bill.titleSingular} Read`,
          align: 'center',
          value: 'billRead',
          type: 'bool',
          group: 'transaction',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.bill.titleSingular} Write`,
          align: 'center',
          value: 'billWrite',
          type: 'bool',
          group: 'transaction',
          show: false
        },
        // {
        //   text: `${this.$store.state.global.pages.approvedBillLine.titleSingular} Read`,
        //   align: 'center',
        //   value: 'approvedBillLineRead',
        //   type: 'bool',
        //   group: 'transaction',
        //   show: false
        // },
        // {
        //   text: `${this.$store.state.global.pages.approvedBillLine.titleSingular} Write`,
        //   align: 'center',
        //   value: 'approvedBillLineWrite',
        //   type: 'bool',
        //   group: 'transaction',
        //   show: false
        // },

        {
          text: `${this.$store.state.global.pages.pod.titleSingular} Read`,
          align: 'center',
          value: 'podRead',
          type: 'bool',
          group: 'transaction',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.pod.titleSingular} Write`,
          align: 'center',
          value: 'podWrite',
          type: 'bool',
          group: 'transaction',
          show: false
        },
        {
          text: 'Edit Pricing',
          align: 'center',
          value: 'editPricing',
          type: 'bool',
          group: 'other',
          show: false
        },
        {
          text: 'Show Cost',
          align: 'center',
          value: 'showCost',
          type: 'bool',
          group: 'other',
          show: false
        },
        {
          text: 'Show Sell',
          align: 'center',
          value: 'showSell',
          type: 'bool',
          group: 'other',
          show: false
        }
      ]
    }
  }
}
