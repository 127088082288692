import Store from '@/store/computed/storeHelpers'
export default {
  computed: {
    defaultHeaders: Store.getSet({
      store: 'billingPeriods',
      prop: 'billingPeriodDefaultHeaders'
    })
  },
  data() {
    return {
      allPossibleHeaders: [
        { text: 'ID', value: 'id', type: 'text' },
        {
          text: 'Name',
          align: 'center',
          value: 'name',
          type: 'text',
          show: false
        },
        {
          text: 'Open',
          align: 'center',
          value: 'isOpen',
          type: 'boolean',
          show: false
        },
        { text: 'Entered By', value: 'createdBy.name', type: 'text' },
        { text: 'Updated By', value: 'updatedBy.name', type: 'text' }
      ]
    }
  }
}
