import Pages from './data/pages'
export default {
  namespaced: true,
  state: {
    possibleMainPages: Pages.mainPages,
    possibleMasterFilePages: Pages.masterFilePages,
    possibleSettingsPages: Pages.settingsPages,
    mainPages: [],
    masterFilePages: [],
    settingsPages: [],
    pages: Pages.pages,
    snackbarText: '',
    snackbarState: false,
    snackbarColor: 'blue',
    showVoid: false,
    showClosed: false,
    showAutoCreated: false,
    darkMode: false,
    enabled2FA: false,
    drawer: false,
    selectedUserId: null,
    selectedProjectId: null,
    selectedSourceId: null,
    selectedDeliveryStatus: null,
    selectedContractorId: null,
    selectedDriverId: null,
    selectedVehicleId: null,
    selectedVehicleTypeId: null,
    selectedDestinationId: null,
    selectedItemTypeId: null,
    selectedBillingPeriod: null,
    selectedPodType: null,
    selectedReportId: null,
    defaultSourceId: null,
    showCreated: false,
    showInactive: false,
    dateRangeStart: null,
    dateRangeEnd: null,
    dateRangeToggle: false,
    useSingleDate: false,
    loadedUserSettings: false
  },
  mutations: {
    modifySnackbarState(state, bool) {
      state.snackbarState = bool
    },
    setState(state, payload) {
      state[payload.prop] = payload.value
    },
    modifySnackbarText(state, text) {
      state.snackbarText = text
    },
    modifySnackbarColor(state, color) {
      state.snackbarColor = color
    }
  },
  actions: {
    displaySnackbar(context, payload) {
      context.commit('modifySnackbarText', payload.text)
      context.commit('modifySnackbarColor', payload.color)
      context.commit('modifySnackbarState', true)
    }
  }
}
