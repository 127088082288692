<template>
  <v-snackbar
    v-model="$store.state.global.snackbarState"
    right
    top
    :vertical="false"
  >
    {{ $store.state.global.snackbarText }}

    <template v-slot:action="{ attrs }">
      <v-btn
        :color="$store.state.global.snackbarColor"
        text
        v-bind="attrs"
        @click="close"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  methods: {
    close() {
      this.$store.commit('global/modifySnackbarState', false)
    }
  }
}
</script>
