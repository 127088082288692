<template>
  <v-card>
    <v-card-text class="pb-0">
      <v-card-title class="px-0 pb-1 font-weight-regular">
        Create {{ $store.state.global.pages.bill.title }} for
        <span class="font-weight-bold px-1">
          {{ selectedProject.name }}: {{ selectedBillingPeriod }}
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <template v-if="previewData">
        <v-card-subtitle>
          {{ linesSubtitle }}
          <div v-if="!!previewData.adjustmentTripCount">
            <span class="indigo--text font-italic">
              {{ creditsSubtitle }}
            </span>
          </div>
        </v-card-subtitle>
        <v-col cols="12" v-if="!!previewData.incompleteDockets.length">
          <v-alert
            :value="true"
            type="error"
            class="mb-3"
            outlined
            style="border-radius: 4px;"
          >
            {{ previewData.incompleteDockets.length }} unconfirmed excluded!
          </v-alert>
          <div>{{ $store.state.global.pages.docket.titleSingular }} IDs</div>
          <div
            v-for="docketId in previewData.incompleteDockets"
            :key="docketId"
            class="text-xs-left body-2 grey--text"
          >
            <div class="grey--text text--darken-1">
              {{ docketId }}
            </div>
          </div>
        </v-col>
        <v-divider></v-divider>
        <v-card-subtitle>
          {{ billByText }}
        </v-card-subtitle>
      </template>
      <div v-if="previewData">
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(subject, i) in previewData.subjects"
            :key="i"
          >
            <v-expansion-panel-header>
              <div class="d-flex align-center">
                <span class="ml-2">
                  {{ subject.name }}
                </span>
              </div>
              <template v-slot:actions>
                <span class="pt-2"> ${{ subject.total.toFixed(2) }} </span>
                <v-icon>
                  mdi-chevron-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list two-line dense class="pt-0">
                <v-divider></v-divider>

                <v-list-item :href="`mailto:${subject.email}`">
                  <v-list-item-action>
                    <v-icon color="primary">mdi-email</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="body-2">
                      Email
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ subject.email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item :href="`tel:${subject.contactPhone}`">
                  <v-list-item-action>
                    <v-icon color="primary">mdi-phone</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="body-2">
                      Phone
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ subject.contactPhone }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
              </v-list>
              <div class="px-3 pb-3 pt-1">
                Lines: {{ subject.docketLineCount }}
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <br />
        <span v-if="previewData.exportTotal">
          Total Export Amount: ${{ previewData.exportTotal.toFixed(2) }}
        </span>
      </div>

      <div v-else class="ma-3 pt-5 subtitle-2 red--text text--lighten-3">
        <div v-if="!loading">
          <div v-if="selectedProject.billType != 'DIRECT'">
            {{ selectedProject.name }} must be billed through Approved Billing
            Register
          </div>
          <div>No transactions found!</div>
          <div>
            Please select a different project or billing period combination.
          </div>
        </div>
        <div v-else class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </div>

      <br />
      <span>Notes</span><br />
      <span
        >These notes will apply for all
        {{ $store.state.global.pages.bill.title }} about to be created</span
      >
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="internalNotes"
            prepend-icon="mdi-note"
            label="Internal Notes"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="invoiceNotes"
            prepend-icon="mdi-note"
            label="Invoice Notes"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="close">
        Close
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="submitForm"
        :disabled="!previewData"
        :loading="loading"
        >Create</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Store from '../../store/computed/storeHelpers'
import { addBillsDirect } from '../../services/requests/bills'
import { getPreviewData } from '../../services/requests/bills'

export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    projects: {
      type: Array,
      required: false
    }
  },
  mounted() {
    this.previewData = null
    this.getPreviewData()
  },
  data() {
    return {
      loading: false,
      internalNotes: null,
      invoiceNotes: null,
      previewData: null
    }
  },
  watch: {
    dialog(val) {
      if (val) {
        this.previewData = null
        this.getPreviewData()
      }
    }
  },
  computed: {
    selectedBillingPeriod: Store.getSet({
      store: 'global',
      prop: 'selectedBillingPeriod'
    }),
    selectedProjectId: Store.getSet({
      store: 'global',
      prop: 'selectedProjectId'
    }),
    settings: Store.getSet({
      store: 'settings',
      prop: 'settings'
    }),
    selectedProject() {
      if (this.selectedProjectId && this.projects.length) {
        return this.projects.find(({ id }) => id == this.selectedProjectId)
      } else {
        return {}
      }
    },
    billByText() {
      if (this.settings?.billBy) {
        return `Subjects (${
          this.$store.state.global.pages[this.settings.billBy.toLowerCase()]
            .titleSingular
        })`
      }
      return ''
    },
    linesSubtitle() {
      if (this.previewData) {
        return `${this.previewData.docketLineCount} lines spanning ${
          this.previewData.docketCount
        } 
        ${this.$store.state.global.pages.docket.titleSingular.toLowerCase()}(s)`
      }
      return ''
    },
    creditsSubtitle() {
      if (this.previewData) {
        return `Includes ${this.previewData.adjustmentTripCount} credit or adjustment lines`
      }
      return ''
    }
  },
  methods: {
    async getPreviewData() {
      if (!this.selectedBillingPeriod || !this.selectedProjectId) return
      try {
        this.loading = true
        const payload = {
          billingPeriod: this.selectedBillingPeriod,
          projectId: this.selectedProjectId
        }
        const result = await getPreviewData(payload)
        if (result) {
          this.previewData = result.data.result
        }
        this.loading = false
        return result
      } catch (err) {
        console.log(err)
        this.loading = false
      }
    },
    submitForm() {
      if (!this.previewData.docketLineIds.length) {
        this.snack({ color: 'amber', text: 'No docket lines found!' })
        return
      }
      this.addBills()
    },
    async addBills() {
      try {
        this.loading = true
        const payload = {
          internalNotes: this.internalNotes,
          invoiceNotes: this.invoiceNotes,
          projectId: this.selectedProjectId,
          billingPeriod: this.selectedBillingPeriod
        }
        const result = await addBillsDirect(payload)
        if (result) {
          this.resetForm()
          this.close()
          const billIds = result.data.result.createdBills.map(
            createdBill => createdBill.bill.id
          )
          const billIdsString = JSON.stringify(billIds)
          this.$router.push({ name: 'bill', query: { ids: billIdsString } })
        }
        this.loading = false
      } catch (err) {
        console.log(err)
        this.snack({ color: 'red', text: err.message })
        this.loading = false
      }
    },
    resetForm() {
      this.internalNotes = ''
      this.invoiceNotes = ''
    }
  }
}
</script>

<style scoped>
::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
}
</style>
