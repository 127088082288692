import Store from '@/store/computed/storeHelpers'
import { getContractorsLite } from '../../../requests/contractors'
export default {
  mounted() {
    this.getContractorsLite()
  },
  data() {
    return {
      loadedContractorsLite: false
    }
  },
  computed: {
    contractorsLite: Store.getSet({
      store: 'contractors',
      prop: 'contractorsLite'
    }),
    contractorsLiteActive: Store.getSet({
      store: 'contractors',
      prop: 'contractorsLiteActive'
    }),
    loadingContractorsLite: Store.getSet({
      store: 'contractors',
      prop: 'loadingContractorsLite'
    })
  },
  methods: {
    async getContractorsLite() {
      try {
        this.loadingContractorsLite = true
        const result = await getContractorsLite()
        if (result) {
          this.contractorsLite = result.data.result
          this.contractorsLiteActive = result.data.result.filter(i => i.active)
        }
        this.loadedContractorsLite = true
        this.loadingContractorsLite = false
      } catch (err) {
        console.log(err)
        this.loadingContractorsLite = false
      }
    }
  }
}
