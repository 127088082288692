export default {
  namespaced: true,
  state: {
    loading: false,
    accessToken: null,
    user: {},
    userAuthClass: {},
    userSettings: {}
  },
  mutations: {
    setState(state, payload) {
      state[payload.prop] = payload.value
    }
  },
  actions: {
    initUser(context, payload) {
      context.commit('setState', { prop: 'user', value: payload.user })
      context.commit('setState', {
        prop: 'userAuthClass',
        value: payload.userAuthClass
      })
      context.commit('setState', {
        prop: 'userSettings',
        value: payload.userSettings
      })
    }
  },
  getters: {
    getUser: state => state.user,
    getLoading: state => state.loading,
    isAdmin: state => state.userAuthClass.id == 1
  }
}
