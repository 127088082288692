import Store from '@/store/computed/storeHelpers'
import { getReports } from '../../requests/reports'
export default {
  mounted() {
    this.getReports()
  },
  data() {
    return {
      loadedReports: false
    }
  },
  computed: {
    reports: Store.getSet({
      store: 'reports',
      prop: 'reports'
    }),
    loadingReports: Store.getSet({
      store: 'reports',
      prop: 'loadingReports'
    })
  },
  methods: {
    async getReports() {
      try {
        this.loadingReports = true
        const result = await getReports()
        if (result) {
          this.reports = result.data.result
        }
        this.loadedReports = true
        this.loadingReports = false
      } catch (err) {
        console.log(err)
        this.loadingReports = false
      }
    }
  }
}
