<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="py-0">
        <ControlCard>
          <template v-slot:header>
            <CardHeader
              :title="$store.state.global.pages[$route.name].title"
              :icon="$store.state.global.pages[$route.name].icon"
            />
          </template>
        </ControlCard>
      </v-col>
      <v-col cols="12">
        <SettingsView />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ControlCard from '../../components/misc/shared/ControlCard'
import CardHeader from '../../components/misc/shared/CardHeader'
import SettingsView from '../../components/settings/SettingsView'
export default {
  components: {
    ControlCard,
    CardHeader,
    SettingsView
  },
  data() {
    return {
      drawer: false,
      addDialog: false
    }
  },
  methods: {
    toggleAdd() {
      this.addDialog = !this.addDialog
    }
  }
}
</script>
