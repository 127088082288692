import { makeShortDate, makeTime } from '../../utils/FormatDates'
class Rcti {
  constructor(rcti) {
    Object.keys(rcti).forEach(key => {
      this[key] = rcti[key]
      this[`_${key}`] = rcti[key]
      const formats = [
        {
          condition: ['date', 'createdAt', 'updatedAt'].includes(key),
          format: val => makeShortDate(val)
        },
        {
          condition: ['start', 'end'].includes(key),
          format: val => makeTime(new Date(val))
        },
        {
          condition: ['total', 'qty', 'lineHours'].includes(key),
          format: val => Number(val).toFixed(2)
        }
      ]

      formats.forEach(item => {
        if (item.condition && rcti[key])
          this[`_${key}`] = item.format(rcti[key])
      })
    })
  }
  get closed() {
    return this.status == 'CLOSED'
  }
  get approved() {
    return this.status == 'APPROVED'
  }
  get hasXero() {
    return !!this.transactionId
  }
  get sent() {
    return this.status == 'SENT'
  }
}

export default Rcti
