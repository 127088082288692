import Api from '@/services/api.js'

export const addProject = payload => {
  return Api.post('projects/', payload)
}
export const getProjects = payload => {
  return Api.post('projects/get', payload)
}
export const getProjectsLite = () => {
  return Api.get('projects/')
}
export const updateProject = (id, payload) => {
  return Api.put(`projects/${id}`, payload)
}
export const deleteProject = id => {
  return Api.delete(`projects/${id}`)
}
