<template>
  <v-menu
    nudge-right="90"
    :open-on-hover="!$vuetify.breakpoint.xsOnly"
    v-if="items"
    rounded="lg"
    open-delay="200"
    close-delay="350"
  >
    <template v-slot:activator="{ on, value }">
      <v-col class="d-flex justify-center drawer-icon" cols="12" v-on="on">
        <v-row>
          <v-col cols="12" class="px-0 pb-0 pt-4 d-flex justify-center">
            <v-icon :color="value || isActive ? 'primary' : ''">
              {{ isActive ? icon : iconAlt || icon }}
            </v-icon>
          </v-col>
          <v-col cols="12" class="px-0 pt-0 pb-4 d-flex justify-center">
            <span
              class="body-2"
              :class="value || isActive ? 'primary--text' : ''"
              >{{ title }}</span
            >
          </v-col>
        </v-row>
      </v-col>
    </template>
    <v-list>
      <v-list-item-group>
        <div v-for="item in items" :key="item.title">
          <v-list-item
            :value="item.title"
            ripple
            router
            :to="{ name: item.route }"
            class="drawer-icon"
          >
            <v-list-item-icon>
              <v-icon :color="item.route == $route.name ? 'primary' : ''">
                {{ item.route == $route.name ? item.icon : item.iconAlt }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              :class="item.route == $route.name ? 'primary--text' : ''"
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
          <v-divider class="mx-3" v-if="item.endOfCategory"></v-divider>
        </div>
      </v-list-item-group>
    </v-list>
  </v-menu>
  <v-col
    class="d-flex justify-center drawer-icon"
    cols="12"
    @click="route !== $route.name ? $router.push({ name: route }) : null"
    v-else
  >
    <v-row>
      <v-col cols="12" class="px-0 pb-0 pt-4 d-flex justify-center">
        <v-icon :color="isActive ? 'primary' : ''">
          {{ isActive ? icon : iconAlt || icon }}
        </v-icon>
      </v-col>
      <v-col cols="12" class="px-0 pt-0 pb-4 d-flex justify-center">
        <span class="body-2" :class="isActive ? 'primary--text' : ''">{{
          title
        }}</span>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: {
    icon: String,
    iconAlt: String,
    title: String,
    route: String,
    items: Array
  },
  computed: {
    isActive() {
      if (!this.items) {
        return this.$route.name === this.route
      } else {
        return this.items.some(({ route }) => this.$route.name == route)
      }
    }
  }
}
</script>

<style>
.drawer-icon:hover {
  color: #5cab99;
  transition: all 0.2s ease;
  cursor: pointer;
}
.drawer-icon:hover .v-list-item__title {
  color: #5cab99;
  transition: all 0.2s ease;
  cursor: pointer;
}
.drawer-icon:hover .v-icon {
  color: #5cab99;
  transition: all 0.2s ease;
  cursor: pointer;
}
</style>
