import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    projects: [],
    projectsLite: [],
    projectsLiteActive: [],
    loadingProjects: false
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
