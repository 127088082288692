import Bill from './bill'
import { makeShortDate, makeTime } from '../../utils/FormatDates'

class BillLine {
  constructor(billLine) {
    Object.keys(billLine).forEach(key => {
      this[key] = billLine[key]
      if (key == 'bill') this[`_${key}`] = this[key] = new Bill(billLine[key])
      else this[`_${key}`] = billLine[key]

      const formats = [
        {
          condition: ['date', 'createdAt', 'updatedAt'].includes(key),
          format: val => makeShortDate(val)
        },
        {
          condition: ['start', 'end'].includes(key),
          format: val => makeTime(new Date(val))
        },
        {
          condition: ['sellTotal', 'costTotal', 'qty', 'hours'].includes(key),
          format: val => Number(val).toFixed(2)
        }
      ]

      formats.forEach(item => {
        if (item.condition && billLine[key])
          this[`_${key}`] = item.format(billLine[key])
      })
    })
  }
}

export default BillLine
