<template>
  <v-container
    fluid
    v-if="$store.state.user.userAuthClass[`${$route.name}Read`]"
  >
    <v-row>
      <v-col cols="12" class="py-0">
        <ControlCard
          :addDialog="addDialog"
          :toggleAdd="toggleAdd"
          :toggleDrawer="() => (drawer = !drawer)"
          :filterCols="filterCols"
          :filterCol="filterCol"
          :filterBy="filterBy"
          :onFilterColChange="val => (filterCol = val)"
          :onFilterByChange="val => (filterBy = val)"
        >
          <template v-slot:header>
            <CardHeader
              :title="title"
              :icon="$store.state.global.pages[$route.name].icon"
              :actions="controlActions"
            />
          </template>
        </ControlCard>
      </v-col>
      <v-col cols="12" v-if="loadedUserSettings">
        <PlanView :vehicles="vehiclesLite" :drivers="driversLite" />
      </v-col>
    </v-row>

    <FilterDrawer
      v-model="drawer"
      :users="usersLite"
      :projects="projectsLite"
      :sources="sourcesLite"
    />

    <v-dialog v-model="addDialog" fullscreen v-if="loadedUserSettings">
      <PlanAdd
        :toggleAdd="toggleAdd"
        :sources="sourcesLiteActive"
        :vehicles="vehiclesWithDriverLite"
        :drivers="driversLiteActive"
        :destinations="destinationsLite"
        :itemTypes="itemTypesLite"
      />
    </v-dialog>

    <v-dialog
      v-model="addMoreDialog"
      max-width="400px"
      @keydown.enter="addMore()"
      @keydown.esc="addMoreDialog = false"
    >
      <v-card>
        <v-card-title class="headline">Add another?</v-card-title>
        <v-card-text>
          Would you like to add another
          {{ $store.state.global.pages.plan.titleSingular }}
          now?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="addMore()">Yes</v-btn>
          <v-btn text @click="addMoreDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="importDialog" width="900px">
      <ImportCsv
        :title="`Import ${$store.state.global.pages.plan.title}`"
        :close="() => (importDialog = false)"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Store from '../../store/computed/storeHelpers'
import getUsersLite from '../../services/mixins/getMasterFiles/lite/getUsersLite'
import getContractorsLite from '../../services/mixins/getMasterFiles/lite/getContractorsLite'
import getProjectsLite from '../../services/mixins/getMasterFiles/lite/getProjectsLite'
import getSourcesLite from '../../services/mixins/getMasterFiles/lite/getSourcesLite'
import getVehiclesWithDriverLite from '../../services/mixins/getMasterFiles/lite/getVehiclesWithDriverLite'
import getVehiclesLite from '../../services/mixins/getMasterFiles/lite/getVehiclesLite'
import getDriversLite from '../../services/mixins/getMasterFiles/lite/getDriversLite'
import getDestinationsLite from '../../services/mixins/getMasterFiles/lite/getDestinationsLite'
import getItemTypesLite from '../../services/mixins/getMasterFiles/lite/getItemTypesLite'
import getBillingPeriods from '../../services/mixins/getMasterFiles/getBillingPeriods'

import ControlCard from '../../components/misc/shared/ControlCard'
import CardHeader from '../../components/misc/shared/CardHeader'
import PlanView from '../../components/plans/PlanView'
import PlanAdd from '../../components/plans/PlanAdd'
import FilterDrawer from '../../components/misc/shared/FilterDrawer'
import ImportCsv from '../../components/misc/shared/importCsv'

export default {
  mixins: [
    getProjectsLite,
    getUsersLite,
    getContractorsLite,
    getSourcesLite,
    getVehiclesWithDriverLite,
    getVehiclesLite,
    getDriversLite,
    getDestinationsLite,
    getItemTypesLite,
    getBillingPeriods
  ],
  components: {
    ControlCard,
    CardHeader,
    PlanView,
    PlanAdd,
    FilterDrawer,
    ImportCsv
  },
  data() {
    return {
      drawer: false,
      addDialog: false,
      addDocketDialog: false,
      importDialog: false,
      addMoreDialog: false,
      selectedPlan: null
    }
  },
  mounted() {
    this.setDocId()
  },
  computed: {
    loadedUserSettings: Store.getSet({
      store: 'global',
      prop: 'loadedUserSettings'
    }),
    filterCol: Store.getSet({ store: 'plans', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'plans', prop: 'filterBy' }),
    title() {
      return this.$store.state.global.pages[this.$route.name].title
    },
    controlActions() {
      const authLimitActions = []
      let actions = [
        {
          action: () => (this.drawer = !this.drawer),
          icon: 'mdi-filter-variant-plus',
          toolTipText: 'Filters'
        }
      ]
      if (this.userAuthClass) return [...authLimitActions, ...actions]
      return actions
    },
    filterCols() {
      const cols = [
        {
          value: 'source.name',
          text: this.$store.state.global.pages.source.titleSingular
        },
        {
          value: 'vehicle.contractor.name',
          text: this.$store.state.global.pages.contractor.titleSingular
        },
        {
          value: 'driver.name',
          text: this.$store.state.global.pages.driver.titleSingular
        },
        {
          value: 'vehicle.name',
          text: this.$store.state.global.pages.vehicle.titleSingular
        },
        {
          value: 'vehicle.vehicleType.name',
          text: this.$store.state.global.pages.vehicleType.titleSingular
        },
        {
          value: 'plan.id',
          text: 'ID'
        }
      ]
      return cols
    }
  },
  methods: {
    toggleAdd(addMore) {
      if (this.licenceExpired) {
        this.snack({
          text: `Your account is on hold. This feature has been disabled`,
          color: 'red'
        })
        return
      }
      this.addDialog = !this.addDialog
      if (!this.addDialog && addMore) this.addMoreDialog = true
    },
    toggleAddDocket() {
      this.addDocketDialog = !this.addDocketDialog
    },
    setDocId() {
      if (this.$route.query.id) {
        this.filterCol = 'plan.id'
        this.filterBy = this.$route.query.id.toString()
      }
    },
    addMore() {
      this.addMoreDialog = false
      this.toggleAdd(false)
    },
    createDocketFromPlan(item) {
      if (this.licenceExpired) {
        this.snack({
          text: `Your account is on hold. This feature has been disabled`,
          color: 'red'
        })
        return
      }
      this.selectedPlan = item
      this.addDocketDialog = true
    }
  }
}
</script>
