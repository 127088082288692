import Store from '../../../store/computed/storeHelpers'
import Docket from '@/services/models/docket'
import { getActiveDockets } from '../../requests/dockets'
export default {
  mounted() {
    this.getOpenDockets()
  },
  data() {
    return {
      count: 0
    }
  },
  computed: {
    openDockets: Store.getSet({ store: 'dockets', prop: 'openDockets' }),
    loadingOpenDockets: Store.getSet({
      store: 'dockets',
      prop: 'loadingOpenDockets'
    })
  },
  methods: {
    async getOpenDockets() {
      try {
        this.loadingOpenDockets = true
        const result = await getActiveDockets()
        if (result) {
          const items = result.data.result
          const dockets = []
          for (const item of items) {
            const docket = new Docket(item.docket)
            docket.coordinates = [item.gpsLines[0].long, item.gpsLines[0].lat]
            docket.direction = item.gpsLines[0].direction
            docket.speed = item.gpsLines[0].speed
            docket.distance = item.gpsLines[0]?.odometer
              ? (item.gpsLines[0].odometer - item.gpsLines[1].odometer) / 1000
              : 0
            docket.startOdometer = item.gpsLines[1].odometer
            dockets.push(docket)
          }
          this.openDockets = dockets
          this.setColors()
          for (const { id } of this.openDockets)
            if (!this.docketIds.includes(id)) this.docketIds.push(id)
        }
        this.loadingOpenDockets = false
      } catch (err) {
        console.log(err)
        this.loadingOpenDockets = false
      }
    }
  }
}
