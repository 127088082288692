<template>
  <v-card flat class="card-outlined">
    <v-data-table
      :headers="headers"
      :items="items"
      no-data-text="No lines found"
      hide-default-footer
      disable-pagination
      :loading="loadingLines"
      mobile-breakpoint="0"
    >
      <template v-slot:loading>
        Loading records...
      </template>
      <template v-slot:item="{ item }">
        <Row
          :headers="headers"
          :item="item"
          :key="item.id"
          showDeliveryDetails
          :hideDetails="hideDetails"
          :defaultDialog="showEdit ? 'editDialog' : 'podDetailsDialog'"
          :showEdit="showEdit"
          :closeParent="closeParent"
          :selectedId="selectedId"
        >
          <template v-slot:podDetails="{ dialog, close }">
            <PlanLineDetails
              v-if="dialog"
              :item="item"
              :dialog="dialog"
              :close="close"
            />
          </template>
          <template v-if="onEdit" v-slot:edit="{ dialog, close }">
            <PlanLineEdit
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :action="onEdit"
              :close="close"
            />
          </template>
          <template v-if="onDelete" v-slot:delete="{ dialog, close }">
            <DeleteDialog
              :dialog="dialog"
              :page="`${$store.state.global.pages.plan.titleSingular} line`"
              :subject="item"
              :action="onDelete"
              :close="close"
            />
          </template>
        </Row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Row from '../../misc/row/Row'
import PlanLineEdit from '@/components/plans/lines/PlanLineEdit'
import PlanLineDetails from '@/components/plans/lines/PlanLineDetails'
import DeleteDialog from '../../misc/DeleteDialog'

export default {
  components: {
    Row,
    PlanLineEdit,
    PlanLineDetails,
    DeleteDialog
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    onEdit: {
      type: Function,
      required: false
    },
    onDelete: {
      type: Function,
      required: false
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: true
    },
    loadingLines: {
      type: Boolean,
      required: false,
      default: false
    },
    closeParent: {
      type: Function,
      required: false
    },
    selectedId: {
      type: Number,
      required: false
    }
  }
}
</script>
