import Store from '@/store/computed/storeHelpers'
import {
  createUserSettings,
  updateSetting
} from '@/services/requests/userSettings'
export default {
  mounted() {
    this.setInitialSettings()
  },
  watch: {
    selectedUserId(val) {
      this.changeSetting('selectedUserId', val)
    },
    selectedDeliveryStatus(val) {
      this.changeSetting('selectedDeliveryStatus', val)
    },
    selectedPodType(val) {
      this.changeSetting('selectedPodType', val)
    },
    selectedReportId(val) {
      this.changeSetting('selectedReportId', val)
    },
    showClosed(val) {
      this.changeSetting('showClosed', val)
    },
    showVoid(val) {
      this.changeSetting('showVoid', val)
    },
    showAutoCreated(val) {
      this.changeSetting('showAutoCreated', val)
    },
    selectedSourceId(val) {
      this.changeSetting('selectedSourceId', val)
    },
    selectedProjectId(val) {
      this.changeSetting('selectedProjectId', val)
    },
    selectedBillingPeriod(val) {
      this.changeSetting('selectedBillingPeriod', val)
    },
    selectedDestinationId(val) {
      this.changeSetting('selectedDestinationId', val)
    },
    selectedContractorId(val) {
      this.changeSetting('selectedContractorId', val)
    },
    selectedDriverId(val) {
      this.changeSetting('selectedDriverId', val)
    },
    selectedVehicleId(val) {
      this.changeSetting('selectedVehicleId', val)
    },
    darkMode(val) {
      this.$vuetify.theme.dark = val
      this.changeSetting('darkMode', val)
    },
    enabled2FA(val) {
      this.changeSetting('enabled2FA', val)
    },
    dateRangeStart(val) {
      this.changeSetting('dateRangeStart', val)
    },
    dateRangeEnd(val) {
      this.changeSetting('dateRangeEnd', val)
    },
    defaultSourceId(val) {
      this.changeSetting('defaultSourceId', val)
    },
    docketDefaultHeaders(val) {
      this.changeSetting('docketDefaultHeaders', val)
    },
    allocationDefaultHeaders(val) {
      this.changeSetting('allocationDefaultHeaders', val)
    },
    planDefaultHeaders(val) {
      this.changeSetting('planDefaultHeaders', val)
    },
    docketLineDefaultHeaders(val) {
      this.changeSetting('docketLineDefaultHeaders', val)
    },
    allocationLineDefaultHeaders(val) {
      this.changeSetting('allocationLineDefaultHeaders', val)
    },
    allocationStagingLineDefaultHeaders(val) {
      this.changeSetting('allocationStagingLineDefaultHeaders', val)
    },
    podDefaultHeaders(val) {
      this.changeSetting('podDefaultHeaders', val)
    },
    rateDefaultHeaders(val) {
      this.changeSetting('rateDefaultHeaders', val)
    },
    complianceDefaultHeaders(val) {
      this.changeSetting('complianceDefaultHeaders', val)
    },
    rctiDefaultHeaders(val) {
      this.changeSetting('rctiDefaultHeaders', val)
    },
    billDefaultHeaders(val) {
      this.changeSetting('billDefaultHeaders', val)
    },
    approvedBillLineDefaultHeaders(val) {
      this.changeSetting('approvedBillLineDefaultHeaders', val)
    },
    userAuthClassDefaultHeaders(val) {
      this.changeSetting('userAuthClassDefaultHeaders', val)
    },
    docketLineView(val) {
      this.changeSetting('docketLineView', val)
    },
    allocationLineView(val) {
      this.changeSetting('allocationLineView', val)
    }
  },
  computed: {
    loadedUserSettings: Store.getSet({
      store: 'global',
      prop: 'loadedUserSettings'
    }),
    selectedUserId: Store.getSet({ store: 'global', prop: 'selectedUserId' }),
    selectedDeliveryStatus: Store.getSet({
      store: 'global',
      prop: 'selectedDeliveryStatus'
    }),
    selectedPodType: Store.getSet({ store: 'global', prop: 'selectedPodType' }),
    selectedReportId: Store.getSet({
      store: 'global',
      prop: 'selectedReportId'
    }),
    selectedSourceId: Store.getSet({
      store: 'global',
      prop: 'selectedSourceId'
    }),
    selectedBillingPeriod: Store.getSet({
      store: 'global',
      prop: 'selectedBillingPeriod'
    }),
    selectedProjectId: Store.getSet({
      store: 'global',
      prop: 'selectedProjectId'
    }),
    selectedDestinationId: Store.getSet({
      store: 'global',
      prop: 'selectedDestinationId'
    }),
    selectedContractorId: Store.getSet({
      store: 'global',
      prop: 'selectedContractorId'
    }),
    selectedDriverId: Store.getSet({
      store: 'global',
      prop: 'selectedDriverId'
    }),
    selectedVehicleId: Store.getSet({
      store: 'global',
      prop: 'selectedVehicleId'
    }),
    showClosed: Store.getSet({ store: 'global', prop: 'showClosed' }),
    showVoid: Store.getSet({ store: 'global', prop: 'showVoid' }),
    showAutoCreated: Store.getSet({ store: 'global', prop: 'showAutoCreated' }),
    darkMode: Store.getSet({ store: 'global', prop: 'darkMode' }),
    dateRangeStart: Store.getSet({ store: 'global', prop: 'dateRangeStart' }),
    dateRangeEnd: Store.getSet({ store: 'global', prop: 'dateRangeEnd' }),
    defaultSourceId: Store.getSet({ store: 'global', prop: 'defaultSourceId' }),
    enabled2FA: Store.getSet({ store: 'global', prop: 'enabled2FA' }),
    docketDefaultHeaders: Store.getSet({
      store: 'dockets',
      prop: 'docketDefaultHeaders'
    }),
    allocationDefaultHeaders: Store.getSet({
      store: 'allocations',
      prop: 'allocationDefaultHeaders'
    }),
    docketLineDefaultHeaders: Store.getSet({
      store: 'dockets',
      prop: 'docketLineDefaultHeaders'
    }),
    planDefaultHeaders: Store.getSet({
      store: 'plans',
      prop: 'planDefaultHeaders'
    }),
    allocationLineDefaultHeaders: Store.getSet({
      store: 'allocations',
      prop: 'allocationLineDefaultHeaders'
    }),
    allocationStagingLineDefaultHeaders: Store.getSet({
      store: 'allocations',
      prop: 'allocationStagingLineDefaultHeaders'
    }),
    podDefaultHeaders: Store.getSet({
      store: 'pods',
      prop: 'podDefaultHeaders'
    }),
    rateDefaultHeaders: Store.getSet({
      store: 'rates',
      prop: 'rateDefaultHeaders'
    }),
    complianceDefaultHeaders: Store.getSet({
      store: 'compliances',
      prop: 'complianceDefaultHeaders'
    }),
    rctiDefaultHeaders: Store.getSet({
      store: 'rctis',
      prop: 'rctiDefaultHeaders'
    }),
    billDefaultHeaders: Store.getSet({
      store: 'bills',
      prop: 'billDefaultHeaders'
    }),
    approvedBillLineDefaultHeaders: Store.getSet({
      store: 'approvedBillLines',
      prop: 'approvedBillLineDefaultHeaders'
    }),
    userAuthClassDefaultHeaders: Store.getSet({
      store: 'users',
      prop: 'userAuthClassDefaultHeaders'
    }),
    docketLineView: Store.getSet({ store: 'dockets', prop: 'lineView' }),
    allocationLineView: Store.getSet({
      store: 'allocations',
      prop: 'lineView'
    }),
    userSettings: Store.getSet({ store: 'user', prop: 'userSettings' })
  },
  beforeDestroy() {
    this.clearSettings()
  },
  methods: {
    async setInitialSettings() {
      if (!this.userSettings) this.userSettings = await createUserSettings()
      for (const setting of Object.keys(this.userSettings)) {
        if (typeof this.userSettings[setting] != 'undefined') {
          if (setting.includes('Headers') || setting == 'selectedPodType') {
            if (this.userSettings[setting]) {
              this[setting] = JSON.parse(this.userSettings[setting])
            }
          } else this[setting] = this.userSettings[setting]
          if (setting == 'darkMode') this.$vuetify.theme.dark = this[setting]
        }
      }
      this.loadedUserSettings = true
    },
    async clearSettings() {
      for (const setting of Object.keys(this.userSettings)) {
        if (typeof this.userSettings[setting] != 'undefined') {
          if (!setting.includes('Headers') && setting != 'selectedPodType') {
            if (typeof this[setting] == 'boolean') this[setting] = false
            else this[setting] = null
            if (setting == 'darkMode') this.$vuetify.theme.dark = false
          }
        }
      }
      this.loadedUserSettings = true
    },
    async changeSetting(field, value) {
      const userSettings = this.$store.state.user.userSettings
      if (!userSettings) await createUserSettings()
      if (field.includes('Headers') || field == 'selectedPodType') {
        value = value ? JSON.stringify(value) : null
      }
      if (userSettings[field] === value) return
      try {
        await updateSetting({ field: field, value: value })
        this.$store.state.user.userSettings[field] = value
      } catch (err) {
        console.log(err)
      }
    }
  }
}
