<template>
  <v-container
    fluid
    v-if="$store.state.user.userAuthClass[`${$route.name}Read`]"
  >
    <v-row>
      <v-col cols="12" class="py-0">
        <ControlCard
          :toggleDrawer="() => (drawer = !drawer)"
          :filterCols="filterCols"
          :filterCol="filterCol"
          :filterBy="filterBy"
          :onFilterColChange="val => (filterCol = val)"
          :onFilterByChange="val => (filterBy = val)"
        >
          <template v-slot:header>
            <CardHeader
              :title="title"
              :icon="$store.state.global.pages[$route.name].icon"
              :actions="controlActions"
            />
          </template>
        </ControlCard>
      </v-col>
      <v-col cols="12" v-if="loadedUserSettings">
        <RctiView />
      </v-col>
    </v-row>

    <FilterDrawer
      :contractors="contractorsLite"
      :projects="projectsLite"
      :users="usersLite"
      v-model="drawer"
    />
  </v-container>
</template>

<script>
import Store from '../../store/computed/storeHelpers'

import ControlCard from '../../components/misc/shared/ControlCard'
import CardHeader from '../../components/misc/shared/CardHeader'
import RctiView from '../../components/rctis/RctiView'
import getUsersLite from '../../services/mixins/getMasterFiles/lite/getUsersLite'
import getContractorsLite from '@/services/mixins/getMasterFiles/lite/getContractorsLite'
import getProjectsLite from '../../services/mixins/getMasterFiles/lite/getProjectsLite'
import getBillingPeriods from '../../services/mixins/getMasterFiles/getBillingPeriods'

import FilterDrawer from '../../components/misc/shared/FilterDrawer'
export default {
  mixins: [
    getUsersLite,
    getContractorsLite,
    getProjectsLite,
    getBillingPeriods
  ],
  components: {
    ControlCard,
    CardHeader,
    RctiView,
    FilterDrawer
  },
  data() {
    return {
      drawer: false
    }
  },
  computed: {
    loadedUserSettings: Store.getSet({
      store: 'global',
      prop: 'loadedUserSettings'
    }),
    filterCol: Store.getSet({ store: 'rctis', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'rctis', prop: 'filterBy' }),
    title() {
      return this.$store.state.global.pages[this.$route.name].title
    },
    controlActions() {
      return [
        {
          action: () => (this.drawer = !this.drawer),
          icon: 'mdi-filter-variant-plus',
          toolTipText: 'Filters',
          color: 'grey'
        }
      ]
    },
    filterCols() {
      const cols = [
        {
          value: 'project.name',
          text: this.$store.state.global.pages.project.titleSingular
        },
        {
          value: 'contractor.name',
          text: this.$store.state.global.pages.contractor.titleSingular
        },
        {
          value: 'externalRef',
          text: 'Reference'
        },
        {
          value: 'internalNotes',
          text: 'Internal notes'
        },
        {
          value: 'invoiceNotes',
          text: 'Invoice notes'
        },
        {
          value: 'rcti.id',
          text: 'ID'
        }
      ]
      return cols
    }
  }
}
</script>
