import PolicyValidation from '../../validation/policy'
export default {
  mixins: [PolicyValidation],
  computed: {
    fields() {
      return [
        {
          label: '* Name',
          prop: 'name',
          value: null,
          rules: this.nameRules,
          sm: '6'
        },
        {
          label: '* Select',
          prop: 'key',
          value: null,
          sm: '6',
          type: 'image'
        }
      ]
    }
  }
}
