import Vue from 'vue'
export default {
  updateArrayState(state, payload) {
    if (payload.index !== -1) {
      state[payload.prop].splice(payload.index, 1, payload.value)
    }
  },
  updateArrayStateByValue(state, payload) {
    var index = state[payload.prop].indexOf(payload.value)
    if (index !== -1) {
      Vue.set(state[payload.prop], index, payload.editedValue)
    }
  },
  updateArrayStateById(state, payload) {
    var index = state[payload.prop].indexOf(
      state[payload.prop].filter(obj => {
        return obj.id == payload.editedValue.id
      })[0]
    )
    if (index !== -1) {
      Vue.set(state[payload.prop], index, payload.editedValue)
    }
  },
  setState(state, payload) {
    state[payload.prop] = payload.value
  },
  addToArrayState(state, payload) {
    var i = state[payload.prop].findIndex(item => {
      return payload.value.id == item.id
    })
    if (i < 0) {
      state[payload.prop].unshift(payload.value)
    }
  },
  addToArrayStatePush(state, payload) {
    state[payload.prop].push(payload.value)
  },
  deleteFromArrayState(state, payload) {
    state[payload.prop].splice(payload.index, 1)
  },
  deleteFromArrayStateByValue(state, payload) {
    var index = state[payload.prop].indexOf(payload.value)
    if (index !== -1) {
      state[payload.prop].splice(index, 1)
    }
  },
  deleteFromArrayStateById(state, payload) {
    var index = state[payload.prop].indexOf(
      state[payload.prop].filter(obj => {
        return obj.id == payload.id
      })[0]
    )
    if (index !== -1) {
      state[payload.prop].splice(index, 1)
    }
  },
  deleteFromArrayStateByParentId(state, payload) {
    const items = state[payload.prop].filter(obj => {
      return obj[`${payload.parent}Id`] == payload.id
    })
    for (const item of items) {
      var index = state[payload.prop].indexOf(item)
      if (index !== -1) {
        state[payload.prop].splice(index, 1)
      }
    }
  },
  replaceInArrayState(state, payload) {
    state[payload.prop] = []
  }
}
