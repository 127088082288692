import Store from '@/store/computed/storeHelpers'
import BillLineAllHeaders from './billLineAllHeaders'
export default {
  mixins: [BillLineAllHeaders],
  computed: {
    defaultHeaders: Store.getSet({
      store: 'bills',
      prop: 'billLineDefaultHeaders'
    }),
    allPossibleHeaders() {
      return this.allBillLineHeaders
    }
  },
  data() {
    return {
      mobileHeaders: [
        {
          text: 'Description',
          align: 'left',
          value: 'description',
          type: 'text'
        },
        {
          text: 'Sell Total',
          align: 'right',
          value: '_sellTotal',
          type: 'curr'
        }
      ]
    }
  }
}
