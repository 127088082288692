<template>
  <v-card>
    <v-card-text class="pb-0">
      <v-card-title class="px-0 pb-1 font-weight-regular">
        Delete
        {{ text }}
      </v-card-title>
      Are you sure you want to permanently delete
      <span class="font-weight-bold">{{ text }}</span
      >?
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text color="grey darken-1" @click="close">Cancel</v-btn>
      <v-btn
        text
        color="red darken-1"
        :loading="loadingDelete"
        @click="deleteSubject(subject)"
        >Delete</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'contractorDelete',
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    page: {
      type: String,
      required: true
    },
    subject: {
      type: Object,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      loadingDelete: false
    }
  },
  computed: {
    text() {
      let text
      if (this.subject.name) {
        text = this.subject.name
      } else if (this.subject.id) {
        text = `${this.page} (${this.subject.id})`
      } else {
        text = this.page
      }
      return text
    }
  },
  methods: {
    async deleteSubject(subject) {
      this.loadingDelete = true
      await this.action(subject)
      this.loadingDelete = false
      this.close()
    }
  }
}
</script>
