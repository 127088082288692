<template>
  <v-list-item :disabled="loading" @click="action">
    <v-list-item-title class="d-flex justify-center" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-list-item-title>
    <v-list-item-title v-else class="d-flex justify-center primary--text">
      {{ text }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: {
    action: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>
