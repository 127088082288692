import Api from '@/services/api.js'

export const addRcti = payload => {
  return Api.post('rctis/', payload)
}
export const addRctis = payload => {
  return Api.post('rctis/addMultiple', payload)
}
export const getRctis = payload => {
  return Api.post('rctis/get', payload)
}
export const getRctiLines = payload => {
  return Api.post('rctis/lines/get', payload)
}
export const getRctisByBillingPeriod = billingPeriod => {
  return Api.get(`rctis/byBillingPeriod/${billingPeriod}`)
}
export const getLinesByRctiId = id => {
  return Api.get(`rctis/lines/byRctiId/${id}`)
}
export const getRctisByIds = payload => {
  return Api.post('rctis/byIds', payload)
}
export const getRctisByIdsWithLines = payload => {
  return Api.post('rctis/byIdsWithLines', payload)
}
export const getRctisWithLines = payload => {
  return Api.post('rctis/getWithLines', payload)
}
export const getLinesByRctiIds = payload => {
  return Api.post('rctis/lines/byRctiIds', payload)
}
export const getRctiLinesForChart = payload => {
  return Api.post('rctis/lines/getChart', payload)
}
export const getPreviewData = payload => {
  return Api.post('rctis/previewData', payload)
}
export const updateRcti = (id, payload) => {
  return Api.put(`rctis/${id}`, payload)
}
export const deleteRcti = id => {
  return Api.delete(`rctis/${id}`)
}
export const importRctis = payload => {
  return Api.post('rctis/import', payload)
}

// LINES
export const getLinesByParentId = id => {
  return Api.get(`rctis/lines/${id}`)
}
export const updateRctiLine = (id, payload) => {
  return Api.put(`rctis/lines/${id}`, payload)
}
export const addRctiLine = payload => {
  return Api.post('rctis/lines', payload)
}
export const deleteRctiLine = id => {
  return Api.delete(`rctis/lines/${id}`)
}
