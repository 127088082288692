<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div>
        <v-icon dense class="pr-2" :color="active ? 'primary' : 'grey'">
          {{ icon }}
        </v-icon>
        {{ title }}
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  }
}
</script>
