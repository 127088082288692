import ContractorValidation from '../../validation/contractor'
import getProjectsLite from '../getMasterFiles/lite/getProjectsLite'
export default {
  mixins: [ContractorValidation, getProjectsLite],
  data() {
    return {
      states: ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'NT', 'ACT']
    }
  },
  computed: {
    fields() {
      return [
        {
          label: '* Name',
          prop: 'name',
          value: null,
          sm: '6',
          rules: this.nameRules
        },
        {
          label: '* ABN',
          prop: 'abn',
          value: null,
          sm: '6',
          rules: this.abnRules
        },
        {
          label: `${this.$store.state.settings.settings.accountingSystem} ID`,
          prop: 'accountingContactId',
          value: null,
          sm: '6',
          rules: this.xeroIdRules
        },
        {
          label: 'Street Address 1',
          prop: 'addressStreet1',
          value: null,
          sm: '6',
          rules: this.addressRules
        },
        {
          label: 'Street Address 2',
          prop: 'addressStreet2',
          value: null,
          sm: '6',
          rules: this.addressRules
        },
        {
          label: 'City',
          prop: 'addressCity',
          value: null,
          sm: '4',
          rules: this.addressCityRules
        },
        {
          label: 'State',
          prop: 'addressState',
          value: null,
          sm: '4',
          items: this.states
        },
        {
          label: 'Post Code',
          prop: 'addressPostCode',
          value: null,
          sm: '4',
          rules: this.addressPostCodeRules
        },
        {
          label: 'Contact Email',
          prop: 'email',
          value: null,
          sm: '6',
          rules: this.contactEmailRules
        },
        {
          label: 'Contact Phone',
          prop: 'contactPhone',
          value: null,
          sm: '6',
          rules: this.contactPhoneRules
        },
        {
          label: 'Override Cost',
          prop: 'override',
          value: 0,
          sm: '6',
          type: 'number'
        },
        {
          label: 'Admin fee %',
          prop: 'adminFeePercentage',
          value: 0,
          sm: '6',
          type: 'number'
        },
        {
          label: 'Image',
          prop: 'imageKey',
          value: null,
          sm: '6',
          type: 'image'
        },
        {
          label: 'Active',
          prop: 'active',
          value: true,
          sm: '6',
          type: 'boolean'
        },
        {
          label: 'Request',
          prop: 'request',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.project.titleSingular} inductions`,
          prop: 'allowedProjects',
          items: this.projectsLiteActive,
          value: [],
          type: 'expansionCheckbox',
          panel: -1,
          icon: this.$store.state.global.pages.project.icon
        }
      ]
    }
  }
}
