<template>
  <span>
    <v-btn
      v-if="useButton"
      color="primary"
      text
      @click="lines.length ? showRoute() : () => null"
    >
      <v-icon left>
        {{ $store.state.global.pages.destination.icon }}
      </v-icon>
      <span>Route</span>
    </v-btn>
    <v-tooltip
      v-else
      bottom
      :disabled="
        (!item.driver || !item.driver.availability) &&
          !item.capacity &&
          !lines.length
      "
    >
      <template v-slot:activator="{ on }">
        <v-chip
          v-on="on"
          :color="lines.length ? 'blue' : 'primary'"
          outlined
          class="ma-1"
          @click="lines.length ? showRoute() : null"
        >
          <v-icon left>
            {{ $store.state.global.pages.vehicle.icon }}
          </v-icon>
          <div>
            {{ item.vehicleName_contractorName_vehicleType }}
          </div>
          <div v-if="item.driver" class="pl-1">/ {{ item.driver.name }}</div>
        </v-chip>
      </template>
      <span>
        <div v-if="item.driver && item.driver.availability">
          Availability: {{ item.driver.availability }}
        </div>
        <div v-if="item.capacity">Capacity: {{ item.capacity }} (m3)</div>
        <div v-if="item.linesAllocated">{{ lines.length }} deliveries</div>
        <div v-if="item.driver.addressCity">
          {{ item.driver.addressCity }}
        </div>
      </span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    useButton: {
      type: Boolean,
      default: true
    },
    lines: {
      type: Array,
      required: true
    }
  },
  methods: {
    showRoute() {
      const sourceAddress1 = this.item.source.addressStreet1 || ''
      const sourceAddress2 = this.item.source.addressStreet2 || ''
      const sourceCity = this.item.source.addressCity || ''
      const sourceState = this.item.source.addressState || ''
      let addressString = `${sourceAddress1.replace(
        '/',
        '%2F'
      )} ${sourceAddress2} ${sourceCity} ${sourceState}`
      for (let line of this.lines) {
        const add1 = line.addressStreet1 || line.destination?.addressStreet1
        const add2 = line.addressStreet2 || line.destination?.addressStreet2
        const addCity = line.addressCity || line.destination?.addressCity
        const addState = line.addressState || line.destination?.addressState

        const street = add1?.replace('/', '%2F') || ''
        const street2 = add2 || ''
        const city = addCity || ''
        const state = addState || ''
        addressString = (addressString
          ? addressString + `/ ${street} ${street2} ${city} ${state}`
          : `${street} ${street2} ${city} ${state}`
        )
          .trim()
          .replace(/\s/g, '+')
      }
      const url = `https://www.google.com/maps/dir/${addressString}`
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped></style>
