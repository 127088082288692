import Store from '@/store/computed/storeHelpers'
import { getUsersLite } from '../../../requests/users'
export default {
  mounted() {
    this.getUsersLite()
  },
  data() {
    return {
      loadedUsersLite: false
    }
  },
  computed: {
    usersLite: Store.getSet({
      store: 'users',
      prop: 'usersLite'
    }),
    loadingUsersLite: Store.getSet({
      store: 'users',
      prop: 'loadingUsersLite'
    })
  },
  methods: {
    async getUsersLite() {
      try {
        this.loadingUsersLite = true
        const result = await getUsersLite()
        if (result) {
          this.usersLite = result.data.result
        }
        this.loadedUsersLite = true
        this.loadingUsersLite = false
      } catch (err) {
        console.log(err)
        this.loadingUsersLite = false
      }
    }
  }
}
