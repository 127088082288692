import Store from '@/store/computed/storeHelpers'
import AllocationAllHeaders from './allocationAllHeaders'
export default {
  mixins: [AllocationAllHeaders],
  computed: {
    defaultHeaders: Store.getSet({
      store: 'allocations',
      prop: 'allocationDefaultHeaders'
    }),
    allPossibleHeaders() {
      return this.allAllocationHeaders
    }
  },
  data() {
    return {
      mobileHeaders: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$store.state.global.pages.driver.titleSingular,
          value: 'driver.name'
        }
      ]
    }
  }
}
