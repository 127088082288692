export default {
  data() {
    return {
      units: ['TONNE', 'HOUR', 'EA', 'DAY'],
      autoCompletes: [
        {
          label: '* Project',
          value: 'projectId',
          items: 'projectsLite',
          page: 'project'
        },
        {
          label: this.$store.state.global.pages.source.titleSingular,
          value: 'sourceId',
          items: 'sourcesLite',
          page: 'source',
          altName: 'sourceName_projectName'
        },
        {
          label: this.$store.state.global.pages.destination.titleSingular,
          value: 'destinationId',
          items: 'destinationsLite',
          page: 'destination'
        }
      ]
    }
  },
  computed: {
    selectedVehicleTypes() {
      const vehicleTypes = this.$store.state.vehicleTypes.vehicleTypes
      return this.selectedVehicleTypeIds.length
        ? vehicleTypes
            .filter(item => {
              let match = false
              this.selectedVehicleTypeIds.forEach(id => {
                if (id == item.id) match = true
              })
              return match
            })
            .map(item => {
              return {
                id: item.id,
                name: item.name
              }
            })
        : []
    },
    selectedItemTypes() {
      const itemTypes = this.$store.state.itemTypes.itemTypesLite
      return this.selectedItemTypeIds.length
        ? itemTypes
            .filter(item => {
              let match = false
              this.selectedItemTypeIds.forEach(id => {
                if (id == item.id) match = true
              })
              return match
            })
            .map(item => {
              return {
                id: item.id,
                name: item.name
              }
            })
        : []
    },
    fields() {
      return [
        { type: 'heading', value: 'Cost', sm: '12' },
        {
          type: 'number',
          label: '* Cost Price',
          rules: this.priceRules,
          icon: 'mdi-currency-usd',
          value: 'costPrice',
          sm: '6'
        },
        {
          type: 'autocomplete',
          label: 'Cost unit',
          items: this.units,
          value: 'costUnit',
          sm: '6',
          prepend: true
        },
        { type: 'heading', value: 'Sell', sm: '12' },
        {
          type: 'number',
          label: '* Sell Price',
          rules: this.priceRules,
          icon: 'mdi-currency-usd',
          value: 'sellPrice',
          sm: '6'
        },
        {
          type: 'autocomplete',
          label: 'Sell unit',
          items: this.units,
          value: 'sellUnit',
          sm: '6',
          prepend: true
        },
        {
          type: 'number',
          label: '* Export Amount',
          rules: this.priceRules,
          icon: 'mdi-currency-usd',
          value: 'exportAmount',
          sm: '6'
        },
        {
          type: 'number',
          label: 'Alt. rate docket lines',
          rules: this.altRateTripsRules,
          icon: 'mdi-calendar-today',
          hint:
            'Alternate rate will be applied to docket lines after dockets lines exceed this number',
          value: 'altRateTrips',
          sm: '4'
        },
        {
          type: 'switch',
          label: 'Admin fee required',
          value: 'adminFeeRequired',
          sm: '4'
        },
        {
          type: 'switch',
          label: `Use  ${this.$store.state.global.pages.vehicle.titleSingular} type tonnage`,
          value: 'useTonnage',
          sm: '4',
          class: 'pt-0'
        },
        {
          type: 'text',
          label: 'Notes',
          rules: this.notesRules,
          icon: 'mdi-note',
          value: 'notes',
          sm: '12'
        }
      ]
    }
  }
}
