import Store from '@/store/computed/storeHelpers'
import { getCompliances } from '../../requests/compliances'
import Compliance from '../../models/compliance'

export default {
  mounted() {
    this.getCompliances()
  },
  data() {
    return {
      pagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: []
      },
      count: 0,
      initPagination: false
    }
  },
  computed: {
    compliances: Store.getSet({ store: 'compliances', prop: 'compliances' }),
    loadingCompliances: Store.getSet({
      store: 'compliances',
      prop: 'loadingCompliances'
    })
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        if (this.initPagination) {
          this.getCompliances()
        } else {
          this.initPagination = true
        }
      }
    }
  },
  methods: {
    async getCompliances() {
      try {
        this.loadingCompliances = true
        const payload = this.buildQuery()
        const result = await getCompliances({
          ...payload,
          pagination: this.excludePagination
            ? this.excludePagination
            : this.pagination
        })
        if (result) {
          const items = result.data.result
          this.compliances = items.map(item => new Compliance(item))
          this.count = result.data.count
        }
        this.loadingCompliances = false
      } catch (err) {
        console.log(err)
        this.loadingCompliances = false
      }
    },
    buildQuery() {
      return {
        userId: this.$store.state.global.selectedUserId,
        driverId: this.$store.state.global.selectedDriverId,
        vehicleId: this.$store.state.global.selectedVehicleId,
        contractorId: this.$store.state.global.selectedContractorId,
        dateRangeStart: this.$store.state.global.dateRangeStart,
        dateRangeEnd: this.$store.state.global.dateRangeEnd,
        masterFile: this.$store.state.compliances.filterCol,
        filterBy: this.$store.state.compliances.filterBy
      }
    }
  }
}
