<template>
  <v-select
    prepend-icon="mdi-format-list-bulleted"
    :label="label"
    v-model="filterCol"
    :items="filterCols"
    item-text="text"
    item-value="value"
    @click:clear="clear"
    clearable
  ></v-select>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: 'Choose filter'
    },
    filterCols: {
      type: Array,
      required: true
    },
    initialFilterCol: {
      type: String,
      required: false,
      default: null
    },
    onChanged: {
      type: Function,
      required: true
    },
    clearFilterBy: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      filterCol: null
    }
  },
  mounted() {
    if (this.initialFilterCol) this.filterCol = this.initialFilterCol
  },
  watch: {
    filterCol(val) {
      this.onChanged(val)
    },
    initialFilterCol(val) {
      if (val) this.filterCol = val
    }
  },
  methods: {
    clear() {
      this.$nextTick(() => (this.filterCol = null))
      if (this.clearFilterBy) this.clearFilterBy()
    }
  }
}
</script>
