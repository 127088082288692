import Api from '@/services/api.js'

export const addSource = payload => {
  return Api.post('sources/', payload)
}
export const getSources = payload => {
  return Api.post('sources/get', payload)
}
export const getSourcesLite = () => {
  return Api.get('sources/')
}
export const updateSource = (id, payload) => {
  return Api.put(`sources/${id}`, payload)
}
export const deleteSource = id => {
  return Api.delete(`sources/${id}`)
}
