<template>
  <v-card flat class="card-outlined mx-3">
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title
        >Add
        {{ $store.state.global.pages.docket.titleSingular }}</v-toolbar-title
      >
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-sheet outlined :color="isCredit ? 'red' : ''" rounded>
            <v-card flat class="card-outlined">
              <v-card-text class="pb-0">
                <CardHeader
                  :title="
                    `${$store.state.global.pages.docket.titleSingular} Entry`
                  "
                  :subtitle="isCredit ? 'CREDIT' : ''"
                  :icon="$store.state.global.pages.docket.icon"
                />
                <v-form ref="addForm" @submit.prevent="add()">
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <v-autocomplete
                        :label="`* Billing Period`"
                        prepend-icon="mdi-calendar-blank"
                        v-model="selectedBillingPeriod"
                        :items="$store.state.billingPeriods.openBillingPeriods"
                        item-value="id"
                        :rules="billingPeriodRules"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      class="d-flex align-center"
                      v-if="isCredit"
                    >
                      <v-checkbox
                        label="Adjust RCTI"
                        hide-details
                        class="mt-0 mr-4"
                        v-model="adjustRcti"
                      ></v-checkbox>
                      <v-checkbox
                        label="Adjust Bill"
                        hide-details
                        class="mt-0"
                        v-model="adjustBill"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="12" sm="6" md="3">
                      <v-autocomplete
                        auto-select-first
                        :label="
                          `* ${$store.state.global.pages.source.titleSingular}`
                        "
                        :prepend-icon="$store.state.global.pages.source.icon"
                        v-model="sourceId"
                        :items="sources"
                        item-value="id"
                        item-text="sourceName_projectName"
                        :rules="sourceRules"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        label="External Docket #"
                        :prepend-icon="$store.state.global.pages.docket.icon"
                        v-model="externalRef"
                        :rules="externalRefRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <DatePickerWrapper
                        label="* Date"
                        v-model="date"
                        :rules="dateRules"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <DatePickerWrapper
                        label="Date Received"
                        v-model="dateReceived"
                        :rules="dateRules"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-autocomplete
                        auto-select-first
                        :label="
                          `* ${$store.state.global.pages.vehicle.titleSingular}`
                        "
                        :prepend-icon="$store.state.global.pages.vehicle.icon"
                        v-model="vehicleId"
                        :items="filteredVehicles"
                        item-value="id"
                        item-text="vehicleName_contractorName_vehicleType"
                        :rules="vehicleRules"
                        @click:clear="() => $nextTick(() => (vehicleId = null))"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-autocomplete
                        auto-select-first
                        :label="
                          `* ${$store.state.global.pages.driver.titleSingular}`
                        "
                        :prepend-icon="$store.state.global.pages.driver.icon"
                        v-model="driverId"
                        :items="filteredDrivers"
                        item-value="id"
                        item-text="driverName_contractorName"
                        :rules="driverRules"
                        @click:clear="() => $nextTick(() => (driverId = null))"
                        clearable
                      >
                        <template v-slot:prepend>
                          <v-icon>{{
                            $store.state.global.pages.driver.icon
                          }}</v-icon>
                          <!-- <v-avatar v-else style="margin-top: -15px">
                          <v-img :src="imageUrl"></v-img>
                        </v-avatar> -->
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Notes"
                        prepend-icon="mdi-note"
                        v-model="notes"
                        :rules="notesRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-switch
                        :label="isDay ? 'Day' : 'Night'"
                        color="primary"
                        v-model="isDay"
                        inset
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" class="pt-0 d-flex justify-end">
                      <v-btn
                        color="primary"
                        outlined
                        type="submit"
                        v-if="lines.length"
                        :loading="loading"
                        class="mb-3"
                        >Save</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-col>

        <v-col cols="12">
          <DocketLineAdd
            :lines="sortedLines"
            :date="date"
            :isDay="isDay"
            :vehicleId="vehicleId"
            :sourceId="sourceId"
            :onAdd="addLine"
            :onEdit="editLine"
            :onDelete="deleteLine"
            :loading="loading"
            :loadingLines="loadingLines"
            :sellTotalForLines="sellTotalForLines"
            :source="source"
            :type="isCredit ? 'CREDIT' : 'DOCKET'"
            :vehicle="vehicle"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Vue from 'vue'
import Store from '@/store/computed/storeHelpers'
import { addDocket, getLinesByDocketId } from '../../services/requests/dockets'
import { getLinesByAllocationId } from '../../services/requests/allocations'
import DocketValidation from '../../services/validation/docket'
import FilterByContractor from '../../services/mixins/filterByContractor'
import Docket from '@/services/models/docket'
import DocketLine from '@/services/models/docketLine'
import AllocationLine from '@/services/models/allocationLine'

import CardHeader from '../misc/shared/CardHeader'
import DatePickerWrapper from '@/components/misc/fields/DatePickerWrapper'
import DocketLineAdd from './lines/DocketLineAdd'
import FormatDates from '@/services/mixins/formatDates'
import { sumProp } from '../../utils/Utils'

export default {
  mixins: [DocketValidation, FilterByContractor, FormatDates],
  components: {
    CardHeader,
    DatePickerWrapper,
    DocketLineAdd
  },
  props: {
    toggleAdd: {
      type: Function,
      required: true
    },
    clearCredit: {
      type: Function,
      required: false
    },
    sources: Array,
    vehicles: Array,
    drivers: Array,
    destinations: {
      type: Array,
      required: true
    },
    itemTypes: {
      type: Array,
      required: true
    },
    item: {
      type: Object,
      required: false
    },
    docketToCredit: {
      type: Object,
      required: false
    },
    isCredit: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      date: null,
      dateReceived: this.makeComputerDate(new Date()),
      externalRef: null,
      sourceId: null,
      vehicleId: null,
      driverId: null,
      notes: null,
      isDay: true,
      lines: [],
      loading: false,
      imageUrl: null,
      adjustRcti: false,
      adjustBill: false,
      loadingLines: false
    }
  },
  mounted() {
    if (this.$store.state.global.defaultSourceId) {
      this.sourceId = this.$store.state.global.defaultSourceId
    }
    if (this.item) this.initDocketAdd(this.item)
    if (this.docketToCredit) this.initCreditAdd(this.docketToCredit)
  },
  watch: {
    isCredit() {
      if (!this.isCredit) this.clear()
    },
    sourceId() {
      if (!this.item && !this.docketToCredit) {
        this.driverId = null
        this.vehicleId = null
      }
    },
    docketToCredit() {
      if (this.docketToCredit) this.initCreditAdd(this.docketToCredit)
    }
  },
  computed: {
    selectedBillingPeriod: Store.getSet({
      store: 'global',
      prop: 'selectedBillingPeriod'
    }),
    sellTotalForLines() {
      return this.lines.length ? sumProp(this.lines, 'sellTotal') : 0
    },
    sortedLines() {
      return [...this.lines].sort((a, b) => (a.id > b.id ? 1 : -1))
    }
  },
  methods: {
    async add() {
      if (!this.$refs.addForm.validate()) {
        this.snackFormError()
        return
      }
      if (this.isCredit && !this.adjustBill && !this.adjustRcti) {
        this.snack({
          text: 'This is a credit! You must select the adjustment status ',
          color: 'amber'
        })
        return
      }
      try {
        this.loading = true
        const lines = []
        const invalidLines = this.lines.filter(({ qty }) => qty == null)
        if (invalidLines.length) {
          this.snack({
            text: 'Lines are incomplete - check quantity for all lines!',
            color: 'amber'
          })
          this.loading = false
          return
        }
        for (const [i, line] of this.lines.entries()) {
          const sellTotal = line.sellPrice * line.qty
          const costTotal = line.costPrice * line.costQty
          lines.push({
            destinationId: line.destinationId,
            lineNumber: i + 1,
            itemTypeId: line.itemTypeId,
            qty: line.qty,
            costQty: line.costQty,
            costUnit: line.costUnit,
            costPrice: line.costPrice,
            sellUnit: line.sellUnit,
            sellPrice: line.sellPrice,
            sellTotal,
            costTotal,
            destinationWeight: line.destinationWeight,
            sourceWeight: line.sourceWeight,
            vehicleWeight: line.vehicleWeight,
            start: line.start ? new Date(`${line.start}`) : line.start,
            end: line.end ? new Date(`${line.end}`) : line.end,
            breaks: line.breaks,
            travel: line.travel,
            hours: line.hours,
            wbDocketNumber: line.wbDocketNumber,
            useAltRate: line.useAltRate,
            useNightRate: line.useNightRate,
            useOverrideCost: line.useOverrideCost,
            notes: line.notes,
            GPSConfirmed: line.GPSConfirmed,
            tippingDocketConfirmed: line.tippingDocketConfirmed,
            massDeclarationConfirmed: line.massDeclarationConfirmed,
            GPSNotes: line.GPSNotes,
            tippingDocketNotes: line.tippingDocketNotes,
            massDeclarationNotes: line.massDeclarationNotes,
            waivedAmount: Number(line.waivedAmount).toFixed(4),
            outstandingBillingAmount: line.waivedAmount
              ? (sellTotal - line.waivedAmount).toFixed(4)
              : sellTotal.toFixed(4),
            approvedBillingAmount: '0.0000'
          })
        }
        const payload = {
          date: this.date,
          sourceId: this.sourceId,
          vehicleId: this.vehicleId,
          driverId: this.driverId,
          isDay: this.isDay,
          notes: this.notes,
          externalRef: this.externalRef,
          dateReceived: this.dateReceived,
          type: this.isCredit ? 'CREDIT' : 'DOCKET',
          billingPeriod: this.selectedBillingPeriod,
          lines,
          allocationId: this.item ? this.item.id : null,
          adjustRcti: this.adjustRcti,
          adjustBill: this.adjustBill
        }
        const result = await addDocket(payload)
        if (result) {
          this.$store.commit('dockets/addToArrayState', {
            prop: 'dockets',
            value: new Docket(result.data.result)
          })
          for (const line of result.data.lines) {
            this.$store.commit('dockets/addToArrayState', {
              prop: 'lines',
              value: new DocketLine(line)
            })
          }
          this.reset()
          if (this.clearCredit) this.clearCredit()
          this.snackCreated()
        }
        this.loading = false
      } catch (err) {
        this.loading = false
        this.snack({ color: 'red', text: err.message })
        console.log(err)
      }
    },

    async addLine(line) {
      const destination = this.destinations.find(
        ({ id }) => id === line.destinationId
      )
      const docket = {
        sourceId: this.sourceId,
        vehicleId: this.vehicleId,
        date: this.date
      }
      const itemType = this.itemTypes.find(({ id }) => id === line.itemTypeId)
      const newLine = new DocketLine({
        ...line,
        docket,
        sellTotal: line.sellPrice * line.qty,
        costTotal: line.costPrice * line.costQty,
        destination,
        itemType
      })
      this.lines.push(newLine)
    },
    editLine({ original, item }) {
      const destination = this.destinations.find(
        ({ id }) => id === item.destinationId
      )
      const itemType = this.itemTypes.find(({ id }) => id === item.itemTypeId)
      item.destination = destination
      item.itemType = itemType
      const i = this.lines.indexOf(original)
      if (~i) Vue.set(this.lines, i, item)
    },
    deleteLine(line) {
      const i = this.lines.indexOf(line)
      if (~i) {
        this.lines.splice(i, 1)
        for (const [i, line] of this.lines.entries()) {
          line.id = i + 1
        }
      }
    },
    close() {
      this.toggleAdd()
      if (this.isCredit) this.clear()
      if (this.clearCredit) this.clearCredit()
    },
    reset() {
      this.toggleAdd()
      this.clear()
      if (this.$route.name === 'allocation') {
        this.$router.push({ name: 'docket' })
      }
    },
    clear() {
      this.date = null
      this.externalRef = null
      this.sourceId = this.$store.state.global.defaultSourceId
      this.vehicleId = null
      this.driverId = null
      this.notes = null
      this.isDay = true
      this.lines = []
      this.adjustBill = false
      this.adjustRcti = false
    },
    async getLines() {
      try {
        this.loadingLines = true
        const result = await getLinesByAllocationId(this.item.id)
        const items = result.data.result
        const allocationLines = items.map(item => new AllocationLine(item))
        this.loadingLines = false
        return allocationLines
      } catch (err) {
        console.log(err)
      }
    },
    async getCreditLines() {
      try {
        this.loadingLines = true
        const result = await getLinesByDocketId(this.docketToCredit.id)
        const items = result.data.result.lines
        const docketLines = items.map(item => new DocketLine(item))
        this.loadingLines = false
        return docketLines
      } catch (err) {
        console.log(err)
      }
    },
    async initDocketAdd(item) {
      this.clear()
      if (item) {
        this.date = item.date
        this.sourceId = item.sourceId
        this.vehicleId = item.vehicleId
        this.driverId = item.driverId
        this.isDay = item.isDay
        const lines = await this.getLines(item.id)
        for (const [i, line] of lines.entries()) {
          const docketLine = {
            id: this.lines.length + i + 1,
            destinationId: line.destinationId,
            itemTypeId: line.itemTypeId,
            GPSConfirmed: 'NOT SET',
            tippingDocketConfirmed: 'NOT SET',
            massDeclarationConfirmed: 'NOT SET',
            approvedBillingAmount: 0,
            waivedAmount: 0,
            qty: null,
            travel: 0,
            breaks: 0,
            sellPrice: 0,
            costQty: 0,
            costPrice: 0,
            useAltRate: false,
            useNightRate: !this.isDay,
            useOverrideCost: false,
            destinationWeight: 0,
            vehicleWeight: 0,
            sourceWeight: 0
          }
          this.addLine(docketLine)
        }
      }
    },
    async initCreditAdd(item) {
      this.clear()
      const creditText = `(Credit for ID: ${item.id})`
      this.selectedBillingPeriod = item.billingPeriod
      this.date = item.date
      this.externalRef = item.externalRef ? item.externalRef + '-CR' : '-CR'
      this.notes = item.notes ? item.notes + ' ' + creditText : creditText
      this.sourceId = item.sourceId
      this.vehicleId = item.vehicleId
      this.driverId = item.driverId
      this.isDay = true
      if (item) {
        const lines = await this.getCreditLines(item.id)
        for (const [i, line] of lines.entries()) {
          const docketLine = {
            ...line,
            waivedAmount: Number(line.waivedAmount),
            id: i + 1
          }
          this.addLine(docketLine)
        }
      }
    }
  }
}
</script>
