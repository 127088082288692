import { getLineByLineIdTracking } from '../requests/allocations'
export default {
  data() {
    return {
      loading: false,
      trackingLine: null
    }
  },
  methods: {
    async getAllocationLineById(id) {
      try {
        this.loading = true
        const result = await getLineByLineIdTracking(id)
        if (result) {
          this.trackingLine = result.data.result.allocationLine
        }
        this.loading = false
      } catch (err) {
        console.log(err)
        this.loading = false
      }
    }
  }
}
