import Store from '@/store/computed/storeHelpers'
import { getItemTypesLite } from '../../../requests/itemTypes'
export default {
  mounted() {
    this.getItemTypesLite()
  },
  data() {
    return {
      loadedItemTypesLite: false
    }
  },
  computed: {
    itemTypesLite: Store.getSet({ store: 'itemTypes', prop: 'itemTypesLite' }),
    loadingItemTypesLite: Store.getSet({
      store: 'itemTypes',
      prop: 'loadingItemTypesLite'
    })
  },
  methods: {
    async getItemTypesLite() {
      try {
        this.loadingItemTypesLite = true
        const result = await getItemTypesLite()
        if (result) {
          this.itemTypesLite = result.data.result
        }
        this.loadedItemTypesLite = true
        this.loadingItemTypesLite = false
      } catch (err) {
        console.log(err)
        this.loadingItemTypesLite = false
      }
    }
  }
}
