import Allocation from './allocation'
import {
  makeShortDate,
  makeTime,
  makeComputerDate
} from '../../utils/FormatDates'

class AllocationLine {
  constructor(allocationLine) {
    Object.keys(allocationLine).forEach(key => {
      this[key] = allocationLine[key]
      if (key == 'allocation')
        this[`_${key}`] = this[key] = new Allocation(allocationLine[key])
      else this[`_${key}`] = allocationLine[key]

      const formats = [
        {
          condition: ['createdAt', 'updatedAt'].includes(key),
          format: val => makeShortDate(val)
        },
        {
          condition: ['start', 'end'].includes(key),
          format: val => makeTime(new Date(val))
        },
        {
          condition: ['sellTotal', 'costTotal', 'qty', 'hours'].includes(key),
          format: val => Number(val).toFixed(2)
        }
      ]

      formats.forEach(item => {
        if (item.condition && allocationLine[key])
          this[`_${key}`] = item.format(allocationLine[key])
      })
    })
  }

  get createdByName() {
    return this.createdById ? this.createdBy.name : this.allocation.driver.name
  }

  get isWithDriver() {
    return !!this.allocation?.docketId && !this.allocation?.docket?.isClosed
  }

  get closed() {
    return (
      (!!this.allocation?.docketId && this.allocation?.docket?.isClosed) ||
      this.allocation.endDate < makeComputerDate(new Date())
    )
  }

  get deliveryAddress() {
    let address = ''
    if (this.addressStreet1) address += `${this.addressStreet1}`
    if (this.addressStreet2) address += ` ${this.addressStreet2}`
    if (this.addressCity) address += `, ${this.addressCity}`
    if (this.addressState) address += ` ${this.addressState}`
    return address
  }

  get isEditable() {
    return !this.allocation.docketId
  }
}

export default AllocationLine
