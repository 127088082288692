import Api from '@/services/api.js'

export const addDocket = payload => {
  return Api.post('dockets/', payload)
}
export const getDockets = payload => {
  return Api.post('dockets/get', payload)
}
export const getActiveDockets = () => {
  return Api.get('dockets/active')
}
export const getDocketTotals = payload => {
  return Api.post('dockets/totals', payload)
}
export const updateDocket = (id, payload) => {
  return Api.put(`dockets/${id}`, payload)
}
export const deleteDocket = id => {
  return Api.delete(`dockets/${id}`)
}
export const importDockets = payload => {
  return Api.post('dockets/import', payload)
}
export const addDocketLine = payload => {
  return Api.post('dockets/lines', payload)
}
export const getDocketLines = payload => {
  return Api.post('dockets/lines/get', payload)
}
export const getDocketLinesForChart = payload => {
  return Api.post('dockets/lines/getChart', payload)
}
export const getLinesByDocketId = id => {
  return Api.get(`dockets/lines/${id}`)
}
export const getDocketsByIds = payload => {
  return Api.post('dockets/byIds', payload)
}
export const getDocketsByIdsWithLines = payload => {
  return Api.post('dockets/byIdsWithLines', payload)
}
export const getDocketsWithLines = payload => {
  return Api.post('dockets/getWithLines', payload)
}
export const getLinesByDocketIds = payload => {
  return Api.post('dockets/lines/byDocketIds', payload)
}
export const updateDocketLine = (id, payload) => {
  return Api.put(`dockets/lines/${id}`, payload)
}
export const deleteDocketLine = id => {
  return Api.delete(`dockets/lines/${id}`)
}
export const getDocketLinesByIds = payload => {
  return Api.post('dockets/lines/byIds', payload)
}
export const getDocketLinesByRctis = payload => {
  return Api.post('dockets/lines/byRctis', payload)
}
export const getDocketLinesByRctiIds = payload => {
  return Api.post('dockets/lines/byRctiIds', payload)
}
export const getDocketLinesByBills = payload => {
  return Api.post('dockets/lines/byBills', payload)
}
export const getDocketLinesByBillIds = payload => {
  return Api.post('dockets/lines/byBillIds', payload)
}
export const repriceDocketLines = payload => {
  return Api.post('dockets/lines/reprice', payload)
}
