import { parseISO } from 'date-fns'
import { makeTime } from '../../utils/FormatDates'
import { makeShortDate } from '../../utils/FormatDates'

class Pod {
  constructor(pod) {
    Object.keys(pod).forEach(key => {
      this[key] = pod[key]
      this[`_${key}`] = pod[key]
      const formats = [
        {
          condition: ['createdAt', 'updatedAt'].includes(key),
          format: val => makeShortDate(val)
        }
      ]
      formats.forEach(item => {
        if (item.condition && pod[key]) this[`_${key}`] = item.format(pod[key])
      })
    })
  }

  get deliveredAt() {
    return makeTime(parseISO(this.createdAt))
  }
}

export default Pod
