<template>
  <v-container
    fluid
    v-if="$store.state.user.userAuthClass[`${$route.name}Read`]"
  >
    <v-row>
      <v-col cols="12" class="py-0">
        <ControlCard
          :toggleDrawer="() => (drawer = !drawer)"
          :filterCols="filterCols"
          :filterCol="filterCol"
          :filterBy="filterBy"
          :onFilterColChange="val => (filterCol = val)"
          :onFilterByChange="val => (filterBy = val)"
        >
          <template v-slot:header>
            <CardHeader
              :title="title"
              :icon="$store.state.global.pages[$route.name].icon"
              :actions="controlActions"
            />
          </template>
        </ControlCard>
      </v-col>
      <v-col cols="12" v-if="loadedUserSettings">
        <template>
          <PodView />
        </template>
      </v-col>
    </v-row>

    <FilterDrawer
      v-model="drawer"
      :sources="sourcesLite"
      :contractors="contractorsLite"
      :projects="projectsLite"
      :vehicles="vehiclesLite"
      :drivers="driversLite"
      :destinations="destinationsLite"
      :itemTypes="itemTypesLite"
      :podTypes="podTypes"
      :reports="reports"
    />
  </v-container>
</template>

<script>
import Store from '../../store/computed/storeHelpers'
import getProjectsLite from '../../services/mixins/getMasterFiles/lite/getProjectsLite'
import getSourcesLite from '../../services/mixins/getMasterFiles/lite/getSourcesLite'
import getContractorsLite from '../../services/mixins/getMasterFiles/lite/getContractorsLite'
import getVehiclesLite from '../../services/mixins/getMasterFiles/lite/getVehiclesLite'
import getDriversLite from '../../services/mixins/getMasterFiles/lite/getDriversLite'
import getDestinationsLite from '../../services/mixins/getMasterFiles/lite/getDestinationsLite'
import getItemTypesLite from '../../services/mixins/getMasterFiles/lite/getItemTypesLite'
import getReports from '../../services/mixins/getMasterFiles/getReports'

import ControlCard from '../../components/misc/shared/ControlCard'
import CardHeader from '../../components/misc/shared/CardHeader'
import PodView from '../../components/pods/PodView'
import FilterDrawer from '../../components/misc/shared/FilterDrawer'
export default {
  mixins: [
    getProjectsLite,
    getSourcesLite,
    getContractorsLite,
    getVehiclesLite,
    getDriversLite,
    getDestinationsLite,
    getItemTypesLite,
    getReports
  ],
  components: {
    ControlCard,
    CardHeader,
    PodView,
    FilterDrawer
  },
  data() {
    return {
      drawer: false,
      importDialog: false,
      podTypes: [
        {
          value: 'timesheet',
          name: 'TIMESHEET'
        },
        {
          value: 'form',
          name: 'FORM'
        },
        {
          value: 'signature',
          name: 'SIGNATURE'
        },
        {
          value: 'photo',
          name: 'PHOTO'
        }
      ]
    }
  },
  mounted() {
    this.setDocId()
  },
  computed: {
    loadedUserSettings: Store.getSet({
      store: 'global',
      prop: 'loadedUserSettings'
    }),
    filterCol: Store.getSet({ store: 'pods', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'pods', prop: 'filterBy' }),
    title() {
      return this.$store.state.global.pages[this.$route.name].title
    },

    controlActions() {
      return [
        {
          action: () => (this.drawer = !this.drawer),
          icon: 'mdi-filter-variant-plus',
          toolTipText: 'Filters',
          color: 'grey'
        }
      ]
    },
    filterCols() {
      const cols = [
        {
          value: 'allocationLine.allocation.source.name',
          text: this.$store.state.global.pages.source.titleSingular
        },
        {
          value: 'allocationLine.allocation.vehicle.contractor.name',
          text: this.$store.state.global.pages.contractor.titleSingular
        },
        {
          value: 'allocationLine.allocation.driver.name',
          text: this.$store.state.global.pages.driver.titleSingular
        },
        {
          value: 'allocationLine.allocation.vehicle.name',
          text: this.$store.state.global.pages.vehicle.titleSingular
        },
        {
          value: 'allocationLine.allocation.vehicle.vehicleType.name',
          text: this.$store.state.global.pages.vehicleType.titleSingular
        },
        {
          value: 'pod.id',
          text: 'ID'
        }
      ]
      return cols
    }
  },
  methods: {
    setDocId() {
      if (this.$route.query.id) {
        this.filterCol = 'pod.id'
        this.filterBy = this.$route.query.id.toString()
      }
    }
  }
}
</script>
