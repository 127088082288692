<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span>
          View {{ titleSingular }} -
          <span class="pl-1 subtitle-1">
            ID: <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="userAuthClass && !item.closed">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="showEditDialog">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit this {{ titleSingular.toLowerCase() }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-sheet outlined :color="item.type == 'CREDIT' ? 'red' : ''" rounded>
            <v-card flat class="card-outlined">
              <v-card-text class="pb-0">
                <CardHeader
                  :title="item.contractor.name"
                  :icon="$store.state.global.pages.docket.icon"
                />
                <v-container fluid class="px-0">
                  <v-row>
                    <Detail
                      icon="mdi-calendar"
                      :text="item.billingPeriod"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      icon="mdi-calendar-range"
                      :text="makeShortDate(item.date)"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="$store.state.global.pages.project.icon"
                      :text="item.project.name"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="typeIcon"
                      :text="item.type"
                      tooltip="Type"
                      cols="6"
                      lg="4"
                    />

                    <Detail
                      icon="mdi-counter"
                      :text="
                        `${
                          item.docketLineCount
                        } ${$store.state.global.pages.docket.titleSingular.toLowerCase()} line(s)`
                      "
                      :tooltip="
                        `${$store.state.global.pages.docket.titleSingular} line(s)`
                      "
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      v-if="item.transactionNumber"
                      icon="mdi-receipt-text-check"
                      :text="item.transactionNumber"
                      :tooltip="
                        `${globalSettings.accountingSystem} invoice #: ${item.transactionNumber}`
                      "
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      icon="mdi-note"
                      :text="item.status"
                      tooltip="Status"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="typeIcon"
                      :text="item.statDecConfirmed"
                      tooltip="Statutory declaration"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      icon="mdi-note"
                      :text="item.internalNotes"
                      v-if="item.internalNotes"
                      lg="4"
                    />
                    <Detail
                      icon="mdi-note"
                      :text="item.invoiceNotes"
                      v-if="item.invoiceNotes"
                      lg="4"
                    />
                  </v-row>
                </v-container>
              </v-card-text>
              <v-col class="pb-0">
                <v-divider></v-divider>
              </v-col>
              <v-card-actions>
                <v-row class="py-4" v-if="$vuetify.breakpoint.smAndUp">
                  <v-col
                    cols="12"
                    class="py-0"
                    v-if="item.updatedAt !== item.createdAt"
                  >
                    <TimeAgo
                      class="grey--text"
                      :subject="item"
                      :time="item.updatedAt"
                      needsParse
                      updated
                    >
                      <v-icon small color="grey" slot="icon" class="mr-2">
                        mdi-clock-outline
                      </v-icon>
                    </TimeAgo>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <TimeAgo
                      class="grey--text"
                      :subject="item"
                      :time="item.createdAt"
                      needsParse
                      created
                    >
                      <v-icon small color="grey" slot="icon" class="mr-2">
                        mdi-clock-outline
                      </v-icon>
                    </TimeAgo>
                  </v-col>
                </v-row>
                <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
                <MakeInvoicePdf :pdfData="pdfData" />
                <ShowAudits :auditData="auditData" />
              </v-card-actions>
            </v-card>
          </v-sheet>
        </v-col>

        <v-col cols="12">
          <LineView
            :headers="lineHeaders"
            :items="lines"
            :loading="loading"
            :loadingLines="loadingLines"
            :selectedId="selectedId"
          />
          <div v-if="totalForLines" class=" mx-3 mt-3 text-right title">
            Total: ${{ addCommas(totalForLines.toFixed(2)) }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { getLinesByParentId } from '../../services/requests/rctis'
import getWindowSize from '../../services/mixins/getWindowSize'
import FormatDates from '@/services/mixins/formatDates'
import LineHeaders from './mixins/lineHeaders'
import HeaderFilters from '../../services/mixins/headerFilters'
import RctiLine from '@/services/models/rctiLine'
import LineView from './lines/LineView'
import Detail from '../misc/Detail'
import TimeAgo from '../misc/TimeAgo'
import CardHeader from '../misc/shared/CardHeader'
import { sumProp } from '../../utils/Utils'
import MakeInvoicePdf from '../misc/shared/MakeInvoicePdf'
import makePdf from './mixins/makeRctiPdf'
import ShowAudits from '../misc/shared/ShowAudits'

export default {
  mixins: [FormatDates, LineHeaders, getWindowSize, HeaderFilters, makePdf],
  components: {
    LineView,
    Detail,
    TimeAgo,
    CardHeader,
    MakeInvoicePdf,
    ShowAudits
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    openEditDialog: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    selectedId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      lines: [],
      docket: null,
      loadingLines: false,
      pdfData: {}
    }
  },
  mounted() {
    this.getLines()
  },
  computed: {
    lineHeaders() {
      return Number(this.window?.width) >= 600
        ? this.headers
        : this.mobileHeaders
    },
    totalForLines() {
      return this.lines.length ? sumProp(this.lines, 'costTotal') : 0
    },
    typeIcon() {
      return this.item.type == 'CREDIT' ? 'mdi-file-minus' : 'mdi-file-plus'
    },
    auditData() {
      return { id: this.item.id, model: 'rcti' }
    }
  },
  methods: {
    async getLines() {
      try {
        this.loadingLines = true
        const result = await getLinesByParentId(this.item.id)
        const items = result.data.result
        this.lines = items.map(item => new RctiLine(item))
        const data = this.item
        data.lines = this.lines
        this.pdfData = this.makePdf([data])[0]
        this.loadingLines = false
      } catch (err) {
        console.log(err)
      }
    },
    goToDoc(route) {
      this.$router.push({
        name: route,
        query: { id: this.item.allocation.id }
      })
    },
    showEditDialog() {
      this.openEditDialog()
      this.close()
    }
  }
}
</script>
