<template>
  <div>
    <div class="d-flex align-center">
      <slot name="icon"></slot>
      <span v-if="updated" class="pr-1">Updated</span>
      <span v-if="created" class="pr-1">Created</span>
      {{ getTimeSince(time) }}
      <!-- <span v-if="!!createdTime">
        / Created {{ getTimeSince(createdTime) }}
      </span> -->
      <span v-if="!!subject">
        <span v-if="updated && !!subject.updatedBy" class="pl-1"
          >by {{ subject.updatedBy.name }}</span
        >
        <span v-if="created && !!subject.createdByName" class="pl-1"
          >by {{ subject.createdByName }}</span
        >
      </span>
    </div>
    <!-- <div v-if="!!subject">
      <div v-if="!!subject.createdByName">
        <slot name="icon"></slot>
        <span>Created by {{ subject.createdByName }}</span>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    time: String,
    updated: Boolean,
    subject: Object,
    created: Boolean,
    createdTime: String,
    needsParse: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    getTimeSince(date) {
      if (this.needsParse) {
        date = Date.parse(date)
      }
      date = Number(date)
      const seconds = Math.floor((new Date() - date) / 1000)
      let interval = Math.floor(seconds / 31536000)
      if (interval >= 1) {
        return interval == 1 ? interval + ' year ago' : interval + ' years ago'
      }
      interval = Math.floor(seconds / 2592000)
      if (interval >= 1) {
        return interval == 1
          ? interval + ' month ago'
          : interval + ' months ago'
      }
      interval = Math.floor(seconds / 86400)
      if (interval >= 1) {
        return interval == 1 ? interval + ' day ago' : interval + ' days ago'
      }
      interval = Math.floor(seconds / 3600)
      if (interval >= 1) {
        return interval == 1 ? interval + ' hour ago' : interval + ' hours ago'
      }
      interval = Math.floor(seconds / 60)
      if (interval >= 1) {
        return interval == 1
          ? interval + ' minute ago'
          : interval + ' minutes ago'
      }
      if (seconds < 30) {
        return 'recently'
      }
      return Math.floor(seconds) + ' seconds ago'
    }
  }
}
</script>

<style></style>
