import Vue from 'vue'
import Vuex from 'vuex'

import Global from '@/store/global'
import User from '@/store/user'
import Users from '../components/masterFiles/store/users'
import Allocations from '../components/allocations/store/allocations'
import Plans from '../components/plans/store/plans'
import Dockets from '../components/dockets/store/dockets'
import Rctis from '../components/rctis/store/rctis'
import Map from '../components/map/store/map'
import Settings from '../components/settings/store/settings'
import Compliances from '../components/compliances/store/compliances'
import Pods from '../components/pods/store/pods'
import Rates from '../components/rates/store/rates'

import Destinations from '../components/masterFiles/store/destinations'
import Sources from '../components/masterFiles/store/sources'

import Contractors from '../components/masterFiles/store/contractors'
import Vehicles from '../components/masterFiles/store/vehicles'
import Drivers from '../components/masterFiles/store/drivers'
import Projects from '../components/masterFiles/store/projects'
import VehicleTypes from '../components/masterFiles/store/vehicleTypes'
import ItemTypes from '../components/masterFiles/store/itemTypes'
import Policies from '../components/masterFiles/store/policies'
import BillingPeriods from '../components/masterFiles/store/billingPeriods'

import Masterfiles from '../components/masterFiles/store/masterfiles'

import Bills from '../components/bills/store/bills'
import ApprovedBillLines from '../components/approvedBillLines/store/approvedBillLines'

import Reports from '../components/reports/store/reports'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global: Global,
    user: User,
    users: Users,
    allocations: Allocations,
    plans: Plans,
    dockets: Dockets,
    rctis: Rctis,
    map: Map,
    policies: Policies,
    settings: Settings,
    billingPeriods: BillingPeriods,
    rates: Rates,
    compliances: Compliances,
    pods: Pods,
    destinations: Destinations,
    sources: Sources,
    contractors: Contractors,
    vehicles: Vehicles,
    drivers: Drivers,
    projects: Projects,
    vehicleTypes: VehicleTypes,
    itemTypes: ItemTypes,
    masterfiles: Masterfiles,
    bills: Bills,
    approvedBillLines: ApprovedBillLines,
    reports: Reports
  }
})
