import Rcti from './rcti'
import { makeShortDate, makeTime } from '../../utils/FormatDates'

class RctiLine {
  constructor(rctiLine) {
    Object.keys(rctiLine).forEach(key => {
      this[key] = rctiLine[key]
      if (key == 'rcti') this[`_${key}`] = this[key] = new Rcti(rctiLine[key])
      else this[`_${key}`] = rctiLine[key]

      const formats = [
        {
          condition: ['createdAt', 'updatedAt'].includes(key),
          format: val => makeShortDate(val)
        },
        {
          condition: ['start', 'end'].includes(key),
          format: val => makeTime(new Date(val))
        },
        {
          condition: ['sellTotal', 'costTotal', 'qty', 'hours'].includes(key),
          format: val => Number(val).toFixed(2)
        }
      ]

      formats.forEach(item => {
        if (item.condition && rctiLine[key])
          this[`_${key}`] = item.format(rctiLine[key])
      })
    })
  }
}

export default RctiLine
