<template>
  <v-col
    cols="12"
    v-if="duplicateVehicle.length || duplicateDriver.length"
    class="red--text"
    @click="duplicateDialog = !duplicateDialog"
    style="cursor: pointer"
  >
    <v-icon class="pr-2" color="red">mdi-alert-circle-outline </v-icon>
    Duplicate staged
    {{ $store.state.global.pages.allocation.titleSingular.toLowerCase() }}(s)
    found on this date for this
    <span v-if="duplicateVehicle.length">
      {{ $store.state.global.pages.vehicle.titleSingular.toLowerCase() }}
      [{{ $store.state.global.pages.allocation.titleSingular }}(s):
      {{ duplicateVehicle.join(', ') }}]
    </span>
    <span v-if="duplicateDriver.length && duplicateVehicle.length">
      &
    </span>
    <span v-if="duplicateDriver.length">
      {{ $store.state.global.pages.driver.titleSingular.toLowerCase() }}
      [{{ $store.state.global.pages.allocation.titleSingular }}(s):
      {{ duplicateDriver.join(', ') }}]</span
    >.
    <v-dialog
      v-model="duplicateDialog"
      @click:outside="() => (duplicateDialog = false)"
      @keydown.esc="() => (duplicateDialog = false)"
    >
      <v-card>
        <v-card-title class="font-weight-regular">
          Duplicated Staged
          {{ $store.state.global.pages.allocation.titleSingular }}s
        </v-card-title>
        <v-container>
          <v-data-table
            :headers="headers"
            :items="duplicatedAllocations"
            class="elevation-1"
          ></v-data-table>
        </v-container>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
export default {
  props: {
    duplicateVehicleLines: {
      type: Array,
      required: true
    },
    duplicateDriverLines: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      duplicateDialog: false,
      headers: [
        { text: 'ID', value: 'allocationId' },
        {
          text: 'Date',
          value: 'allocation._date'
        },
        {
          text: this.$store.state.global.pages.project.titleSingular,
          value: 'allocation.source.project.name'
        },
        {
          text: this.$store.state.global.pages.source.titleSingular,
          value: 'allocation.source.name'
        },
        {
          text: this.$store.state.global.pages.driver.titleSingular,
          value: 'allocation.driver.name'
        },
        {
          text: this.$store.state.global.pages.vehicle.titleSingular,
          value: 'allocation.vehicle.name'
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          value: 'destination.name'
        },
        {
          text: this.$store.state.global.pages.itemType.titleSingular,
          value: 'itemType.name'
        }
      ]
    }
  },
  computed: {
    duplicateDriver() {
      return this.duplicateDriverLines.map(item => item.allocationId)
    },
    duplicateVehicle() {
      return this.duplicateVehicleLines.map(item => item.allocationId)
    },
    duplicatedAllocations() {
      const allocationSet = new Set([
        ...this.duplicateVehicleLines,
        ...this.duplicateDriverLines
      ])
      return [...allocationSet]
    }
  }
}
</script>
