<template>
  <v-card-title
    class="px-0 pt-0 pb-3 font-weight-regular d-flex align-center justify-space-between"
  >
    <div class="d-flex align-center">
      <v-btn v-if="backAction" class="mr-2" icon @click="backAction">
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-icon class="pr-2" color="primary" v-if="icon">
        {{ icon }}
      </v-icon>
      <span
        :style="!$vuetify.breakpoint.smAndUp ? titleWidthStr : ''"
        class="title text-truncate"
        >{{ title }}</span
      ><span v-if="subtitle" class="subtitle-1 ml-1">({{ subtitle }})</span>
    </div>
    <div>
      <v-btn
        v-if="actionBtn"
        @click="actionBtn.onClick"
        text
        color="primary"
        class="mr-2"
        >{{ actionBtn.text }}</v-btn
      >
      <span class="mr-3 body-2" v-if="!!message">{{ message }}</span>
      <span v-if="!showMenu">
        <ToolTipButton
          v-for="(item, i) in actions"
          :key="i"
          :action="item.action"
          :icon="item.icon"
          :text="item.text"
          :color="item.color"
          :toolTipText="item.toolTipText"
          :disabled="item.disabled"
        />
      </span>
      <span v-if="showMenu && actions">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :loading="loading" v-on="on" v-bind="attrs">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in [...actions].reverse()"
              :key="index"
              @click="item.action"
              :disabled="item.disabled"
            >
              <v-list-item-icon>
                <v-icon :color="item.color" v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title
                :class="{
                  'green--text': item.color == 'green'
                }"
                >{{ item.toolTipText }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <DropdownGrid :actions="actions" :loading="loading" /> -->
      </span>
    </div>
  </v-card-title>
</template>

<script>
import ToolTipButton from '../ToolTipButton'
import getWindowSize from '../../../services/mixins/getWindowSize'
// import DropdownGrid from './DropdownGrid'

export default {
  mixins: [getWindowSize],
  components: {
    ToolTipButton
    // DropdownGrid
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    message: {
      type: String,
      required: false
    },
    actions: {
      type: Array,
      required: false
    },
    actionBtn: {
      type: Object,
      required: false
    },
    backAction: {
      type: Function,
      required: false
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    titleWidthStr() {
      const divisor = this.actionBtn ? 2.9 : 1.9
      return `max-width: ${(this.window.width / divisor).toString()}px;`
    },
    showMenu() {
      return (
        (!this.$vuetify.breakpoint.smAndUp && !!this.actions) ||
        (!!this.actions && this.actions.length >= 2)
      )
    }
  }
}
</script>
