import Plan from './plan'
import {
  makeShortDate,
  makeTime,
  makeComputerDate
} from '../../utils/FormatDates'

class PlanLine {
  constructor(planLine) {
    Object.keys(planLine).forEach(key => {
      this[key] = planLine[key]
      if (key == 'plan') this[`_${key}`] = this[key] = new Plan(planLine[key])
      else this[`_${key}`] = planLine[key]

      const formats = [
        {
          condition: ['createdAt', 'updatedAt'].includes(key),
          format: val => makeShortDate(val)
        },
        {
          condition: ['start', 'end'].includes(key),
          format: val => makeTime(new Date(val))
        },
        {
          condition: ['sellTotal', 'costTotal', 'qty', 'hours'].includes(key),
          format: val => Number(val).toFixed(2)
        }
      ]

      formats.forEach(item => {
        if (item.condition && planLine[key])
          this[`_${key}`] = item.format(planLine[key])
      })
    })
  }

  get createdByName() {
    return this.createdById ? this.createdBy.name : this.plan.driver.name
  }

  get closed() {
    return (
      (!!this.plan?.docketId && this.plan?.docket?.isClosed) ||
      this.plan.endDate < makeComputerDate(new Date())
    )
  }

  get deliveryAddress() {
    let address = ''
    if (this.addressStreet1) address += `${this.addressStreet1}`
    if (this.addressStreet2) address += ` ${this.addressStreet2}`
    if (this.addressCity) address += `, ${this.addressCity}`
    if (this.addressState) address += ` ${this.addressState}`
    return address
  }

  get isEditable() {
    return !this.plan.docketId
  }
}

export default PlanLine
