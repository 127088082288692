<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon :loading="loading" v-on="on" v-bind="attrs">
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in [...actions].reverse()"
        :key="index"
        @click="item.action"
        :disabled="item.disabled"
      >
        <v-list-item-icon>
          <v-icon :color="item.color" v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-title
          :class="{
            'green--text': item.color == 'green'
          }"
          >{{ item.toolTipText }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    actions: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  }
}
</script>
