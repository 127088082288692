<template>
  <span>
    <download-csv
      class="btn btn-default"
      :data="exportData"
      :name="filename"
      style="display: none"
      ref="downloadCsv"
    >
    </download-csv>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          dark
          text
          color="primary"
          class="mr-3 mb-3"
          icon
          @click="handleExportClick"
        >
          <v-icon>mdi-file-export</v-icon>
        </v-btn>
      </template>
      <span>
        Export {{ $store.state.global.pages[masterFile].title.toLowerCase() }}
      </span>
    </v-tooltip>
  </span>
</template>

<script>
import Store from '../../store/computed/storeHelpers'
import exportMasterFileKeys from '../misc/shared/exportMasterFileKeys'
import { getProjects } from '@/services/requests/projects'
import { getSources } from '@/services/requests/sources'
import { getContractors } from '@/services/requests/contractors'
import { getVehicles } from '@/services/requests/vehicles'
import { getDrivers } from '@/services/requests/drivers'
import { getItemTypes } from '@/services/requests/itemTypes'
import { getDestinations } from '@/services/requests/destinations'

export default {
  mixins: [exportMasterFileKeys],
  props: {
    masterFile: {
      type: String,
      required: true
    },
    filterBy: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      exportData: [],
      filename: '',
      isExported: false,
      getData: {
        project: payload => getProjects(payload),
        source: payload => getSources(payload),
        contractor: payload => getContractors(payload),
        vehicle: payload => getVehicles(payload),
        driver: payload => getDrivers(payload),
        itemType: payload => getItemTypes(payload),
        destination: payload => getDestinations(payload)
      }
    }
  },
  computed: {
    sourceParentId: Store.getSet({ store: 'sources', prop: 'parentId' }),
    driverParentId: Store.getSet({ store: 'drivers', prop: 'parentId' }),
    vehicleParentId: Store.getSet({ store: 'vehicles', prop: 'parentId' }),
    showInactive: Store.getSet({ store: 'masterfiles', prop: 'showInactive' }),
    showRequest: Store.getSet({ store: 'masterfiles', prop: 'showRequest' })
  },
  methods: {
    setExportData({ name, data }) {
      this.filename = `${name}.csv`
      this.exportData = data
      return this.exportData
    },
    async handleExportClick() {
      const data = await this.fetchData()
      const name = this.$store.state.global.pages[
        this.masterFile
      ].title.toLowerCase()
      if (data.length) {
        // This does not work on the first click without awaiting non async function
        const res = await this.setExportData({ name, data })
        if (res) this.$refs.downloadCsv.$el.click()
      }
    },
    async fetchData() {
      const data = []
      const method = this.getData[this.masterFile]
      const projectId =
        this.masterFile === 'source' ? this.sourceParentId : undefined
      const contractorId =
        this.masterFile === 'driver'
          ? this.driverParentId
          : this.masterFile === 'vehicle'
          ? this.vehicleParentId
          : undefined
      const payload = {
        pagination: undefined,
        filterBy: this.filterBy || '',
        projectId: projectId || undefined,
        contractorId: contractorId || undefined,
        showInactive: this.showInactive || false,
        showRequest: this.showRequest || false
      }
      const result = await method(payload)
      for (const item of result.data.result) {
        const obj = this.formatExportRowFromKeys(
          item,
          this[`${this.masterFile}Keys`]
        )
        data.push(obj)
      }
      // if (exportValue.format) {
      //   for (const item of result) {
      //     const obj = exportValue.format(item)
      //     data.push(obj)
      //   }
      // } else if (exportValue.multiPdf) {
      //   return result
      // } else {
      //   for (const item of result) {
      //     const obj = this.formatExportRowFromKeys(item, exportValue.keys)
      //     data.push(obj)
      //   }
      // }
      return data
    },
    formatExportRowFromKeys(item, keys) {
      const obj = {}
      for (const key of keys) {
        let value = item[key.value]
        if (key.value instanceof Array) {
          let keyObj = item[key.value[0]]
          for (const [i, val] of key.value.entries()) {
            if (i === 0) continue
            keyObj = keyObj[val]
          }
          value = keyObj
        }
        if (this.getObjectPathRootExists(key.value, item)) {
          value = this.getObjectPath(key.value, item)
        }
        const result =
          typeof value === 'object' && value !== null ? value.name : value
        obj[key.text] = result
      }
      return obj
    }
  }
}
</script>
