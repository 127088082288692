import Rules from '@/services/validation/rules/rules'
export default {
  data() {
    return {
      nameRules: [
        v => Rules.isRequired(v),
        v => Rules.minLength(v, 1),
        v => Rules.maxLength(v, 90)
      ],
      tonnageRules: [
        v => (v === 0 ? true : !!v || 'Field is required (enter 0 to disable)'),
        v => Rules.isNumber(v)
      ]
    }
  }
}
