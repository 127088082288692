export const sumProp = (array, prop) => {
  return array.reduce((total, item) => {
    const value = item[prop]
    if (value) {
      return total + Number(value)
    }
    return total
  }, 0)
}

export const calculateCurrentHours = start => {
  const end = new Date()
  const openHours = (
    (end.getTime() - new Date(start).getTime()) /
    1000 /
    60 /
    60
  ).toFixed(2)
  return openHours
}
