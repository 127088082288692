export default {
  data() {
    return {
      allPlanHeaders: [
        {
          text: 'ID',
          value: 'id'
        },
        { text: 'Date', value: '_date' },
        { text: 'Start', value: 'start' },
        { text: 'Reference', value: '_reference' },
        {
          text: this.$store.state.global.pages.project.titleSingular,
          value: 'source.project.name'
        },
        {
          text: this.$store.state.global.pages.source.titleSingular,
          value: 'source.name'
        },
        {
          text: 'Deliveries',
          align: 'right',
          value: 'lineCount',
          type: 'decimal',
          noOfDecimals: 0
        },
        {
          text: 'Notes',
          align: 'left',
          value: 'notes',
          type: 'text'
        },
        {
          text: 'Day / Night',
          align: 'center',
          value: 'isDay',
          type: 'dayBoolean'
        },
        {
          text: 'Status',
          value: 'status'
        },
        { text: 'Entered By', value: 'createdByName' },
        { text: 'Updated By', value: 'updatedBy.name' },
        { text: 'Created At', value: '_createdAt' },
        { text: 'Updated At', value: '_updatedAt' }
      ]
    }
  }
}
