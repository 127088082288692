<template>
  <v-menu :close-on-content-click="false" offset-x v-model="menu">
    <template v-slot:activator="{ on, attrs }">
      <v-row class="fill-height" v-bind="attrs" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-col class="ml-1 mt-1" v-on="on">
              <span>
                {{ event.title }}<br />
                {{ event.sourceName }}
                <span v-if="event.lineCount">
                  <span> - </span>
                  {{ event.lineCount }}
                  {{ event.lineCount > 1 ? 'deliveries' : 'delivery' }}
                </span>
              </span>
            </v-col>
          </template>
          <span>
            {{ event.title }} ({{ event.contractorName }})<br />
            {{ event.vehicleName }} ({{ event.vehicleType }})<br /><br />
            {{ event.projectName }}<br />
            {{ event.sourceName }}<br />
          </span>
        </v-tooltip>
      </v-row>
    </template>
    <AllocationCalEventCard :event="event" :close="() => (menu = false)" />
  </v-menu>
</template>

<script>
import AllocationCalEventCard from './AllocationCalEventCard'
export default {
  components: {
    AllocationCalEventCard
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      menu: false
    }
  }
}
</script>
