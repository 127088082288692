import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    approvedBillLines: [],
    drawer: false,
    loadingApprovedBillLines: false,
    selected: [],
    selectedAll: false,
    dates: [],
    filterBy: null,
    filterCol: null,
    fetchApprovedBillLines: false,
    approvedBillLineDefaultHeaders: [
      'id',
      'date',
      'source.name',
      'vehicle.name',
      'driver.name',
      'notes',
      'lineCount',
      'createdBy.name',
      'updatedBy.name'
    ]
  },
  mutations: Mutations
}
