<template>
  <v-card flat class="card-outlined mt-3">
    <v-card-text>
      <CardHeader
        :title="item.name"
        :icon="$store.state.global.pages.contractor.icon"
        :actionBtn="{
          text: 'Close',
          onClick: () => $emit('close')
        }"
      />
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="pb-0"
          v-if="item.email || item.contactPhone"
        >
          <v-divider></v-divider>
          <v-list>
            <v-list-item
              :href="`mailto:${item.email}`"
              class="drawer-icon"
              v-if="item.email"
            >
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ item.email }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :href="`tel:${item.contactPhone}`"
              class="drawer-icon"
              v-if="item.contactPhone"
            >
              <v-list-item-icon>
                <v-icon>mdi-phone</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ item.contactPhone }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" class="py-0 pt-3">
          <TimeAgo
            class="grey--text"
            :subject="item"
            :time="item.updatedAt"
            needsParse
            updated
          >
            <v-icon small color="grey" slot="icon" class="mr-2">
              mdi-clock-outline
            </v-icon>
          </TimeAgo>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CardHeader from '../misc/shared/CardHeader'
import TimeAgo from '../misc/TimeAgo'
export default {
  components: {
    CardHeader,
    TimeAgo
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
