<template>
  <MasterFilePage>
    <template v-slot:control>
      <MasterFileControl
        :masterFile="masterFile"
        :addDialog="addDialog"
        :toggleAdd="toggleAdd"
        :parents="projectsLite"
        :parent="parent"
        :parentId="parentId"
        :onSortChange="updateSortBy"
        :onFilterChange="updateFilterBy"
        :onParentChange="updateParent"
      />
    </template>
    <template v-slot:details>
      <MasterFileDetails
        :item="parent"
        v-if="parent"
        @close="parentId = null"
      />
    </template>
    <template v-slot:view>
      <MasterFileView
        :count="Math.ceil(sourceCount / pagination.itemsPerPage)"
        :masterFile="masterFile"
        :subjects="sortedSources"
        :fields="fields"
        :loadingMasterFile="loadingSources"
        :loadingMasterFiles="!loadedProjectsLite || !loadedDestinationsLite"
        :loading="loading"
        :onEdit="editSource"
        :onDelete="deleteSource"
        :filterBy="filterBy"
      />
    </template>
    <template v-slot:form>
      <v-dialog
        v-model="addDialog"
        width="700"
        @click:outside="() => (addDialog = false)"
        @keydown.esc="() => (addDialog = false)"
        v-if="loadedProjectsLite"
      >
        <MasterFileForm
          :masterFile="masterFile"
          :fields="fields"
          :dialog="addDialog"
          :close="toggleAdd"
          :onSubmit="createSource"
          :loading="loading"
        />
      </v-dialog>
    </template>
  </MasterFilePage>
</template>

<script>
import Store from '../../../store/computed/storeHelpers'
import {
  addSource,
  updateSource,
  deleteSource
} from '../../../services/requests/sources'
import getSources from '../../../services/mixins/getMasterFiles/getSources'
import sourceFields from '@/services/mixins/fields/sourceFields'
import MasterFileCrudFunctions from '../../../services/mixins/masterFileCrudFunctions'
import Pagination from '../../../services/mixins/getMasterFiles/pagination'

import MasterFilePage from '../../../components/masterFiles/MasterFilePage'
import MasterFileControl from '../../../components/masterFiles/MasterFileControl'
import MasterFileDetails from '../../../components/masterFiles/MasterFileDetails'
import MasterFileView from '../../../components/masterFiles/MasterFileView'
import MasterFileForm from '../../../components/masterFiles/MasterFileForm'

import { debounce } from 'lodash'
export default {
  mixins: [getSources, sourceFields, MasterFileCrudFunctions, Pagination],
  components: {
    MasterFilePage,
    MasterFileControl,
    MasterFileView,
    MasterFileDetails,
    MasterFileForm
  },
  data() {
    return {
      masterFile: 'source',
      prop: 'sources',
      addDialog: false,
      sortBy: null,
      filterBy: '',
      loading: false
    }
  },
  mounted() {
    this.setFid()
  },
  watch: {
    $route() {
      this.setFid()
    },
    filterBy: debounce(function() {
      this.getSources()
      this.pagination.page = 1
    }, 300),
    parentId: debounce(function() {
      this.getSources()
      this.pagination.page = 1
    }, 300)
  },
  computed: {
    parentId: Store.getSet({ store: 'sources', prop: 'parentId' }),
    parentFilteredSources() {
      if (this.parentId) {
        return this.sources.filter(source => {
          return source.projectId == this.parentId
        })
      } else {
        return this.sources
      }
    },
    sortedSources() {
      if (this.sortBy) {
        const path = this.sortBy
        return [...this.parentFilteredSources].sort((a, b) => {
          if (this.getObjectPath(path, a) < this.getObjectPath(path, b)) {
            if (path === 'updatedAt') {
              return 1
            }
            return -1
          } else {
            if (path === 'updatedAt') {
              return -1
            }
            return 1
          }
        })
      } else {
        return this.parentFilteredSources
      }
    },
    parent() {
      return this.projectsLite.find(({ id }) => this.parentId === id)
    }
  },
  methods: {
    async createSource(payload) {
      await this.createMasterFile({ payload, action: addSource })
    },
    async editSource(source) {
      source.geofenceRadius = Number(source.geofenceRadius)
      const { id, ...payload } = source
      await this.editMasterFile({ id, payload, action: updateSource })
    },
    async deleteSource(source) {
      await this.deleteMasterFile({
        payload: source,
        action: deleteSource
      })
    },
    toggleAdd() {
      this.addDialog = !this.addDialog
    },
    updateSortBy(sortBy) {
      this.sortBy = sortBy
    },
    updateFilterBy(filterBy) {
      this.filterBy = filterBy
    },
    updateParent(parentId) {
      this.parentId = parentId
    },
    setFid() {
      const fid = Number(this.$route.query.fid)
      fid && (this.parentId = fid)
    }
  }
}
</script>
