import Rules from '@/services/validation/rules/rules'
export default {
  data() {
    return {
      nameRules: [
        v => Rules.isRequired(v),
        v => Rules.minLength(v, 1),
        v => Rules.maxLength(v, 90)
      ]
    }
  }
}
