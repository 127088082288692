export default {
  getSet: options => {
    return {
      get() {
        return this.$store.state[options.store][options.prop]
      },
      set(val) {
        this.$store.commit(`${options.store}/setState`, {
          prop: options.prop,
          value: val
        })
      }
    }
  }
}
