import Rules from '@/services/validation/rules/rules'
export default {
  data() {
    return {
      emailRules: [
        v => Rules.isEmail(v),
        v => !!v || 'Email is required',
        v => v.length >= 2 || 'Email must be at least 2 characters long',
        v => v.length <= 120 || 'Email must be 120 characters or less'
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => v.length >= 2 || 'Password must be at least 2 characters long',
        v => v.length <= 120 || 'Password must be 120 characters or less'
      ]
    }
  }
}
