<template>
  <v-form @submit.prevent="submitBillsForm()" ref="billingsForm">
    <v-card>
      <v-card-text class="pb-0">
        <v-card-title class="pa-0 font-weight-regular">
          <span class="mr-2">
            <v-icon :color="$store.state.global.pages.bill.color">{{
              $store.state.global.pages.bill.icon
            }}</v-icon>
          </span>
          <span class="headline">
            Create {{ $store.state.global.pages.bill.title }}
            <span class="grey--text subheading">
              -
              <span class="font-weight-bold">{{ projectName }}</span> dockets
              for the billing period of
              <span class="font-weight-bold">{{ billingPeriod }}</span>
            </span>
          </span>
        </v-card-title>

        <v-container>
          <v-row>
            <v-col cols="12" class="d-flex justify-center" v-if="working">
              <v-progress-circular
                indeterminate
                :color="$store.state.global.pages.bill.color"
              ></v-progress-circular>
              <div
                class="text-xs-center body-1 grey--text text--lighten-1 mt-3"
              >
                Loading your data...
              </div>
            </v-col>
            <v-card-subtitle class="pb-2">
              Control Total
              <div class="font-italic caption grey--text">
                {{ $store.state.global.pages.bill.title }} will only be created
                if control total and bill/docket total are equal
              </div>
            </v-card-subtitle>
            <v-col cols="12">
              <v-text-field
                label="Control Total"
                prepend-icon="mdi-currency-usd"
                type="number"
                v-model.number="controlTotal"
                :rules="totalRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <DatePickerWrapper
                v-model="date"
                :rules="dateRules"
                :label="`${$store.state.global.pages.bill.titleSingular} Date`"
                color="indigo"
              />
            </v-col>
            <v-card-subtitle class="py-2">
              Reference #
              <div class="font-italic caption grey--text">
                Notes: Reference numbers will be populated across all
                {{ $store.state.global.pages.bill.title }}
                created by this process.
              </div>
            </v-card-subtitle>
            <v-col cols="6">
              <v-text-field
                :label="
                  `Reference (${$store.state.global.pages.bill.titleSingular})`
                "
                prepend-icon="mdi-file"
                type="text"
                v-model="externalRef"
                :rules="externalRefRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="`${globalSettings.accountingSystem} Reference`"
                prepend-icon="mdi-file"
                type="text"
                disabled
                v-model="transactionId"
              ></v-text-field>
            </v-col>
            <v-card-subtitle class="py-2">
              Notes
              <div class="font-italic caption grey--text">
                These notes will apply for all bills about to be created
              </div>
            </v-card-subtitle>
            <v-col cols="12">
              <v-text-field
                label="Internal Notes"
                prepend-icon="mdi-note"
                v-model="internalNotes"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Invoice Notes"
                prepend-icon="mdi-note"
                v-model="invoiceNotes"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()" color="grey">Close</v-btn>
        <v-btn text type="submit" color="primary" :loading="loading"
          >Create</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import Store from '@/store/computed/storeHelpers'
import { addBills } from '@/services/requests/bills'
import BillValidation from '@/services/validation/bill'
import DatePickerWrapper from '../misc/fields/DatePickerWrapper'
export default {
  components: {
    DatePickerWrapper
  },
  mixins: [BillValidation],
  props: {
    dialogOpen: Boolean
  },
  data() {
    return {
      loading: false,
      working: false,
      controlTotal: null,
      date: new Date().toISOString().substr(0, 10),
      internalNotes: '',
      invoiceNotes: '',
      externalRef: '',
      transactionId: '',
      previewData: null
    }
  },
  computed: {
    billingPeriod: Store.getSet({
      store: 'global',
      prop: 'selectedBillingPeriod'
    }),
    projectId: Store.getSet({ store: 'global', prop: 'selectedProjectId' }),
    projectName() {
      if (this.projectId) {
        return this.$store.state.projects.projects.filter(project => {
          return project.id == this.projectId
        })[0]?.name
      } else {
        return ''
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    submitBillsForm() {
      if (!this.$refs.billingsForm.validate()) {
        this.snack({ color: 'amber', text: 'Please correct form errors!' })
        return
      }
      this.addBills()
    },
    async addBills() {
      this.loading = true
      const payload = {
        projectId: this.projectId,
        billingPeriod: this.billingPeriod,
        controlTotal: this.controlTotal,
        externalRef: this.externalRef,
        transactionId: this.transactionId,
        internalNotes: this.internalNotes,
        invoiceNotes: this.invoiceNotes,
        date: this.date
      }
      try {
        const result = await addBills(payload)
        this.loading = false
        this.resetForm()
        this.close()
        const billIds = result.data.result.createdBills.map(
          createdBill => createdBill.bill.id
        )
        const billIdsString = JSON.stringify(billIds)
        this.$router.push({ name: 'bill', query: { ids: billIdsString } })
      } catch (err) {
        console.log(err)
        this.snack({ text: err.response.data.message, color: 'red' })
        this.loading = false
      }
    },
    resetForm() {
      this.internalNotes = ''
      this.invoiceNotes = ''
    }
  }
}
</script>
