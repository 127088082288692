export default {
  data() {
    return {
      allAllocationStagingLineHeaders: [
        {
          text: `${this.$store.state.global.pages.allocation.titleSingular} ID`,
          value: 'allocation.id'
        },
        {
          text: 'ID',
          value: 'id'
        },
        { text: 'Date', value: 'allocation._date' },
        { text: 'Reference', value: 'allocation._reference' },
        { text: 'End Date', value: 'allocation._endDate' },
        {
          text: this.$store.state.global.pages.project.titleSingular,
          value: 'allocation.source.project.name'
        },
        {
          text: this.$store.state.global.pages.source.titleSingular,
          value: 'allocation.source.name'
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          value: 'destination.name'
        },
        {
          text: this.$store.state.global.pages.vehicle.titleSingular,
          value: 'allocation.vehicle.name'
        },
        {
          text: this.$store.state.global.pages.vehicleType.titleSingular,
          value: 'allocation.vehicle.vehicleType.name'
        },
        {
          text: this.$store.state.global.pages.contractor.titleSingular,
          value: 'allocation.vehicle.contractor.name'
        },
        {
          text: this.$store.state.global.pages.driver.titleSingular,
          value: 'allocation.driver.name'
        },

        {
          text: this.$store.state.global.pages.itemType.titleSingular,
          value: 'itemType.name'
        },
        { text: 'Quantity', value: 'qty', type: 'decimal', noOfDecimals: 2 },
        { text: 'POD', value: 'lineCount', type: 'decimal', noOfDecimals: 0 },
        {
          text: 'Notes',
          align: 'left',
          value: 'notes',
          type: 'text'
        },
        {
          text: `${this.$store.state.global.pages.allocation.titleSingular} Notes`,
          align: 'left',
          value: 'allocation.notes',
          type: 'text'
        },
        {
          text: 'Run #',
          align: 'right',
          value: 'destination.runCode',
          sortable: true
        },
        {
          text: `${this.$store.state.global.pages.docket.titleSingular} ID`,
          align: 'left',
          value: 'allocation.docketId',
          type: 'text'
        },
        {
          text: `${this.$store.state.global.pages.docket.titleSingular} lines`,
          align: 'right',
          value: 'docketLineCount',
          type: 'decimal',
          noOfDecimals: 0
        },
        {
          text: 'Delivery Status',
          align: 'center',
          value: 'deliveryStatus',
          type: 'deliveryStatus',
          sortable: true
        },
        {
          text: 'Staged',
          align: 'center',
          value: 'allocation.isStaged',
          type: 'boolean'
        },
        {
          text: `${this.$store.state.global.pages.source.titleSingular} Confirmed`,
          align: 'center',
          value: 'allocation.sourceConfirmed',
          type: 'confirmedIcon',
          icon: this.$store.state.global.pages.source.icon
        },
        {
          text: `${this.$store.state.global.pages.destination.titleSingular} Confirmed`,
          align: 'center',
          value: 'allocation.destinationConfirmed',
          type: 'confirmedIcon',
          icon: this.$store.state.global.pages.destination.icon
        },
        {
          text: 'Amendments Confirmed',
          align: 'center',
          value: 'allocation.amendmentsConfirmed',
          type: 'confirmedIcon',
          icon: 'mdi-pen-plus'
        },
        {
          text: 'Void',
          align: 'center',
          value: 'allocation.void',
          type: 'boolean'
        },
        {
          text: 'Void notes',
          align: 'center',
          value: 'allocation.voidNotes',
          type: 'text'
        },
        { text: 'Delivery Address', value: 'deliveryAddress', sortable: true },
        { text: 'Address1', value: 'addressStreet1' },
        { text: 'Address2', value: 'addressStreet2' },
        { text: 'City', value: 'addressCity' },
        { text: 'State', value: 'addressState' },
        { text: 'Entered By', value: 'createdByName' },
        { text: 'Updated By', value: 'updatedBy.name' },
        { text: 'Created At', value: '_createdAt' },
        { text: 'Updated At', value: '_updatedAt' }
      ]
    }
  }
}
