import Store from '@/store/computed/storeHelpers'
import AllocationStagingLineAllHeaders from './allocationStagingLineAllHeaders'
export default {
  mixins: [AllocationStagingLineAllHeaders],
  computed: {
    defaultHeaders: Store.getSet({
      store: 'allocations',
      prop: 'allocationStagingLineDefaultHeaders'
    }),
    allPossibleHeaders() {
      return this.allAllocationStagingLineHeaders
    }
  },
  data() {
    return {
      mobileHeaders: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          value: 'destination.name'
        }
      ]
    }
  }
}
