<template>
  <v-card flat class="card-outlined">
    <Loading
      v-if="
        loadingGpsLines || (this.$route.query.id && !this.currentRoute.length)
      "
    />
    <MglMap
      v-else
      :accessToken="accessToken"
      :mapStyle.sync="mapStyle"
      :center="center"
      :zoom="zoom"
      :style="`height: ${height}vh`"
    >
      <div v-if="!docket">
        <div v-for="item in selectedDockets" :key="item.id">
          <MglMarker
            v-if="item.coordinates"
            :coordinates="item.coordinates"
            :color="item.color"
          >
            <div slot="marker">
              <v-icon
                x-large
                :color="item.color"
                :class="getIconDirection(item)"
                >{{
                  item.speed > 15 / 3.6
                    ? 'mdi-truck-fast'
                    : $store.state.global.pages.vehicle.icon
                }}</v-icon
              >
            </div>
            <MapRoutePopup
              :item="item"
              :action="() => getRoute(item.id)"
              :loading="loadingRouteLines"
            />
          </MglMarker>
        </div>
        <div v-if="showTargets">
          <div v-for="item in targets" :key="`target-key-${item.id}`">
            <MglMarker v-if="item.coordinates" :coordinates="item.coordinates">
              <div slot="marker">
                <v-icon color="#555555">{{
                  $store.state.global.pages.destination.icon
                }}</v-icon>
              </div>
              <MapDestinationPopup :item="item" />
            </MglMarker>
          </div>
        </div>
      </div>

      <div v-else>
        <MglMarker
          v-if="currentRoute.length"
          :coordinates="currentRoute[currentRoute.length - 1]"
          :color="docket.color"
          :offset="[0, -20]"
        >
          <div slot="marker">
            <v-icon
              x-large
              :color="docket.driver.color"
              :class="getIconDirection(gpsLines[gpsLines.length - 1])"
              >{{ $store.state.global.pages.vehicle.icon }}</v-icon
            >
          </div>
          <MapCurrentLocPopup
            :item="{ docket, ...gpsLines[gpsLines.length - 1] }"
            :loading="loadingRouteLines"
          />
        </MglMarker>
        <div v-if="showTargets">
          <div v-for="item in targets" :key="`target-key2-${item.id}`">
            <MglMarker v-if="item.coordinates" :coordinates="item.coordinates">
              <div slot="marker">
                <v-icon color="#555555">{{
                  $store.state.global.pages.destination.icon
                }}</v-icon>
              </div>
              <MapDestinationPopup :item="item" />
            </MglMarker>
          </div>
        </div>

        <div v-for="(line, i) in currentRoute" :key="i">
          <MglMarker :coordinates="line" v-if="i % 20 == 0">
            <div slot="marker">
              <v-icon x-large :color="getColorFromSpeed(gpsLines[i].speed)"
                >mdi-circle-small</v-icon
              >
            </div>
            <MapRoutePointPopup
              v-if="docket"
              :item="{
                ...gpsLines[i],
                docket
              }"
              :loading="loadingRouteLines"
            />
          </MglMarker>
        </div>

        <div v-for="line in allocationLines" :key="`pod-key-${line.id}`">
          <MglMarker
            v-if="line.pods"
            :coordinates="[line.pods[0].long, line.pods[0].lat]"
          >
            <MapPodPopup
              :item="line"
              :action="() => showPods(line)"
              :loading="loadingRouteLines"
            />
          </MglMarker>
        </div>

        <MglGeojsonLayer
          :source="geoJsonSource"
          :sourceId="geoJsonSource.data.id"
          :layerId="geoJsonLayer.id"
          :layer="geoJsonLayer"
          ref="pathLayer"
        ></MglGeojsonLayer>
      </div>
    </MglMap>
  </v-card>
</template>

<script>
import Mapbox from 'mapbox-gl'
import { getDegrees, findFurthestPoints } from '../../utils/Distance'
import { MglMap, MglMarker, MglGeojsonLayer } from 'vue-mapbox'
import MapRoutePopup from './components/MapRoutePopup'
import MapRoutePointPopup from './components/MapRoutePointPopup'
import MapCurrentLocPopup from './components/MapCurrentLocPopup'
import MapDestinationPopup from './components/MapDestinationPopup'
import MapPodPopup from './components/MapPodPopup'
import Loading from '../misc/Loading'
import Store from '../../store/computed/storeHelpers'
import getDestinationsLite from '../../services/mixins/getMasterFiles/lite/getDestinationsLite'

export default {
  name: 'MapView',
  mixins: [getDestinationsLite],
  components: {
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    MapRoutePopup,
    MapRoutePointPopup,
    MapCurrentLocPopup,
    MapDestinationPopup,
    MapPodPopup,
    Loading
  },
  props: {
    docket: Object,
    allocationLines: Array,
    currentRoute: Array,
    gpsLines: Array,
    selectedDockets: Array,
    loadingGpsLines: Boolean,
    loadingRouteLines: Boolean,
    getRoute: Function,
    showPods: Function,
    clearDriver: Function,
    defaultGPSCoordinates: Array,
    height: {
      type: Number,
      default: () => 73
    }
  },
  data: () => ({
    accessToken: process.env.VUE_APP_MGL_KEY,
    mapStyle: 'mapbox://styles/mapbox/streets-v12', // your map style
    currentCenter: [133.7751, -25.2744]
  }),
  mounted() {
    this.$root.$on('removeLayer', () => {
      if (this.$refs.pathLayer) this.$refs.pathLayer.remove()
      this.clearDriver()
    })
  },
  created() {
    this.mapbox = Mapbox
  },
  methods: {
    getIconDirection(item) {
      if (item.speed < 0.5) return ''
      if (item.direction?.toLowerCase().includes('w')) return 'icon-flipped'
      if (item.direction?.toLowerCase().includes('e')) return ''
      if (item.direction?.toLowerCase().includes('south'))
        return 'icon-rotate-90'
      if (item.direction?.toLowerCase().includes('north'))
        return 'icon-rotate-270'
      return ''
    },
    getColorFromSpeed(mtrPerSec) {
      const speed = mtrPerSec * 3.6
      if (speed < 0.5) return '#FFFFFF'
      if (speed < 20) return '#1fe076'
      if (speed < 40) return '#93f10e'
      if (speed < 60) return '#e5e11a'
      if (speed < 80) return '#e8a817'
      if (speed < 111) return '#ed6412'
      return '#eb2214'
    },
    setCenter(center) {
      this.currentCenter = center
    }
  },
  computed: {
    targets() {
      return this.destinationsLite
        .filter(item => !!item.lat && !!item.long)
        .map(item => {
          return {
            id: item.id,
            name: item.name,
            addressStreet1: item.addressStreet1,
            addressCity: item.addressCity,
            coordinates: [item.long, item.lat]
          }
        })
    },
    lockPosition: Store.getSet({ store: 'map', prop: 'lockPosition' }),
    showTargets: Store.getSet({ store: 'map', prop: 'showTargets' }),
    geoJsonSource() {
      const coordinates = this.docket ? this.currentRoute : []
      return {
        type: 'geojson',
        data: {
          id: 'test',
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: coordinates
          }
        }
      }
    },
    geoJsonLayer() {
      const color = '#1ecbe1'
      return {
        id: 'route',
        type: 'line',
        source: 'test',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': color,
          'line-width': 8
        }
      }
    },
    center() {
      if (this.lockPosition) return this.currentCenter
      if (!this.currentDriverLocations.length && !this.currentRoute.length) {
        this.setCenter(this.defaultGPSCoordinates)
        return this.defaultGPSCoordinates
      }
      if (
        this.currentDriverLocations.length == 1 &&
        !this.currentRoute.length
      ) {
        this.setCenter(this.currentDriverLocations[0])
        return this.currentDriverLocations[0]
      }
      if (this.currentRoute.length == 1) {
        this.setCenter(this.currentRoute[0])
        return this.currentRoute[0]
      }
      const coordinates = this.currentRoute.length
        ? this.currentRoute
        : this.currentDriverLocations
      const degrees = getDegrees(coordinates)
      if (!degrees) {
        this.setCenter(this.defaultGPSCoordinates)
        return this.defaultGPSCoordinates
      }
      const { longDegrees, latDegrees, end } = degrees
      const pos = [end[0] + longDegrees / 2, end[1] + latDegrees / 2]
      this.setCenter(pos)
      return pos
    },
    zoom() {
      const defaultZoom = 8
      const { maxDist } = findFurthestPoints(
        this.currentRoute.length
          ? this.currentRoute
          : this.currentDriverLocations
      )
      if (!this.currentDriverLocations.length && !this.currentRoute.length)
        return 3.8
      if (maxDist < 1) return 13
      if (maxDist < 5) return 12
      if (maxDist < 10) return 12
      if (maxDist < 30) return 11 //
      if (maxDist < 50) return 10 //
      if (maxDist < 65) return 9.3 //
      if (maxDist < 90) return 9 //
      if (maxDist < 120) return 8.5 //
      if (maxDist < 200) return 7.85 //
      if (maxDist < 400) return 6.8 //
      if (maxDist < 800) return 5.9 //
      if (maxDist < 1600) return 4.9
      if (maxDist < 3200) return 3.9
      if (maxDist < 4000) return 4
      if (maxDist < 6400) return 3
      if (maxDist > 6400) return 1
      return defaultZoom
    },
    currentDriverLocations() {
      return this.selectedDockets?.map(item => {
        return item.coordinates
          ? [item.coordinates[0], item.coordinates[1]]
          : this.defaultGPSCoordinates
      })
    }
  }
}
</script>
<style>
.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}
.icon-rotate-90 {
  transform: rotate(90deg);
}

.icon-rotate-270 {
  transform: rotate(270deg);
}
</style>
