import Store from '@/store/computed/storeHelpers'
import { getProjects } from '../../requests/projects'
export default {
  mounted() {
    if (this.pagination) this.initPagination()
    if (!this.loadingProjects) this.getProjects()
  },
  data() {
    return {
      loadedProjects: false,
      projectCount: 0
    }
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        if (!this.loadingProjects) this.getProjects()
      }
    },
    sortDesc(val) {
      this.pagination.sortDesc = [val]
    },
    showInactive() {
      this.getProjects()
    }
  },
  computed: {
    sortDesc: Store.getSet({ store: 'masterfiles', prop: 'sortDesc' }),
    projects: Store.getSet({ store: 'projects', prop: 'projects' }),
    loadingProjects: Store.getSet({
      store: 'projects',
      prop: 'loadingProjects'
    }),
    showInactive: Store.getSet({ store: 'masterfiles', prop: 'showInactive' })
  },
  methods: {
    async getProjects() {
      try {
        this.loadingProjects = true
        const payload =
          this.masterFile == 'project'
            ? {
                pagination: this.pagination,
                filterBy: this.filterBy,
                showInactive: this.showInactive
              }
            : {}
        const result = await getProjects(payload)
        if (result) {
          this.projects = result.data.result
          this.projectCount = result.data.count
        }
        this.loadedProjects = true
        this.loadingProjects = false
      } catch (err) {
        console.log(err)
        this.loadingProjects = false
      }
    }
  }
}
