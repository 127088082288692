<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>{{ item.name }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-menu left right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="() => openDetailsDialog(item.key)">
            <v-list-item-icon>
              <v-icon color="primary">mdi-eye</v-icon>
            </v-list-item-icon>
            <v-list-item-title>View</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="() => (editDialog = true)"
            v-if="$store.state.user.userAuthClass.policyWrite"
          >
            <v-list-item-icon>
              <v-icon color="grey">
                mdi-pencil
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Edit
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="goToAudit">
            <v-list-item-icon>
              <v-icon color="grey">
                {{ $store.state.global.pages.audit.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Audit
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="() => (deleteDialog = true)"
            v-if="$store.state.user.userAuthClass.policyWrite"
          >
            <v-list-item-icon>
              <v-icon color="red">mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>

    <v-dialog v-model="detailsDialog">
      <v-card>
        <v-img :src="url" alt="" />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editDialog"
      width="700"
      @click:outside="() => (editDialog = false)"
      @keydown.esc="() => (editDialog = false)"
    >
      <MasterFileForm
        masterFile="policy"
        :dialog="editDialog"
        :fields="fields"
        :subject="item"
        :onSubmit="onEdit"
        :loading="loading"
        :close="() => (editDialog = false)"
      />
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      @click:outside="() => (deleteDialog = false)"
      @keydown.esc="() => (deleteDialog = false)"
      max-width="500px"
    >
      <DeleteDialog
        :dialog="deleteDialog"
        :page="titleSingular"
        :subject="item"
        :action="deletePolicy"
        :close="() => (deleteDialog = false)"
      />
    </v-dialog>
  </v-list-item>
</template>

<script>
import { getS3Url } from '../../../services/authentication'
import policyFields from '../../../services/mixins/fields/policyFields'
import MasterFileForm from '../../masterFiles/MasterFileForm'
import DeleteDialog from '../../misc/DeleteDialog'
export default {
  mixins: [policyFields],
  components: { MasterFileForm, DeleteDialog },
  props: {
    item: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    onEdit: {
      type: Function,
      required: true
    },
    deletePolicy: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      detailsDialog: false,
      editDialog: false,
      deleteDialog: false,
      url: null
    }
  },
  methods: {
    goToAudit() {
      this.$router.push({
        name: 'audit',
        query: {
          id: this.item.id,
          model: 'policy'
        }
      })
    },
    async openDetailsDialog(key) {
      this.url = await this.getUrl(key)
      const isPdf = this.url.toLowerCase().includes('pdf')
      if (isPdf) window.open(this.url)
      else this.detailsDialog = true
    },
    async getUrl(key) {
      if (key) {
        try {
          const result = await getS3Url({
            key,
            bucket: 'payloader-policies'
          })
          return result.data.result
        } catch (err) {
          console.log(err)
        }
      }
    }
  }
}
</script>
