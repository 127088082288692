import Store from '@/store/computed/storeHelpers'
import RateAllHeaders from './rateAllHeaders'
export default {
  mixins: [RateAllHeaders],
  computed: {
    defaultHeaders: Store.getSet({
      store: 'rates',
      prop: 'rateDefaultHeaders'
    }),
    allPossibleHeaders() {
      return this.allRateHeaders
    }
  },
  data() {
    return {
      mobileHeaders: [
        {
          text: this.$store.state.global.pages.source.titleSingular,
          value: 'source.name'
        },
        {
          text: this.$store.state.global.pages.vehicleType.titleSingular,
          value: 'truncatedVehicleTypes'
        }
      ]
    }
  }
}
