<template>
  <v-row class="pt-5">
    <v-col cols="9" class="d-flex align-center"
      >Default
      {{ $store.state.global.pages.source.titleSingular.toLowerCase() }}</v-col
    >
    <v-col cols="3" class="d-flex align-center">
      <v-autocomplete
        v-model="defaultSourceId"
        hide-details
        class="mt-0"
        :items="sourcesLite"
        item-text="sourceName_projectName"
        item-value="id"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between align-center">
      Dark mode
      <v-switch inset v-model="darkMode" hide-details class="mt-0"></v-switch>
    </v-col>
    <v-col cols="12">
      <v-divider></v-divider>
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between align-center">
      <span>
        Two-factor Authentication
        <span v-if="verified2FA == 2">(Active)</span>
      </span>
      <v-btn @click="generateQr" v-if="!enabled2FA">Enable 2FA</v-btn>
      <v-btn @click="() => (disableDialog = true)" v-if="enabled2FA">
        Disable 2FA
      </v-btn>
      <v-dialog v-model="disableDialog" max-width="500">
        <v-card>
          <v-form ref="disableForm" @submit.prevent="disable2FA()">
            <v-card-title>Disable two-factor authentication</v-card-title>
            <v-card-text>
              Are you sure you want to disable two-factor authentication?
              <v-text-field
                label="Password"
                type="password"
                v-model="password"
                :rules="passwordRules"
              >
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn type="submit" :loading="disableLoading">Disable 2FA</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col cols="12" class="pb-0" v-if="enabled2FA && verified2FA != 2">
      To activate two-factor authentication scan the QR code using Google
      Authenticator and enter the code labelled Payloader from the app and click
      verify.
      <br /><br />
      {{ !verified2FA ? 2 : 1 }} code(s) remaining until two-factor
      authentication is activated.
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
      xl="3"
      class="d-flex justify-space-between"
      v-if="enabled2FA && verified2FA != 2"
    >
      <v-text-field label="2FA Code" v-model="code">
        <template v-slot:append>
          <v-icon :color="verified2FA > 0 ? 'green' : 'grey'">
            mdi-check-circle
          </v-icon>
          <v-icon :color="verified2FA == 2 ? 'green' : 'grey'">
            mdi-check-circle
          </v-icon>
        </template>
      </v-text-field>
      <v-btn @click="verify2FA" class="ml-3 mt-3">Verify</v-btn>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="8"
      xl="9"
      v-if="qrUrl && verified2FA != 2 && enabled2FA"
      class="d-flex justify-end"
    >
      <v-img :src="qrUrl" height="200" max-width="200"></v-img>
    </v-col>
  </v-row>
</template>

<script>
import Store from '../../store/computed/storeHelpers'
import getSourcesLite from '../../services/mixins/getMasterFiles/lite/getSourcesLite'
import {
  generateQrCode,
  verify2FA,
  disable2FA,
  getVerified2FA
} from '../../services/authentication'
import AuthValidation from '@/services/validation/authValidation'
export default {
  mixins: [getSourcesLite, AuthValidation],
  data() {
    return {
      qrUrl: null,
      code: null,
      verified2FA: null,
      disableDialog: false,
      password: '',
      disableLoading: false
    }
  },
  mounted() {
    if (this.enabled2FA) this.get2FAVerification()
  },
  computed: {
    darkMode: Store.getSet({ store: 'global', prop: 'darkMode' }),
    defaultSourceId: Store.getSet({ store: 'global', prop: 'defaultSourceId' }),
    enabled2FA: Store.getSet({ store: 'global', prop: 'enabled2FA' })
  },
  methods: {
    async generateQr() {
      const result = await generateQrCode()
      if (!result) return
      this.qrUrl = result.data.result
      this.enabled2FA = true
    },
    async verify2FA() {
      if (!this.code) return
      const result = await verify2FA({ code: this.code })
      this.verified2FA = result.data.result.verified2FA
    },
    async disable2FA() {
      if (!this.$refs.disableForm.validate()) {
        this.snackFormError()
        return
      }
      this.disableLoading = true

      const payload = { password: this.password }
      try {
        await disable2FA(payload)
        this.enabled2FA = false
        this.disableLoading = false
        this.disableDialog = false
      } catch (err) {
        this.disableLoading = false
        this.snack({
          text: `${err.response.data.message} (${err.response.status}) `,
          color: 'amber'
        })
      }
    },
    async get2FAVerification() {
      const result = await getVerified2FA()
      this.verified2FA = result.data.result.verified2FA
    }
  }
}
</script>
