import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    users: [],
    usersLite: [],
    loadingUsers: false,
    loadingUsersLite: false,
    userAuthClasses: [],
    loadingUserAuthClasses: false,
    userAuthClassDefaultHeaders: [
      'name',
      'allocationWrite',
      'docketWrite',
      'projectWrite'
    ]
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
