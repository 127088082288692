export default {
  computed: {
    passwordsMatchErrorMessage() {
      let password = ''
      let confirmedPassword = ''
      if (this.clonedFields) {
        for (const field of this.clonedFields) {
          if (field.prop === 'password') password = field.value
          if (field.prop === 'confirmedPassword')
            confirmedPassword = field.value
        }
      } else {
        password = this.password
        confirmedPassword = this.confirmedPassword
      }
      if (password === confirmedPassword) {
        return []
      }
      return ['Passwords do not match!']
    }
  }
}
