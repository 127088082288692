<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      flat
      class="card-outlined"
      :elevation="hover ? 3 : 0"
      :class="{ 'on-hover': hover }"
      ripple
      router
      :to="{ name: route }"
    >
      <v-card-text>
        <v-row>
          <v-col cols="3" class="d-flex justify-center pa-0">
            <v-icon size="50px" color="primary">{{ icon }}</v-icon>
          </v-col>
          <v-col cols="9" class="pa-0">
            <v-card-title class="font-weight-regular">{{ title }}</v-card-title>
            <v-card-subtitle>{{ text }}</v-card-subtitle>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="mx-5"></v-divider>
      <v-card-text class="d-flex justify-space-between">
        <div v-if="route != 'map'">
          <div v-if="loading">
            <v-progress-circular
              width="3"
              size="20"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <v-scale-transition>
            <div v-show="!loading && number">
              <span>{{ loading || !number ? '...' : number }}</span>
              <span>
                {{ number > 1 ? label : label.slice(0, -1) }}
                <span v-if="route == 'docket' || route == 'allocation'">
                  this month</span
                >
              </span>

              <v-tooltip
                v-if="(route == 'docket' || route == 'allocation') && !loading"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <span
                    v-on="on"
                    :class="{
                      'green--text': growthPercentage > 0,
                      'red--text': growthPercentage < 0,
                      'ml-3': true
                    }"
                    >{{ growthPercentage.toFixed(2) }}%
                    <v-icon
                      v-if="growthPercentage"
                      small
                      class="mt-0 pb-1"
                      :color="growthPercentage < 0 ? 'red' : 'green'"
                      >{{
                        growthPercentage > 0
                          ? 'mdi-arrow-up-bold'
                          : 'mdi-arrow-down-bold'
                      }}</v-icon
                    >
                  </span>
                </template>
                <span>Compared with last month to date</span>
              </v-tooltip>
            </div>
          </v-scale-transition>
        </div>
        <div v-else></div>

        <v-icon class="pr-1" color="grey">{{ icon }}</v-icon>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    title: String,
    text: String,
    number: Number,
    lastMth: Number,
    label: String,
    icon: String,
    route: String,
    loading: Boolean
  },
  computed: {
    growthPercentage() {
      if (!this.lastMth) return 100
      return ((this.number - this.lastMth) / this.lastMth) * 100
    }
  }
}
</script>
