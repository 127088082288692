<template>
  <v-card>
    <v-card-text class="pb-0">
      <v-card-title class="px-0 pb-1 font-weight-regular">
        Create {{ $store.state.global.pages.rcti.title }} for
        <span class="font-weight-bold px-1">
          {{ selectedProject.name }}: {{ selectedBillingPeriod }}
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <template v-if="previewData">
        <v-card-subtitle>
          {{ linesSubtitle }}
          <div v-if="!!previewData.adjustmentTripCount">
            <span class="indigo--text font-italic">
              {{ creditsSubtitle }}
            </span>
          </div>
        </v-card-subtitle>
        <v-col cols="12" v-if="!!previewData.incompleteDockets.length">
          <v-alert
            :value="true"
            type="error"
            class="mb-3"
            outlined
            style="border-radius: 4px;"
          >
            {{ previewData.incompleteDockets.length }} unconfirmed excluded!
          </v-alert>
          <div
            v-for="docketRef in previewData.incompleteDockets"
            :key="docketRef"
            class="text-xs-left pl-2 body-2 grey--text"
          >
            -
            <a
              href
              @click.prevent="searchForDocket(docketRef)"
              class="grey--text text--darken-1"
              >{{ docketRef }}</a
            >
          </div>
        </v-col>
        <v-divider></v-divider>
        <v-card-subtitle>
          {{ $store.state.global.pages.contractor.title }}
        </v-card-subtitle>
      </template>

      <v-expansion-panels v-if="previewData">
        <v-expansion-panel
          v-for="(contractor, i) in previewData.subjects"
          :key="i"
        >
          <v-expansion-panel-header>
            <div class="font-weight-medium grey--text text--darken-2">
              {{ `${contractor.name} - ${contractor.adminFeePercentage}%` }}
            </div>
            <template v-slot:actions>
              <span class="pt-2"> ${{ contractor.total.toFixed(2) }} </span>
              <v-icon>
                mdi-chevron-down
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list two-line dense class="pt-0">
              <v-divider></v-divider>

              <v-list-item :href="`mailto:${contractor.email}`">
                <v-list-item-action>
                  <v-icon color="primary">mdi-email</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title class="body-2">
                    Email
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ contractor.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item :href="`tel:${contractor.contactPhone}`">
                <v-list-item-action>
                  <v-icon color="primary">mdi-phone</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title class="body-2">
                    Phone
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ contractor.contactPhone }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
            </v-list>
            <div class="px-3 pb-3 pt-1">
              Lines: {{ contractor.docketLineCount }}
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div v-else class="ma-3 pt-5 subtitle-2 red--text text--lighten-3">
        <div v-if="!loading">
          <div>No transactions found!</div>
          <div>
            Please select a different project or billing period combination.
          </div>
        </div>
        <div v-else class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </div>

      <br />
      <span>Notes</span><br />
      <span
        >These notes will apply for all
        {{ $store.state.global.pages.rcti.title }} about to be created</span
      >
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="internalNotes"
            prepend-icon="mdi-note"
            label="Internal Notes"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="invoiceNotes"
            prepend-icon="mdi-note"
            label="Invoice Notes"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="close">
        Close
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="submitRctisForm"
        :disabled="!previewData"
        :loading="loading"
        >Create</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Store from '../../store/computed/storeHelpers'
import { addRctis } from '../../services/requests/rctis'
import { getPreviewData } from '../../services/requests/rctis'

export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    projects: {
      type: Array,
      required: false
    }
  },
  mounted() {
    this.previewData = null
    this.getPreviewData()
  },
  data() {
    return {
      loading: false,
      internalNotes: null,
      invoiceNotes: null,
      previewData: null
    }
  },
  watch: {
    dialog(val) {
      if (val) {
        this.previewData = null
        this.getPreviewData()
      }
    }
  },
  computed: {
    selectedBillingPeriod: Store.getSet({
      store: 'global',
      prop: 'selectedBillingPeriod'
    }),
    selectedProjectId: Store.getSet({
      store: 'global',
      prop: 'selectedProjectId'
    }),
    selectedProject() {
      if (this.selectedProjectId && this.projects.length) {
        return this.projects.find(({ id }) => id == this.selectedProjectId)
      } else {
        return {}
      }
    },
    linesSubtitle() {
      if (this.previewData) {
        return `${this.previewData.docketLineCount} lines spanning ${
          this.previewData.docketCount
        } 
        ${this.$store.state.global.pages.docket.titleSingular.toLowerCase()}(s)`
      }
      return ''
    },
    creditsSubtitle() {
      if (this.previewData) {
        return `Includes ${this.previewData.adjustmentTripCount} credit or adjustment lines`
      }
      return ''
    }
  },
  methods: {
    async getPreviewData() {
      try {
        if (!this.selectedBillingPeriod || !this.selectedProjectId) return
        this.loading = true
        const payload = {
          billingPeriod: this.selectedBillingPeriod,
          projectId: this.selectedProjectId
        }
        const result = await getPreviewData(payload)
        if (result) this.previewData = result.data.result
        this.loading = false
      } catch (err) {
        console.log(err)
        this.loading = false
      }
    },
    openAddRctisDialog() {
      if (!this.selectedProjectId || !this.selectedBillingPeriod) {
        this.snack({
          color: 'amber',
          text: 'You must select an open billing period and a project!'
        })
        return
      } else {
        this.addRctisDialog = true
      }
    },
    submitRctisForm() {
      if (!this.previewData.docketLineIds.length) {
        this.snack({ color: 'amber', text: 'No docketLines found!' })
        return
      }
      this.addRctis()
    },
    async addRctis() {
      try {
        this.loading = true
        const payload = {
          internalNotes: this.internalNotes,
          invoiceNotes: this.invoiceNotes,
          docketLineIds: this.previewData.docketLineIds
        }
        const result = await addRctis(payload)
        if (result) {
          this.resetForm()
          this.close()
          let rctiIds = result.data.result.createdRctis.map(createdRcti => {
            return createdRcti.rcti.id
          })
          rctiIds = JSON.stringify(rctiIds)
          this.$router.push({ name: 'rcti', query: { ids: rctiIds } })
        }
        this.loading = false
      } catch (err) {
        console.log(err)
        this.snack({ color: 'red', text: err.message })
        this.loading = false
      }
    },
    resetForm() {
      this.internalNotes = ''
      this.invoiceNotes = ''
    }
  }
}
</script>

<style scoped>
::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
}
</style>
