<template>
  <v-tooltip left transition="slide-x-transition" :disabled="!toolTipText">
    <template v-slot:activator="{ on }">
      <div v-on="on" class="py-3">
        <v-menu
          bottom
          left
          transition="scale-transition"
          max-height="600px"
          origin="top right"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-btn text icon color="grey" v-on="on">
              <v-icon>mdi-view-column</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="header of possibleHeaders" :key="header.value">
              <v-list-item-action>
                <v-checkbox
                  color="secondary"
                  v-model="selectedHeaders"
                  :label="header.text"
                  :value="header.value"
                ></v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <span>{{ toolTipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    possibleHeaders: {
      type: Array,
      required: true
    },
    defaultHeaders: {
      type: Array,
      required: true
    },
    toolTipText: {
      type: String,
      required: false
    },
    onChanged: {
      type: Function,
      required: true
    }
  },
  computed: {
    selectedHeaders: {
      get() {
        return this.defaultHeaders
      },
      set(val) {
        this.onChanged(val)
      }
    }
  }
}
</script>
