import Store from '@/store/computed/storeHelpers'
import { getPolicies } from '../../requests/policies'
export default {
  mounted() {
    if (this.pagination) this.initPagination()
    this.getPolicies()
  },
  computed: {
    policies: Store.getSet({ store: 'policies', prop: 'policies' }),
    loadingPolicies: Store.getSet({
      store: 'policies',
      prop: 'loadingPolicies'
    })
  },
  methods: {
    async getPolicies() {
      try {
        this.loadingPolicies = true
        const result = await getPolicies()
        if (result) this.policies = result.data.result
        this.loadingPolicies = false
      } catch (err) {
        console.log(err)
        this.loadingPolicies = false
      }
    }
  }
}
