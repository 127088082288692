export default {
  data() {
    return {
      allAllocationHeaders: [
        {
          text: 'ID',
          value: 'id'
        },
        { text: 'Date', value: '_date' },
        { text: 'Reference', value: '_reference' },
        { text: 'End Date', value: '_endDate' },
        {
          text: this.$store.state.global.pages.project.titleSingular,
          value: 'source.project.name'
        },
        {
          text: this.$store.state.global.pages.source.titleSingular,
          value: 'source.name'
        },
        {
          text: this.$store.state.global.pages.contractor.titleSingular,
          value: 'vehicle.contractor.name'
        },
        {
          text: this.$store.state.global.pages.driver.titleSingular,
          value: 'driver.name'
        },
        {
          text: this.$store.state.global.pages.vehicle.titleSingular,
          value: 'vehicle.name'
        },
        {
          text: this.$store.state.global.pages.vehicleType.titleSingular,
          value: 'vehicle.vehicleType.name'
        },
        {
          text: 'Max tonnage',
          value: 'vehicle.vehicleType.tonnage'
        },
        {
          text: 'Deliveries',
          align: 'right',
          value: 'lineCount',
          type: 'decimal',
          noOfDecimals: 0
        },
        {
          text: 'Notes',
          align: 'left',
          value: 'notes',
          type: 'text'
        },
        {
          text: 'Day / Night',
          align: 'center',
          value: 'isDay',
          type: 'dayBoolean'
        },
        {
          text: 'Void',
          align: 'center',
          value: 'void',
          type: 'boolean'
        },
        {
          text: 'Void notes',
          align: 'center',
          value: 'voidNotes',
          type: 'text'
        },
        {
          text: `${this.$store.state.global.pages.docket.titleSingular} ID`,
          align: 'left',
          value: 'docketId',
          type: 'text'
        },
        {
          text: `${this.$store.state.global.pages.plan.titleSingular} ID`,
          align: 'left',
          value: 'planId',
          type: 'text'
        },
        { text: 'Entered By', value: 'createdByName' },
        { text: 'Updated By', value: 'updatedBy.name' },
        { text: 'Created At', value: '_createdAt' },
        { text: 'Updated At', value: '_updatedAt' }
      ]
    }
  }
}
