import Store from '@/store/computed/storeHelpers'
import ApprovedBillAllHeaders from './approvedBillLineAllHeaders'
export default {
  mixins: [ApprovedBillAllHeaders],
  computed: {
    defaultHeaders: Store.getSet({
      store: 'approvedBillLines',
      prop: 'approvedBillLineDefaultHeaders'
    }),
    allPossibleHeaders() {
      return this.allApprovedBillLineHeaders
    }
  }
}
