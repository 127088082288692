<template>
  <div class="v-list-item__content">
    <div v-if="!active" class="grey--text v-list-item__title">
      {{ name }} (Inactive)
    </div>
    <div v-else class="v-list-item__title">
      {{ name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  }
}
</script>
