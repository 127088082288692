<template>
  <MglPopup :closeButton="false">
    <v-list dense class="py-0">
      <v-list-item>
        <v-list-item-icon class="mr-4">
          <v-icon>{{ $store.state.global.pages.destination.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.destination.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <MapPopupButton
        text="DELIVERY DETAILS"
        :action="action"
        :loading="loading"
      />
    </v-list>
  </MglPopup>
</template>

<script>
import { MglPopup } from 'vue-mapbox'
import MapPopupButton from './MapPopupButton'
export default {
  components: {
    MglPopup,
    MapPopupButton
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  }
}
</script>
