import { getCurrentGpsLines } from '../../../services/requests/gpsLines'
import { getActiveDockets } from '../../../services/requests/dockets'
import Docket from '@/services/models/docket'
export default {
  data() {
    return {
      locationsInterval: null,
      loadingGpsLines: false
    }
  },
  methods: {
    async getCurrentGpsLines(payload) {
      try {
        this.loadingGpsLines = true
        const result = await getCurrentGpsLines(payload)
        if (result.data.result.length) this.currentGpsLines = result.data.result
        this.loadingGpsLines = false
      } catch (err) {
        this.loadingGpsLines = false
        console.log(err)
      }
    },
    // * REFRESH LOCATIONS FUNCTIONS
    setLocationsInterval(docket) {
      if (docket) {
        if (this.locationsInterval) clearInterval(this.locationsInterval)
      } else {
        this.locationsInterval = setInterval(this.updateLocations, 15000)
      }
    },
    async updateLocations() {
      const result = await getActiveDockets()
      if (!result) return
      const items = result.data.result
      this.removeOldDockets(items)
      this.addNewDockets(items)
    },
    removeOldDockets(items) {
      const toRemove = []
      for (const [i, item] of this.openDockets.entries()) {
        const duplicate = items.find(({ id }) => id === item.id)
        if (!duplicate) toRemove.push(i)
      }
      for (const i of toRemove) {
        this.openDockets.splice(i, 1)
      }
    },
    addNewDockets(items) {
      const dockets = []
      for (const item of items) {
        const exists = this.openDockets.find(({ id }) => id === item.docket.id)
        if (exists) continue
        const docket = new Docket(item.docket)
        docket.coordinates = [item.gpsLines[0].long, item.gpsLines[0].lat]
        docket.direction = item.gpsLines[0].direction
        docket.speed = item.gpsLines[0].speed
        docket.distance = item.gpsLines[0]?.odometer
          ? (item.gpsLines[0].odometer - item.gpsLines[1].odometer) / 1000
          : 0
        docket.startOdometer = item.gpsLines[1].odometer
        dockets.push(docket)
      }

      if (dockets.length) {
        this.openDockets.push(...dockets)
        this.setColors()
      }
    }
  }
}
