import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    destinations: [],
    destinationsLite: [],
    runs: [],
    adding: false,
    loadingDestinations: false,
    loadingDestinationsLite: false
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
