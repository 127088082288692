<template>
  <v-container
    fluid
    v-if="$store.state.user.userAuthClass[`${$route.name}Read`]"
  >
    <v-row>
      <v-col cols="12" class="py-0">
        <ControlCard
          :toggleDrawer="() => (drawer = !drawer)"
          :filterCols="filterCols"
          :filterCol="filterCol"
          :filterBy="filterBy"
          :onFilterColChange="val => (filterCol = val)"
          :onFilterByChange="val => (filterBy = val)"
        >
          <template v-slot:header>
            <CardHeader
              :title="$store.state.global.pages.bill.title"
              :icon="$store.state.global.pages[$route.name].icon"
              :actions="controlActions"
              :actionBtn="actionBtn"
            />
          </template>
        </ControlCard>
      </v-col>
      <v-col cols="12" v-if="loadedUserSettings">
        <template>
          <BillView />
        </template>
      </v-col>
    </v-row>

    <FilterDrawer
      v-model="drawer"
      :users="usersLite"
      :sources="sourcesLite"
      :projects="projectsLite"
      :destinations="destinationsLite"
    />
  </v-container>
</template>

<script>
import Store from '../../store/computed/storeHelpers'

import ControlCard from '../../components/misc/shared/ControlCard'
import CardHeader from '../../components/misc/shared/CardHeader'
import BillView from '../../components/bills/BillView'
import FilterDrawer from '../../components/misc/shared/FilterDrawer'
import getUsersLite from '../../services/mixins/getMasterFiles/lite/getUsersLite'
import getSourcesLite from '../../services/mixins/getMasterFiles/lite/getSourcesLite'
import getProjectsLite from '../../services/mixins/getMasterFiles/lite/getProjectsLite'
import getDestinationsLite from '../../services/mixins/getMasterFiles/lite/getDestinationsLite'
import getBillingPeriods from '../../services/mixins/getMasterFiles/getBillingPeriods'
import GetSettings from '../../services/mixins/getSettings'

export default {
  components: {
    ControlCard,
    CardHeader,
    BillView,
    FilterDrawer
  },
  mixins: [
    getUsersLite,
    getSourcesLite,
    getProjectsLite,
    getDestinationsLite,
    getBillingPeriods,
    GetSettings
  ],
  data() {
    return {
      drawer: false,
      addDialog: false,
      actionBtn: undefined
    }
  },
  mounted() {
    this.setDocId(), this.getAction()
  },
  computed: {
    loadedUserSettings: Store.getSet({
      store: 'global',
      prop: 'loadedUserSettings'
    }),
    filterCol: Store.getSet({ store: 'bills', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'bills', prop: 'filterBy' }),
    controlActions() {
      return [
        {
          action: () => (this.drawer = !this.drawer),
          icon: 'mdi-filter-variant-plus',
          toolTipText: 'Filters',
          color: 'grey'
        }
      ]
    },
    filterCols() {
      const cols = [
        {
          value: 'project.name',
          text: this.$store.state.global.pages.project.titleSingular
        },
        {
          value: 'source.name',
          text: this.$store.state.global.pages.source.titleSingular
        },
        {
          value: 'createdBy.name',
          text: 'Entered By'
        },
        {
          value: 'transactionId',
          text: `${this.$store.state.settings.settings.accountingSystem} Reference`
        },
        {
          value: 'externalRef',
          text: `${this.$store.state.global.pages.bill.titleSingular} reference`
        },
        {
          value: 'abn',
          text: 'ABN'
        },
        {
          value: 'internalNotes',
          text: 'Internal Notes'
        },
        {
          value: 'invoiceNotes',
          text: 'Invoice Notes'
        },
        {
          value: 'type',
          text: 'Type'
        },
        {
          value: 'status',
          text: 'Status'
        },
        {
          value: 'bill.id',
          text: `${this.$store.state.global.pages.bill.titleSingular} ID`
        }
      ]
      return cols
    }
  },
  methods: {
    async getAction() {
      const { useApprovedBilling } = await this.getSettings()
      if (!useApprovedBilling) this.actionBtn = undefined
      else
        this.actionBtn = {
          text: this.$store.state.global.pages.approvedBillLine.title,
          onClick: () => this.$router.push({ name: 'approvedBillLine' })
        }
    },
    toggleAdd() {
      this.addDialog = !this.addDialog
      if (!this.addDialog) this.addMoreDialog = true
    },
    setDocId() {
      if (this.$route.query.id) {
        this.filterCol = 'bill.id'
        this.filterBy = this.$route.query.id.toString()
      }
    }
  }
}
</script>
