var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadingMasterFile)?_c('Loading'):_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-data-iterator',{attrs:{"items":_vm.subjects,"items-per-page":_vm.pagination.itemsPerPage,"page":_vm.pagination.page,"sort-by":_vm.pagination.sortBy[0],"sort-desc":_vm.pagination.sortDesc[0],"hide-default-footer":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"mt-8 mx-4",attrs:{"align":"center","justify":"center"}},[_vm._v(" No matching records found ")])]},proxy:true},{key:"header",fn:function(){return [_c('v-row',{staticClass:"mt-8 mx-4",attrs:{"align":"center","justify":"center"}},[_c('span',{staticClass:"grey--text mb-3"},[_vm._v("Items per page")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mb-3",attrs:{"dark":"","text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.pagination.itemsPerPage)+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function () { return (_vm.pagination.itemsPerPage = number); }}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1),_c('v-spacer'),(_vm.$route.name != 'vehicleType' && _vm.$route.name !== 'user')?_c('MasterFileExport',{attrs:{"masterFile":_vm.masterFile,"filterBy":_vm.filterBy}}):_vm._e(),_c('span',{staticClass:"mr-4 mb-3 grey--text"},[_vm._v(" Page "+_vm._s(_vm.pagination.page)+" of "+_vm._s(_vm.count)+" ")]),_c('v-btn',{staticClass:"mr-1 mb-3",attrs:{"outlined":"","color":"primary","small":"","disabled":_vm.pagination.page <= 1,"icon":""},on:{"click":function () { return (_vm.pagination.page = _vm.pagination.page - 1); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ml-1 mb-3",attrs:{"outlined":"","color":"primary","small":"","disabled":_vm.pagination.page >= _vm.count,"icon":""},on:{"click":function () { return (_vm.pagination.page = _vm.pagination.page + 1); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1)],1)]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('transition-group',{attrs:{"name":"data-list"}},_vm._l((items),function(subject){return _c('v-col',{key:subject.id,staticClass:"pb-0 data-list-item",attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('MasterFileCard',{attrs:{"masterFile":_vm.masterFile,"fields":_vm.fields,"subject":subject,"text":'Test',"label":'Test',"icon":_vm.$store.state.global.pages[_vm.$route.name].icon,"loading":_vm.loading,"loadingMasterFiles":_vm.loadingMasterFiles,"onEdit":_vm.onEdit,"onUpdatePassword":_vm.onUpdatePassword,"onValidateLogin":_vm.onValidateLogin,"onDelete":_vm.onDelete}})],1)}),1)]}},{key:"footer",fn:function(){return [(_vm.count)?_c('v-row',{staticClass:"ma-4",attrs:{"align":"center","justify":"center"}},[_c('span',{staticClass:"grey--text mb-3"},[_vm._v("Items per page")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mb-3",attrs:{"dark":"","text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.pagination.itemsPerPage)+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2562754172)},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function () { return (_vm.pagination.itemsPerPage = number); }}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1),_c('v-spacer'),_c('span',{staticClass:"mr-4 mb-3 grey--text"},[_vm._v(" Page "+_vm._s(_vm.pagination.page)+" of "+_vm._s(_vm.count)+" ")]),_c('v-btn',{staticClass:"mr-1 mb-3",attrs:{"outlined":"","color":"primary","small":"","disabled":_vm.pagination.page <= 1,"icon":""},on:{"click":function () { return (_vm.pagination.page = _vm.pagination.page - 1); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ml-1 mb-3",attrs:{"outlined":"","color":"primary","small":"","disabled":_vm.pagination.page >= _vm.count,"icon":""},on:{"click":function () { return (_vm.pagination.page = _vm.pagination.page + 1); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1)],1):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }