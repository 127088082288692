<template>
  <v-container fluid class="px-5">
    <v-row>
      <transition-group name="data-list">
        <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
          v-for="page in $store.state.global.mainPages"
          :key="page.title"
          class="data-list-item pa-1"
        >
          <DashboardCard
            :title="page.menuTitle ? page.menuTitle : page.title"
            :text="page.text"
            :number="transactionCount[page.route]"
            :lastMth="transactionCount[page.route + 'LastMth']"
            :label="page.label"
            :icon="page.icon"
            :route="page.route"
            :loading="loadingTransactionCount"
          />
        </v-col>
        <div key="divider" class="text-center py-2">
          <v-divider inset style="width:100%; margin: auto;" />
        </div>
        <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
          v-for="page in $store.state.global.masterFilePages"
          :key="page.title"
          class="data-list-item pa-1"
        >
          <DashboardCard
            :title="page.menuTitle ? page.menuTitle : page.title"
            :text="page.text"
            :number="masterCount[page.route]"
            :label="page.label"
            :icon="page.icon"
            :route="page.route"
            :loading="loadingMasterCount"
          />
        </v-col>
      </transition-group>
    </v-row>
  </v-container>
</template>

<script>
import getCounts from '../../services/mixins/getCounts'
import DashboardCard from '@/components/dashboard/DashboardCard'
export default {
  name: 'dashboard',
  mixins: [getCounts],
  components: {
    DashboardCard
  }
}
</script>
