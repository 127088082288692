<template>
  <v-card flat>
    <v-card-subtitle>
      Timesheet
    </v-card-subtitle>
    <v-card-text>
      Auto created timesheeet
      <div>
        {{ item.notes }}
      </div>
      <v-divider class="my-2"></v-divider>
      <div v-if="item.docketLine">
        {{ makeTime(new Date(item.docketLine.start)) }}
        {{
          item.docketLine.end
            ? ` - ${makeTime(new Date(item.docketLine.end))}`
            : ' - Now'
        }}
        {{ getPodHours(item) }}
      </div>
      <div v-if="item.docketLine.destinationWeight">
        Weight: {{ item.docketLine.destinationWeight.toFixed(2) }} tonnes
      </div>
      <v-container
        v-if="item.docketLine && item.docketLine.start && item.docketLine.end"
      >
        <v-row>
          <v-col cols="12">
            <v-range-slider
              :tick-labels="hours"
              :value="
                getTimelineIndex(item.docketLine.start, item.docketLine.end)
              "
              min="0"
              max="23"
              ticks="always"
              tick-size="2"
              readonly
            >
            </v-range-slider>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import FormatDates from '../../services/mixins/formatDates'
export default {
  mixins: [FormatDates],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hours: [
        '12',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11'
      ]
    }
  },
  methods: {
    getPodHours(pod) {
      if (pod.docketLine.hours)
        return ` (${pod.docketLine.hours.toFixed(2)} hours)`
      if (pod.docketLine.start) {
        const start = new Date(pod.docketLine.start)
        const end = new Date()
        return ` (${(
          (end.getTime() - start.getTime()) /
          1000 /
          60 /
          60
        ).toFixed(2)} hours)`
      }
      return ''
    },
    findIndex(time) {
      let i = 0
      const t = this.makeTime(new Date(time))
      const meridiem = t.slice(-2)
      const hrsMins = t.slice(0, -2).split(':')
      const hrs = hrsMins[0] == '12' ? 0 : Number(hrsMins[0])
      const mins = Number(hrsMins[1])
      if (meridiem == 'PM') i = hrs + 12
      else i = hrs
      console.log()
      if (mins > 30) i = i + 1
      return i
    },
    getTimelineIndex(start, end) {
      let startIndex = this.findIndex(start)
      let endIndex = this.findIndex(end)
      console.log(startIndex, endIndex)
      return [startIndex, endIndex]
    }
  }
}
</script>
