import router from '@/router'
import Api from '@/services/api.js'
import Axios from 'axios'

const ApiInstance = Axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}api/`
})

export const setTokenForRequests = token => {
  Api.defaults.headers['Authorization'] = `Bearer ${token}`
}

export const unsetTokenForRequests = () => {
  Api.defaults.headers['Authorization'] = null
}

export const checkAuth = () => {
  return Api.post('authentication/checkAuth')
}

export const getMe = () => {
  return Api.get('users/me')
}

export const getAccessTokenFromStorage = () => {
  return localStorage.getItem('accessToken')
}

export const getRefreshTokenFromStorage = () => {
  return localStorage.getItem('refreshToken')
}

export const getAccessTokenExpiryTimeFromStorage = () => {
  return localStorage.getItem('accessTokenExpiry')
}

export const hasAccessTokenExpired = () => {
  const expiryTime = getAccessTokenExpiryTimeFromStorage()
  if (!expiryTime) return true
  const expiry = parseInt(expiryTime)
  if (isNaN(expiry)) return true
  const now = Date.now()
  if (expiry <= now) return true
  return false
}

export const requestAuthUser = async () => {
  const token = getAccessTokenFromStorage()
  if (!token) return
  setTokenForRequests(token)
  const result = await getMe()
  return result.data
}

const unauthenticatedMessage =
  'Your session has expired. Please login to continue.'
const notifyUserLoggedOut = () => {
  // const snackbar = useSnackbar()
  // snackbar.open({ color: 'red', text: unauthenticatedMessage })
}

export const refreshAccessToken = async () => {
  const refreshToken = getRefreshTokenFromStorage()
  if (!refreshToken) {
    notifyUserLoggedOut()
    logout()
    throw new Error(unauthenticatedMessage)
  }

  try {
    const result = await ApiInstance.post(
      'authentication/refreshToken',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshToken}`
        }
      }
    )
    if (result) {
      const token = setLocalStorageTokens({
        accessToken: result.data.accessToken,
        refreshToken: result.data.refreshToken,
        accessTokenExpiry: result.data.accessTokenExpiry
      })
      setTokenForRequests(token)
      return token
    } else {
      throw new Error('No Authentication result from Refresh Access Token call')
    }
  } catch (err) {
    console.warn('An error occurred whilst trying to refresh ID token: ', err)
    notifyUserLoggedOut()
    logout()
    throw new Error(unauthenticatedMessage)
  }
}

export const login = async payload => {
  const { data } = await ApiInstance.post('authentication/login', payload)
  if (!data) {
    throw new Error(
      'Your account requires further verification. Please contact support.'
    )
  }
  if (data.require2FA) return data
  const token = setLocalStorageTokens(data)
  setTokenForRequests(token)
  return token
}

export const signUp = async payload => {
  const { data } = await ApiInstance.post('authentication/register', payload)
  if (!data) {
    throw new Error(
      'Your account requires further verification. Please contact support.'
    )
  }
  const token = setLocalStorageTokens(data)
  setTokenForRequests(token)
  return token
}

export const setLocalStorageTokens = ({
  accessToken,
  refreshToken,
  accessTokenExpiry
}) => {
  localStorage.setItem('accessToken', accessToken)
  localStorage.setItem('refreshToken', refreshToken)
  localStorage.setItem('accessTokenExpiry', accessTokenExpiry.toString())
  return accessToken
}

export const logout = () => {
  unsetLocalStorageTokens()
  router.replace({ name: 'login' })
}

export const unsetLocalStorageTokens = () => {
  unsetTokenForRequests()
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('accessTokenExpiry')
}

export const getS3PutUrl = payload => {
  return Api.post('authentication/getS3PutUrl', payload)
}

export const getS3Url = payload => {
  return Api.post('authentication/getS3Url', payload)
}

export const generateQrCode = () => {
  return Api.get('authentication/qr')
}

export const verify2FA = payload => {
  return Api.post('authentication/verify2FA', payload)
}

export const disable2FA = payload => {
  return Api.post('authentication/disable2FA', payload)
}

export const getVerified2FA = () => {
  return Api.get('authentication/getVerified2FA')
}
