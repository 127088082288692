<template>
  <v-card flat class="card-outlined">
    <v-card-text class="pb-0 d-flex justify-space-between align-center">
      <v-row>
        <v-col cols="12" sm="10" class="py-0">
          <MultiActions
            page="pods"
            isLines
            :selectedCount="selectedCount"
            :fieldsToChange="actionFields"
            :exportOptions="exportOptions"
          />
        </v-col>
        <v-col cols="12" sm="2" class="py-0 d-flex justify-end align-center">
          <ColumnSelector
            :defaultHeaders="defaultHeaders"
            :possibleHeaders="possibleHeaders"
            toolTipText="Column selector"
            :onChanged="val => (defaultHeaders = val)"
          />
        </v-col>
        <SelectAlert
          :selected="selected"
          :selectedAll="selectedAll"
          :totalRecordCount="count"
          :selectAll="() => (selectedAll = true)"
          :unselectAll="() => ((selectedAll = false), (selected = []))"
        />
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="pods"
      :loading="loadingPods"
      :options.sync="pagination"
      :server-items-length="count"
      mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [10, 20, 40, 50],
        showFirstLastPage: true
      }"
      v-model="selected"
      show-select
    >
      <template v-slot:no-data>
        No matching records found
      </template>
      <template v-slot:loading>
        Loading records...
      </template>
      <template v-slot:item="{ item, isSelected }">
        <Row
          :headers="headers"
          :item="item"
          showEdit
          :key="item.id"
          :value="isSelected"
          @input="toggleSelected"
          select
          defaultDialog="podDetailsDialog"
          editDialogFullscreen
        >
          <template v-slot:podDetails="{ dialog, close }">
            <AllocationLineDetails
              v-if="dialog"
              :item="{ id: item.allocationLineId, ...item.allocationLine }"
              :podId="item.id"
              :dialog="dialog"
              :close="close"
            />
          </template>
          <template v-slot:delete="{ dialog, close }">
            <DeleteDialog
              :dialog="dialog"
              :page="titleSingular"
              :subject="item"
              :action="deletePod"
              :close="close"
            />
          </template>
        </Row>
      </template>
      <template
        v-slot:footer[`page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        <div class="text-center mt-1">
          <span v-if="itemsLength">
            {{ `${pageStart}-${pageStop} of ${itemsLength}` }}
          </span>
          <span v-else> - </span>
          <v-progress-linear
            v-if="loadingPods && itemsLength"
            size="15"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <div v-else style="height:5px"></div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { deletePod } from '../../services/requests/allocations'

import Store from '@/store/computed/storeHelpers'
import getPods from '../../services/mixins/getTransactionFiles/getPods'
import Headers from './mixins/podHeaders'
import HeaderFilters from '../../services/mixins/headerFilters'
import MultipleRecords from '../../services/mixins/multipleRecords'
import PodExport from './mixins/podExport'

import Row from '../misc/row/Row'
import AllocationLineDetails from '@/components/allocations/lines/AllocationLineDetails'
import DeleteDialog from '../misc/DeleteDialog'
import ColumnSelector from '../misc/shared/ColumnSelector'
import MultiActions from '../misc/shared/MultiActions'
import SelectAlert from '../misc/shared/SelectAlert'

import { debounce } from 'lodash'
export default {
  mixins: [getPods, Headers, HeaderFilters, MultipleRecords, PodExport],
  components: {
    Row,
    AllocationLineDetails,
    DeleteDialog,
    ColumnSelector,
    MultiActions,
    SelectAlert
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    actionFields() {
      const authLimitedActions = [
        {
          text: 'Delete selected',
          icon: 'mdi-delete',
          color: 'red',
          value: { value: null, text: 'Delete Selected', label: 'delete' },
          action: async () => {
            await this.deleteMultipleRecords({
              ids: this.selected.map(({ id }) => id),
              table: 'pods',
              isLine: true,
              query: this.selectedAll ? this.buildQuery() : null
            })
          }
        }
      ]
      let actions = [
        {
          text: 'Export selected',
          icon: 'mdi-file-export',
          color: 'grey',
          value: { value: null, text: 'Export Selected', label: 'export' }
        },
        {
          text: 'Print selected',
          icon: 'mdi-file-pdf-box',
          color: 'grey',
          value: { value: null, text: 'Print selected', label: 'print' }
        }
      ]
      if (this.userAuthClass) actions = [...actions, ...authLimitedActions]
      return actions
    },

    showClosed: Store.getSet({ store: 'global', prop: 'showClosed' }),
    selectedUserId: Store.getSet({ store: 'global', prop: 'selectedUserId' }),
    selectedProjectId: Store.getSet({
      store: 'global',
      prop: 'selectedProjectId'
    }),
    selectedSourceId: Store.getSet({
      store: 'global',
      prop: 'selectedSourceId'
    }),
    selectedDestinationId: Store.getSet({
      store: 'global',
      prop: 'selectedDestinationId'
    }),
    selectedContractorId: Store.getSet({
      store: 'global',
      prop: 'selectedContractorId'
    }),
    selectedDriverId: Store.getSet({
      store: 'global',
      prop: 'selectedDriverId'
    }),
    selectedVehicleId: Store.getSet({
      store: 'global',
      prop: 'selectedVehicleId'
    }),
    selectedPodType: Store.getSet({
      store: 'global',
      prop: 'selectedPodType'
    }),
    selectedReportId: Store.getSet({
      store: 'global',
      prop: 'selectedReportId'
    }),
    dateRangeStart: Store.getSet({ store: 'global', prop: 'dateRangeStart' }),
    dateRangeEnd: Store.getSet({ store: 'global', prop: 'dateRangeEnd' }),
    filterCol: Store.getSet({ store: 'pods', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'pods', prop: 'filterBy' }),
    selected: Store.getSet({ store: 'pods', prop: 'selectedLines' }),
    selectedAll: Store.getSet({
      store: 'pods',
      prop: 'selectedAllLines'
    }),
    selectedCount() {
      return this.selected.length
    }
  },
  watch: {
    pods() {
      this.selected = []
      this.selectedAll = false
    },
    showClosed() {
      this.getPods()
    },
    selectedUserId() {
      this.getPods()
    },
    selectedProjectId() {
      this.getPods()
    },
    selectedSourceId() {
      this.getPods()
    },
    selectedDestinationId() {
      this.getPods()
    },
    selectedContractorId() {
      this.getPods()
    },
    selectedDriverId() {
      this.getPods()
    },
    selectedVehicleId() {
      this.getPods()
    },
    selectedPodType() {
      this.getPods()
    },
    selectedReportId() {
      this.getPods()
    },
    dateRangeStart() {
      this.getPods()
    },
    dateRangeEnd() {
      this.getPods()
    },
    filterCol() {
      this.getPods()
    },
    filterBy: debounce(function() {
      this.getPods()
    }, 300)
  },
  methods: {
    toggleSelected(val) {
      if (val.value) {
        this.selected.push(val.object)
      } else {
        var index = this.selected.indexOf(val.object)
        this.selected.splice(index, 1)
      }
    },
    async deletePod(line) {
      try {
        this.loading = true
        const result = await deletePod(line.id)
        if (result) {
          this.$store.commit('pods/deleteFromArrayStateByValue', {
            prop: 'pods',
            value: line
          })
          this.snackDeleted()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    }
  }
}
</script>
