var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-card',{staticClass:"card-outlined",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end pb-0 pt-5"},[_c('v-menu',{attrs:{"bottom":"","left":"","transition":"scale-transition","max-height":"600px","origin":"top right","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5",attrs:{"icon":"","color":"grey"}},on),[_c('v-icon',[_vm._v("mdi-view-column")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_vm._v("Master files")]),_vm._l((_vm.possibleHeaders.filter(
                function (item) { return item.group == 'master'; }
              )),function(header){return _c('v-list-item',{key:header.value},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"secondary","label":header.text,"value":header.value},model:{value:(_vm.defaultHeaders),callback:function ($$v) {_vm.defaultHeaders=$$v},expression:"defaultHeaders"}})],1)],1)}),_c('v-list-item',[_vm._v("Transaction files")]),_vm._l((_vm.possibleHeaders.filter(
                function (item) { return item.group == 'transaction'; }
              )),function(header){return _c('v-list-item',{key:header.value},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"secondary","label":header.text,"value":header.value},model:{value:(_vm.defaultHeaders),callback:function ($$v) {_vm.defaultHeaders=$$v},expression:"defaultHeaders"}})],1)],1)}),_c('v-list-item',[_vm._v("Other settings")]),_vm._l((_vm.possibleHeaders.filter(
                function (item) { return item.group == 'other'; }
              )),function(header){return _c('v-list-item',{key:header.value},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"secondary","label":header.text,"value":header.value},model:{value:(_vm.defaultHeaders),callback:function ($$v) {_vm.defaultHeaders=$$v},expression:"defaultHeaders"}})],1)],1)})],2)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.subjects},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('Row',{key:item.id,attrs:{"headers":_vm.headers,"item":item,"masterFile":_vm.masterFile,"fields":_vm.fields,"onEdit":_vm.onEdit,"onDelete":_vm.onDelete,"loading":_vm.loading}})]}},{key:"loading",fn:function(){return [_vm._v(" Loading records... ")]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }