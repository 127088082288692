<template>
  <v-col cols="12" v-if="compliances.length">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-icon class="pr-2" :color="icon.color" v-on="on">
          {{ icon.name }}
        </v-icon>
      </template>
      <div v-for="compliance in compliances" :key="compliance.id">
        <span>
          {{ getComplianceTypeName(compliance.type) }}
          {{ Number(compliance.daysTillExpired) &lt; 1 
            ? `EXPIRED: ${makeShortDate(compliance.expiryDate)}` 
            : `expires in ${compliance.daysTillExpired} 
              ${compliance.daysTillExpired > 1 
                ? 'days' 
                : 'day'}`
          }}
        </span>
      </div>
    </v-tooltip>
    {{ count }} compliance issues for
    {{ name }}
  </v-col>
</template>

<script>
import FormatDates from '../../services/mixins/formatDates'
import models from '../../components/compliances/mixins/models'
export default {
  mixins: [FormatDates, models],
  props: {
    compliances: {
      type: Array,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    icon() {
      if (this.compliances.find(({ daysTillExpired }) => daysTillExpired < 1)) {
        return { name: 'mdi-alert-circle', color: 'red' }
      } else if (
        this.compliances.find(({ daysTillExpired }) => daysTillExpired <= 7)
      ) {
        return { name: 'mdi-alert-circle-outline', color: 'red' }
      } else {
        return { name: 'mdi-alert', color: 'amber lighten-2' }
      }
    }
  }
}
</script>
