var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"card-outlined",class:{ 'on-hover': hover },attrs:{"flat":"","elevation":hover ? 3 : 0,"ripple":"","router":"","to":{ name: _vm.route }}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center pa-0",attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"size":"50px","color":"primary"}},[_vm._v(_vm._s(_vm.icon))])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"9"}},[_c('v-card-title',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.title))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.text))])],1)],1)],1),_c('v-divider',{staticClass:"mx-5"}),_c('v-card-text',{staticClass:"d-flex justify-space-between"},[(_vm.route != 'map')?_c('div',[(_vm.loading)?_c('div',[_c('v-progress-circular',{attrs:{"width":"3","size":"20","indeterminate":"","color":"primary"}})],1):_vm._e(),_c('v-scale-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading && _vm.number),expression:"!loading && number"}]},[_c('span',[_vm._v(_vm._s(_vm.loading || !_vm.number ? '...' : _vm.number))]),_c('span',[_vm._v(" "+_vm._s(_vm.number > 1 ? _vm.label : _vm.label.slice(0, -1))+" "),(_vm.route == 'docket' || _vm.route == 'allocation')?_c('span',[_vm._v(" this month")]):_vm._e()]),((_vm.route == 'docket' || _vm.route == 'allocation') && !_vm.loading)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({class:{
                    'green--text': _vm.growthPercentage > 0,
                    'red--text': _vm.growthPercentage < 0,
                    'ml-3': true
                  }},on),[_vm._v(_vm._s(_vm.growthPercentage.toFixed(2))+"% "),(_vm.growthPercentage)?_c('v-icon',{staticClass:"mt-0 pb-1",attrs:{"small":"","color":_vm.growthPercentage < 0 ? 'red' : 'green'}},[_vm._v(_vm._s(_vm.growthPercentage > 0 ? 'mdi-arrow-up-bold' : 'mdi-arrow-down-bold'))]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Compared with last month to date")])]):_vm._e()],1)])],1):_c('div'),_c('v-icon',{staticClass:"pr-1",attrs:{"color":"grey"}},[_vm._v(_vm._s(_vm.icon))])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }