import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    vehicles: [],
    vehiclesLite: [],
    vehiclesLiteActive: [],
    vehiclesWithDriverLite: [],
    contractorId: null,
    driverId: null,
    adding: false,
    loadingVehicles: false,
    loadingVehiclesLite: false,
    parentId: null
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
