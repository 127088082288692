<template>
  <MasterFilePage>
    <template v-slot:control>
      <MasterFileControl
        :masterFile="masterFile"
        :addDialog="addDialog"
        :toggleAdd="toggleAdd"
        :onSortChange="updateSortBy"
        :onFilterChange="updateFilterBy"
      />
    </template>
    <template v-slot:view>
      <MasterFileView
        :count="Math.ceil(itemTypeCount / pagination.itemsPerPage)"
        :masterFile="masterFile"
        :subjects="sortedItemTypes"
        :fields="fields"
        :loadingMasterFile="loadingItemTypes"
        :loadingMasterFiles="!loadedDestinationsLite"
        :loading="loading"
        :onEdit="editItemType"
        :onDelete="deleteItemType"
        :filterBy="filterBy"
      />
    </template>
    <template v-slot:form>
      <v-dialog
        v-model="addDialog"
        width="700"
        @click:outside="() => (addDialog = false)"
        @keydown.esc="() => (addDialog = false)"
        v-if="loadedDestinationsLite"
      >
        <MasterFileForm
          :masterFile="masterFile"
          :fields="fields"
          :dialog="addDialog"
          :close="toggleAdd"
          :onSubmit="createItemType"
          :loading="loading"
        />
      </v-dialog>
    </template>
  </MasterFilePage>
</template>

<script>
import {
  addItemType,
  updateItemType,
  deleteItemType
} from '../../../services/requests/itemTypes'
import getItemTypes from '../../../services/mixins/getMasterFiles/getItemTypes'
import itemTypeFields from '@/services/mixins/fields/itemTypeFields'
import MasterFileCrudFunctions from '../../../services/mixins/masterFileCrudFunctions'
import Pagination from '../../../services/mixins/getMasterFiles/pagination'

import MasterFilePage from '../../../components/masterFiles/MasterFilePage'
import MasterFileControl from '../../../components/masterFiles/MasterFileControl'
import MasterFileView from '../../../components/masterFiles/MasterFileView'
import MasterFileForm from '../../../components/masterFiles/MasterFileForm'

import { debounce } from 'lodash'
export default {
  mixins: [getItemTypes, itemTypeFields, MasterFileCrudFunctions, Pagination],
  components: {
    MasterFilePage,
    MasterFileControl,
    MasterFileView,
    MasterFileForm
  },
  data() {
    return {
      masterFile: 'itemType',
      prop: 'itemTypes',
      addDialog: false,
      sortBy: null,
      filterBy: '',
      loading: false
    }
  },
  watch: {
    filterBy: debounce(function() {
      this.getItemTypes()
      this.pagination.page = 1
    }, 300)
  },
  computed: {
    sortedItemTypes() {
      if (this.sortBy) {
        const path = this.sortBy
        return [...this.itemTypes].sort((a, b) => {
          if (this.getObjectPath(path, a) < this.getObjectPath(path, b)) {
            if (path === 'updatedAt') {
              return 1
            }
            return -1
          } else {
            if (path === 'updatedAt') {
              return -1
            }
            return 1
          }
        })
      } else {
        return this.itemTypes
      }
    }
  },
  methods: {
    async createItemType(payload) {
      await this.createMasterFile({ payload, action: addItemType })
    },
    async editItemType(itemType) {
      const { id, ...payload } = itemType
      await this.editMasterFile({ id, payload, action: updateItemType })
    },
    async deleteItemType(itemType) {
      await this.deleteMasterFile({
        payload: itemType,
        action: deleteItemType
      })
    },
    toggleAdd() {
      this.addDialog = !this.addDialog
    },
    updateSortBy(sortBy) {
      this.sortBy = sortBy
    },
    updateFilterBy(filterBy) {
      this.filterBy = filterBy
    }
  }
}
</script>
