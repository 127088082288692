import { getGpsData, getLatestGpsData } from './helpers'
export default {
  data() {
    return {
      routeInterval: null,
      latestRouteLineId: null,
      latestAllocationLineId: null,
      latestDocketId: null,
      loadingRouteLines: false,
      gpsLines: []
    }
  },
  methods: {
    async getGpsLines(docketId) {
      try {
        this.loadingRouteLines = true
        const { allocation, gpsLines, lines, pods } = await getGpsData(docketId)
        this.latestDocketId = docketId
        if (lines.length)
          this.latestAllocationLineId = lines[lines.length - 1].id
        const linesWithPods = this.addPodsToLines(lines, pods)
        this.allocationLines = linesWithPods.filter(({ pods }) => pods)
        this.setRoute(gpsLines)
        this.setDocket(docketId, allocation)
        this.loadingRouteLines = false
        this.routeInterval = setInterval(this.updateRoute, 15000)
      } catch (err) {
        console.log(err)
        this.loadingRouteLines = false
        return null
      }
    },
    async updateRoute() {
      const { gpsLines, lines, pods } = await getLatestGpsData(
        this.latestDocketId,
        this.latestRouteLineId,
        this.latestAllocationLineId
      )
      for (const item of gpsLines) {
        this.gpsLines.push(item)
        this.currentRoute.push([item.long, item.lat])
      }
      const linesWithPods = this.addPodsToLines(lines, pods)
      if (linesWithPods.length)
        this.allocationLines = linesWithPods.filter(({ pods }) => pods)
      if (gpsLines.length)
        this.latestRouteLineId = gpsLines[gpsLines.length - 1].id
      if (lines.length) this.latestAllocationLineId = lines[lines.length - 1].id
    },
    setDocket(docketId, allocation) {
      if (this.$route.query.id && allocation) {
        this.docket = allocation.docket
        this.docket.driver = allocation.driver
        this.docket.vehicle = allocation.vehicle
        this.docket.startOdometer = this.gpsLines[0]?.odometer
        this.docket.allocation = allocation
        this.docket.color = 'green'
        this.docket.id = this.$route.query.id
      } else {
        this.docket = this.openDockets.find(({ id }) => id === docketId)
      }
    },
    setRoute(gpsLines) {
      this.currentRoute = []
      this.gpsLines = gpsLines
      for (const item of gpsLines) {
        this.currentRoute.push([item.long, item.lat])
        this.latestRouteLineId = item.id
      }
    },
    addPodsToLines(lines, pods) {
      for (const line of lines) {
        const linePods = pods.filter(
          ({ allocationLineId }) => allocationLineId === line.id
        )
        if (linePods.length > 0) line.pods = linePods
      }
      return lines
    },
    clearDriver() {
      this.docket = null
      this.currentRoute = []
      this.gpsLines = []
      if (this.routeInterval) clearInterval(this.routeInterval)
    }
  }
}
