<template>
  <v-card flat>
    <v-card-subtitle>
      {{ item.report.name }}
    </v-card-subtitle>
    <v-card-text>
      <PodForm :fields="JSON.parse(item.fields)" :pod="item" />
    </v-card-text>
  </v-card>
</template>

<script>
import PodForm from '../pods/PodForm'
export default {
  components: {
    PodForm
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
