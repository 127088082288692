<template>
  <v-form ref="form" @submit.prevent="submit()">
    <v-row class="pt-5">
      <v-col cols="12" class="pb-0">
        <v-card-subtitle class="pa-0 font-weight-bold">
          Add Billing Period
        </v-card-subtitle>
      </v-col>
      <v-col cols="3" class="d-flex align-center">
        <v-text-field
          :disabled="!userAuthClass"
          label="New billing period"
          placeholder="YYYY-MM"
          v-model="name"
          :rules="nameRules"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="3" class="d-flex align-center">
        <v-switch
          :disabled="!userAuthClass"
          inset
          label="Active"
          v-model="isOpen"
          hide-details
          class="mt-0"
        ></v-switch>
      </v-col>
      <v-col cols="6" class="d-flex align-center justify-end">
        <v-btn
          :disabled="!userAuthClass"
          text
          color="primary"
          type="submit"
          :loading="loading"
        >
          Add
        </v-btn>
      </v-col>

      <v-col cols="12" v-if="billingPeriods.length">
        <v-card flat class="card-outlined">
          <v-list>
            <v-list-item v-for="item in billingPeriods" :key="item.id">
              <v-list-item-icon>
                <v-icon
                  v-text="item.isOpen ? 'mdi-check' : 'mdi-close'"
                  :color="item.isOpen ? 'green' : 'red'"
                ></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action-text>
                Created by {{ item.createdBy.name }}
              </v-list-item-action-text>
              <v-list-item-action>
                <v-menu left right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="userAuthClass"
                      @click="
                        () =>
                          editBillingPeriod({
                            ...item,
                            isOpen: !item.isOpen
                          })
                      "
                    >
                      <v-list-item-icon>
                        <v-icon :color="item.isOpen ? 'red' : 'green'">
                          {{ item.isOpen ? 'mdi-close' : 'mdi-check' }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{
                        item.isOpen ? 'Disable' : 'Enable'
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="goToAudit(item)">
                      <v-list-item-icon>
                        <v-icon>{{
                          $store.state.global.pages.audit.icon
                        }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Audit</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="userAuthClass"
                      @click="() => deleteBillingPeriod(item)"
                    >
                      <v-list-item-icon>
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  addBillingPeriod,
  updateBillingPeriod,
  deleteBillingPeriod
} from '../../services/requests/billingPeriods'
import BillingPeriodValidation from '../../services/validation/billingPeriod'
import getBillingPeriods from '../../services/mixins/getMasterFiles/getBillingPeriods'
import MasterFileCrudFunctions from '../../services/mixins/masterFileCrudFunctions'
export default {
  mixins: [getBillingPeriods, MasterFileCrudFunctions, BillingPeriodValidation],
  data() {
    return {
      name: null,
      isOpen: false,
      prop: 'billingPeriods',
      loading: false
    }
  },
  methods: {
    goToAudit(item) {
      this.$router.push({
        name: 'audit',
        query: { id: item.id, model: 'billingPeriod' }
      })
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        this.snackFormError()
        return
      }
      try {
        await this.createBillingPeriod({ name: this.name, isOpen: this.isOpen })
        this.name = null
      } catch (err) {
        console.log(err)
      }
    },
    async createBillingPeriod(billingPeriod) {
      const periodExists = this.billingPeriods.filter(
        item => item.name == billingPeriod.name
      )
      if (periodExists.length)
        return this.snack({
          text: `Billing period ${billingPeriod.name} already exists!`,
          color: 'amber'
        })
      await this.createMasterFile({
        payload: billingPeriod,
        action: addBillingPeriod,
        store: 'billingPeriods'
      })
    },
    async editBillingPeriod(billingPeriod) {
      const payload = {
        name: billingPeriod.name,
        isOpen: billingPeriod.isOpen
      }
      await this.editMasterFile({
        id: billingPeriod.id,
        payload: payload,
        action: updateBillingPeriod,
        store: 'billingPeriods'
      })
    },
    async deleteBillingPeriod(billingPeriod) {
      await this.deleteMasterFile({
        payload: billingPeriod,
        action: deleteBillingPeriod,
        store: 'billingPeriods'
      })
    }
  }
}
</script>
>
