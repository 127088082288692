<template>
  <v-container
    fluid
    v-if="$store.state.user.userAuthClass[`${$route.name}Read`]"
  >
    <v-row>
      <v-col cols="12" class="py-0">
        <ControlCard
          :addDialog="addDialog"
          :toggleAdd="toggleAdd"
          :toggleDrawer="() => (drawer = !drawer)"
          :filterCols="filterCols"
          :filterCol="filterCol"
          :filterBy="filterBy"
          :lineView="lineView"
          :onFilterColChange="val => (filterCol = val)"
          :onFilterByChange="val => (filterBy = val)"
          :color="isStaged ? 'amber' : ''"
        >
          <template v-slot:header>
            <CardHeader
              :title="title"
              :icon="$store.state.global.pages[$route.name].icon"
              :actions="controlActions"
              :actionBtn="
                !isStaged
                  ? {
                      text: 'Staging',
                      onClick: toggleIsStaged
                    }
                  : null
              "
              :backAction="
                isStaged ? toggleIsStaged : showCalendar ? toggleCalendar : null
              "
            />
          </template>
        </ControlCard>
      </v-col>
      <v-col cols="12" v-if="loadedUserSettings">
        <template v-if="!showCalendar">
          <AllocationStagingLineView v-if="isStaged && componentLoaded" />
          <AllocationLineView
            v-else-if="lineView && !isStaged && componentLoaded"
            :createDocketFromAllocation="createDocketFromAllocation"
          />
          <AllocationView
            v-else-if="!isStaged && !lineView && componentLoaded"
            :createDocketFromAllocation="createDocketFromAllocation"
          />
        </template>
        <template v-else>
          <AllocationCalendar :showAdd="() => (addDialog = true)" />
        </template>
      </v-col>
    </v-row>

    <FilterDrawer
      v-model="drawer"
      :lineView="lineView"
      :users="usersLite"
      :projects="projectsLite"
      :contractors="contractorsLite"
      :sources="sourcesLite"
      :vehicles="vehiclesLite"
      :drivers="driversLite"
      :destinations="destinationsLite"
    />

    <v-dialog v-model="addDialog" fullscreen v-if="loadedUserSettings">
      <AllocationAdd
        :toggleAdd="toggleAdd"
        :sources="sourcesLiteActive"
        :vehicles="vehiclesLiteActive"
        :drivers="driversLiteActive"
        :destinations="destinationsLite"
        :itemTypes="itemTypesLite"
      />
    </v-dialog>

    <v-dialog
      v-model="addMoreDialog"
      max-width="400px"
      @keydown.enter="addMore()"
      @keydown.esc="addMoreDialog = false"
    >
      <v-card>
        <v-card-title class="headline">Add another?</v-card-title>
        <v-card-text>
          Would you like to add another
          {{ $store.state.global.pages.allocation.titleSingular }}
          now?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="addMore()">Yes</v-btn>
          <v-btn text @click="addMoreDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="importDialog" width="900px">
      <ImportCsv
        :title="`Import ${$store.state.global.pages.allocation.title}`"
        :close="() => (importDialog = false)"
      />
    </v-dialog>

    <v-dialog v-model="addDocketDialog" fullscreen>
      <DocketAdd
        :toggleAdd="toggleAddDocket"
        :sources="sourcesLiteActive"
        :vehicles="vehiclesLiteActive"
        :drivers="driversLiteActive"
        :destinations="destinationsLite"
        :itemTypes="itemTypesLite"
        :item="selectedAllocation"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Store from '../../store/computed/storeHelpers'
import getUsersLite from '../../services/mixins/getMasterFiles/lite/getUsersLite'
import getContractorsLite from '../../services/mixins/getMasterFiles/lite/getContractorsLite'
import getProjectsLite from '../../services/mixins/getMasterFiles/lite/getProjectsLite'
import getSourcesLite from '../../services/mixins/getMasterFiles/lite/getSourcesLite'
import getVehiclesLite from '../../services/mixins/getMasterFiles/lite/getVehiclesLite'
import getDriversLite from '../../services/mixins/getMasterFiles/lite/getDriversLite'
import getDestinationsLite from '../../services/mixins/getMasterFiles/lite/getDestinationsLite'
import getItemTypesLite from '../../services/mixins/getMasterFiles/lite/getItemTypesLite'
import getBillingPeriods from '../../services/mixins/getMasterFiles/getBillingPeriods'

import ControlCard from '../../components/misc/shared/ControlCard'
import CardHeader from '../../components/misc/shared/CardHeader'
import AllocationView from '../../components/allocations/AllocationView'
import AllocationLineView from '../../components/allocations/AllocationLineView'
import AllocationStagingLineView from '../../components/allocations/AllocationStagingLineView'
import AllocationCalendar from '../../components/allocations/AllocationCalendar'
import AllocationAdd from '../../components/allocations/AllocationAdd'
import FilterDrawer from '../../components/misc/shared/FilterDrawer'
import ImportCsv from '../../components/misc/shared/importCsv'
import DocketAdd from '../../components/dockets/DocketAdd'

export default {
  mixins: [
    getProjectsLite,
    getUsersLite,
    getContractorsLite,
    getSourcesLite,
    getVehiclesLite,
    getDriversLite,
    getDestinationsLite,
    getItemTypesLite,
    getBillingPeriods
  ],
  components: {
    ControlCard,
    CardHeader,
    AllocationView,
    AllocationLineView,
    AllocationStagingLineView,
    AllocationCalendar,
    AllocationAdd,
    FilterDrawer,
    ImportCsv,
    DocketAdd
  },
  data() {
    return {
      drawer: false,
      addDialog: false,
      addDocketDialog: false,
      importDialog: false,
      addMoreDialog: false,
      selectedAllocation: null,
      componentLoaded: false
    }
  },
  mounted() {
    this.setDocId()
  },
  computed: {
    loadedUserSettings: Store.getSet({
      store: 'global',
      prop: 'loadedUserSettings'
    }),
    lineView: Store.getSet({ store: 'allocations', prop: 'lineView' }),
    showCalendar: Store.getSet({ store: 'allocations', prop: 'showCalendar' }),
    isStaged: Store.getSet({ store: 'allocations', prop: 'isStaged' }),
    filterCol: Store.getSet({ store: 'allocations', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'allocations', prop: 'filterBy' }),
    lineViewIcon() {
      return this.lineView ? 'mdi-view-list' : 'mdi-view-list-outline'
    },
    title() {
      if (this.lineView) {
        return this.isStaged
          ? `${this.titleSingular} (Staged) Lines`
          : `${this.titleSingular} Lines`
      } else if (this.showCalendar) {
        return `${this.titleSingular} Calendar`
      } else {
        return this.isStaged
          ? `${this.titleSingular} (Staged) Lines`
          : this.$store.state.global.pages[this.$route.name].title
      }
    },
    lineViewTooltipText() {
      if (this.lineView) {
        return `${this.titleSingular}s`
      } else {
        return `${this.titleSingular} Lines`
      }
    },
    controlActions() {
      const authLimitActions = [
        {
          action: () => (this.importDialog = !this.importDialog),
          text: 'Import CSV',
          icon: 'mdi-paperclip',
          toolTipText: 'Import CSV',
          color: 'primary'
        }
      ]
      let actions = [
        {
          action: () => this.toggleCalendar(),
          icon: 'mdi-calendar',
          toolTipText: 'Calendar',
          color: this.showCalendar ? 'primary' : ''
        },
        {
          action: () => this.toggleIsStaged(),
          icon: this.lineViewIcon,
          toolTipText: 'Staging area',
          color: this.isStaged ? 'primary' : ''
        },
        {
          action: () => this.toggleLineView(),
          icon: this.lineViewIcon,
          toolTipText: this.lineViewTooltipText
        },
        {
          action: () => (this.drawer = !this.drawer),
          icon: 'mdi-filter-variant-plus',
          toolTipText: 'Filters'
        }
      ]
      if (this.userAuthClass) return [...authLimitActions, ...actions]
      return actions
    },
    filterCols() {
      const cols = this.lineView
        ? [
            {
              value: 'allocation.source.name',
              text: this.$store.state.global.pages.source.titleSingular
            },
            {
              value: 'allocation.vehicle.contractor.name',
              text: this.$store.state.global.pages.contractor.titleSingular
            },
            {
              value: 'allocation.driver.name',
              text: this.$store.state.global.pages.driver.titleSingular
            },
            {
              value: 'allocation.vehicle.name',
              text: this.$store.state.global.pages.vehicle.titleSingular
            },
            {
              value: 'allocation.vehicle.vehicleType.name',
              text: this.$store.state.global.pages.vehicleType.titleSingular
            },
            {
              value: 'allocation.id',
              text: 'ID'
            },
            {
              value: 'destination.runCode',
              text: 'Run Number'
            }
          ]
        : [
            {
              value: 'source.name',
              text: this.$store.state.global.pages.source.titleSingular
            },
            {
              value: 'vehicle.contractor.name',
              text: this.$store.state.global.pages.contractor.titleSingular
            },
            {
              value: 'driver.name',
              text: this.$store.state.global.pages.driver.titleSingular
            },
            {
              value: 'vehicle.name',
              text: this.$store.state.global.pages.vehicle.titleSingular
            },
            {
              value: 'vehicle.vehicleType.name',
              text: this.$store.state.global.pages.vehicleType.titleSingular
            },
            {
              value: 'allocation.id',
              text: 'ID'
            }
          ]
      return cols
    }
  },
  methods: {
    toggleAdd(addMore) {
      if (this.licenceExpired) {
        this.snack({
          text: `Your account is on hold. This feature has been disabled`,
          color: 'red'
        })
        return
      }
      this.addDialog = !this.addDialog
      if (!this.addDialog && addMore) this.addMoreDialog = true
    },
    toggleAddDocket() {
      this.addDocketDialog = !this.addDocketDialog
    },
    setDocId() {
      if (this.$route.query.id) {
        this.filterCol = 'allocation.id'
        this.filterBy = this.$route.query.id.toString()
      }
      this.componentLoaded = true
    },
    addMore() {
      this.addMoreDialog = false
      this.toggleAdd(false)
    },
    createDocketFromAllocation(item) {
      if (this.licenceExpired) {
        this.snack({
          text: `Your account is on hold. This feature has been disabled`,
          color: 'red'
        })
        return
      }
      this.selectedAllocation = item
      this.addDocketDialog = true
    },
    toggleLineView() {
      this.showCalendar = false
      this.isStaged = false
      this.lineView = !this.lineView
    },
    toggleCalendar() {
      this.lineView = false
      this.isStaged = false
      this.showCalendar = !this.showCalendar
    },
    toggleIsStaged() {
      this.showCalendar = false
      this.lineView = true
      this.isStaged = !this.isStaged
    }
  }
}
</script>
