import Store from '@/store/computed/storeHelpers'
import { getApprovedBillLines } from '../../requests/approvedBillLines'
export default {
  mounted() {
    this.getApprovedBillLines()
  },
  data() {
    return {
      pagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: []
      },
      count: 0,
      initPagination: false
    }
  },
  computed: {
    approvedBillLines: Store.getSet({
      store: 'approvedBillLines',
      prop: 'approvedBillLines'
    }),
    loadingApprovedBillLines: Store.getSet({
      store: 'approvedBillLines',
      prop: 'loadingApprovedBillLines'
    })
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        if (this.initPagination) {
          this.getApprovedBillLines()
        } else {
          this.initPagination = true
        }
      }
    }
  },
  methods: {
    async getApprovedBillLines() {
      try {
        this.loadingApprovedBillLines = true
        const payload = this.buildQuery()
        const result = await getApprovedBillLines({
          ...payload,
          pagination: this.excludePagination
            ? this.excludePagination
            : this.pagination
        })
        if (result) {
          this.approvedBillLines = result.data.result
          this.count = result.data.count
          this.totals = result.data.totals
        }
        this.loadingApprovedBillLines = false
      } catch (err) {
        console.log(err)
        this.loadingApprovedBillLines = false
      }
    },
    buildQuery() {
      return {
        billingPeriod: this.$store.state.global.selectedBillingPeriod,
        userId: this.$store.state.global.selectedUserId,
        showClosed: this.$store.state.global.showClosed,
        projectId: this.$store.state.global.selectedProjectId,
        sourceId: this.$store.state.global.selectedSourceId,
        dateRangeStart: this.$store.state.global.dateRangeStart,
        dateRangeEnd: this.$store.state.global.dateRangeEnd,
        filterCol: this.$store.state.approvedBillLines.filterCol,
        filterBy: this.$store.state.approvedBillLines.filterBy
      }
    }
  }
}
