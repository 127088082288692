import Store from '@/store/computed/storeHelpers'
import { getPods } from '../../requests/allocations'
import Pod from '../../models/pod'
export default {
  mounted() {
    this.getPods()
  },
  data() {
    return {
      pagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: []
      },
      count: 0,
      initPagination: false
    }
  },
  computed: {
    pods: Store.getSet({ store: 'pods', prop: 'pods' }),
    loadingPods: Store.getSet({
      store: 'pods',
      prop: 'loadingPods'
    })
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        if (this.initPagination) {
          this.getPods()
        } else {
          this.initPagination = true
        }
      }
    }
  },
  methods: {
    async getPods() {
      try {
        this.loadingPods = true
        const payload = this.buildQuery()
        const result = await getPods({
          ...payload,
          pagination: this.pagination
        })
        if (result) {
          this.pods = result.data.result.map(pod => new Pod(pod))
          this.count = result.data.count
        }
        this.loadingPods = false
      } catch (err) {
        console.log(err)
        this.loadingPods = false
      }
    },
    buildQuery() {
      return {
        userId: this.$store.state.global.selectedUserId,
        deliveryStatus: this.$store.state.global.selectedDeliveryStatus,
        billingPeriod: this.$store.state.global.selectedBillingPeriod,
        projectId: this.$store.state.global.selectedProjectId,
        sourceId: this.$store.state.global.selectedSourceId,
        contractorId: this.$store.state.global.selectedContractorId,
        driverId: this.$store.state.global.selectedDriverId,
        vehicleId: this.$store.state.global.selectedVehicleId,
        destinationId: this.$store.state.global.selectedDestinationId,
        podType: this.$store.state.global.selectedPodType,
        reportId: this.$store.state.global.selectedReportId,
        dateRangeStart: this.$store.state.global.dateRangeStart,
        dateRangeEnd: this.$store.state.global.dateRangeEnd,
        filterCol: this.$store.state.pods.filterCol,
        filterBy: this.$store.state.pods.filterBy
      }
    }
  }
}
