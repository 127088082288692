import Store from '@/store/computed/storeHelpers'
import { getRates } from '../../requests/rates'
import Rate from '../../models/rate'

export default {
  mounted() {
    this.getRates()
  },
  data() {
    return {
      pagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: []
      },
      count: 0,
      initPagination: false
    }
  },
  computed: {
    rates: Store.getSet({ store: 'rates', prop: 'rates' }),
    loadingRates: Store.getSet({
      store: 'rates',
      prop: 'loadingRates'
    })
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        if (this.initPagination) {
          this.getRates()
        } else {
          this.initPagination = true
        }
      }
    }
  },
  methods: {
    async getRates() {
      try {
        this.loadingRates = true
        const payload = this.buildQuery()
        const result = await getRates({
          ...payload
        })

        if (result) {
          this.rates = result.data.result.map(rate => new Rate(rate))
          this.count = result.data.count
        }
        this.loadingRates = false
      } catch (err) {
        console.log(err)
        this.loadingRates = false
      }
    },
    buildQuery() {
      const vehicleTypeId = this.$store.state.global.selectedVehicleTypeId
      const itemTypeId = this.$store.state.global.selectedItemTypeId
      return {
        userId: this.$store.state.global.selectedUserId,
        filterCol: this.$store.state.rates.filterCol,
        filterBy: this.$store.state.rates.filterBy,
        projectId: this.$store.state.global.selectedProjectId,
        sourceId: this.$store.state.global.selectedSourceId,
        destinationId: this.$store.state.global.selectedDestinationId,
        showClosed: this.$store.state.global.showClosed,
        vehicleTypeIds: vehicleTypeId ? [vehicleTypeId] : undefined,
        itemTypeIds: itemTypeId ? [itemTypeId] : undefined
      }
    }
  }
}
