<template>
  <v-menu bottom offset-y>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon class="ml-2">
        <v-icon color="grey">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        class="drawer-icon"
        router
        :to="item.routeAction"
        v-for="item in items"
        :key="item.text"
      >
        <v-list-item-icon>
          <v-icon>
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    icon: String,
    items: Array
  }
}
</script>
