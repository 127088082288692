<template>
  <v-card height="100%">
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-1">
        <span class="text-truncate">
          Add {{ titleSingular.toLowerCase() }} line
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-form ref="addForm" @submit.prevent="add()">
            <v-card flat class="card-outlined mb-3">
              <v-card-text class="pb-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="* Description"
                      prepend-icon="mdi-note"
                      v-model="description"
                      :rules="requiredMaxLength500Rules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Notes"
                      prepend-icon="mdi-note"
                      v-model="notes"
                      :rules="maxLength500Rules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Sell Total"
                      prefix="$"
                      type="number"
                      v-model.number="sellTotal"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="grey darken-1" @click="close">Cancel</v-btn>
                <v-btn text color="primary" :loading="loading" type="submit">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import BillValidation from '@/services/validation/bill'

export default {
  mixins: [BillValidation],

  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      description: '',
      notes: '',
      sellTotal: 0
    }
  },
  methods: {
    async add() {
      if (!this.$refs.addForm.validate()) {
        this.snackFormError()
        return
      }
      const payload = {
        billId: this.item.id,
        description: this.description,
        notes: this.notes,
        sellTotal: this.sellTotal
      }
      await this.action(payload)
      this.clear()
      this.close()
    },
    clear() {
      this.description = ''
      this.notes = ''
      this.sellTotal = 0
    },
    newAddress(address) {
      this.currentDeliveryAddress = address
      this.editAddress = false
    }
  },
  watch: {
    destinationId() {
      this.currentDeliveryAddress = {
        addressStreet1: this.currentDestination?.addressStreet1,
        addressStreet2: this.currentDestination?.addressStreet2,
        addressCity: this.currentDestination?.addressCity,
        addressState: this.currentDestination?.addressState
      }
    }
  }
}
</script>
