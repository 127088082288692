import Api from '@/services/api.js'

export const getGpsLines = id => {
  return Api.get(`gpsLines/${id}`)
}
export const getCurrentGpsLines = payload => {
  return Api.post('gpsLines/get', payload)
}
export const getLatestGpsLine = (id, payload) => {
  return Api.post(`gpsLines/latest/${id}`, payload)
}
