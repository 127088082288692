import { getRates, getRatesByIds } from '../../../services/requests/rates'
import ExportKeys from '../../misc/shared/exportKeys'
import Rate from '@/services/models/rate'

export default {
  mixins: [ExportKeys],
  computed: {
    exportOptions() {
      const currentHeaders = [...this.headers]
      currentHeaders.pop()

      return [
        {
          name: this.$store.state.global.pages.rate.title,
          fetchData: () => this.getHeaders(),
          keys: this.rateKeys
        },
        {
          name: 'selected (rate) columns',
          keys: currentHeaders,
          fetchData: () => this.getHeaders()
        }
      ]
    }
  },
  methods: {
    async getHeaders() {
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getRates(payload)
        const items = result.data.result.map(item => new Rate(item))
        return items
        // return result.data.result
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getRatesByIds({ ids })
        const items = result.data.result.map(item => new Rate(item))
        return items
        // return result.data.result
      }
    }
  }
}
