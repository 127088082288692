import {
  getDockets,
  getDocketLines,
  getDocketsByIds,
  getDocketLinesByIds
} from '../../../services/requests/dockets'
import Docket from '@/services/models/docket'
import DocketLine from '@/services/models/docketLine'
import ExportKeys from '../../misc/shared/exportKeys'

export default {
  mixins: [ExportKeys],
  computed: {
    exportOptions() {
      const currentHeaders = [...this.headers]
      currentHeaders.pop()
      return [
        {
          name: this.$store.state.global.pages.docket.title.toLowerCase(),
          fetchData: () => this.getHeaders(),
          keys: this.docketHeaderKeys
        },
        {
          name: `${this.$store.state.global.pages.docket.titleSingular.toLowerCase()} lines`,
          fetchData: () => this.getLines(),
          keys: this.docketLineKeys
        },
        {
          name: 'selected (docketline) columns',
          keys: currentHeaders,
          fetchData: () => this.getLines()
        }
      ]
    }
  },
  methods: {
    async getHeaders() {
      if (this.selectedAll) {
        const payload = {
          userId: this.$store.state.global.selectedUserId,
          showClosed: this.$store.state.global.showClosed,
          showAutoCreated: this.$store.state.global.showAutoCreated,
          billingPeriod: this.$store.state.global.selectedBillingPeriod,
          projectId: this.$store.state.global.selectedProjectId,
          sourceId: this.$store.state.global.selectedSourceId,
          contractorId: this.$store.state.global.selectedContractorId,
          driverId: this.$store.state.global.selectedDriverId,
          vehicleId: this.$store.state.global.selectedVehicleId,
          dateRangeStart: this.$store.state.global.dateRangeStart,
          dateRangeEnd: this.$store.state.global.dateRangeEnd,
          filterCol: this.$store.state.dockets.filterCol,
          filterBy: this.$store.state.dockets.filterBy
        }
        const result = await getDockets(payload)
        const items = result.data.result.map(item => new Docket(item))
        return items
      } else {
        const ids = this.selected.map(({ docketId }) => docketId)
        const uniq = [...new Set(ids)]
        const result = await getDocketsByIds({ ids: uniq })
        const items = result.data.result.map(item => new Docket(item))
        return items
      }
    },
    async getLines() {
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getDocketLines(payload)
        const items = result.data.result.map(item => new DocketLine(item))
        return items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const uniq = [...new Set(ids)]
        const result = await getDocketLinesByIds({ ids: uniq })
        const items = result.data.result.map(item => new DocketLine(item))
        return items
      }
    }
  }
}
