import Docket from './docket'
const BILLING_OVERRIDE_AMOUNT = 0.1
import { makeShortDate, makeTime } from '../../utils/FormatDates'
import { calculateCurrentHours } from '../../utils/Utils'

class DocketLine {
  constructor(docketLine) {
    Object.keys(docketLine).forEach(key => {
      this[key] = docketLine[key]
      if (key == 'docket')
        this[`_${key}`] = this[key] = new Docket(docketLine[key])
      else this[`_${key}`] = docketLine[key]

      const formats = [
        {
          condition: ['date', 'createdAt', 'updatedAt'].includes(key),
          format: val => makeShortDate(val)
        },
        {
          condition: ['start', 'end'].includes(key),
          format: val => makeTime(new Date(val))
        },
        {
          condition: [
            'qty',
            'costQty',
            'hours',
            'sellPrice',
            'costPrice',
            'sellTotal',
            'exportTotal',
            'costTotal'
          ].includes(key),
          format: val => Number(val).toFixed(2)
        }
      ]

      formats.forEach(item => {
        if (item.condition && docketLine[key])
          this[`_${key}`] = item.format(docketLine[key])
      })
    })

    if (!!this.start && !this.end) {
      this._qty = calculateCurrentHours(this.start)
    }
  }

  get createdByName() {
    return this.createdById ? this.createdBy.name : this.docket.driver.name
  }
  get currentOpenHours() {
    if (!!this.start && !this.end) {
      const openHours = calculateCurrentHours(this.start)
      return openHours
    }
    return this.hours
  }

  get balanced() {
    return (
      this.outstandingBillingAmount != null &&
      this.outstandingBillingAmount >= -1 * BILLING_OVERRIDE_AMOUNT &&
      this.outstandingBillingAmount <= BILLING_OVERRIDE_AMOUNT &&
      this.outstandingBillingAmount != this.sellTotal &&
      this.sellTotal != null &&
      !this.isWithDriver
    )
  }
  get isWithDriver() {
    return !this.docket.isClosed
  }
  get closed() {
    return this.isFinalised
  }
  get isEditable() {
    return true
    // return !this.docket.docketId
  }
}

export default DocketLine
