import Api from '@/services/api.js'

export const getSettings = () => {
  return Api.get('settings/')
}
export const updateSettings = (id, payload) => {
  return Api.post(`settings/${id}`, payload)
}
export const updateAccounting = (id, payload) => {
  return Api.post(`settings/accounting/${id}`, payload)
}
