<template>
  <v-card v-if="loadingMasterFiles" flat class="card-outlined">
    <v-card-text>
      <v-card-title class="pa-0 mt-3 font-weight-regular">
        {{ `${!subject ? 'Add' : 'Edit'} ${titleSingular}` }}
      </v-card-title>
      <Loading />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text color="grey darken-1" @click="cancel">Cancel</v-btn>
    </v-card-actions>
  </v-card>

  <v-form ref="form" @submit.prevent="submit()" v-else>
    <v-card flat class="card-outlined">
      <v-card-text class="pb-0">
        <v-card-title class="pa-0 font-weight-regular">
          <span class="mr-3" v-if="imageUrl">
            <v-avatar color="primary" class="mt-3">
              <v-img :src="imageUrl"></v-img>
            </v-avatar>
          </span>

          {{ `${!subject ? 'Add' : 'Edit'} ${titleSingular}` }}
          <span class="font-weight-medium subtitle-1" v-if="subject">
            <span class="pl-1"> - </span>{{ subject.name }} (ID:
            {{ subject.id }})
          </span>
        </v-card-title>
        <v-row>
          <v-col
            cols="12"
            :sm="field.sm ? field.sm : '12'"
            v-for="field of clonedFields.filter(({ type }) => type != 'hidden')"
            :key="field.prop"
          >
            <v-autocomplete
              auto-select-first
              v-if="field.items && field.type != 'expansionCheckbox'"
              :label="field.label"
              v-model="field.value"
              :disabled="disableEdit"
              :clearable="field.clearable"
              :items="getItems(field)"
              item-value="id"
              :item-text="field.nameValue ? field.nameValue : 'name'"
              :rules="field.rules ? field.rules : []"
            ></v-autocomplete>
            <v-autocomplete
              :disabled="field.disabled || disableEdit"
              v-else-if="field.itemsWithLabel"
              :label="field.label"
              v-model="field.value"
              :clearable="field.clearable"
              :items="field.itemsWithLabel"
              item-value="id"
              item-text="text"
              :rules="field.rules ? field.rules : []"
            ></v-autocomplete>
            <v-text-field
              v-else-if="field.type === 'number'"
              :label="field.label"
              v-model.number="field.value"
              type="number"
              :disabled="disableEdit"
              :rules="field.rules"
            ></v-text-field>
            <div v-else-if="field.title">
              <span>{{ field.title }}</span>
              <v-divider class="mt-2"></v-divider>
            </div>
            <v-switch
              v-else-if="field.type === 'boolean'"
              :label="field.label"
              v-model="field.value"
              :disabled="disableEdit"
              inset
            ></v-switch>
            <v-text-field
              v-else-if="field.type === 'password'"
              :label="field.label"
              v-model="field.value"
              autocomplete="New password"
              type="password"
              :error-messages="passwordsMatchErrorMessage"
              :rules="field.rules"
            ></v-text-field>
            <span v-else-if="field.type === 'addressAutocomplete'">
              <GoogleAutocompleteCustom
                v-show="showAddressSearch"
                :id="`addressAutocomplete${subject ? subject.id : 'Add'}`"
                :onChanged="getAddressData"
              />
            </span>
            <ColorPickerWrapper
              v-else-if="field.type === 'color'"
              :label="field.label"
              v-model="field.value"
              :disabled="disableEdit"
              :value="field.value"
            />
            <div v-else-if="field.type === 'image'">
              <v-text-field
                label="Select file"
                readonly
                :disabled="disableEdit"
                :value="!!file ? file.name : ''"
                @click="openFileDialog"
                color="primary"
              ></v-text-field>
            </div>
            <ExpansionCheckList
              :field="field"
              :disabled="disableEdit"
              v-else-if="field.type === 'expansionCheckbox'"
              v-show="showCheckList(field)"
            />
            <div v-else>
              <v-text-field
                :label="field.label"
                v-model="field.value"
                :disabled="field.disabled || disableEdit"
                :rules="field.rules"
                :error-messages="field.type === 'login' ? loginErrors : []"
                :hint="field.hint"
              >
                <template v-slot:append v-if="allowAutocomplete">
                  <v-tooltip bottom>
                    <template
                      v-if="field.prop == 'addressStreet1'"
                      v-slot:activator="{ on }"
                    >
                      <v-icon
                        @click="showAddressSearch = !showAddressSearch"
                        v-on="on"
                        >mdi-magnify</v-icon
                      >
                    </template>
                    <span>Search for an address</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center"
            v-if="masterFile == 'destination' && subject"
          >
            <v-btn text color="primary" @click="locationDialog = true"
              >Adjust Geo-location</v-btn
            >
          </v-col>
        </v-row>
        <v-col
          cols="12"
          class="d-flex justify-center"
          v-if="masterFile == 'vehicle' && subject"
        >
          <QrCodeDisplay :id="subject.id" />
        </v-col>
        <input
          type="file"
          ref="fileUpload"
          style="display:none"
          @change="onFileChange"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="cancel">Cancel</v-btn>
        <v-btn
          text
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="disableEdit"
        >
          {{ subject ? 'Edit' : 'Add' }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <LocationPicker
      v-if="(masterFile == 'destination' || masterFile == 'source') && subject"
      :dialog="locationDialog"
      :coordinates="[long, lat]"
      :select="selectLocation"
      :close="() => (locationDialog = false)"
    />
  </v-form>
</template>

<script>
import { uploadFile } from '../../services/requests/s3Service'
import { getS3Url, getS3PutUrl } from '../../services/authentication'
import PasswordMatch from '../../services/mixins/passwordMatch'
import GoogleAutocompleteCustom from '../misc/GoogleAutocompleteCustom'
import ColorPickerWrapper from '../misc/fields/ColorPickerWrapper'
import LocationPicker from '../misc/fields/LocationPicker'
import ExpansionCheckList from '../misc/fields/ExpansionCheckList'
import QrCodeDisplay from '../misc/QrCodeDisplay.vue'
import Loading from '../misc/Loading'
export default {
  components: {
    GoogleAutocompleteCustom,
    ColorPickerWrapper,
    LocationPicker,
    ExpansionCheckList,
    QrCodeDisplay,
    Loading
  },
  mixins: [PasswordMatch],
  props: {
    masterFile: {
      type: String,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    loadingMasterFiles: {
      type: Boolean,
      required: false,
      default: false
    },
    subject: {
      type: Object,
      required: false
    },
    close: {
      type: Function,
      required: false
    },
    onSubmit: {
      type: Function,
      required: true
    },
    onValidateLogin: {
      type: Function,
      required: false
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      clonedFields: [],
      loginErrors: [],
      rawAddress: '',
      showAddressSearch: false,
      locationDialog: false,
      long: null,
      lat: null,
      file: null,
      imageUrl: null,
      allowAutocomplete: process.env.VUE_APP_GOOGLE_MAPS_API_KEY != ''
    }
  },
  mounted() {
    this.initForm()
  },
  methods: {
    initForm() {
      this.placeholder = ''
      this.clonedFields = []
      this.long = this.subject?.long
      this.lat = this.subject?.lat
      for (const field of this.fields) {
        const clonedField = Object.assign({}, field)
        if (field.prop === 'addressStreet1')
          this.clonedFields.push({
            type: 'addressAutocomplete',
            label: 'Address'
          })
        if (this.subject) {
          if (field.type !== 'password') this.clonedFields.push(clonedField)
        } else {
          this.clonedFields.push(clonedField)
        }
      }
      for (const field of this.clonedFields) {
        if (this.subject) field.value = this.subject[field.prop]
        if (field.formatValues) field.value = field.formatValues(field.value)
      }
      this.getUrl()
    },
    getAddressData(place) {
      let address1 = '',
        address2,
        city,
        state,
        postCode
      for (const component of place.address_components) {
        const type = component.types[0]
        if (type === 'subpremise') address1 += `${component.long_name}/`
        if (type === 'street_number') address1 += `${component.long_name} `
        if (type === 'route') address1 += component.long_name
        if (type === 'locality') city = component.long_name
        if (type === 'administrative_area_level_1') state = component.short_name
        if (type === 'postal_code') postCode = component.long_name
      }
      // !autocomplete test address examples

      // 9/321 Pitt Street, Sydney, New South Wales, Australia
      // 2/321 Pitt Street, Brunswick, Victoria, Australia << Incorrect address
      // 2a/68-72 The Entrance Rd, The Entrance NSW
      // Suite 104 Level 1/66 King St, Sydney NSW 2000
      // 17 Loyola Avenue, Brunswick VIC

      for (const field of this.clonedFields) {
        if (field.prop === 'addressStreet1') field.value = address1
        if (field.prop === 'addressStreet2') field.value = address2
        if (field.prop === 'addressCity') field.value = city
        if (field.prop === 'addressState') field.value = state
        if (field.prop === 'addressPostCode') field.value = postCode
        if (field.prop === 'lat') {
          field.value = place.lat
          this.lat = place.lat
        }
        if (field.prop === 'long') {
          field.value = place.long
          this.long = place.long
        }
      }
    },
    getItems(field) {
      const contractor = this.clonedFields.filter(
        clonedField => clonedField.prop == 'contractorId'
      )[0]
      if (contractor && field.prop == 'defaultVehicleId') {
        return field.items.filter(record => {
          return record.contractorId == contractor.value
        })
      }
      return field.items
    },
    selectLocation(coordinates) {
      this.long = coordinates.long
      this.lat = coordinates.lat
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        this.snackFormError()
        return
      }
      try {
        const subject = {}
        for (const field of this.clonedFields) {
          if (field.type === 'login') {
            if (this.subject?.[field.prop] !== field.value) {
              const validLogin = await this.validateLogin(field.value)
              if (!validLogin) return
            }
          }
          if (field.type === 'image' && this.file) {
            const key = await this.uploadImage()
            if (key) subject[field.prop] = key
          } else if (field.prop == 'lat' || field.prop == 'long') {
            subject.lat = this.lat
            subject.long = this.long
          } else subject[field.prop] = field.value
          if (field.panel > -1) field.panel = -1
          if (field.type == 'expansionCheckbox') {
            if (field.refs) {
              const newAllowedValues = []
              const primaryId = this.$route.name + 'Id'
              const secondaryId =
                field.prop.slice(7, field.prop.length - 1).toLowerCase() + 'Id'
              for (const val of field.newValue) {
                newAllowedValues.push({
                  [primaryId]: this.subject ? this.subject.id : null,
                  [secondaryId]: val,
                  reference: field.refs[val] ? field.refs[val] : null
                })
              }
              subject[field.prop] = newAllowedValues
            } else {
              subject[field.prop] = field.newValue || []
            }
          }
        }

        if (this.subject) subject.id = this.subject.id
        await this.onSubmit(subject)
        if (!this.subject) this.clear()
        else this.showAddressSearch = false
        this.Long = null
        this.Lat = null
        this.close()
      } catch (err) {
        console.log(err)
      }
    },
    async validateLogin(login) {
      if (this.onValidateLogin && login) {
        const result = await this.onValidateLogin(login)
        if (result) {
          const loginExists = result.data.result
          if (loginExists) {
            this.loginErrors = ['Login must be unique!']
            this.snackFormError()
            return false
          } else {
            this.loginErrors = []
          }
        }
      }
      return true
    },
    showCheckList(field) {
      const inductionCondition =
        field.label.toLowerCase().includes('induction') &&
        this.globalSettings.useInductions
      const projectDestinationCondition =
        field.prop == 'allowedDestinations' &&
        this.globalSettings.limitDestinations &&
        this.$route.name == 'project'
      const destinationProjectCondition =
        field.prop == 'allowedProjects' &&
        this.globalSettings.limitDestinations &&
        this.$route.name == 'destination'
      const itemDestinationCondition =
        field.prop == 'allowedDestinations' &&
        this.globalSettings.limitItemTypes &&
        this.$route.name == 'itemType'
      const destinationItemCondition =
        field.prop == 'allowedItemTypes' &&
        this.globalSettings.limitItemTypes &&
        this.$route.name == 'destination'
      const vehicleSourceCondition =
        field.prop == 'allowedVehicles' && this.$route.name == 'source'
      return (
        inductionCondition ||
        projectDestinationCondition ||
        itemDestinationCondition ||
        destinationProjectCondition ||
        destinationItemCondition ||
        vehicleSourceCondition
      )
    },
    openFileDialog() {
      this.$refs.fileUpload.click()
    },
    onFileChange() {
      this.file = {}
      this.file = this.$refs.fileUpload.files[0]
    },
    cancel() {
      this.clear()
      if (this.subject) {
        this.close()
      } else {
        this.close()
      }
    },
    clear() {
      this.showAddressSearch = false
      this.initForm()
    },
    async getUrl() {
      if (this.subject?.imageKey) {
        try {
          const result = await getS3Url({
            key: this.subject.imageKey,
            bucket:
              this.$route.name == 'settings'
                ? 'payloader-policies'
                : 'payloader-masterfile-images'
          })
          this.imageUrl = result.data.result
        } catch (err) {
          console.log(err)
        }
      }
    },

    async uploadImage() {
      const bucket =
        this.$route.name == 'settings'
          ? 'payloader-policies'
          : 'payloader-masterfile-images'
      const result = await getS3PutUrl({
        key: this.file.name,
        bucket
      })
      const { url, key } = result.data.result
      const file = await uploadFile(url, this.file)
      if (file) return key
    }
  },
  computed: {
    disableEdit() {
      return !this.$store.state.user.userAuthClass[`${this.$route.name}Write`]
    }
  }
}
</script>
