<template>
  <MglPopup closeOnClick>
    <v-list dense class="py-0">
      <v-list-item>
        <v-list-item-icon class="mr-4">
          <v-icon :color="item.color">
            {{ $store.state.global.pages.destination.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <div>{{ item.addressStreet1 }} <br />{{ item.addressCity }}</div>
          </v-list-item-subtitle>
          <div class="text-right">ID: {{ item.id }}</div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </MglPopup>
</template>

<script>
import { MglPopup } from 'vue-mapbox'
export default {
  components: { MglPopup },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>
.mapboxgl-popup-close-button {
  font-size: 20px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
