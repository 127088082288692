var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card-outlined",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pb-0 d-flex justify-end align-center"},[_c('ColumnSelector',{attrs:{"defaultHeaders":_vm.defaultHeaders,"possibleHeaders":_vm.possibleHeaders,"toolTipText":"Column selector","onChanged":function (val) { return (_vm.defaultHeaders = val); }}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.compliances,"loading":_vm.loadingCompliances,"options":_vm.pagination,"server-items-length":_vm.count,"mobile-breakpoint":"0","footer-props":{
      'items-per-page-options': [10, 20, 40, 50],
      showFirstLastPage: true
    },"search":_vm.filterBy},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No matching records found ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Loading records... ")]},proxy:true},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('Row',{key:item.id,attrs:{"headers":_vm.headers,"item":item,"showEdit":"","defaultDialog":"detailsDialog"},scopedSlots:_vm._u([{key:"details",fn:function(ref){
    var dialog = ref.dialog;
    var close = ref.close;
    var open = ref.open;
    var openEditDialog = ref.openEditDialog;
return [_c('ComplianceDetails',{attrs:{"dialog":dialog,"item":item,"loading":_vm.loading,"close":close,"open":open,"openEditDialog":openEditDialog}})]}},{key:"delete",fn:function(ref){
    var dialog = ref.dialog;
    var close = ref.close;
return [_c('DeleteDialog',{attrs:{"dialog":dialog,"page":_vm.titleSingular,"subject":item,"action":_vm.deleteCompliance,"close":close}})]}},{key:"edit",fn:function(ref){
    var dialog = ref.dialog;
    var close = ref.close;
return [_c('ComplianceEdit',{attrs:{"dialog":dialog,"item":item,"loading":_vm.loading,"action":_vm.editCompliance,"close":close}})]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }