<template>
  <v-card>
    <v-card-text>
      <v-container fluid class="ma-0">
        <div v-show="showMass">
          <slot name="mass" :onChange="onChange"></slot>
        </div>
        <div v-show="showHours">
          <slot name="time" :onChange="onChange"></slot>
        </div>
      </v-container>
    </v-card-text>
    <v-card-actions class="text-right">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        type="submit"
        class="mt-5 mb-3"
        @click="close"
        >{{
          (showHours && hours) || (showMass && destinationWeight)
            ? 'Ok'
            : 'Close'
        }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    onChange: {
      type: Function,
      required: true
    },
    showMass: {
      type: Boolean,
      required: true
    },
    showHours: {
      type: Boolean,
      required: true
    },
    hours: {
      type: Number,
      required: false
    },
    destinationWeight: {
      type: Number,
      required: false
    },
    close: {
      type: Function,
      required: true
    }
  }
}
</script>
