export default {
  data() {
    return {
      allRctiLineHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          type: 'text'
        },
        {
          text: `${this.$store.state.global.pages.rcti.titleSingular} ID`,
          align: 'left',
          value: 'rctiId',
          type: 'text'
        },
        { text: 'Billing Period', value: 'rcti.billingPeriod' },
        { text: 'Date', value: 'rcti._date' },
        { text: 'Reference', value: 'rcti.externalRef' },

        {
          text: this.$store.state.global.pages.contractor.titleSingular,
          value: 'rcti.contractor.name'
        },
        { text: 'ABN', value: 'rcti.abn' },
        {
          text: 'Description',
          align: 'left',
          value: 'description',
          type: 'text'
        },
        {
          text: 'Notes',
          align: 'left',
          value: 'notes',
          type: 'text'
        },
        { text: 'Status', value: 'rcti.status' },
        { text: 'Type', value: 'rcti.type' },
        {
          text: 'Cost Total',
          align: 'right',
          value: '_costTotal',
          type: 'curr'
        }
      ]
    }
  }
}
