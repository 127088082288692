export const getDegrees = coordinates => {
  const { start, end } = findFurthestPoints(coordinates)
  if (!start || !end) return null
  const longDegrees = start[0] - end[0]
  const latDegrees = start[1] - end[1]
  return { longDegrees, latDegrees, end }
}

export const calculateDistance = (start, end) => {
  if (!start || !end) return 0
  let p = 0.017453292519943295
  let c = Math.cos
  let a =
    0.5 -
    c((end[0] - start[0]) * p) / 2 +
    (c(start[0] * p) * c(end[0] * p) * (1 - c((end[1] - start[1]) * p))) / 2
  return 12742 * Math.asin(Math.sqrt(a))
}

export const findFurthestPoints = coordinates => {
  let maxDist = 0
  let start, end
  for (const a of coordinates) {
    for (const b of coordinates) {
      if (a && b) {
        const distanceInKlms = calculateDistance(a, b)
        if (distanceInKlms > maxDist || maxDist === 0) {
          maxDist = distanceInKlms
          start = a
          end = b
        }
      }
    }
  }
  return { start, end, maxDist }
}
