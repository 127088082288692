<template>
  <v-fab-transition>
    <v-btn
      v-show="!condition"
      fab
      bottom
      right
      absolute
      color="primary"
      @click="action"
      elevation="5"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  props: {
    condition: {
      type: Boolean,
      required: true
    },
    action: {
      type: Function,
      required: true
    }
  }
}
</script>

<style></style>
