<template>
  <div>
    <div v-for="field of fields" :key="field.prop" class="py-1">
      <div
        v-if="typeof field === 'object' && fields !== null"
        class="d-flex justify-space-between"
      >
        <span class="pr-2 subtitle-2">{{ field.label }}</span>
        <v-icon
          v-if="field.type === 'boolean'"
          small
          :color="checkFieldValue(field.value) ? 'primary' : 'red'"
        >
          {{
            checkFieldValue(field.value)
              ? 'mdi-check-circle'
              : 'mdi-close-circle'
          }}
        </v-icon>
        <span v-else-if="field.value && field.type === 'date'">
          {{ makeShortDate(Date.parse(field.value)) }}
        </span>
        <span v-else-if="field.value && field.type === 'time'">
          {{ makeTime(Date.parse(field.value)) }}
        </span>
        <span v-else-if="field.type === 'signature'">
          <v-img
            class="ma-1"
            v-if="pod.url"
            :src="pod.url"
            max-width="100px"
          ></v-img>
        </span>
        <span v-else class="text-right">
          {{ field.value }}
        </span>
      </div>
      <div v-else class="d-flex justify-space-between">
        <span>
          {{ formatFieldName(field.prop) }}
        </span>
        <span> [{{ field }}] </span>
      </div>
      <!-- <v-divider></v-divider> -->
    </div>
  </div>
</template>

<script>
import FormatDates from '../../services/mixins/formatDates'

export default {
  mixins: [FormatDates],
  props: {
    pod: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    imageUrl: {
      type: String,
      required: false
    }
  },
  computed: {
    isImage() {
      return this.pod.type === 'photo' || this.pod.type === 'signature'
    }
  },
  methods: {
    formatFieldName(text) {
      const result = text.replace(/([A-Z])/g, ' $1')
      return result.charAt(0).toUpperCase() + result.slice(1)
    },
    checkFieldValue(value) {
      if (value === 'true' || value === 'false') {
        return value === 'true'
      } else {
        return value
      }
    }
  }
}
</script>
