<template>
  <v-col cols="12" class="d-flex justify-center align-end">
    <v-list>
      <v-list-item class="d-flex justify-center mb-2">
        <v-btn icon large @click="settingsOnClick">
          <v-icon :color="isSetting ? 'primary' : ''">
            {{
              isSetting
                ? $store.state.global.pages.settings.icon
                : $store.state.global.pages.settings.iconAlt
            }}
          </v-icon>
        </v-btn>
      </v-list-item>

      <v-menu
        nudge-right="65"
        nudge-top="10"
        open-on-hover
        rounded="lg"
        open-delay="200"
        close-delay="350"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-list-item class="d-flex justify-center">
            <v-list-item-avatar class="mx-0" color="primary" v-on="on">
              <span class="white--text headline">{{
                $store.state.user.user.name
                  ? $store.state.user.user.name.charAt(0)
                  : 'C'
              }}</span>
            </v-list-item-avatar>
          </v-list-item>
        </template>
        <v-card>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-list>
  </v-col>
</template>

<script>
export default {
  props: {
    logout: {
      type: Function,
      required: true
    },
    isSetting: {
      type: Boolean,
      required: true
    },
    settingsOnClick: {
      type: Function,
      required: true
    }
  }
}
</script>
