<template>
  <tr style="cursor: pointer" @click="rowClick">
    <td v-for="(header, i) in headers" :key="i" :class="`text-${header.align}`">
      <span v-if="header.type == 'text'">
        {{ getObjectPath(header.value, item) || '-' }}
      </span>

      <span v-else-if="header.type == 'action'" @click.stop>
        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn icon text color="grey" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editDialog = true" v-if="userAuthClass">
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item @click="goToAudit">
              <v-list-item-icon>
                <v-icon>{{ $store.state.global.pages.audit.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Audit</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteDialog = true" v-if="userAuthClass">
              <v-list-item-icon>
                <v-icon color="red lighten-3">mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>

      <span v-else>
        <span v-if="getObjectPath(header.value, item)">
          <v-icon color="green">mdi-check</v-icon>
        </span>
        <span v-else>
          <v-icon color="red">mdi-close</v-icon>
        </span>
      </span>
    </td>

    <v-dialog
      v-model="editDialog"
      width="700"
      @click:outside="() => (editDialog = false)"
      @keydown.esc="() => (editDialog = false)"
    >
      <MasterFileForm
        :masterFile="masterFile"
        :dialog="editDialog"
        :fields="fields"
        :subject="item"
        :onSubmit="onEdit"
        :loading="loading"
        :close="() => (editDialog = false)"
      />
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      @click:outside="() => (deleteDialog = false)"
      @keydown.esc="() => (deleteDialog = false)"
      max-width="500px"
    >
      <DeleteDialog
        :dialog="deleteDialog"
        :page="masterFile"
        :subject="item"
        :action="onDelete"
        :close="() => (deleteDialog = false)"
      />
    </v-dialog>
  </tr>
</template>

<script>
import MasterFileForm from '../masterFiles/MasterFileForm'
import DeleteDialog from '../misc/DeleteDialog'
export default {
  components: {
    MasterFileForm,
    DeleteDialog
  },
  props: {
    item: Object,
    headers: Array,
    masterFile: String,
    fields: Array,
    onEdit: Function,
    onDelete: Function,
    loading: Boolean
  },
  data() {
    return {
      editDialog: false,
      deleteDialog: false
    }
  },
  methods: {
    toggleEditDialog() {
      this.editDialog = !this.editDialog
    },
    toggleDeleteDialog() {
      this.deleteDialog = !this.deleteDialog
    },
    rowClick() {
      if (this.userAuthClass) this.toggleEditDialog()
    },
    goToAudit() {
      this.$router.push({
        name: 'audit',
        query: { id: this.item.id, model: 'userAuthClass' }
      })
    }
  }
}
</script>
