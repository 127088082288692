<template>
  <v-dialog
    v-model="dialog"
    width="400"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-text class="pb-0 px-0">
        <v-card-title class="font-weight-regular">
          Location Picker
          <span class="font-weight-medium subtitle-1 ml-2" v-if="latLong">
            {{ ` (${latLong.lat.toFixed(6)}, ${latLong.long.toFixed(6)})` }}
          </span>
          <span
            class="font-weight-medium subtitle-1 ml-2"
            v-if="coordinates[0] && coordinates[1] && !latLong"
          >
            {{
              ` (${coordinates[1].toFixed(6)}, ${coordinates[0].toFixed(6)})`
            }}
          </span>
        </v-card-title>
        <MglMap
          :accessToken="accessToken"
          :mapStyle.sync="mapStyle"
          :center="coordinates"
          :zoom="17"
          :style="`height: ${40}vh`"
        >
          <div>
            <MglMarker
              v-if="coordinates"
              :coordinates="coordinates"
              color="primary"
              draggable
              @dragend="dragEnd"
            />
          </div>
          +
        </MglMap>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="cancel">Cancel</v-btn>
        <v-btn text color="primary" @click="submit">Select</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Mapbox from 'mapbox-gl'
import { MglMap, MglMarker } from 'vue-mapbox'

export default {
  name: 'LocationPicker',
  components: {
    MglMap,
    MglMarker
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    coordinates: {
      type: Array,
      required: false,
      default: () => [50, 50]
    },
    select: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      accessToken: process.env.VUE_APP_MGL_KEY,
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      latLong: null
    }
  },
  created() {
    this.mapbox = Mapbox
  },
  methods: {
    submit() {
      this.select(this.latLong)
      this.close()
    },
    cancel() {
      this.latLong = null
      this.close()
    },
    dragEnd(e) {
      const latLong = e.mapboxEvent.target._lngLat
      this.latLong = { lat: latLong.lat, long: latLong.lng }
    }
  }
}
</script>
