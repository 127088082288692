<template>
  <MasterFilePage>
    <template v-slot:control>
      <MasterFileControl
        :masterFile="masterFile"
        :addDialog="addDialog"
        :toggleAdd="toggleAdd"
        :action="
          $store.state.user.userAuthClass.userAuthClassRead
            ? () => $router.push({ name: 'userAuthClass' })
            : undefined
        "
        actionIcon="mdi-security"
        actionText="User authorization..."
        :onSortChange="updateSortBy"
        :onFilterChange="updateFilterBy"
      />
    </template>
    <template v-slot:view>
      <MasterFileView
        :count="Math.ceil(userCount / pagination.itemsPerPage)"
        :masterFile="masterFile"
        :subjects="sortedUsers"
        :fields="fields"
        :loadingMasterFile="loadingUsers"
        :loadingMasterFiles="!loadedUserAuthClasses"
        :loading="loading"
        :onEdit="editUser"
        :onUpdatePassword="updatePassword"
        :onValidateLogin="validateLogin"
        :onDelete="deleteUser"
      />
    </template>
    <template v-slot:form>
      <v-dialog
        v-model="addDialog"
        width="700"
        @click:outside="() => (addDialog = false)"
        @keydown.esc="() => (addDialog = false)"
        v-if="loadedUserAuthClasses"
      >
        <MasterFileForm
          :masterFile="masterFile"
          :fields="fields"
          :dialog="addDialog"
          :close="toggleAdd"
          :onSubmit="createUser"
          :onValidateLogin="validateLogin"
          :loading="loading"
        />
      </v-dialog>
    </template>
  </MasterFilePage>
</template>

<script>
import {
  addUser,
  updateUser,
  updateUserPassword,
  checkLoginExists,
  deleteUser
} from '../../../services/requests/users'
import getUsers from '../../../services/mixins/getMasterFiles/getUsers'
import userFields from '../../../services/mixins/fields/userFields'
import MasterFileCrudFunctions from '../../../services/mixins/masterFileCrudFunctions'
import Pagination from '../../../services/mixins/getMasterFiles/pagination'

import MasterFilePage from '../../../components/masterFiles/MasterFilePage'
import MasterFileControl from '../../../components/masterFiles/MasterFileControl'
import MasterFileView from '../../../components/masterFiles/MasterFileView'
import MasterFileForm from '../../../components/masterFiles/MasterFileForm'

import { debounce } from 'lodash'
export default {
  mixins: [getUsers, userFields, MasterFileCrudFunctions, Pagination],
  components: {
    MasterFilePage,
    MasterFileControl,
    MasterFileView,
    MasterFileForm
  },
  data() {
    return {
      masterFile: 'user',
      prop: 'users',
      addDialog: false,
      sortBy: null,
      filterBy: '',
      loading: false
    }
  },
  watch: {
    filterBy: debounce(function() {
      this.getUsers()
      this.pagination.page = 1
    }, 300)
  },
  computed: {
    sortedUsers() {
      if (this.sortBy) {
        const path = this.sortBy
        return [...this.users].sort((a, b) => {
          if (this.getObjectPath(path, a) < this.getObjectPath(path, b)) {
            if (path === 'updatedAt') {
              return 1
            }
            return -1
          } else {
            if (path === 'updatedAt') {
              return -1
            }
            return 1
          }
        })
      } else {
        return this.users
      }
    }
  },
  methods: {
    async createUser(user) {
      const payload = {
        name: user.name,
        email: user.email,
        password: user.password,
        userAuthClassId: user.userAuthClassId
      }
      await this.createMasterFile({
        payload,
        action: addUser
      })
    },
    async editUser(user) {
      const payload = {
        name: user.name,
        email: user.email,
        userAuthClassId: user.userAuthClassId
      }
      await this.editMasterFile({
        id: user.id,
        payload: payload,
        action: updateUser
      })
    },
    async updatePassword(id, payload) {
      await this.updateMasterFilePassword({
        id: id,
        payload: payload,
        action: updateUserPassword
      })
    },
    async deleteUser(user) {
      await this.deleteMasterFile({
        payload: user,
        action: deleteUser
      })
    },
    toggleAdd() {
      this.addDialog = !this.addDialog
    },
    updateSortBy(sortBy) {
      this.sortBy = sortBy
    },
    updateFilterBy(filterBy) {
      this.filterBy = filterBy
    },
    validateLogin(login) {
      return checkLoginExists(login)
    }
  }
}
</script>
