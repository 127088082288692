export const initTimeline = (rangeList, timesList, labelInterval = 2) => {
  const x = 15 //minutes interval
  let tt = 0 // start time
  for (let i = 0; tt < 24 * 60; i++) {
    const hh = Math.floor(tt / 60) // getting hours of day in 0-24 format
    const mm = tt % 60 // getting minutes of the hour in 0-55 format
    rangeList[i] =
      mm == 0 && !(hh % labelInterval)
        ? ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2)
        : ''
    timesList[i] = ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2)
    tt = tt + x
  }
  return { rangeList, timesList }
}
