import Api from '@/services/api.js'

export const addBill = bill => {
  return Api.post('bills/add', bill)
}
export const addBills = bills => {
  return Api.post('bills/addMultiple', bills)
}
export const addBillsDirect = bills => {
  return Api.post('bills/addBillsDirect', bills)
}
export const getAllBills = () => {
  // DON'T THINK THIS IS BEING USED
  return Api.get('bills')
}
export const getBillsByIdsWithLines = payload => {
  return Api.post('bills/byIdsWithLines', payload)
}
export const getBillsWithLines = payload => {
  return Api.post('bills/getWithLines', payload)
}
export const getBills = payload => {
  return Api.post('bills/get', payload)
}
export const getConfirmedBills = () => {
  return Api.get('bills/confirmed')
}

export const getUnConfirmedBills = () => {
  // DON'T THINK THIS IS BEING USED
  return Api.get('bills/unconfirmed')
}
export const getPreviewData = payload => {
  return Api.post('bills/previewData', payload)
}
export const getBillLinesForChart = payload => {
  return Api.post('bills/lines/getChart', payload)
}
export const getFinalisedBills = () => {
  // DON'T THINK THIS IS BEING USED
  return Api.get('bills/finalised')
}
export const updateBill = (id, payload) => {
  return Api.put(`bills/${id}`, payload)
}
export const updateBills = ids => {
  // DON'T THINK THIS IS BEING USED
  return Api.patch(`bills/bills`, ids)
}
export const deleteBill = id => {
  return Api.delete(`bills/delete/${id}`)
}
export const deleteBills = obj => {
  return Api.post(`bills/deleteMultiple`, obj)
}
export const getExportBills = billingPeriod => {
  // DON'T THINK THIS IS BEING USED
  return Api.post(`bills/getExportBills/${billingPeriod}`)
}
export const getBillsByIds = payload => {
  return Api.post('bills/byIds', payload)
}
// LINES
export const getLinesByParentId = id => {
  return Api.get(`bills/lines/${id}`)
}
export const updateBillLine = (id, payload) => {
  return Api.put(`bills/lines/${id}`, payload)
}
export const addBillLine = payload => {
  return Api.post('bills/lines', payload)
}
export const deleteBillLine = id => {
  return Api.delete(`bills/lines/${id}`)
}
export const getBillLinesByBills = payload => {
  return Api.post('bills/lines/get', payload)
}
export const getLinesByBillIds = payload => {
  return Api.post('bills/lines/byBillIds', payload)
}
