import Store from '@/store/computed/storeHelpers'
import { getDestinationsLite } from '../../../requests/destinations'
export default {
  mounted() {
    this.getDestinationsLite()
  },
  data() {
    return {
      loadedDestinationsLite: false
    }
  },
  computed: {
    destinationsLite: Store.getSet({
      store: 'destinations',
      prop: 'destinationsLite'
    }),
    loadingDestinationsLite: Store.getSet({
      store: 'destinations',
      prop: 'loadingDestinationsLite'
    })
  },
  methods: {
    async getDestinationsLite() {
      try {
        this.loadingDestinationsLite = true
        const result = await getDestinationsLite()
        if (result) {
          this.destinationsLite = result.data.result
        }
        this.loadedDestinationsLite = true
        this.loadingDestinationsLite = false
      } catch (err) {
        console.log(err)
        this.loadingDestinationsLite = false
      }
    }
  }
}
