<template>
  <v-form @submit.prevent="submit()" ref="form">
    <v-card flat class="card-outlined">
      <v-card-text class="pb-0">
        <v-card-title class="pa-0 font-weight-regular">
          {{ `Edit ${getComplianceTypeName(editedItem.type)}` }}
          <span class="subtitle-1 ml-2"> - ID: {{ editedItem.id }}</span>
        </v-card-title>
        <v-row>
          <v-col cols="12">
            <Detail
              :icon="$store.state.global.pages[item.model].icon"
              :text="item.belongsTo"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <input
              type="file"
              ref="fileUpload"
              style="display:none"
              @change="onFileChange"
            />
            <v-text-field
              :label="`* Select ${getComplianceTypeName(editedItem.type)} file`"
              color="primary"
              readonly
              :value="!!file ? file.name : ''"
              @click="openFileDialog"
              prepend-icon="mdi-paperclip"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <DatePickerWrapper
              :label="`* Expiry Date`"
              color="primary"
              v-model="editedItem.expiryDate"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Notes"
              v-model="editedItem.notes"
              color="primary"
              prepend-icon="mdi-note"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="cancel">Cancel</v-btn>
        <v-btn text color="primary" type="submit" :loading="loading">
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import DatePickerWrapper from '../misc/fields/DatePickerWrapper'
import models from './mixins/models'
import Detail from '../misc/Detail'

export default {
  mixins: [models],
  components: { Detail, DatePickerWrapper },
  props: {
    item: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      editedItem: Object.assign({}, this.item),
      file: null
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        this.snackFormError()
        return
      }
      await this.action(this.editedItem, this.file)
      this.close()
    },
    openFileDialog() {
      this.$refs.fileUpload.click()
    },
    onFileChange() {
      this.file = {}
      this.file.type = this.type
      this.file = this.$refs.fileUpload.files[0]
      this.$emit('fileSelected', this.file)
    },
    cancel() {
      this.close()
      this.clear()
    },
    clear() {
      this.name = null
      this.$refs.fileUpload.value = ''
    }
  }
}
</script>
