<template>
  <div>
    <v-sheet height="64">
      <v-toolbar flat>
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
          Today
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title>4 days</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="100%">
      <v-calendar
        ref="calendar"
        :start="dateRangeStart || makeComputerDate(new Date())"
        :value="dateRangeStart || makeComputerDate(new Date())"
        v-model="value"
        :weekdays="weekday"
        first-interval="6"
        :event-height="Number(60)"
        :type="type"
        :events="events"
        event-overlap-mode="column"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:more="viewDay"
        @click:date="viewDay"
        @contextmenu:time="addAllocation"
        @contextmenu:date="addAllocation"
        @mousedown:event="startDrag"
        @mousedown:time="startTime"
        @mousemove:time="mouseMove"
        @mouseup:time="endDrag"
        @change="getEvents"
      >
        <template v-slot:event="{ event }">
          <AllocationCalEvent :event="event" />
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>
<script>
import Store from '@/store/computed/storeHelpers'
import getAllocations from '../../services/mixins/getTransactionFiles/getAllocations'
import CalendarDrag from './mixins/calendarDrag'
import FormatDates from '@/services/mixins/formatDates'
import AllocationCalEvent from './components/AllocationCalEvent.vue'

export default {
  mixins: [getAllocations, FormatDates, CalendarDrag],
  components: { AllocationCalEvent },
  props: {
    showAdd: {
      type: Function,
      required: false
    }
  },
  data: () => ({
    excludePagination: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 1000,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ['date', 'driver.name'],
      sortDesc: [false]
    },
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day'
    },
    type: 'week',
    types: ['month', 'week', 'day'],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
      { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
      { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] }
    ],
    value: '',
    events: [],
    colors: [
      'blue',
      'indigo',
      'deep-purple',
      'cyan',
      'green',
      'orange',
      'grey darken-1'
    ]
  }),
  watch: {
    propsToWatch() {
      this.getEvents()
    },
    filterCol() {
      if (this.filterBy) this.getEvents()
    },
    filterBy() {
      this.getEvents()
    },
    calNewAdded(val) {
      if (val) {
        this.getEvents()
        this.calNewAdded = false
      }
    }
  },
  computed: {
    propsToWatch() {
      const props = [
        this.showClosed,
        this.selectedUserId,
        this.selectedProjectId,
        this.selectedSourceId,
        this.selectedContractorId,
        this.selectedVehicleId,
        this.selectedDriverId
      ]
      return props.join('|')
    },
    showClosed: Store.getSet({ store: 'global', prop: 'showClosed' }),
    selectedUserId: Store.getSet({ store: 'global', prop: 'selectedUserId' }),
    selectedProjectId: Store.getSet({
      store: 'global',
      prop: 'selectedProjectId'
    }),
    selectedSourceId: Store.getSet({
      store: 'global',
      prop: 'selectedSourceId'
    }),
    selectedContractorId: Store.getSet({
      store: 'global',
      prop: 'selectedContractorId'
    }),
    selectedVehicleId: Store.getSet({
      store: 'global',
      prop: 'selectedVehicleId'
    }),
    selectedDriverId: Store.getSet({
      store: 'global',
      prop: 'selectedDriverId'
    }),
    dateRangeStart: Store.getSet({ store: 'global', prop: 'dateRangeStart' }),
    dateRangeEnd: Store.getSet({ store: 'global', prop: 'dateRangeEnd' }),
    filterCol: Store.getSet({ store: 'allocations', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'allocations', prop: 'filterBy' }),
    calNewAdded: Store.getSet({ store: 'allocations', prop: 'calNewAdded' })
  },
  methods: {
    addAllocation(e) {
      this.$store.state.allocations.calDate = e.date
      this.showAdd()
    },
    viewDay({ date }) {
      this.value = date
      this.type = 'day'
    },
    setToday() {
      this.value = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    formatEvent(allocation, start, date) {
      const range = this.getEventRange(start, date, allocation)
      const color =
        allocation.driver.color ||
        this.colors[this.rnd(0, this.colors.length - 1)]
      return {
        id: allocation.id,
        title: allocation.driver.name,
        updatedBy: allocation.updatedBy,
        createdBy: allocation.createdBy,
        updatedAt: allocation.updatedAt,
        allocation: allocation,
        isClosed: !!allocation.docketId,
        createdAt: allocation.createdAt,
        name: `${allocation.driver.name} - ID: ${allocation.id}`,
        details: `${allocation.vehicle.name}<br>${allocation.lineCount} ${
          allocation.lineCount > 1 ? 'deliveries' : 'delivery'
        }`,
        projectName: allocation.source.project.name,
        sourceName: allocation.source.name,
        vehicleName: allocation.vehicle.name,
        vehicleType: allocation.vehicle.vehicleType.name,
        contractorName: allocation.vehicle.contractor.name,
        lineCount: allocation.lineCount,
        start: new Date(`${allocation.date}${range.startStr}`),
        end: new Date(`${allocation.date}${range.endStr}`),
        endTime: range.end,
        date: range.date,
        color: color,
        timed: true
      }
    },
    async getEvents(data) {
      const events = []
      if (data?.start) this.dateRangeStart = data.start.date
      if (data?.end) this.dateRangeEnd = data.end.date
      await this.getAllocations()
      let start = 7
      let date = null
      for (const allocation of this.allocations) {
        const event = this.formatEvent(allocation, start, date)
        start = event.endTime
        date = event.date
        events.push(event)
      }
      this.events = events
    },
    formatTime(time) {
      let hours = Math.floor(time)
      let mins = Math.floor((Math.abs(time) * 60) % 60)
      if (hours < 10) hours = `0${hours}`
      if (mins < 10) mins = `0${mins}`
      return `T${hours}:${mins}:00`
    },
    getEventRange(initialStart, initialDate, allocation) {
      const start =
        initialStart > 17 || initialDate !== allocation.date ? 7 : initialStart
      const date =
        initialDate === allocation.date ? initialDate : allocation.date
      const eventsOnSameDate = this.allocations.filter(
        ({ date }) => date === allocation.date
      )
      const eventLength = 10 / eventsOnSameDate.length
      const end = start + eventLength
      const startStr = this.formatTime(start)
      const endStr = this.formatTime(end)
      return { startStr, endStr, date, end }
    },
    getEventColor(event) {
      return event.allocation.closed ? 'grey' : event.color
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    }
  }
}
</script>
