const BILLING_OVERRIDE_AMOUNT = 0.1
import { makeShortDate, makeTime } from '../../utils/FormatDates'
import { calculateCurrentHours } from '../../utils/Utils'

class Docket {
  constructor(docket) {
    Object.keys(docket).forEach(key => {
      this[key] = docket[key]
      this[`_${key}`] = docket[key]
      const formats = [
        {
          condition: [
            'date',
            'dateReceived',
            'createdAt',
            'updatedAt'
          ].includes(key),
          format: val => makeShortDate(val)
        },
        {
          condition: ['start', 'end'].includes(key),
          format: val => makeTime(new Date(val))
        },
        {
          condition: [
            'sellTotal',
            'costTotal',
            'qty',
            'hours',
            'totalDistance',
            'lineHours'
          ].includes(key),
          format: val => Number(val).toFixed(2)
        }
      ]

      formats.forEach(item => {
        if (item.condition && docket[key])
          this[`_${key}`] = item.format(docket[key])
      })
    })

    if (!!this.start && !this.end) {
      this._qty = calculateCurrentHours(this.start)
    }
  }

  get createdByName() {
    return this.createdById ? this.createdBy.name : this.driver.name
  }
  get isEditable() {
    return true
    // return !this.docket.docketId
  }

  get balanced() {
    return (
      this.totalOutstanding != null &&
      this.totalOutstanding >= -1 * BILLING_OVERRIDE_AMOUNT &&
      this.totalOutstanding <= BILLING_OVERRIDE_AMOUNT &&
      this.totalOutstanding != this.sellTotal &&
      !this.isWithDriver
    )
  }
  get closed() {
    return this.finalisedLineCount > 0
  }
  get isWithDriver() {
    return !this.isClosed
  }
  get currentOpenHours() {
    if (!!this.start && !this.end) {
      const openHours = calculateCurrentHours(this.start)
      return openHours
    }
    return this.hours
  }
}

export default Docket
