<template>
  <v-container fill-height>
    <v-row class="fill-height">
      <v-col cols="12" class="pa-0 mb-2 d-flex justify-center align-start">
        <v-img
          src="@/assets/payloader-large1.png"
          max-width="50"
          contain
        ></v-img>
      </v-col>

      <v-col cols="12" class="pa-0 d-flex align-center">
        <v-fade-transition>
          <v-row v-show="!loading">
            <SideDrawerIcon
              title="Dashboard"
              icon="mdi-view-dashboard"
              iconAlt="mdi-view-dashboard-outline"
              route="dashboard"
            />
            <SideDrawerIcon
              v-for="page in $store.state.global.mainPages"
              :key="page.title"
              :title="page.menuTitle ? page.menuTitle : page.title"
              :icon="page.icon"
              :iconAlt="page.iconAlt"
              :route="page.route"
            />
            <SideDrawerIcon
              v-if="$store.state.global.masterFilePages.length > 0"
              title="Master files"
              icon="mdi-folder"
              iconAlt="mdi-folder-outline"
              :items="$store.state.global.masterFilePages"
            />
          </v-row>
        </v-fade-transition>
      </v-col>

      <SideDrawerSettings
        :logout="logout"
        :isSetting="isSetting"
        :settingsOnClick="settingsOnClick"
      />
    </v-row>
  </v-container>
</template>

<script>
import SideDrawerIcon from './SideDrawerIcon'
import SideDrawerSettings from './SideDrawerSettings'
import { logout } from '@/services/authentication'

export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  components: {
    SideDrawerIcon,
    SideDrawerSettings
  },
  computed: {
    isSetting() {
      return this.$store.state.global.settingsPages.some(
        ({ route }) => this.$route.name == route
      )
    }
  },
  methods: {
    logout() {
      logout()
    },
    settingsOnClick() {
      this.$store.state.global.pages.settings.route !== this.$route.name
        ? this.$router.push({
            name: this.$store.state.global.pages.settings.route
          })
        : null
    }
  }
}
</script>
