import Api from '@/services/api.js'

export const addAllocation = payload => {
  return Api.post('allocations/', payload)
}
export const addAllocations = payload => {
  return Api.post('allocations/allocations', payload)
}
export const getAllocations = payload => {
  return Api.post('allocations/get', payload)
}
export const updateAllocation = (id, payload) => {
  return Api.put(`allocations/${id}`, payload)
}
export const makeAllocationVoid = (id, payload) => {
  return Api.put(`allocations/void/${id}`, payload)
}
export const deleteAllocation = id => {
  return Api.delete(`allocations/${id}`)
}
export const importAllocations = payload => {
  return Api.post('allocations/import', payload)
}
export const matchDockets = () => {
  return Api.post('allocations/matchDockets')
}
export const addAllocationLine = payload => {
  return Api.post('allocations/lines', payload)
}
export const getAllocationLines = payload => {
  return Api.post('allocations/lines/get', payload)
}
export const getPods = payload => {
  return Api.post('allocations/pods/get', payload)
}
export const getPodsByIds = payload => {
  return Api.post('pods/byIds', payload)
}
export const deletePod = id => {
  return Api.delete(`pods/${id}`)
}
export const getLinesByAllocationId = id => {
  return Api.get(`allocations/lines/${id}`)
}
export const getLineWithPodsByLineId = id => {
  return Api.get(`allocations/pods/${id}`)
}
export const getLineByLineIdTracking = id => {
  return Api.get(`tracking/${id}`)
}
export const getAllocationByDocketId = id => {
  return Api.get(`allocations/route/${id}`)
}
export const downloadPod = key => {
  return Api.get(`allocations/pod-dl/${key}`)
}
export const updateAllocationLine = (id, payload) => {
  return Api.put(`allocations/lines/${id}`, payload)
}
export const deleteAllocationLine = id => {
  return Api.delete(`allocations/lines/${id}`)
}
export const getAllocationsByIds = payload => {
  return Api.post('allocations/byIds', payload)
}
export const getAllocationsByIdsWithLines = payload => {
  return Api.post('allocations/byIdsWithLines', payload)
}
export const getAllocationsWithLines = payload => {
  return Api.post('allocations/getWithLines', payload)
}
export const getLinesByAllocationIds = payload => {
  return Api.post('allocations/lines/byAllocationIds', payload)
}
export const getAllocationLinesByIds = payload => {
  return Api.post('allocations/lines/byIds', payload)
}
