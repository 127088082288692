import getWindowSize from './getWindowSize'

export default {
  mixins: [getWindowSize],
  methods: {
    filterHeaders() {
      return this.possibleHeaders.filter(header => {
        if (header.value == 'actions') {
          return true
        }
        return !!this.defaultHeaders.filter(field => {
          return header.value == field
        }).length
      })
    }
  },
  computed: {
    headers() {
      let tempArray = []
      tempArray =
        Number(this.window?.width) >= 600
          ? this.filterHeaders()
          : this.mobileHeaders
          ? [...this.mobileHeaders]
          : this.filterHeaders()
      const actions = {
        text: 'Actions',
        align: 'center',
        value: '',
        sortable: false,
        colData: '',
        type: 'action'
      }
      tempArray.push(actions)
      return tempArray
    },
    possibleHeaders() {
      let tempHeaders = this.allPossibleHeaders
      return tempHeaders
    }
  }
}
