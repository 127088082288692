import Api from '@/services/api.js'

export const connectXero = () => {
  return Api.get('xeroService/connect')
}
export const createXeroBill = payload => {
  return Api.post('xeroService/bill', payload)
}
export const createXeroRcti = payload => {
  return Api.post('xeroService/rcti', payload)
}

export const connectQuickbooks = () => {
  return Api.get('quickbooksService/connect')
}
export const createQuickbooksBill = payload => {
  return Api.post('quickbooksService/bill', payload)
}
export const createQuickbooksRcti = payload => {
  return Api.post('quickbooksService/rcti', payload)
}

export const connectMyob = () => {
  return Api.get('myobService/connect')
}
export const createMyobBill = payload => {
  return Api.post('myobService/bill', payload)
}
export const createMyobRcti = payload => {
  return Api.post('myobService/rcti', payload)
}
