import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    pagination: {
      page: 1,
      sortDesc: [],
      itemsPerPage: 10,
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
      sortBy: []
    },
    sortDesc: false,
    showInactive: false,
    showRequest: false
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
