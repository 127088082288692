<template>
  <div>
    <v-text-field
      slot="activator"
      :ref="refString"
      mask="time"
      :rules="timeRules"
      :value="value"
      :disabled="disabled"
      @input="fieldInput"
      :label="label"
      prepend-icon="mdi-clock"
    ></v-text-field>
  </div>
</template>

<script>
import Rules from '@/services/validation/rules/rules'
export default {
  data() {
    return {
      timeMenu: false,
      defaultRules: [
        v => {
          if (!v) return true
          if (v == '') return true
          if (v.toString().length == 4 && !v.includes(':')) {
            v = `${v.slice(0, 2)}:${v.slice(2)}`
          }
          var check = this.checkTime(v)
          if (!check) return 'Field must be valid 24hr time (HH:mm)'
          return check
        }
      ]
    }
  },
  props: {
    value: String,
    label: String,
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    refString: {
      type: String,
      required: false,
      default: 'timeField'
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    timeRules() {
      const rules = this.required
        ? [...this.defaultRules, v => Rules.isRequired(v)]
        : this.defaultRules
      return rules
    }
  },
  methods: {
    checkTime(v) {
      if (v.length < 5) return false
      var strings = v.split(':')
      if (Number(strings[0]) > 23) return false
      if (Number(strings[1]) > 59) return false
      return true
    },
    pickerInput(val) {
      this.$emit('input', val)
      this.timeMenu = false
    },
    fieldInput(val) {
      if (val.toString().length == 4) {
        val = val.replace(/(.{2})$/, ':$1')
      }
      this.$emit('input', val)
      this.timeMenu = false
    },
    toggleTimePicker() {
      this.timeMenu = !this.timeMenu
    }
  }
}
</script>
