import {
  updateMultipleRecords,
  deleteMultipleRecords
} from '../requests/shared'
export default {
  methods: {
    async updateMultipleRecords({
      items,
      table,
      field,
      value,
      query,
      model,
      prop,
      store,
      headerFromLines,
      notes,
      notesField
    }) {
      try {
        const ids =
          table == 'allocations'
            ? items.map(({ allocationId }) => allocationId)
            : items.map(({ id }) => id)
        const result = await updateMultipleRecords({
          ids,
          table,
          field,
          value,
          notesField,
          notes: notes || undefined,
          query
        })
        const rows = !headerFromLines
          ? result.data.result.rows
          : items.map(item => {
              return {
                ...item,
                allocation: result.data.result.rows.find(
                  ({ id }) => id == item.allocationId
                )
              }
            })
        for (const item of rows) {
          if (table == 'allocations' && field == 'isStaged') {
            this.$store.commit(`${store}/deleteFromArrayStateByParentId`, {
              parent: 'allocation',
              prop,
              id: item.id
            })
          } else {
            this.$store.commit(`${store}/updateArrayStateById`, {
              prop,
              editedValue: new model(item)
            })
          }
        }
        return result.data.result
      } catch (err) {
        console.log(err)
      }
    },
    async deleteMultipleRecords({ ids, table, isLine, query }) {
      try {
        const result = await deleteMultipleRecords({
          ids,
          route: `${table}/delete${isLine ? '/lines' : ''}`,
          query
        })
        for (const id of result.data.result.ids) {
          this.$store.commit(`${table}/deleteFromArrayStateById`, {
            prop: isLine ? 'lines' : table,
            id
          })
        }
        this.count -= result.data.result.ids.length
        this.selected = []
        return result.data.result
      } catch (err) {
        console.log(err)
      }
    }
  }
}
