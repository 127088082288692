<template>
  <v-card flat class="card-outlined">
    <v-data-table
      :headers="headers"
      :items="items"
      no-data-text="No lines found"
      hide-default-footer
      disable-pagination
      :loading="loadingLines"
      mobile-breakpoint="0"
    >
      <template v-slot:loading>
        Loading records...
      </template>
      <template v-slot:top>
        <div class="text-right mr-2 mt-2">
          <v-tooltip top v-if="showAddBtn">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                v-on="on"
                text
                @click="$emit('showAddLineDialog')"
              >
                ADD NEW LINE
              </v-btn>
            </template>
            <span>
              Add new line
            </span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <Row
          :headers="headers"
          :item="item"
          :key="item.id"
          showDeliveryDetails
          :hideDetails="hideDetails"
          :defaultDialog="showEdit ? 'editDialog' : 'podDetailsDialog'"
          :showEdit="showEdit"
          :closeParent="closeParent"
          :selectedId="selectedId"
        >
          <template v-slot:podDetails="{ dialog, close, openEditDialog }">
            <LineDetails
              v-if="dialog"
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :close="close"
              :openEditDialog="openEditDialog"
            />
          </template>
          <template v-if="onEdit" v-slot:edit="{ dialog, close }">
            <LineEdit
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :action="onEdit"
              :close="close"
            />
          </template>
          <template v-if="onDelete" v-slot:delete="{ dialog, close }">
            <DeleteDialog
              :dialog="dialog"
              :page="`${$store.state.global.pages.rcti.titleSingular} line`"
              :subject="item"
              :action="onDelete"
              :close="close"
            />
          </template>
        </Row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Row from '../../misc/row/Row'
import LineEdit from './LineEdit'
import DeleteDialog from '../../misc/DeleteDialog'
import LineDetails from './LineDetails'

export default {
  components: {
    Row,
    LineEdit,
    DeleteDialog,
    LineDetails
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    onEdit: {
      type: Function,
      required: false
    },
    onDelete: {
      type: Function,
      required: false
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    showAddBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: true
    },
    loadingLines: {
      type: Boolean,
      required: false,
      default: false
    },
    closeParent: {
      type: Function,
      required: false
    },
    selectedId: {
      type: Number,
      required: false
    }
  }
}
</script>
