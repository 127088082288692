<template>
  <v-card flat class="card-outlined">
    <v-card-text>
      <CardHeader
        :title="titleSingular"
        :icon="$store.state.global.pages[$route.name].icon"
        :actions="controlActions"
      />
      <v-row v-if="!docket && openDockets">
        <v-col cols="12" sm="6" md="3" class="py-0">
          <MapSelectField
            :openDockets="openDockets"
            :docketIds="docketIds"
            :loadingOpenDockets="loadingOpenDockets"
            :toggleAll="toggleAll"
            :toggleDocket="toggleDocket"
            :icon="icon"
          />
        </v-col>
        <v-col cols="6">
          <v-switch
            inset
            label="Lock position"
            v-model="lockPosition"
            hide-details
            class="mt-0 ml-3"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" sm="6" class="d-flex justify-space-between pb-0 mb-0">
          <div>
            <v-btn icon @click="backToDrivers">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            {{ `Showing ${docket.driver.name}\'s route` }}
          </div>
        </v-col>
        <v-col cols="6" sm="3">
          <v-switch
            inset
            label="Lock position"
            v-model="lockPosition"
            hide-details
            class="mt-0 ml-3"
          ></v-switch>
        </v-col>
        <v-col class="py-0 my-0">
          <div v-if="docket.allocation" class="text-right">
            {{
              `${$store.state.global.pages.allocation.titleSingular} ID: ${docket.allocation.id}`
            }}
          </div>
          <div class="text-right">
            {{ makePhoneStyleDate(docket.date) }}
          </div>
          <div class="text-right">
            {{
              `${$store.state.global.pages.docket.title.slice(0, -1)} ID: ${
                docket.id
              }`
            }}
          </div>
          <div class="text-right">
            {{ $store.state.global.pages.vehicle.titleSingular }}:
            {{ docket.vehicle.name }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <FilterDrawer
      v-model="drawer"
      :contractors="contractors"
      :sources="sources"
    />
  </v-card>
</template>

<script>
import Store from '../../store/computed/storeHelpers'
import CardHeader from '../misc/shared/CardHeader'
import MapSelectField from './components/MapSelectField'
import FormatDates from '../../services/mixins/formatDates'
import FilterDrawer from '../../components/misc/shared/FilterDrawer'

export default {
  mixins: [FormatDates],
  components: {
    CardHeader,
    MapSelectField,
    FilterDrawer
  },
  props: {
    openDockets: Array,
    docket: Object,
    docketIds: Array,
    loadingOpenDockets: Boolean,
    toggleAll: Function,
    toggleDocket: Function,
    icon: String,
    contractors: Array,
    sources: Array
  },
  data() {
    return {
      drawer: false
    }
  },
  computed: {
    lockPosition: Store.getSet({ store: 'map', prop: 'lockPosition' }),
    controlActions() {
      return [
        {
          action: () => (this.drawer = !this.drawer),
          icon: 'mdi-filter-variant-plus',
          toolTipText: 'Filters',
          color: 'grey'
        }
      ]
    }
  },
  methods: {
    backToDrivers() {
      this.$route.query.id = null
      this.$root.$emit('removeLayer')
    }
  }
}
</script>
