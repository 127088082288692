<template>
  <MglPopup closeOnClick>
    <v-list dense class="py-0">
      <v-list-item>
        <v-list-item-icon class="mr-4">
          <v-avatar
            :color="item.docket.driver.color"
            class="mt-0"
            :style="`border: 2px solid ${item.docket.driver.color};`"
          >
            <!-- <v-img v-if="item.imageUrl" :src="item.imageUrl"></v-img> -->
            <span class="white--text headline">{{
              item.docket.driver.name.charAt(0)
            }}</span>
          </v-avatar>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <span v-if="item.docket.isClosed">Final</span>
            <span v-else>Current</span> location
            <div v-if="item.docket.isClosed">
              {{ makeShortDateAndTime(item.pingTime) }}
            </div>
          </v-list-item-title>
          <v-list-item-subtitle>
            <div>
              Speed:
              {{ (item.speed >= 0 ? item.speed * 3.6 : 0).toFixed(0) }}
              km/h
            </div>
            <div>Heading: {{ item.direction }}</div>
            <div v-if="item.odometer && item.docket.startOdometer">
              {{
                ((item.odometer - item.docket.startOdometer) / 1000).toFixed(2)
              }}km travelled
            </div>
            <div class="pt-2">{{ item.docket.driver.name }}</div>
            <div>Vehicle: {{ item.docket.vehicle.name }}</div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </MglPopup>
</template>

<script>
import { MglPopup } from 'vue-mapbox'
import FormatDates from '@/services/mixins/formatDates'

export default {
  mixins: [FormatDates],
  components: { MglPopup },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>
.mapboxgl-popup-close-button {
  font-size: 20px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
