import Rules from '@/services/validation/rules/rules'
export default {
  data() {
    return {
      requiredRules: [v => !!v || 'Field is required'],
      totalRules: [v => !!v || 'Field is required'],
      dateRules: [],
      externalRefRules: [v => !!v || 'Field is required'],
      xeroRefRules: [v => !!v || 'Field is required'],
      maxLength500Rules: [v => Rules.maxLength(v, 500)],
      requiredMaxLength500Rules: [
        v => !!v || 'Field is required',
        v => Rules.maxLength(v, 500)
      ],
      internalNotesRules: [v => Rules.maxLength(v, 500)],
      invoiceNotesRules: [v => Rules.maxLength(v, 500)]
    }
  }
}
