import Api from '../api'

export const updateMultipleRecords = ({
  ids,
  table,
  field,
  value,
  query,
  notesField,
  notes
}) => {
  return Api.put(`${table}/update/field`, {
    ids,
    field,
    value,
    query,
    notesField,
    notes
  })
}

export const deleteMultipleRecords = ({ route, ids, query }) => {
  return Api.post(route, { ids, query })
}
