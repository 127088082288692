import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    dockets: [],
    lines: [],
    totals: null,
    openDockets: [],
    loadingDockets: false,
    loadingLines: false,
    loadingOpenDockets: false,
    loadingTotals: false,
    lineView: false,
    filterCol: null,
    filterBy: null,
    selectedAll: false,
    selectedAllLines: false,
    selected: [],
    selectedLines: [],
    docketDefaultHeaders: [
      'id',
      '_date',
      'source.name',
      'vehicle.name',
      'driver.name',
      'notes',
      'lineCount',
      'createdBy.name',
      'updatedBy.name'
    ],
    docketLineDefaultHeaders: [
      'id',
      'destination.name',
      'itemType.name',
      'notes',
      'qty',
      'start',
      'end',
      'sellTotal'
    ]
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
