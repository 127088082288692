export default {
  data() {
    return {
      allApprovedBillLineHeaders: [
        {
          text: 'ID',
          align: 'left',
          type: 'text',
          value: 'id'
        },
        {
          text: 'Billing Period',
          align: 'left',
          value: 'docketLine.docket.billingPeriod',
          type: 'text'
        },
        {
          text: 'Date',
          align: 'left',
          value: 'docketLine.docket.date',
          type: 'date'
        },
        {
          text: 'Customer Claim Reference',
          align: 'left',
          value: 'customerClaimRef',
          type: 'text'
        },
        {
          text: this.$store.state.global.pages.project.titleSingular,
          align: 'left',
          value: 'docketLine.docket.source.project.name',
          type: 'text'
        },
        {
          text: this.$store.state.global.pages.source.titleSingular,
          align: 'left',
          value: 'docketLine.docket.source.name',
          type: 'text'
        },
        {
          text: `${this.$store.state.global.pages.docket.titleSingular} line ID`,
          align: 'right',
          value: 'docketLineId',
          type: 'text'
        },
        {
          text: this.$store.state.global.pages.itemType.titleSingular,
          align: 'left',
          value: 'docketLine.itemType.name',
          type: 'text'
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          align: 'left',
          value: 'docketLine.destination.name',
          type: 'text'
        },
        {
          text: `${this.$store.state.global.pages.bill.titleSingular} quantity`,
          align: 'right',
          type: 'text',
          decimals: 2,
          value: 'qty'
        },
        {
          text: `${this.$store.state.global.pages.bill.titleSingular}  Rate`,
          align: 'right',
          value: 'rate',
          decimals: 2,
          type: 'curr'
        },
        {
          text: `${this.$store.state.global.pages.bill.titleSingular}  Amount`,
          align: 'right',
          value: 'amount',
          decimals: 4,
          type: 'curr'
        },

        {
          text: 'Total Approved',
          align: 'right',
          value: 'docketLine.approvedBillingAmount',
          decimals: 4,
          type: 'curr'
        },
        {
          text: 'Waived',
          align: 'right',
          value: 'docketLine.waivedAmount',
          decimals: 2,
          type: 'curr'
        },
        {
          text: `${this.$store.state.global.pages.docket.titleSingular} line rate`,
          align: 'right',
          type: 'curr',
          decimals: 2,
          value: 'docketLine.sellPrice'
        },
        {
          text: `${this.$store.state.global.pages.docket.titleSingular} line total`,
          align: 'right',
          value: 'docketLine.sellTotal',
          decimals: 4,
          type: 'curr'
        },
        {
          text: 'Outstanding',
          align: 'right',
          value: 'docketLine.outstandingBillingAmount',
          decimals: 2,
          type: 'curr'
        },
        {
          text: 'Notes',
          align: 'left',
          value: 'notes',
          type: 'text'
        },
        {
          text: `${this.$store.state.global.pages.bill.titleSingular} line ID`,
          align: 'right',
          value: 'billLineId',
          type: 'text'
        },
        {
          text: 'Created By',
          align: 'left',
          value: 'createdBy.name',
          type: 'text'
        },
        {
          text: 'Created At',
          align: 'right',
          value: 'createdAt',
          type: 'date'
        },
        {
          text: 'Updated At',
          align: 'left',
          value: 'updatedAt',
          type: 'date'
        }
      ]
    }
  }
}
