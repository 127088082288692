import { makeShortDate } from '../../utils/FormatDates'
class Rate {
  constructor(rate) {
    Object.keys(rate).forEach(key => {
      this[key] = rate[key]
      this[`_${key}`] = rate[key]
      const formats = [
        {
          condition: ['createdAt', 'updatedAt'].includes(key),
          format: val => makeShortDate(val)
        },
        {
          condition: [
            'costPrice',
            'costPriceAlt',
            'costPriceAltSunday',
            'costPriceNight',
            'costPriceSunday',
            'sellPrice',
            'exportAmount',
            'sellPriceAlt',
            'sellPriceAltSunday',
            'sellPriceNight',
            'sellPriceSunday',
            'qty',
            'hours'
          ].includes(key),
          format: val => Number(val).toFixed(2)
        }
      ]

      formats.forEach(item => {
        if (item.condition && rate[key])
          this[`_${key}`] = item.format(rate[key])
      })
    })
  }
  get vehicleTypeIds() {
    const types = this.rateVehicleTypes
    return types ? types.map(item => item.vehicleTypeId) : []
  }
  get itemTypeIds() {
    const types = this.rateItemTypes
    return types ? types.map(item => item.itemTypeId) : []
  }
  get vehicleTypeNames() {
    const types = this.rateVehicleTypes
    return types?.length
      ? types
          .map(item => item.vehicleType.name)
          .toString()
          .replace(',', '; ')
      : 'All'
  }
  get itemTypeNames() {
    const types = this.rateItemTypes
    return types?.length
      ? types
          .map(item => item.itemType.name)
          .toString()
          .replace(',', '; ')
      : 'All'
  }

  get truncatedVehicleTypes() {
    return this.vehicleTypeNames.length
      ? this.vehicleTypeNames?.length < 25
        ? this.vehicleTypeNames
        : `${this.vehicleTypeNames.substring(0, 25)}...`
      : 'All'
  }

  get truncatedItemTypes() {
    return this.itemTypeNames.length
      ? this.itemTypeNames?.length < 25
        ? this.itemTypeNames
        : `${this.itemTypeNames.substring(0, 25)}...`
      : 'All'
  }
}

export default Rate
