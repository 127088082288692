import Store from '@/store/computed/storeHelpers'
export default {
  mounted() {
    this.initPagination()
  },
  computed: {
    pagination: Store.getSet({ store: 'masterfiles', prop: 'pagination' })
  },
  methods: {
    initPagination() {
      this.pagination = {
        page: 1,
        sortDesc: [],
        itemsPerPage: 10,
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        sortBy: ['name']
      }
    }
  }
}
