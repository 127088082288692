import Api from '@/services/api.js'

export const addContractor = payload => {
  return Api.post('contractors/', payload)
}
export const getContractors = payload => {
  return Api.post('contractors/get', payload)
}
export const getContractorsLite = () => {
  return Api.get('contractors/')
}
export const updateContractor = (id, payload) => {
  return Api.put(`contractors/${id}`, payload)
}
export const deleteContractor = id => {
  return Api.delete(`contractors/${id}`)
}
