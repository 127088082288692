const titles = {
  allocation: 'Allocations',
  plan: 'Plans',
  docket: 'Dockets',
  source: 'Sites',
  destination: 'Tips',
  itemType: 'Spoils',
  project: 'Projects',
  rate: 'Rates',
  rcti: 'RCTIs',
  contractor: 'Contractors',
  bill: 'Bills',
  pod: 'PoDs',
  approvedBillLine: 'Approved Billing',
  settings: 'Settings',
  billingPeriod: 'Billing Periods',
  vehicle: 'Vehicles',
  vehicleType: 'Vehicle Types',
  driver: 'Drivers',
  policy: 'Policy Agreements',
  compliance: 'Compliance',
  audit: 'Audit'
}

const pages = {
  dashboard: {
    title: 'Dashboard',
    icon: 'mdi-view-dashboard',
    iconAlt: 'mdi-view-dashboard-outline',
    text: 'Dashboard overview',
    route: 'dashboard'
  },
  project: {
    title: titles.project,
    titleSingular: 'Project',
    icon: 'mdi-view-list',
    iconAlt: 'mdi-view-list-outline',
    text: `Add, view and edit ${titles.project.toLowerCase()}`,
    route: 'project',
    label: `active ${titles.project.toLowerCase()}`
  },
  rate: {
    title: titles.rate,
    titleSingular: 'Rate',
    icon: 'mdi-cash-multiple',
    iconAlt: 'mdi-cash-multiple',
    text: `Add, view and edit ${titles.rate.toLowerCase()}`,
    route: 'rate',
    label: `active ${titles.rate.toLowerCase()}`
  },
  rcti: {
    title: titles.rcti,
    titleSingular: 'RCTI',
    icon: 'mdi-view-list',
    iconAlt: 'mdi-view-list-outline',
    text: `Add, view and edit ${titles.rcti.toLowerCase()}`,
    route: 'rcti',
    label: `active ${titles.rcti.toLowerCase()}`
  },
  allocation: {
    title: titles.allocation,
    titleSingular: 'Allocation',
    icon: 'mdi-note-text',
    iconAlt: 'mdi-note-text-outline',
    text: `Add, view and edit ${titles.allocation.toLowerCase()}`,
    route: 'allocation',
    label: titles.allocation.toLowerCase()
  },
  plan: {
    title: titles.plan,
    titleSingular: 'Plan',
    icon: 'mdi-note-text',
    iconAlt: 'mdi-note-text-outline',
    text: `Add, view and edit ${titles.plan.toLowerCase()}`,
    route: 'plan',
    label: titles.plan.toLowerCase()
  },
  pod: {
    title: titles.pod,
    titleSingular: 'Proof of Delivery',
    icon: 'mdi-map-marker-check',
    iconAlt: 'mdi-map-marker-check-outline',
    text: `Add, view and edit ${titles.pod.toLowerCase()}`,
    route: 'pod',
    label: titles.pod.toLowerCase()
  },
  docket: {
    title: titles.docket,
    titleSingular: 'Docket',
    icon: 'mdi-text-box',
    iconAlt: 'mdi-text-box-outline',
    text: `Add, view and edit ${titles.docket.toLowerCase()}`,
    route: 'docket',
    label: titles.docket.toLowerCase()
  },
  map: {
    title: 'Map',
    titleSingular: 'Map',
    icon: 'mdi-map',
    iconAlt: 'mdi-map-outline',
    text: 'Location tracking',
    route: 'map',
    label: 'active drivers',
    number: '11',
    endOfCategory: true
  },
  user: {
    title: 'Users',
    titleSingular: 'User',
    icon: 'mdi-account-multiple',
    iconAlt: 'mdi-account-multiple-outline',
    text: 'Add, view and edit users',
    route: 'user',
    label: 'active users',
    number: '7'
  },
  userAuthClass: {
    title: 'User Authorisation',
    titleSingular: 'User Authorisation',
    icon: 'mdi-security',
    text: 'Add, view and edit user authorisation class',
    route: 'userAuthClass',
    label: 'active users',
    number: '7'
  },
  source: {
    title: titles.source,
    titleSingular: 'Site',
    icon: 'mdi-arrow-decision',
    iconAlt: 'mdi-arrow-decision-outline',
    text: `Add, view and edit ${titles.source.toLowerCase()}`,
    route: 'source',
    label: `active ${titles.source.toLowerCase()}`,
    endOfCategory: true
  },
  destination: {
    title: titles.destination,
    titleSingular: 'Tip',
    icon: 'mdi-map-marker-radius',
    iconAlt: 'mdi-map-marker-radius-outline',
    text: `Add, view and edit ${titles.destination.toLowerCase()}`,
    route: 'destination',
    label: `active ${titles.destination.toLowerCase()}`
  },
  itemType: {
    title: titles.itemType,
    titleSingular: 'Spoil',
    icon: 'mdi-package-variant',
    iconAlt: 'mdi-package-variant-closed',
    text: `Add, view and edit ${titles.itemType.toLowerCase()}`,
    route: 'itemType',
    label: `active ${titles.itemType.toLowerCase()}`,
    endOfCategory: true
  },
  contractor: {
    title: titles.contractor,
    titleSingular: 'Contractor',
    icon: 'mdi-account-box-multiple',
    iconAlt: 'mdi-account-box-multiple-outline',
    text: `Add, view and edit ${titles.contractor.toLowerCase()}`,
    route: 'contractor',
    label: `active ${titles.contractor.toLowerCase()}`
  },
  vehicle: {
    title: titles.vehicle,
    titleSingular: 'Vehicle',
    icon: 'mdi-truck',
    iconAlt: 'mdi-truck-outline',
    text: `Add, view and edit ${titles.vehicle.toLowerCase()}`,
    route: 'vehicle',
    label: `active ${titles.vehicle.toLowerCase()}`
  },
  vehicleType: {
    title: titles.vehicleType,
    titleSingular: 'Vehicle Type',
    icon: 'mdi-wrench',
    iconAlt: 'mdi-wrench-outline',
    text: `Add, view and edit ${titles.vehicleType.toLowerCase()}`,
    route: 'vehicleType',
    label: `active ${titles.vehicleType.toLowerCase()}`
  },
  driver: {
    title: titles.driver,
    titleSingular: 'Driver',
    icon: 'mdi-card-account-details',
    iconAlt: 'mdi-card-account-details-outline',
    text: `Add, view and edit ${titles.driver.toLowerCase()}`,
    route: 'driver',
    label: `active ${titles.driver.toLowerCase()}`
  },
  policy: {
    title: titles.policy,
    titleSingular: 'Policy',
    icon: 'mdi-file-sign',
    iconAlt: 'mdi-file-sign',
    text: `Add, view and edit ${titles.policy.toLowerCase()}`,
    route: 'policy',
    label: 'active policies'
  },
  compliance: {
    title: titles.compliance,
    titleSingular: 'Compliance',
    icon: 'mdi-security',
    text: `Add, view and edit ${titles.compliance.toLowerCase()}`,
    route: 'compliance',
    label: `expired ${titles.compliance.toLowerCase()}s`
  },
  audit: {
    title: 'Audits',
    titleSingular: 'Audit',
    icon: 'mdi-history',
    text: 'View Audits',
    route: 'audit',
    label: ''
  },
  bill: {
    title: titles.bill,
    titleSingular: 'Bill',
    icon: 'mdi-folder-account',
    text: `Add, view and edit ${titles.bill.toLowerCase()}`,
    route: 'bill',
    label: `active ${titles.bill.toLowerCase()}`
  },
  approvedBillLine: {
    title: titles.approvedBillLine,
    titleSingular: titles.approvedBillLine,
    menuTitle: 'Bills',
    icon: 'mdi-folder-account',
    text: `Add, view and edit ${titles.bill.toLowerCase()}`,
    route: 'approvedBillLine',
    label: `active ${titles.approvedBillLine.toLowerCase()}`
  },
  settings: {
    title: titles.settings,
    titleSingular: 'Settings',
    icon: 'mdi-cog',
    iconAlt: 'mdi-cog-outline',
    text: `Add, view and edit ${titles.settings.toLowerCase()}`,
    route: 'settings',
    label: `active ${titles.settings.toLowerCase()}`
  },
  billingPeriod: {
    title: titles.billingPeriod,
    titleSingular: 'Billing Period',
    icon: 'mdi-clock',
    iconAlt: 'mdi-clock-outline',
    text: `Add, view and edit ${titles.billingPeriod.toLowerCase()}`,
    route: 'billingPeriod',
    label: `active ${titles.billingPeriod.toLowerCase()}`
  }
}
export default {
  mainPages: [
    pages.allocation,
    pages.plan,
    pages.docket,
    // pages.approvedBillLine,
    pages.bill,
    pages.rcti,
    pages.user,
    pages.pod,
    pages.map,
    pages.compliance,
    pages.audit
  ],
  masterFilePages: [
    pages.project,
    pages.rate,
    pages.source,
    pages.destination,
    pages.itemType,
    pages.contractor,
    pages.vehicle,
    pages.driver
  ],
  settingsPages: [pages.settings, pages.policy],
  pages: pages
}
