<template>
  <v-form ref="form" @submit.prevent="add()">
    <v-card flat class="card-outlined">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="toggleAdd">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="pl-1">
          <span class="text-truncate">
            Add {{ $store.state.global.pages.rate.titleSingular }}
          </span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pb-0">
        <v-card-title
          class="pa-0 font-weight-regular d-flex justify-space-between"
        >
          <span></span>
          <!-- {{ `Add ${titleSingular}` }} -->
          <v-switch
            class="text-right"
            color="primary"
            label="Active rate"
            v-model="active"
            inset
          ></v-switch>
        </v-card-title>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            v-for="item in autoCompletes"
            :key="item.value"
          >
            <v-autocomplete
              :label="item.label"
              :items="
                item.page == 'source'
                  ? sources
                  : item.page == 'project'
                  ? projects
                  : destinations
              "
              :prepend-icon="$store.state.global.pages[item.page].icon"
              :item-text="item.altName || 'name'"
              item-value="id"
              :rules="rules[item.page]"
              v-model="rate[item.value]"
              no-data-text="No data found"
              color="primary"
              clearable
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-expansion-panels flat class="card-outlined mb-3">
              <SelectListPanel
                v-if="$store.state.vehicleTypes.vehicleTypes.length"
                :title="
                  `${$store.state.global.pages.vehicleType.titleSingular}`
                "
                :icon="$store.state.global.pages.vehicleType.icon"
                :subtitle="getSubtitle('vehicleType')"
                :items="$store.state.vehicleTypes.vehicleTypes"
                :select="selected => (selectedVehicleTypeIds = selected)"
              />
            </v-expansion-panels>
            <v-expansion-panels flat class="card-outlined">
              <SelectListPanel
                v-if="$store.state.itemTypes.itemTypesLite.length"
                :title="$store.state.global.pages.itemType.titleSingular"
                :icon="$store.state.global.pages.itemType.icon"
                :subtitle="getSubtitle('itemType')"
                :items="$store.state.itemTypes.itemTypesLite"
                :select="selected => (selectedItemTypeIds = selected)"
              />
            </v-expansion-panels>
          </v-col>

          <v-col
            cols="12"
            :sm="item.sm"
            v-for="item of fields"
            :key="item.value"
          >
            <h4 v-if="item.type === 'heading'">{{ item.value }}</h4>

            <v-text-field
              v-else-if="item.type === 'text'"
              :label="item.label"
              v-model="rate[item.value]"
              :rules="item.rules"
              :prepend-icon="item.icon"
              color="primary"
            ></v-text-field>

            <v-text-field
              v-else-if="item.type === 'number'"
              :type="item.type"
              :label="item.label"
              v-model.number="rate[item.value]"
              :rules="item.rules"
              :prepend-icon="item.icon"
              :hint="item.hint"
              color="primary"
            ></v-text-field>

            <v-autocomplete
              v-else-if="item.type === 'autocomplete'"
              :label="item.label"
              v-model="rate[item.value]"
              :items="units"
              no-data-text="No data found"
              color="primary"
            ></v-autocomplete>

            <v-switch
              v-else-if="item.type === 'switch'"
              :label="item.label"
              v-model="rate[item.value]"
              color="primary"
              :class="item.class"
              inset
            ></v-switch>
          </v-col>

          <v-col cols="12">
            <v-expansion-panels>
              <AltRatePanel
                :values="{
                  costPriceAlt: rate.costPriceAlt,
                  costUnitAlt: rate.costUnitAlt,
                  sellPriceAlt: rate.sellPriceAlt,
                  sellUnitAlt: rate.sellUnitAlt,
                  costPriceAltSunday: rate.costPriceAltSunday,
                  sellPriceAltSunday: rate.sellPriceAltSunday
                }"
                :units="units"
                :priceRules="priceRules"
                :onChange="updateValue"
              />
              <NightRatePanel
                :values="{
                  costPriceNight: rate.costPriceNight,
                  costUnitNight: rate.costUnitNight,
                  sellPriceNight: rate.sellPriceNight,
                  sellUnitNight: rate.sellUnitNight
                }"
                :units="units"
                :priceRules="priceRules"
                :onChange="updateValue"
              />
              <SundayRatePanel
                :values="{
                  costPriceSunday: rate.costPriceSunday,
                  costUnitSunday: rate.costUnitSunday,
                  sellPriceSunday: rate.sellPriceSunday,
                  sellUnitSunday: rate.sellUnitSunday
                }"
                :units="units"
                :priceRules="priceRules"
                :onChange="updateValue"
              />
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="cancel">Cancel</v-btn>
        <v-btn text color="primary" type="submit" :loading="loading">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { addRate } from '../../services/requests/rates'
import RateValidation from '../../services/validation/rate'
import RateFields from './mixins/rateFields'
import Rate from '@/services/models/rate'

import SelectListPanel from '../misc/SelectListPanel'
import AltRatePanel from './AltRatePanel'
import NightRatePanel from './NightRatePanel'
import SundayRatePanel from './SundayRatePanel'

export default {
  mixins: [RateValidation, RateFields],
  components: {
    SelectListPanel,
    AltRatePanel,
    NightRatePanel,
    SundayRatePanel
  },
  props: {
    toggleAdd: {
      type: Function,
      required: true
    },
    projects: {
      type: Array,
      required: true
    },
    sources: {
      type: Array,
      required: true
    },
    destinations: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      rate: {
        projectId: null,
        sourceId: null,
        itemTypeId: null,
        destinationId: null,
        costPrice: 0,
        costUnit: 'TONNE',
        costPriceAlt: 0,
        costPriceAltSunday: 0,
        costUnitAlt: 'HOUR',
        costPriceSunday: 0,
        costUnitSunday: 'TONNE',
        costPriceNight: 0,
        costUnitNight: 'TONNE',
        sellPrice: 0,
        sellUnit: 'TONNE',
        exportAmount: 0,
        sellPriceAlt: 0,
        sellPriceAltSunday: 0,
        sellUnitAlt: 'HOUR',
        sellPriceSunday: 0,
        sellUnitSunday: 'TONNE',
        sellPriceNight: 0,
        sellUnitNight: 'TONNE',
        altRateTrips: 0,
        useTonnage: false,
        adminFeeRequired: false,
        notes: ''
      },
      active: true,
      selectedVehicleTypeIds: [],
      selectedItemTypeIds: [],
      loading: false
    }
  },
  methods: {
    getSubtitle(type) {
      if (type == 'itemType')
        return this.selectedItemTypes.length
          ? this.selectedItemTypes.map(item => item.name).toString()
          : `All ${this.$store.state.global.pages.itemType.title}`
      else
        return this.selectedVehicleTypes.length
          ? this.selectedVehicleTypes.map(item => item.name).toString()
          : `All ${this.$store.state.global.pages.vehicleType.title}`
    },
    async add() {
      if (!this.$refs.form.validate()) {
        this.snackFormError()
        return
      }
      try {
        this.loading = true
        const payload = {
          ...this.rate,
          active: this.active,
          vehicleTypes: this.selectedVehicleTypes.length
            ? JSON.stringify(this.selectedVehicleTypes)
            : null,
          itemTypes: this.selectedItemTypes.length
            ? JSON.stringify(this.selectedItemTypes)
            : null,
          selectedVehicleTypeIds: this.selectedVehicleTypeIds,
          selectedItemTypeIds: this.selectedItemTypeIds
        }
        const result = await addRate(payload)
        if (result) {
          this.$store.commit('rates/addToArrayState', {
            prop: 'rates',
            value: new Rate(result.data.result)
          })
          this.cancel()
          this.snackCreated()
        }
        this.loading = false
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    updateValue(key, value) {
      this.rate[key] = value
    },
    cancel() {
      this.toggleAdd()
      this.clear()
    },
    clear() {
      this.rate = {
        costPrice: 0,
        costUnit: 'TONNE',
        costPriceAlt: 0,
        costPriceAltSunday: 0,
        costUnitAlt: 'HOUR',
        costPriceSunday: 0,
        costUnitSunday: 'TONNE',
        costPriceNight: 0,
        costUnitNight: 'TONNE',
        sellPrice: 0,
        sellUnit: 'TONNE',
        sellPriceAlt: 0,
        sellPriceAltSunday: 0,
        sellUnitAlt: 'HOUR',
        sellPriceSunday: 0,
        sellUnitSunday: 'TONNE',
        sellPriceNight: 0,
        sellUnitNight: 'TONNE',
        useTonnage: false,
        altRateTrips: 0,
        adminFeeRequired: false,
        notes: '',
        projectId: null,
        sourceId: null,
        itemTypeId: null,
        destinationId: null
      }
      this.active = true
      this.selectedVehicleTypeIds = []
      this.selectedItemTypeIds = []
      this.name = null
    }
  }
}
</script>
