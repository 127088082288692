import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    plans: [],
    lines: [],
    loadingPlans: false,
    loadingLines: false,
    lineView: false,
    filterCol: null,
    filterBy: null,
    selectedAll: false,
    selectedAllLines: false,
    selected: [],
    selectedLines: [],
    planDefaultHeaders: [
      'id',
      '_date',
      'source.name',
      'notes',
      'lineCount',
      'status',
      'createdBy.name',
      'updatedBy.name'
    ],
    planLineDefaultHeaders: ['id', 'destination.name', 'itemType.name', 'notes']
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
