import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    rctis: [],
    loadingRctis: false,
    filterCol: null,
    filterBy: null,
    selectedAll: false,
    selected: [],
    rctiDefaultHeaders: [
      'id',
      'date',
      'source.name',
      'contractor.name',
      'driver.name',
      'notes',
      'total',
      'createdBy.name'
    ],
    rctiLineDefaultHeaders: [
      'id',
      'description',
      '_costTotal',
      'notes',
      'createdBy.name',
      'updatedBy.name'
    ]
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
