export default {
  data() {
    return {
      allBillLineHeaders: [
        {
          text: `${this.$store.state.global.pages.bill.titleSingular} ID`,
          align: 'left',
          value: 'billId',
          type: 'text'
        },
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          type: 'text'
        },
        {
          text: 'Date',
          align: 'left',
          value: 'bill._date'
        },
        {
          text: this.$store.state.global.pages.project.titleSingular,
          align: 'left',
          value: 'bill.project.name',
          type: 'text',
          show: false
        },
        {
          text: this.$store.state.global.pages.source.titleSingular,
          align: 'left',
          value: 'bill.source.name',
          show: false
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          align: 'left',
          value: 'bill.destination.name',
          show: false
        },
        {
          text: 'ABN',
          align: 'left',
          value: 'bill.abn',
          type: 'text',
          show: false
        },
        {
          text: 'Description',
          align: 'left',
          value: 'description',
          type: 'text'
        },
        {
          text: 'Notes',
          align: 'left',
          value: 'notes',
          type: 'text'
        },
        {
          text: 'Sell Total',
          align: 'left',
          value: '_sellTotal',
          type: 'curr'
        },
        {
          text: 'Created By',
          align: 'left',
          value: 'createdBy.name',
          type: 'text',
          show: false
        },
        {
          text: 'Updated By',
          align: 'left',
          value: 'updatedBy.name',
          type: 'text',
          show: false
        },
        {
          text: 'Created At',
          align: 'left',
          value: '_createdAt',
          show: false
        },
        {
          text: 'Updated At',
          align: 'left',
          value: '_updatedAt',
          show: false
        }
      ]
    }
  }
}
