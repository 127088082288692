<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span>
          Edit
          {{
            editedItem.isStaged ? `(staged) ${titleSingular}` : titleSingular
          }}
          -
          <span class="pl-1 subtitle-1">
            ID: <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-sheet outlined :color="editedItem.isStaged ? 'amber' : ''" rounded>
            <v-card flat class="card-outlined">
              <v-form ref="editForm" @submit.prevent="edit()">
                <v-card-text class="pb-0">
                  <v-row class="pt-3">
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        label="Reference"
                        prepend-icon="mdi-group"
                        v-model="editedItem.reference"
                        :rules="referenceRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <DatePickerWrapper
                        label="* Date"
                        v-model="editedItem.date"
                        :rules="dateRules"
                        :disabled="editedItem.void"
                      />
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-autocomplete
                        :label="
                          `* ${$store.state.global.pages.source.titleSingular}`
                        "
                        :prepend-icon="$store.state.global.pages.source.icon"
                        v-model="editedItem.sourceId"
                        :items="sources.filter(i => i.project.active)"
                        item-value="id"
                        item-text="sourceName_projectName"
                        clearable
                        :rules="sourceRules"
                        :disabled="editedItem.void"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-autocomplete
                        :label="
                          `* ${$store.state.global.pages.vehicle.titleSingular}`
                        "
                        :prepend-icon="$store.state.global.pages.vehicle.icon"
                        v-model="editedItem.vehicleId"
                        :items="
                          filteredVehicles.filter(
                            i => i.contractor.active && i.active
                          )
                        "
                        item-value="id"
                        item-text="vehicleName_contractorName_vehicleType"
                        clearable
                        :rules="vehicleRules"
                        :disabled="editedItem.void"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-autocomplete
                        :label="
                          `* ${$store.state.global.pages.driver.titleSingular}`
                        "
                        :prepend-icon="$store.state.global.pages.driver.icon"
                        v-model="editedItem.driverId"
                        :items="
                          filteredDrivers.filter(
                            i => i.contractor.active && i.active
                          )
                        "
                        item-value="id"
                        item-text="driverName_contractorName"
                        clearable
                        :rules="driverRules"
                        :disabled="editedItem.void"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        label="Notes"
                        prepend-icon="mdi-note"
                        v-model="editedItem.notes"
                        :rules="notesRules"
                        :disabled="editedItem.void"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <DatePickerWrapper
                        label="End Date"
                        v-model="editedItem.endDate"
                        :rules="[]"
                        :disabled="editedItem.void"
                      />
                    </v-col>
                    <v-col cols="6" sm="3">
                      <v-switch
                        :label="editedItem.isDay ? 'Day' : 'Night'"
                        color="primary"
                        v-model="editedItem.isDay"
                        inset
                        :disabled="editedItem.void"
                      ></v-switch>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <v-switch
                        :label="
                          `Staged ${$store.state.global.pages.allocation.titleSingular.toLowerCase()}`
                        "
                        color="primary"
                        v-model="editedItem.isStaged"
                        inset
                        :disabled="editedItem.void"
                      ></v-switch>
                    </v-col>
                    <v-col cols="6" sm="3" v-if="isStaged">
                      <v-switch
                        label="Duplicate"
                        color="primary"
                        v-model="editedItem.hasDuplicate"
                        inset
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" v-if="editedItem.isStaged">
                      <v-expansion-panels flat class="card-outlined">
                        <v-expansion-panel active-class="mt-0">
                          <v-expansion-panel-header>
                            <h4 class="grey--text">
                              Staging Confirmations
                            </h4>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row
                              v-for="item of stagingConfirmations"
                              :key="item.name"
                            >
                              <v-col cols="12" sm="3" class="py-0">
                                <v-select
                                  :label="`${item.name} Status`"
                                  :prepend-icon="item.icon"
                                  v-model="item.status"
                                  :items="['CONFIRMED', 'REVIEW', 'NOT SET']"
                                  :rules="stagingStatusRules"
                                ></v-select>
                              </v-col>
                              <v-col cols="12" sm="9" class="py-0">
                                <v-text-field
                                  :label="`${item.name} Notes`"
                                  prepend-icon="mdi-note"
                                  v-model="item.notes"
                                  :rules="stagingNotesRules"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="grey darken-1" @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    :loading="loading"
                    type="submit"
                    :disabled="editedItem.void"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-sheet>
        </v-col>
        <v-col>
          <AllocationLineAdd
            :lines="allocationLines"
            :onAdd="addAllocationLine"
            :onEdit="onEdit"
            :onDelete="onDelete"
            :loading="loading"
            :loadingLines="loadingLines"
            isEditAllocation
            :lineHeaders="lineHeaders"
            :source="source"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Vue from 'vue'
import Store from '../../store/computed/storeHelpers'
import {
  getLinesByAllocationId,
  updateAllocationLine,
  addAllocationLine,
  deleteAllocationLine
} from '../../services/requests/allocations'
import AllocationValidation from '@/services/validation/allocation'
import LineHeaders from './lines/mixins/lineHeaders'
import HeaderFilters from '../../services/mixins/headerFilters'
import FilterByContractor from '../../services/mixins/filterByContractor'
import AllocationLine from '../../services/models/allocationLine'
import AllocationLineAdd from '../allocations/lines/AllocationLineAdd'

import DatePickerWrapper from '../misc/fields/DatePickerWrapper'
export default {
  mixins: [
    AllocationValidation,
    LineHeaders,
    HeaderFilters,
    FilterByContractor
  ],
  components: {
    DatePickerWrapper,
    AllocationLineAdd
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    this.getLines()
  },
  data() {
    return {
      editedItem: Object.assign({}, this.item),
      allocationLines: [],
      loadingLines: false,
      addLineDialog: false,
      stagingConfirmations: [
        {
          name: this.$store.state.global.pages.source.titleSingular,
          icon: this.$store.state.global.pages.source.icon,
          status: this.item.sourceConfirmed,
          notes: this.item.sourceNotes
        },
        {
          name: this.$store.state.global.pages.destination.titleSingular,
          icon: this.$store.state.global.pages.destination.icon,
          status: this.item.destinationConfirmed,
          notes: this.item.destinationNotes
        },
        {
          name: 'Amendments',
          icon: 'mdi-pen-plus',
          status: this.item.amendmentsConfirmed,
          notes: this.item.amendmentsNotes
        }
      ]
    }
  },
  computed: {
    sources: Store.getSet({ store: 'sources', prop: 'sourcesLite' }),
    isStaged: Store.getSet({ store: 'allocations', prop: 'isStaged' }),
    vehicles: Store.getSet({ store: 'vehicles', prop: 'vehiclesLite' }),
    drivers: Store.getSet({ store: 'drivers', prop: 'driversLite' }),
    lineHeaders() {
      return Number(this.window?.width) >= 600
        ? this.allPossibleHeaders
        : this.mobileHeaders
    },
    calNewAdded: Store.getSet({ store: 'allocations', prop: 'calNewAdded' })
  },
  watch: {
    item() {
      this.editedItem = Object.assign({}, this.item)
    }
  },
  methods: {
    async getLines() {
      try {
        this.loadingLines = true
        const result = await getLinesByAllocationId(this.item.id)
        const items = result.data.result
        this.allocationLines = items.map(item => new AllocationLine(item))
        this.loadingLines = false
      } catch (err) {
        console.log(err)
      }
    },
    async edit() {
      if (!this.$refs.editForm.validate()) {
        this.snackFormError()
        return
      }
      if (
        this.editedItem.endDate &&
        this.editedItem.endDate < this.editedItem.date
      ) {
        this.snack({
          text: 'End Date must be greater or equal to Date!',
          color: 'red'
        })
        return
      }
      const payload = {
        ...this.editedItem,
        sourceConfirmed: this.stagingConfirmations[0].status,
        sourceNotes: this.stagingConfirmations[0].notes,
        destinationConfirmed: this.stagingConfirmations[1].status,
        destinationNotes: this.stagingConfirmations[1].notes,
        amendmentsConfirmed: this.stagingConfirmations[2].status,
        amendmentsNotes: this.stagingConfirmations[2].notes
      }
      await this.action(payload)
      this.calNewAdded = true
      this.close()
    },
    async onEdit({ item }) {
      try {
        this.loadingLines = true
        const payload = {
          allocationId: item.allocationId,
          destinationId: item.destinationId,
          itemTypeId: item.itemTypeId,
          qty: item.qty,
          notes: item.notes,
          addressStreet1: item.addressStreet1,
          addressStreet2: item.addressStreet2,
          addressCity: item.addressCity,
          addressState: item.addressState
        }
        const result = await updateAllocationLine(item.id, payload)
        if (result) {
          const i = this.allocationLines.findIndex(line => line.id === item.id)
          if (~i) {
            const editedAllocationLine = new AllocationLine(result.data.result)
            Vue.set(this.allocationLines, i, editedAllocationLine)
            this.$store.commit(`allocations/updateArrayStateById`, {
              prop: 'lines',
              editedValue: editedAllocationLine
            })
          }
          this.snack({
            text: `${this.titleSingular} line updated`,
            color: 'green'
          })
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }
    },
    async addAllocationLine(line) {
      try {
        this.loadingLines = true
        const payload = {
          destinationId: line.destinationId,
          itemTypeId: line.itemTypeId,
          notes: line.notes,
          allocationId: this.editedItem.id,
          addressStreet1: line.addressStreet1,
          addressStreet2: line.addressStreet2,
          addressCity: line.addressCity,
          addressState: line.addressState
        }
        const result = await addAllocationLine(payload)
        if (result) {
          await this.getLines()
          const newAllocationLine = new AllocationLine(result.data.line)
          this.$store.commit('allocations/addToArrayState', {
            prop: 'lines',
            value: newAllocationLine
          })
          this.$store.commit('allocations/updateArrayStateById', {
            prop: 'allocations',
            editedValue: result.data.result
          })
          this.snack({
            text: `${this.titleSingular} line created`,
            color: 'green'
          })
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }
    },
    async onDelete(line) {
      try {
        this.loadingLines = true
        const result = await deleteAllocationLine(line.id)
        if (result) {
          this.$store.commit('allocations/deleteFromArrayStateById', {
            prop: 'lines',
            id: line.id
          })
          this.$store.commit('allocations/updateArrayStateById', {
            prop: 'allocations',
            editedValue: result.data.result
          })
          await this.getLines()
          this.snack({
            text: `${this.titleSingular} line deleted`,
            color: 'amber'
          })
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }
    }
  }
}
</script>
