<template>
  <v-tabs
    :vertical="$vuetify.breakpoint.smAndUp"
    v-model="tab"
    class="my-tabs"
    show-arrows
  >
    <v-tab>
      <v-icon left>
        {{ $store.state.global.pages.pod.icon }}
      </v-icon>
      All {{ $store.state.global.pages.pod.title }}
    </v-tab>

    <v-tab v-for="item in timesheets" :key="item.id">
      <v-icon left>mdi-clock</v-icon>
      Timesheet
    </v-tab>

    <v-tab v-for="item in images" :key="item.id">
      <v-icon left>mdi-camera</v-icon>
      Image
    </v-tab>

    <v-tab v-for="item in forms" :key="item.id">
      <v-icon left>mdi-note</v-icon>
      Form
    </v-tab>

    <v-tab-item>
      <PodDetails
        :loading="loading"
        :timesheets="timesheets"
        :images="images"
        :forms="forms"
        :changeTab="changeTab"
        :lines="lines"
      />
    </v-tab-item>

    <v-tab-item v-for="item in timesheets" :key="item.id">
      <TimesheetDetails :item="item" />
    </v-tab-item>

    <v-tab-item v-for="item in images" :key="item.id">
      <ImageDetails :item="item" />
    </v-tab-item>

    <v-tab-item v-for="item in forms" :key="item.id">
      <FormDetails :item="item" />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import PodDetails from './PodDetails'
import TimesheetDetails from './TimesheetDetails'
import ImageDetails from './ImageDetails'
import FormDetails from './FormDetails'
export default {
  components: {
    PodDetails,
    TimesheetDetails,
    ImageDetails,
    FormDetails
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    timesheets: {
      type: Array,
      required: true
    },
    images: {
      type: Array,
      required: true
    },
    forms: {
      type: Array,
      required: true
    },
    lines: {
      type: Array,
      required: true
    },
    podId: {
      type: Number,
      required: false
    },
    allPods: {
      type: Array,
      required: true
    }
  },
  mounted() {
    if (this.podId) {
      this.changeTab(this.podId)
    }
  },
  data() {
    return {
      tab: 0
    }
  },
  methods: {
    changeTab(podId) {
      const pod = this.allPods.find(({ id }) => podId == id)
      const i = this.allPods.indexOf(pod)
      if (i >= 0) this.tab = i + 1
    }
  }
}
</script>

<style>
div.my-tabs [role='tab'] {
  justify-content: flex-start;
}
</style>
