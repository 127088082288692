import {
  getApprovedBillLines,
  getApprovedBillLinesByIds
} from '../../../services/requests/approvedBillLines'

export default {
  data() {
    return {
      exportOptions: [
        {
          name: this.$store.state.global.pages.approvedBillLine.title,
          fetchData: () => this.getHeaders(),
          keys: [
            { text: 'ID', value: 'id' },
            {
              text: 'Billing Period',
              value: ['docketLine', 'docket', 'billingPeriod']
            },
            { text: 'Date', value: ['docketLine', 'docket', 'date'] },
            { text: 'Customer Claim Ref', value: 'customerClaimRef' },
            {
              text: this.$store.state.global.pages.project.titleSingular,
              value: ['docketLine', 'docket', 'source', 'project']
            },
            {
              text: this.$store.state.global.pages.source.titleSingular,
              value: ['docketLine', 'docket', 'source']
            },
            {
              text: `${this.$store.state.global.pages.docket.titleSingular} line ID`,
              value: 'docketLineId'
            },
            {
              text: this.$store.state.global.pages.itemType.titleSingular,
              value: ['docketLine', 'itemType']
            },
            {
              text: this.$store.state.global.pages.destination.titleSingular,
              value: ['docketLine', 'destination']
            },
            { text: 'Qty', value: 'qty' },
            { text: 'Rate', value: 'rate' },
            { text: 'Amount', value: 'amount' },
            { text: 'Notes', value: 'notes' },
            {
              text: `${this.$store.state.global.pages.bill.titleSingular} line ID`,
              value: 'billLineId'
            },
            { text: 'Created By', value: 'createdBy' },
            { text: 'Updated By', value: 'updatedBy' },
            { text: 'Created At', value: 'createdAt' },
            { text: 'Updated At', value: 'updatedAt' }
          ]
        }
      ]
    }
  },
  methods: {
    async getHeaders() {
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getApprovedBillLines(payload)
        return result.data.result
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getApprovedBillLinesByIds({ ids })
        return result.data.result
      }
    }
  }
}
