import SourceValidation from '../../validation/source'
import getProjectsLite from '../../mixins/getMasterFiles/lite/getProjectsLite'
import getDestinationsLite from '../../mixins/getMasterFiles/lite/getDestinationsLite'
import getVehiclesLite from '../getMasterFiles/lite/getVehiclesLite'
export default {
  mixins: [
    SourceValidation,
    getProjectsLite,
    getDestinationsLite,
    getVehiclesLite
  ],
  data() {
    return {
      states: ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'NT', 'ACT']
    }
  },
  computed: {
    fields() {
      return [
        {
          label: '* Name',
          prop: 'name',
          value: null,
          sm: '6',
          rules: this.nameRules
        },
        {
          label: '* Project',
          prop: 'projectId',
          value: null,
          sm: '6',
          rules: this.projectRules,
          items: this.projectsLite
        },
        {
          label: 'ABN',
          prop: 'abn',
          value: null,
          sm: '6',
          rules: this.abnRules
        },
        {
          label: 'Contact Email',
          prop: 'email',
          value: null,
          sm: '6',
          rules: this.contactEmailRules
        },
        {
          label: 'Contact Phone',
          prop: 'contactPhone',
          value: null,
          sm: '6',
          rules: this.contactPhoneRules
        },
        {
          label: `${this.$store.state.settings.settings.accountingSystem} ID`,
          prop: 'accountingContactId',
          value: null,
          sm: '6',
          rules: this.xeroIdRules
        },
        {
          label: 'Street Address 1',
          prop: 'addressStreet1',
          value: null,
          sm: '6',
          rules: this.addressRules
        },
        {
          label: 'Street Address 2',
          prop: 'addressStreet2',
          value: null,
          sm: '6',
          rules: this.addressRules
        },
        {
          label: 'City',
          prop: 'addressCity',
          value: null,
          sm: '4',
          rules: this.addressCityRules
        },
        {
          label: 'State',
          prop: 'addressState',
          value: null,
          sm: '4',
          items: this.states
        },
        {
          label: 'Post Code',
          prop: 'addressPostCode',
          value: null,
          sm: '4',
          rules: this.addressPostCodeRules
        },
        {
          label: 'Run ID',
          prop: 'runCode',
          value: null,
          sm: '6',
          rules: this.runCodeRules
        },
        {
          label: 'Details',
          prop: 'details',
          value: null,
          sm: '6',
          rules: this.detailsRules
        },
        {
          label: 'Geofence Radius (meters)',
          prop: 'geofenceRadius',
          value: 50,
          sm: '6',
          rules: this.geofenceRadiusRules,
          type: 'number'
        },
        {
          label: 'Latitude',
          prop: 'lat',
          value: null,
          sm: '6',
          rules: null,
          type: 'hidden'
        },
        {
          label: 'Longitude',
          prop: 'long',
          value: null,
          sm: '6',
          rules: null,
          type: 'hidden'
        },

        {
          label: 'Location',
          prop: 'location',
          value: null,
          sm: '6',
          rules: this.locationRules
        },
        {
          label: `Default ${this.$store.state.global.pages.destination.titleSingular}`,
          prop: 'destinationId',
          value: null,
          clearable: true,
          sm: '6',
          items: this.destinationsLite
        },
        {
          label: 'Request',
          prop: 'request',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.plan.titleSingular} ${this.$store.state.global.pages.vehicle.title}`,
          prop: 'allowedVehicles',
          items: this.vehiclesLiteActive,
          value: [],
          nameValue: 'vehicleName_contractorName_vehicleType',
          type: 'expansionCheckbox',
          panel: -1,
          icon: this.$store.state.global.pages.vehicle.icon
        }
      ]
    }
  }
}
