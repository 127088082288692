import VehicleTypeValidation from '../../validation/vehicleType'
export default {
  mixins: [VehicleTypeValidation],
  computed: {
    fields() {
      return [
        {
          label: '* Name',
          prop: 'name',
          value: null,
          sm: '6',
          rules: this.nameRules
        },
        {
          label: 'Max Tonnage',
          prop: 'tonnage',
          type: 'number',
          value: 0,
          sm: '6',
          rules: this.tonnageRules
        },
        {
          label: 'Class',
          prop: 'class',
          value: 'TRUCK',
          items: ['TRUCK', 'PLANT'],
          sm: '6'
        }
      ]
    }
  }
}
