import Api from '@/services/api.js'

export const addApprovedBillLine = approvedBillLine => {
  return Api.post('approvedBillLines/add', approvedBillLine)
}
export const updateApprovedBillLine = (id, payload) => {
  return Api.put(`approvedBillLines/${id}`, payload)
}
export const getApprovedBillLines = payload => {
  return Api.post(`approvedBillLines/get`, payload)
}
export const deleteApprovedBillLine = id => {
  return Api.delete(`approvedBillLines/${id}`)
}
export const deleteMultipleApprovedBillLines = payload => {
  return Api.post('approvedBillLines/deleteMultiple', payload)
}
export const importApprovedBillLines = file => {
  return Api.post('approvedBillLines/import', file)
}
export const getApprovedBillLinesByIds = payload => {
  return Api.post('approvedBillLines/byIds', payload)
}
export const getApprovedBillLinesByBillingPeriod = billingPeriod => {
  return Api.get(`approvedBillLines/byBillingPeriod/${billingPeriod}`)
}
export const getApprovedBillLinesByBill = id => {
  return Api.get(`approvedBillLines/byBill/${id}`)
}
