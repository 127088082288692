var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MasterFilePage',{scopedSlots:_vm._u([{key:"control",fn:function(){return [_c('MasterFileControl',{attrs:{"masterFile":_vm.masterFile,"addDialog":_vm.addDialog,"toggleAdd":_vm.toggleAdd,"parents":_vm.contractorsLite,"parent":_vm.parent,"parentId":_vm.parentId,"onSortChange":_vm.updateSortBy,"onFilterChange":_vm.updateFilterBy,"onParentChange":_vm.updateParent}})]},proxy:true},{key:"details",fn:function(){return [(_vm.parent)?_c('MasterFileDetails',{attrs:{"item":_vm.parent},on:{"close":function($event){_vm.parentId = null}}}):_vm._e()]},proxy:true},{key:"view",fn:function(){return [_c('MasterFileView',{attrs:{"count":Math.ceil(_vm.driverCount / _vm.pagination.itemsPerPage),"masterFile":_vm.masterFile,"subjects":_vm.sortedDrivers,"fields":_vm.fields,"loadingMasterFile":_vm.loadingDrivers,"loadingMasterFiles":!_vm.loadedContractorsLite ||
          !_vm.loadedSourcesLite ||
          !_vm.loadedVehiclesLite ||
          !_vm.loadedUsers ||
          !_vm.loadedProjectsLite,"loading":_vm.loading,"onEdit":_vm.editDriver,"onUpdatePassword":_vm.updatePassword,"onValidateLogin":_vm.validateLogin,"onDelete":_vm.deleteDriver,"filterBy":_vm.filterBy}})]},proxy:true},{key:"form",fn:function(){return [(
        _vm.loadedContractorsLite &&
          _vm.loadedSourcesLite &&
          _vm.loadedVehiclesLite &&
          _vm.loadedUsers &&
          _vm.loadedProjectsLite
      )?_c('v-dialog',{attrs:{"width":"700"},on:{"click:outside":function () { return (_vm.addDialog = false); },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return (function () { return (_vm.addDialog = false); })($event)}},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('MasterFileForm',{attrs:{"masterFile":_vm.masterFile,"fields":_vm.fields,"dialog":_vm.addDialog,"close":_vm.toggleAdd,"onSubmit":_vm.createDriver,"onValidateLogin":_vm.validateLogin,"loading":_vm.loading}})],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }