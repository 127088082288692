<template>
  <MasterFilePage>
    <template v-slot:control>
      <MasterFileControl
        :masterFile="masterFile"
        :addDialog="addDialog"
        :toggleAdd="toggleAdd"
        :backAction="() => $router.push({ name: 'user' })"
      />
    </template>
    <template v-slot:view>
      <UserAuthClassTable
        v-if="$store.state.user.userAuthClass[`${$route.name}Read`]"
        :masterFile="masterFile"
        :subjects="userAuthClasses"
        :fields="fields"
        :loading="loading"
        :onEdit="editUserAuthClass"
        :onDelete="deleteUserAuthClass"
      />
    </template>
    <template v-slot:form>
      <v-dialog
        v-model="addDialog"
        width="700"
        @click:outside="() => (addDialog = false)"
        @keydown.esc="() => (addDialog = false)"
      >
        <MasterFileForm
          :masterFile="masterFile"
          :fields="fields"
          :dialog="addDialog"
          :close="toggleAdd"
          :onSubmit="createUserAuthClass"
          :loading="loading"
        />
      </v-dialog>
    </template>
  </MasterFilePage>
</template>

<script>
import {
  addUserAuthClass,
  updateUserAuthClass,
  deleteUserAuthClass
} from '../../../services/requests/users'
import getUserAuthClasses from '../../../services/mixins/getMasterFiles/getUserAuthClasses'
import userAuthClassFields from '../../../services/mixins/fields/userAuthClassFields'
import MasterFileCrudFunctions from '../../../services/mixins/masterFileCrudFunctions'

import MasterFilePage from '../../../components/masterFiles/MasterFilePage'
import MasterFileControl from '../../../components/masterFiles/MasterFileControl'
import MasterFileForm from '../../../components/masterFiles/MasterFileForm'
import UserAuthClassTable from '../../../components/userAuthClass/UserAuthClassTable'
export default {
  mixins: [getUserAuthClasses, userAuthClassFields, MasterFileCrudFunctions],
  components: {
    MasterFilePage,
    MasterFileControl,
    MasterFileForm,
    UserAuthClassTable
  },
  data() {
    return {
      masterFile: 'userAuthClass',
      prop: 'userAuthClasses',
      addDialog: false,
      sortBy: null,
      filterBy: '',
      loading: false
    }
  },
  methods: {
    async createUserAuthClass(userAuthClass) {
      await this.createMasterFile({
        payload: userAuthClass,
        action: addUserAuthClass,
        store: 'users'
      })
    },
    async editUserAuthClass(userAuthClass) {
      const payload = {
        name: userAuthClass.name,
        allocationRead: userAuthClass.allocationRead,
        allocationWrite: userAuthClass.allocationWrite,
        planRead: userAuthClass.planRead,
        planWrite: userAuthClass.planWrite,
        docketRead: userAuthClass.docketRead,
        docketWrite: userAuthClass.docketWrite,
        rctiRead: userAuthClass.rctiRead,
        rctiWrite: userAuthClass.rctiWrite,
        billRead: userAuthClass.billRead,
        billWrite: userAuthClass.billWrite,
        // approvedBillLineRead: userAuthClass.approvedBillLineRead,
        // approvedBillLineWrite: userAuthClass.approvedBillLineWrite,
        podRead: userAuthClass.podRead,
        podWrite: userAuthClass.podWrite,
        mapRead: userAuthClass.mapRead,
        userRead: userAuthClass.userRead,
        userWrite: userAuthClass.userWrite,
        userAuthClassRead: userAuthClass.userAuthClassRead,
        userAuthClassWrite: userAuthClass.userAuthClassWrite,
        rateRead: userAuthClass.rateRead,
        rateWrite: userAuthClass.rateWrite,
        settingsRead: userAuthClass.settingsRead,
        settingsWrite: userAuthClass.settingsWrite,
        clientRead: userAuthClass.clientRead,
        clientWrite: userAuthClass.clientWrite,
        projectRead: userAuthClass.projectRead,
        projectWrite: userAuthClass.projectWrite,
        sourceRead: userAuthClass.sourceRead,
        sourceWrite: userAuthClass.sourceWrite,
        contractorRead: userAuthClass.contractorRead,
        contractorWrite: userAuthClass.contractorWrite,
        vehicleRead: userAuthClass.vehicleRead,
        vehicleWrite: userAuthClass.vehicleWrite,
        vehicleTypeRead: userAuthClass.vehicleTypeRead,
        vehicleTypeWrite: userAuthClass.vehicleTypeWrite,
        driverRead: userAuthClass.driverRead,
        driverWrite: userAuthClass.driverWrite,
        destinationRead: userAuthClass.destinationRead,
        destinationWrite: userAuthClass.destinationWrite,
        itemTypeRead: userAuthClass.itemTypeRead,
        itemTypeWrite: userAuthClass.itemTypeWrite,
        policyRead: userAuthClass.policyRead,
        policyWrite: userAuthClass.policyWrite,
        complianceRead: userAuthClass.complianceRead,
        auditRead: userAuthClass.auditRead,
        complianceWrite: userAuthClass.complianceWrite,
        editPricing: userAuthClass.editPricing,
        showCost: userAuthClass.showCost,
        showSell: userAuthClass.showSell
      }
      await this.editMasterFile({
        id: userAuthClass.id,
        payload: payload,
        action: updateUserAuthClass,
        store: 'users'
      })
    },
    async deleteUserAuthClass(userAuthClass) {
      await this.deleteMasterFile({
        payload: userAuthClass,
        action: deleteUserAuthClass,
        store: 'users'
      })
    },
    toggleAdd() {
      this.addDialog = !this.addDialog
    }
  }
}
</script>
