<template>
  <v-form @submit.prevent="submit()" ref="form">
    <v-card flat class="card-outlined">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="pl-1">
          <span class="text-truncate">
            Edit {{ $store.state.global.pages.rate.titleSingular }} -
            <span class="pl-1 subtitle-1">
              ID:
              <span v-if="editedItem" class="font-weight-bold">{{
                editedItem.id
              }}</span>
            </span>
          </span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pb-0">
        <v-card-title class="pa-0 font-weight-regular justify-space-between">
          <span> </span>
          <v-switch
            class="text-right"
            color="primary"
            label="Active rate"
            v-model="editedItem.active"
            inset
          ></v-switch>
        </v-card-title>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            v-for="item in autoCompletes"
            :key="item.value"
          >
            <v-autocomplete
              :label="item.label"
              :items="$store.state[`${item.page}s`][item.items]"
              :prepend-icon="$store.state.global.pages[item.page].icon"
              :item-text="item.altName || 'name'"
              item-value="id"
              :rules="rules[item.page]"
              v-model="editedItem[item.value]"
              no-data-text="No data found"
              color="primary"
              clearable
            >
              <template v-slot:item="data">
                <FilterAutoComplete
                  :name="
                    item.page == 'source'
                      ? data.item.sourceName_projectName
                      : data.item.name
                  "
                  :active="
                    item.page == 'source'
                      ? data.item.project.active
                      : item.page == 'project'
                      ? data.item.active
                      : true
                  "
                />
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-expansion-panels flat class="card-outlined mb-3">
              <SelectListPanel
                :title="$store.state.global.pages.vehicleType.title"
                :icon="$store.state.global.pages.vehicleType.icon"
                :subtitle="getSubtitle('vehicleType')"
                :items="$store.state.vehicleTypes.vehicleTypes"
                :select="selected => (selectedVehicleTypeIds = selected)"
                :value="selectedVehicleTypeIds"
              />
            </v-expansion-panels>
            <v-expansion-panels flat class="card-outlined">
              <SelectListPanel
                :title="$store.state.global.pages.itemType.title"
                :icon="$store.state.global.pages.itemType.icon"
                :subtitle="getSubtitle('itemType')"
                :items="$store.state.itemTypes.itemTypesLite"
                :select="selected => (selectedItemTypeIds = selected)"
                :value="selectedItemTypeIds"
              />
            </v-expansion-panels>
          </v-col>

          <v-col
            cols="12"
            :sm="item.sm"
            v-for="item of fields"
            :key="item.value"
          >
            <h4 v-if="item.type === 'heading'">{{ item.value }}</h4>

            <v-text-field
              v-else-if="item.type === 'text'"
              :label="item.label"
              v-model="editedItem[item.value]"
              :rules="item.rules"
              :prepend-icon="item.icon"
              color="primary"
            ></v-text-field>

            <v-text-field
              v-else-if="item.type === 'number'"
              :type="item.type"
              :label="item.label"
              v-model.number="editedItem[item.value]"
              :rules="item.rules"
              :prepend-icon="item.icon"
              :hint="item.hint"
              color="primary"
            ></v-text-field>

            <v-autocomplete
              v-else-if="item.type === 'autocomplete'"
              :label="item.label"
              v-model="editedItem[item.value]"
              :items="units"
              no-data-text="No data found"
              color="primary"
            ></v-autocomplete>

            <v-switch
              v-else-if="item.type === 'switch'"
              :label="item.label"
              v-model="editedItem[item.value]"
              color="primary"
              :class="item.class"
              inset
            ></v-switch>
          </v-col>

          <v-col cols="12">
            <v-expansion-panels>
              <AltRatePanel
                :values="{
                  costPriceAlt: editedItem.costPriceAlt,
                  costUnitAlt: editedItem.costUnitAlt,
                  sellPriceAlt: editedItem.sellPriceAlt,
                  sellUnitAlt: editedItem.sellUnitAlt,
                  costPriceAltSunday: editedItem.costPriceAltSunday,
                  sellPriceAltSunday: editedItem.sellPriceAltSunday
                }"
                :units="units"
                :priceRules="priceRules"
                :onChange="updateValue"
              />
              <NightRatePanel
                :values="{
                  costPriceNight: editedItem.costPriceNight,
                  costUnitNight: editedItem.costUnitNight,
                  sellPriceNight: editedItem.sellPriceNight,
                  sellUnitNight: editedItem.sellUnitNight
                }"
                :units="units"
                :priceRules="priceRules"
                :onChange="updateValue"
              />
              <SundayRatePanel
                :values="{
                  costPriceSunday: editedItem.costPriceSunday,
                  costUnitSunday: editedItem.costUnitSunday,
                  sellPriceSunday: editedItem.sellPriceSunday,
                  sellUnitSunday: editedItem.sellUnitSunday
                }"
                :units="units"
                :priceRules="priceRules"
                :onChange="updateValue"
              />
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="cancel">Cancel</v-btn>
        <v-btn text color="primary" type="submit" :loading="loading">
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import RateValidation from '../../services/validation/rate'
import RateFields from './mixins/rateFields'
import FilterAutoComplete from '@/components/misc/shared/FilterAutoComplete'

import SelectListPanel from '../misc/SelectListPanel'
import AltRatePanel from './AltRatePanel'
import NightRatePanel from './NightRatePanel'
import SundayRatePanel from './SundayRatePanel'
export default {
  mixins: [RateValidation, RateFields],
  components: {
    SelectListPanel,
    AltRatePanel,
    NightRatePanel,
    SundayRatePanel,
    FilterAutoComplete
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      editedItem: Object.assign({}, this.item),
      selectedVehicleTypeIds: [],
      selectedItemTypeIds: []
    }
  },
  mounted() {
    if (this.item.vehicleTypeIds.length)
      this.selectedVehicleTypeIds = this.item.vehicleTypeIds
    if (this.item.itemTypeIds.length)
      this.selectedItemTypeIds = this.item.itemTypeIds
  },
  methods: {
    getSubtitle(type) {
      if (type == 'itemType')
        return this.selectedItemTypes.length
          ? this.selectedItemTypes.map(item => item.name).toString()
          : `All ${this.$store.state.global.pages.itemType.title}`
      else
        return this.selectedVehicleTypes.length
          ? this.selectedVehicleTypes.map(item => item.name).toString()
          : `All ${this.$store.state.global.pages.vehicleType.title}`
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        this.snackFormError()
        return
      }
      await this.action(
        this.editedItem,
        this.selectedVehicleTypes,
        this.selectedVehicleTypeIds,
        this.selectedItemTypes,
        this.selectedItemTypeIds
      )
      this.close()
    },
    updateValue(key, value) {
      this.editedItem[key] = value
    },
    cancel() {
      this.close()
      this.clear()
    },
    clear() {
      this.editedItem = Object.assign({}, this.item)
    }
  }
}
</script>
