<template>
  <v-btn color="primary" text @click="showAudits">AUDIT</v-btn>
</template>

<script>
export default {
  props: {
    auditData: {
      type: Object,
      required: true
    }
  },
  methods: {
    showAudits() {
      const routeData = this.$router.resolve({
        path: 'audit',
        query: { id: this.auditData.id, model: this.auditData.model }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
