export default {
  methods: {
    async createMasterFile({ payload, action, store }) {
      this.loading = true
      try {
        const result = await action(payload)
        this.$store.commit(`${store || this.prop}/addToArrayState`, {
          prop: this.prop,
          value: result.data.result
        })
        this.loading = false
        this.snackCreated()
      } catch (err) {
        this.loading = false
        console.log(err)
        this.snack({ text: err.message, color: 'red' })
        throw err
      }
    },
    async editMasterFile({ id, payload, action, store }) {
      this.loading = true
      try {
        const result = await action(id, payload)
        this.$store.commit(`${store || this.prop}/updateArrayStateById`, {
          prop: this.prop,
          editedValue: result.data.result
        })
        this.loading = false
        this.snackUpdated()
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    async updateMasterFilePassword({ id, payload, action }) {
      this.loading = true
      try {
        await action(id, payload)
        this.loading = false
        this.snack({ color: 'green', text: 'User password was updated' })
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    async deleteMasterFile({ payload, action, store }) {
      this.loading = true
      try {
        await action(payload.id)
        this.$store.commit(
          `${store || this.prop}/deleteFromArrayStateByValue`,
          {
            prop: this.prop,
            value: payload
          }
        )
        this.loading = false
        this.snackDeleted()
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    }
  }
}
