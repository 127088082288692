import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    selected: [],
    bills: [],
    lines: [],
    dates: [],
    filterBy: '',
    filterCol: '',
    selectedAll: false,
    loadingBills: false,
    billDefaultHeaders: [
      'id',
      '_date',
      'source.name',
      'vehicle.name',
      'driver.name',
      'notes',
      'lineCount',
      'createdBy.name',
      'updatedBy.name'
    ],
    billLineDefaultHeaders: [
      'id',
      'description',
      '_sellTotal',
      'notes',
      'createdBy.name',
      'updatedBy.name'
    ]
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
