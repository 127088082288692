<template>
  <v-autocomplete
    v-model="docketIds"
    :items="openDockets"
    item-text="driverName_contractorName_vehicleName"
    item-value="id"
    :label="`${$store.state.global.pages.driver.titleSingular}s`"
    :prepend-icon="$store.state.global.pages.driver.icon"
    multiple
    item-color="secondary"
    no-data-text="No active drivers"
    :loading="loadingOpenDockets"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip small v-if="index < maxChipCount" :color="item.color">
        <span class="white--text">{{ item.driver.name }}</span>
      </v-chip>
      <span v-if="index === maxChipCount" class="grey--text caption">
        (+{{ docketIds.length - maxChipCount }}
        others)
      </span>
    </template>
    <template v-slot:prepend-item>
      <v-list-item ripple @click="toggleAll">
        <v-list-item-action>
          <v-icon :color="docketIds.length > 0 ? 'primary' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Select All
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-slot:item="data">
      <v-list-item ripple two-line @click="toggleDocket(data.item.id)">
        <v-list-item-action>
          <v-icon
            :color="docketIds.includes(data.item.id) ? data.item.color : ''"
          >
            {{
              docketIds.includes(data.item.id)
                ? 'mdi-checkbox-marked'
                : 'mdi-checkbox-blank-outline'
            }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.driver.name }} ({{ data.item.vehicle.name }})
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.vehicle.contractor.name }}
            ({{ data.item.id }})
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    openDockets: Array,
    docketIds: Array,
    loadingOpenDockets: Boolean,
    toggleAll: Function,
    toggleDocket: Function,
    icon: String
  },
  data() {
    return {
      maxChipCount: 5
    }
  }
}
</script>
