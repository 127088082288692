import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    rates: [],
    loadingRates: false,
    filterCol: null,
    filterBy: null,
    selectedAll: false,
    selectedAllLines: false,
    selected: [],
    selectedLines: [],
    rateDefaultHeaders: [
      'id',
      'source.name',
      'truncatedVehicleTypes',
      'truncatedItemTypes',
      'notes'
    ]
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
