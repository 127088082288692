import { format } from 'date-fns'

export const makeLongDate = date => {
  return format(new Date(date), 'MMMM do, yyyy')
}
export const makeShortDate = date => {
  return format(new Date(date), 'dd/MM/yyyy')
}
export const makeMediumDate = date => {
  return format(new Date(date), 'E, do MMMM yyyy')
}
export const makeShortDateAndTime = date => {
  return format(new Date(date), 'dd/MM/yyyy h:mma')
}
export const makePhoneStyleDate = date => {
  return format(new Date(date), 'E, do MMMM')
}
export const makeComputerDate = date => {
  return format(new Date(date), 'yyyy-MM-dd')
}
export const makeTime = date => {
  return format(date, 'h:mm a')
}
export const makeTimeForEntry = date => {
  return format(date, 'HH:mm')
}
export const makeTimeIgnoreZone = date => {
  if (date) {
    return format(date, 'h:mma')
  } else {
    return null
  }
}
export const makeBillingPeriod = date => {
  return format(date, 'YYYY-MM')
}
