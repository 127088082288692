<template>
  <v-container
    fluid
    v-if="$store.state.user.userAuthClass[`${$route.name}Read`]"
  >
    <v-row>
      <v-col cols="12" class="py-0">
        <v-sheet outlined rounded class="card-outlined">
          <v-card flat>
            <v-card-text>
              <CardHeader
                :title="$store.state.global.pages[$route.name].title"
                :icon="$store.state.global.pages[$route.name].icon"
              />
              <v-row class="pb-3">
                <v-col cols="12" sm="3" class="py-0">
                  <v-select
                    label="Select audit record"
                    v-model="model"
                    :prepend-icon="$store.state.global.pages[$route.name].icon"
                    :items="models"
                    type="text"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="3" class="py-0">
                  <v-text-field
                    label="Record ID"
                    :disabled="!model"
                    v-model.number="modelId"
                    clearable
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
      <v-col cols="12" v-if="loadedUserSettings">
        <AuditView :model="model" :modelId="modelId" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Store from '../../store/computed/storeHelpers'

import CardHeader from '../../components/misc/shared/CardHeader'
import AuditView from '../../components/audits/AuditView'
export default {
  components: {
    CardHeader,
    AuditView
  },
  data() {
    return {
      model: null,
      modelId: null,
      models: [
        {
          text: this.$store.state.global.pages.allocation.title,
          value: 'allocation'
        },
        {
          text: `${this.$store.state.global.pages.allocation.titleSingular} lines`,
          value: 'allocationLine'
        },
        { text: this.$store.state.global.pages.docket.title, value: 'docket' },
        {
          text: `${this.$store.state.global.pages.docket.titleSingular} lines`,
          value: 'docketLine'
        },
        { text: this.$store.state.global.pages.rate.title, value: 'rate' },
        { text: this.$store.state.global.pages.rcti.title, value: 'rcti' },
        {
          text: `${this.$store.state.global.pages.rcti.titleSingular} lines`,
          value: 'rctiLine'
        },
        { text: this.$store.state.global.pages.bill.title, value: 'bill' },
        {
          text: `${this.$store.state.global.pages.bill.titleSingular} lines`,
          value: 'billLine'
        },
        {
          text: this.$store.state.global.pages.destination.title,
          value: 'destination'
        },
        {
          text: this.$store.state.global.pages.contractor.title,
          value: 'contractor'
        },
        { text: this.$store.state.global.pages.driver.title, value: 'driver' },
        {
          text: this.$store.state.global.pages.vehicle.title,
          value: 'vehicle'
        },
        {
          text: `${this.$store.state.global.pages.vehicle.titleSingular} types`,
          value: 'vehicleType'
        },
        {
          text: this.$store.state.global.pages.itemType.title,
          value: 'itemType'
        },
        {
          text: this.$store.state.global.pages.project.title,
          value: 'project'
        },
        { text: this.$store.state.global.pages.source.title, value: 'source' },
        { text: 'Compliances', value: 'compliance' },
        { text: 'Policies', value: 'policy' },
        { text: 'Settings', value: 'settings' },
        { text: 'Billing Periods', value: 'billingPeriod' },
        { text: 'Users', value: 'user' },
        { text: 'UserAuth Classes', value: 'userAuthClass' }
      ]
    }
  },
  mounted() {
    if (this.$route.query.id && this.$route.query.model) {
      this.model = this.$route.query.model
      setTimeout(this.setModelId, 500)
    }
  },
  methods: {
    setModelId() {
      this.modelId = Number(this.$route.query.id)
    }
  },
  computed: {
    loadedUserSettings: Store.getSet({
      store: 'global',
      prop: 'loadedUserSettings'
    })
  }
}
</script>
