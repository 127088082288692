import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    reports: [],
    loadingReports: false,
    filterCol: null,
    filterBy: null
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
