import {
  getRctis,
  getRctiLines,
  getRctisByIds,
  getRctisWithLines,
  getRctisByIdsWithLines,
  getLinesByRctiIds
} from '../../../services/requests/rctis'
import {
  getDocketLinesByRctis,
  getDocketLinesByRctiIds
} from '../../../services/requests/dockets'
import Rcti from '@/services/models/rcti'
import RctiLine from '@/services/models/rctiLine'
import DocketLine from '@/services/models/docketLine'
import ExportKeys from '../../misc/shared/exportKeys'
import { addDays } from 'date-fns'
import FormatDates from '@/services/mixins/formatDates'
import makePdf from './makeRctiPdf'

export default {
  mixins: [ExportKeys, FormatDates, makePdf],
  computed: {
    exportOptions() {
      const currentHeaders = [...this.headers]
      currentHeaders.pop()
      return [
        {
          name: `${this.$store.state.global.pages.rcti.titleSingular.toLowerCase()} details`,
          fetchData: () => this.getPdfData(),
          multiPdf: true,
          includeLinesExportValue: {
            name: `${this.$store.state.global.pages.docket.titleSingular.toLowerCase()}_lines`,
            fetchData: () => this.getAlts(),
            keys: this.rctiDocketLineKeys
          }
        },
        {
          name: this.$store.state.global.pages.rcti.title.toLowerCase(),
          fetchData: () => this.getHeaders(),
          keys: this.rctiHeaderKeys
        },
        {
          name: `${this.$store.state.global.pages.rcti.titleSingular.toLowerCase()} lines`,
          fetchData: () => this.getLines(),
          keys: this.rctiLineKeys
        },
        {
          name: `${this.$store.state.global.pages.rcti.titleSingular.toLowerCase()} lines (accounting)`,
          fetchData: () => this.getLines(),
          format: item => this.xeroFormat(item)
        },
        {
          name: `${this.$store.state.global.pages.docket.titleSingular.toLowerCase()} lines`,
          fetchData: () => this.getAlts(),
          keys: this.rctiDocketLineKeys
        },
        {
          name: 'selected (rcti) columns',
          keys: currentHeaders,
          fetchData: () => this.getHeaders()
        }
      ]
    }
  },
  methods: {
    async getPdfData() {
      let pdfData = []
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getRctisWithLines(payload)
        const items = result.data.result.map(item => new Rcti(item))
        pdfData = items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getRctisByIdsWithLines({ ids })
        const items = result.data.result.map(item => new Rcti(item))
        pdfData = items
      }
      const result = this.makePdf(pdfData)
      return result
    },
    async getHeaders() {
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getRctis(payload)
        const items = result.data.result.map(item => new Rcti(item))
        return items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getRctisByIds({ ids })
        const items = result.data.result.map(item => new Rcti(item))
        return items
      }
    },
    async getLines() {
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getRctiLines(payload)
        const items = result.data.result.map(item => new RctiLine(item))
        return items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getLinesByRctiIds({ ids })
        const items = result.data.result.map(item => new RctiLine(item))
        return items
      }
    },
    async getAlts() {
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getDocketLinesByRctis(payload)
        const items = result.data.result.map(item => new DocketLine(item))
        return items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getDocketLinesByRctiIds({ ids })
        const items = result.data.result.map(item => new DocketLine(item))
        return items
      }
    },
    xeroFormat(item) {
      return {
        ContactName: item.rcti.contractor.name,
        EmailAddress: '',
        POAddressLine1: '',
        POAddressLine2: '',
        POAddressLine3: '',
        POAddressLine4: '',
        POCity: '',
        PORegion: '',
        POPostalCode: '',
        POCountry: '',
        InvoiceNumber: item.rcti.externalRef ? item.rcti.externalRef : item.id,
        InvoiceDate: this.makeShortDate(item.rcti.date),
        DueDate: this.makeShortDate(addDays(Date.parse(item.rcti.date), 45)),
        InventoryItemCode: '',
        Description: '',
        Quantity: 1,
        UnitAmount: '',
        AccountCode: '',
        TaxType: '',
        TrackingName1: '',
        TrackingOption1: '',
        TrackingName2: '',
        TrackingOption2: '',
        Currency: 'AUD'
      }
    }
  }
}
