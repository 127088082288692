<template>
  <v-form ref="form" @submit.prevent="updatePassword()">
    <v-card>
      <v-card-text class="pb-0">
        <v-card-title class="pa-0 font-weight-regular">
          Edit Password -
          {{ text }}
        </v-card-title>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-text-field
              type="password"
              label="* Password"
              autocomplete="New password"
              v-model="password"
              :rules="passwordRules"
              :error-messages="passwordsMatchErrorMessage"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-text-field
              type="password"
              autocomplete="New password"
              label="* Confirm Password"
              v-model="confirmedPassword"
              :rules="passwordRules"
              :error-messages="passwordsMatchErrorMessage"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="close">Cancel</v-btn>
        <v-btn text color="primary" :loading="loading" type="submit"
          >Change Password</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import UserValidation from '../../services/validation/user'
import PasswordMatch from '../../services/mixins/passwordMatch'
export default {
  name: 'contractorDelete',
  mixins: [UserValidation, PasswordMatch],
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    page: {
      type: String,
      required: true
    },
    subject: {
      type: Object,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      password: '',
      confirmedPassword: ''
    }
  },
  computed: {
    text() {
      let text
      if (this.subject.name) {
        text = this.subject.name
      } else if (this.subject.id) {
        text = `${this.page} (${this.subject.id})`
      } else {
        text = this.page
      }
      return text
    }
  },
  methods: {
    async updatePassword() {
      if (!this.$refs.form.validate()) return this.snackFormError()
      await this.action(this.subject.id, {
        password: this.password
      })
      this.clear()
      this.close()
    },
    clear() {
      this.password = ''
      this.confirmedPassword = ''
    }
  }
}
</script>
