<template>
  <v-card-text class="my-3">
    <div v-if="loading" class="d-flex justify-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div
      v-else-if="totals && (totals.totalSell || totals.totalCost)"
      class="d-flex justify-space-between"
    >
      <div>
        <v-row class="pa-1">
          <v-col cols="12" class="d-flex py-0 justify-space-between">
            <span class="font-weight-bold">Total hours</span>
            <span>{{
              addCommas(
                totals.totalHours ? totals.totalHours.toFixed(2) : '0.00'
              )
            }}</span>
          </v-col>
          <v-col cols="12" class="d-flex py-0 justify-space-between">
            <span class="font-weight-bold">Total mass</span>
            <span>
              {{
                addCommas(
                  totals.totalMass ? totals.totalMass.toFixed(2) : '0.00'
                )
              }}</span
            >
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row class="pa-1">
          <v-col cols="12" class="d-flex py-0 justify-space-between">
            <span class="font-weight-bold">Cost total</span>
            <span
              >${{
                addCommas(
                  totals.totalCost ? totals.totalCost.toFixed(2) : '0.00'
                )
              }}</span
            >
          </v-col>
          <v-col cols="12" class="d-flex py-0 justify-space-between">
            <span class="font-weight-bold">Sell total</span>
            <span
              >${{
                addCommas(
                  totals.totalSell ? totals.totalSell.toFixed(2) : '0.00'
                )
              }}</span
            >
          </v-col>
          <v-col cols="12" class="d-flex py-0 justify-space-between">
            <span class="font-weight-bold">Export total</span>
            <span
              >${{
                addCommas(
                  totals.totalExport ? totals.totalExport.toFixed(2) : '0.00'
                )
              }}</span
            >
          </v-col>
        </v-row>
      </div>
    </div>
  </v-card-text>
</template>

<script>
export default {
  props: {
    totals: {
      type: Object,
      required: false
    },
    loading: {
      type: Boolean,
      required: true
    }
  }
}
</script>
