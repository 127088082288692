import Store from '@/store/computed/storeHelpers'
import Bill from '../../models/bill'
import { getBills } from '../../requests/bills'
export default {
  mounted() {
    this.getBills()
  },
  data() {
    return {
      pagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: []
      },
      count: 0,
      initPagination: false
    }
  },
  computed: {
    bills: Store.getSet({ store: 'bills', prop: 'bills' }),
    loadingBills: Store.getSet({
      store: 'bills',
      prop: 'loadingBills'
    })
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        if (this.initPagination) {
          this.getBills()
        } else {
          this.initPagination = true
        }
      }
    }
  },
  methods: {
    async getBills() {
      try {
        this.loadingBills = true
        const payload = this.buildQuery()
        const result = await getBills({
          ...payload,
          pagination: this.excludePagination
            ? this.excludePagination
            : this.pagination
        })
        if (result) {
          this.bills = result.data.result.map(item => new Bill(item))
          this.count = result.data.count
        }
        this.loadingBills = false
      } catch (err) {
        console.log(err)
        this.loadingBills = false
      }
    },
    buildQuery() {
      return {
        billingPeriod: this.$store.state.global.selectedBillingPeriod,
        userId: this.$store.state.global.selectedUserId,
        showClosed: this.$store.state.global.showClosed,
        projectId: this.$store.state.global.selectedProjectId,
        sourceId: this.$store.state.global.selectedSourceId,
        dateRangeStart: this.$store.state.global.dateRangeStart,
        dateRangeEnd: this.$store.state.global.dateRangeEnd,
        filterCol: this.$store.state.bills.filterCol,
        filterBy: this.$store.state.bills.filterBy
      }
    }
  }
}
