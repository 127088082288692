import Store from '@/store/computed/storeHelpers'
import { getVehiclesWithDriverLite } from '../../../requests/vehicles'
export default {
  mounted() {
    this.getVehiclesWithDriverLite()
  },
  data() {
    return {
      loadedVehiclesLite: false
    }
  },
  computed: {
    vehiclesWithDriverLite: Store.getSet({
      store: 'vehicles',
      prop: 'vehiclesWithDriverLite'
    }),
    loadingVehiclesLite: Store.getSet({
      store: 'vehicles',
      prop: 'loadingVehiclesLite'
    })
  },
  methods: {
    async getVehiclesWithDriverLite() {
      try {
        this.loadingVehiclesLite = true
        const result = await getVehiclesWithDriverLite()
        if (result) {
          this.vehiclesWithDriverLite = result.data.result.filter(
            i => i.active && i.contractor.active
          )
        }
        this.loadedVehiclesLite = true
        this.loadingVehiclesLite = false
      } catch (err) {
        console.log(err)
        this.loadingVehiclesLite = false
      }
    }
  }
}
