import Store from '@/store/computed/storeHelpers'
import DocketLineAllHeaders from './docketLineAllHeaders'
export default {
  mixins: [DocketLineAllHeaders],
  computed: {
    defaultHeaders: Store.getSet({
      store: 'dockets',
      prop: 'docketLineDefaultHeaders'
    }),
    allPossibleHeaders() {
      return this.allDocketLineHeaders
    }
  },
  data() {
    return {
      mobileHeaders: [
        {
          text: 'Line ID',
          value: 'id'
        },
        {
          text: this.$store.state.global.pages.driver.titleSingular,
          value: 'docket.driver.name'
        }
      ]
    }
  }
}
