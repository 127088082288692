import { format } from 'date-fns'

export default {
  methods: {
    makeLongDate(date) {
      return format(new Date(date), 'MMMM do, yyyy')
    },
    makeShortDate(date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },
    makeMediumDate(date) {
      return format(new Date(date), 'E, do MMMM yyyy')
    },
    makeShortDateAndTime(date) {
      return format(new Date(date), 'dd/MM/yyyy h:mma')
    },
    makePhoneStyleDate(date) {
      return format(new Date(date), 'E, do MMMM')
    },
    makeComputerDate(date) {
      return format(new Date(date), 'yyyy-MM-dd')
    },
    makeTime(date) {
      return format(date, 'h:mma')
    },
    makeTimeForEntry(date) {
      return format(date, 'HH:mm')
    },
    makeTimeIgnoreZone(date) {
      if (date) {
        return format(date, 'h:mma')
      } else {
        return null
      }
    },
    makeBillingPeriod(date) {
      return format(date, 'YYYY-MM')
    },
    convertTime(time, date) {
      const dateString = date.toString().slice(0, 15)
      const zoneString = date.toString().slice(24, date.toString().length)
      const newDateString = dateString + ' ' + time + zoneString
      const datetime = new Date(newDateString)
      if (time?.length == 5) {
        return datetime
      } else {
        return time
      }
    }
  }
}
