import Api from '@/services/api.js'

export const addPolicy = payload => {
  return Api.post('policies/', payload)
}
export const getPolicies = () => {
  return Api.get('policies/')
}
export const updatePolicy = (id, payload) => {
  return Api.post(`policies/${id}`, payload)
}
export const deletePolicy = id => {
  return Api.delete(`policies/${id}`)
}
