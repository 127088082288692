import {
  getBills,
  getBillsByIds,
  getBillsWithLines,
  getBillsByIdsWithLines,
  getBillLinesByBills,
  getLinesByBillIds
} from '../../../services/requests/bills'
import {
  getDocketLinesByBills,
  getDocketLinesByBillIds
} from '../../../services/requests/dockets'
import Bill from '@/services/models/bill'
import BillLine from '@/services/models/billLine'
import DocketLine from '@/services/models/docketLine'
import ExportKeys from '../../misc/shared/exportKeys'
import { addDays } from 'date-fns'
import FormatDates from '@/services/mixins/formatDates'
import makePdf from './makeBillPdf'

export default {
  mixins: [ExportKeys, FormatDates, makePdf],

  computed: {
    exportOptions() {
      const currentHeaders = [...this.headers]
      currentHeaders.pop()
      return [
        {
          name: `${this.$store.state.global.pages.bill.titleSingular.toLowerCase()} details`,
          fetchData: () => this.getPdfData(),
          multiPdf: true,
          includeLinesExportValue: {
            name: `${this.$store.state.global.pages.bill.titleSingular.toLowerCase()}_${this.$store.state.global.pages.docket.titleSingular.toLowerCase()}_lines`,
            fetchData: () => this.getAlts(),
            keys: this.billDocketLineKeys
          }
        },
        {
          name: this.$store.state.global.pages.bill.title.toLowerCase(),
          fetchData: () => this.getHeaders(),
          keys: this.billHeaderKeys
        },
        {
          name: `${this.$store.state.global.pages.bill.titleSingular.toLowerCase()} lines`,
          fetchData: () => this.getLines(),
          keys: this.billLineKeys
        },
        {
          name: `${this.$store.state.global.pages.bill.titleSingular.toLowerCase()} lines (accounting)`,
          fetchData: () => this.getLines(),
          format: item => this.xeroFormat(item)
        },
        {
          name: `${this.$store.state.global.pages.docket.titleSingular.toLowerCase()} lines`,
          fetchData: () => this.getAlts(),
          keys: this.billDocketLineKeys
        },
        {
          name: 'selected (bill) columns',
          keys: currentHeaders,
          fetchData: () => this.getHeaders()
        }
      ]
    }
  },
  methods: {
    async getPdfData() {
      let pdfData = []
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getBillsWithLines(payload)
        const items = result.data.result.map(item => new Bill(item))
        pdfData = items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getBillsByIdsWithLines({ ids })
        const items = result.data.result.map(item => new Bill(item))
        pdfData = items
      }
      const result = this.makePdf(pdfData)
      return result
    },
    async getHeaders() {
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getBills(payload)
        const items = result.data.result.map(item => new Bill(item))
        return items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getBillsByIds({ ids })
        const items = result.data.result.rows.map(item => new Bill(item))
        return items
      }
    },
    async getLines() {
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getBillLinesByBills(payload)
        const items = result.data.result.map(item => new BillLine(item))
        return items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getLinesByBillIds({ ids })
        const items = result.data.result.map(item => new BillLine(item))
        return items
      }
    },
    async getAlts() {
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getDocketLinesByBills(payload)
        const items = result.data.result.map(item => new DocketLine(item))
        return items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getDocketLinesByBillIds({ ids })
        const items = result.data.result.map(item => new DocketLine(item))
        return items
      }
    },
    xeroFormat(item) {
      return {
        ContactName: item.bill.project.name,
        EmailAddress: '',
        POAddressLine1: '',
        POAddressLine2: '',
        POAddressLine3: '',
        POAddressLine4: '',
        POCity: '',
        PORegion: '',
        POPostalCode: '',
        POCountry: '',
        InvoiceNumber: item.bill.externalRef ? item.bill.externalRef : item.id,
        InvoiceDate: this.makeShortDate(item.bill.date),
        DueDate: this.makeShortDate(addDays(Date.parse(item.bill.date), 45)),
        InventoryItemCode: '',
        Description: '',
        Quantity: 1,
        UnitAmount: '',
        AccountCode: '',
        TaxType: '',
        TrackingName1: '',
        TrackingOption1: '',
        TrackingName2: '',
        TrackingOption2: '',
        Currency: 'AUD'
      }
    }
  }
}
