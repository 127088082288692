<template>
  <div>
    <v-img v-if="url" :src="url"></v-img>
  </div>
</template>

<script>
import { getVehicleQrCode } from '@/services/requests/vehicles'
export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      url: null
    }
  },
  mounted() {
    this.generateQr()
  },
  methods: {
    async generateQr() {
      const result = await getVehicleQrCode(this.id)
      if (!result) return
      this.url = result.data.result
    }
  }
}
</script>
