export default {
  computed: {
    updated() {
      const time = new Date(Date.parse(this.item.updatedAt))
      const diffInSeconds = this.getTimeDiff(time)
      return diffInSeconds <= 180 && diffInSeconds >= 0
    },
    created() {
      const time = new Date(Date.parse(this.item.createdAt))
      const diffInSeconds = this.getTimeDiff(time)
      return diffInSeconds <= 180 && diffInSeconds >= 0
    }
  },
  methods: {
    getTimeDiff(time) {
      var now = new Date()
      var diffInSeconds = (now.getTime() - time.getTime()) / 1000
      return diffInSeconds
    }
  }
}
