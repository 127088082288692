import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'
import { requestAuthUser } from '@/services/authentication'

import FrontEnd from '../views/FrontEnd'
import BackEnd from '../views/BackEnd'

import Landing from '../views/frontEnd/Landing'
import Tracking from '../views/frontEnd/Tracking'

import Dashboard from '../views/backEnd/Dashboard'
import Allocation from '../views/backEnd/Allocations'
import Plan from '../views/backEnd/Plans'
import Pod from '../views/backEnd/Pod'
import Docket from '../views/backEnd/Dockets'
import Rcti from '../views/backEnd/Rctis'
import Map from '../views/backEnd/Map'
import Policy from '../views/backEnd/masterFiles/Policies'
import Settings from '../views/backEnd/Settings'
import BillingPeriod from '../views/backEnd/masterFiles/BillingPeriods'
import Compliance from '../views/backEnd/Compliances'
import Audit from '../views/backEnd/Audits'

import User from '../views/backEnd/masterFiles/Users'
import UserAuthClass from '../views/backEnd/masterFiles/UserAuthClass'

import Project from '../views/backEnd/masterFiles/Projects'
import Rate from '../views/backEnd/masterFiles/Rates'
import Destination from '../views/backEnd/masterFiles/Destinations'
import Source from '../views/backEnd/masterFiles/Sources'
import ItemType from '../views/backEnd/masterFiles/ItemTypes'

import Contractor from '../views/backEnd/masterFiles/Contractors'
import Vehicle from '../views/backEnd/masterFiles/Vehicles'
import VehicleType from '../views/backEnd/masterFiles/VehicleTypes'
import Driver from '../views/backEnd/masterFiles/Drivers'

import Bill from '../views/backEnd/Bills'
import ApprovedBillLines from '../views/backEnd/ApprovedBillLines'

Vue.use(VueRouter)

async function beforeEnterFront(...[, , next]) {
  try {
    const auth = await requestAuthUser()
    if (auth) next({ name: 'dashboard' })
    else next()
  } catch (err) {
    console.error('Error occurred before navigating to "front"', err)
    next()
  }
}

async function beforeEnterBack(...[, , next]) {
  const toLoginParams = { name: 'login' }
  try {
    const auth = await requestAuthUser()
    if (!auth) next(toLoginParams)
    else {
      Store.dispatch('user/initUser', auth)
      next()
    }
  } catch (err) {
    this.snack({ text: err.message, color: 'red' })
    console.error('Error occurred before navigating to "back"', err)
    next(toLoginParams)
  }
}

const routes = [
  {
    path: '/',
    component: FrontEnd,
    beforeEnter: beforeEnterFront,
    children: [
      {
        path: '',
        name: 'frontEnd',
        redirect: {
          name: 'login'
        }
      },
      {
        path: '/login',
        name: 'login',
        component: Landing
      },
      {
        path: '/tracking',
        name: 'tracking',
        component: Tracking
      }
    ]
  },
  {
    path: '/app',
    component: BackEnd,
    beforeEnter: beforeEnterBack,
    children: [
      {
        path: '',
        name: 'backEnd',
        redirect: {
          name: 'dashboard'
        }
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard
      },
      {
        path: '/allocation',
        name: 'allocation',
        component: Allocation
      },
      {
        path: '/plan',
        name: 'plan',
        component: Plan
      },
      {
        path: '/pod',
        name: 'pod',
        component: Pod
      },
      {
        path: '/docket',
        name: 'docket',
        component: Docket
      },
      {
        path: '/rcti',
        name: 'rcti',
        component: Rcti
      },
      {
        path: '/map',
        name: 'map',
        component: Map
      },
      {
        path: '/policy',
        name: 'policy',
        component: Policy
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings
      },
      {
        path: '/billingPeriod',
        name: 'billingPeriod',
        component: BillingPeriod
      },
      {
        path: '/compliance',
        name: 'compliance',
        component: Compliance
      },
      {
        path: '/user',
        name: 'user',
        component: User
      },
      {
        path: '/userAuthClass',
        name: 'userAuthClass',
        component: UserAuthClass
      },
      {
        path: '/bill',
        name: 'bill',
        component: Bill
      },
      {
        path: '/approvedBillLine',
        name: 'approvedBillLine',
        component: ApprovedBillLines
      },
      {
        path: '/project',
        name: 'project',
        component: Project
      },
      {
        path: '/rate',
        name: 'rate',
        component: Rate
      },
      {
        path: '/destination',
        name: 'destination',
        component: Destination
      },
      {
        path: '/source',
        name: 'source',
        component: Source
      },
      {
        path: '/itemType',
        name: 'itemType',
        component: ItemType
      },
      {
        path: '/contractor',
        name: 'contractor',
        component: Contractor
      },
      {
        path: '/vehicle',
        name: 'vehicle',
        component: Vehicle
      },
      {
        path: '/vehicleType',
        name: 'vehicleType',
        component: VehicleType
      },
      {
        path: '/driver',
        name: 'driver',
        component: Driver
      },
      {
        path: '/audit',
        name: 'audit',
        component: Audit
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
