<template>
  <span>
    <span v-if="getObjectPath(value, item)">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            v-if="getObjectPath(value, item) === 'timesheet'"
            color="green lighten-2"
            >mdi-clock</v-icon
          >
          <v-icon
            v-on="on"
            v-if="getObjectPath(value, item) === 'form'"
            color="green lighten-2"
            >mdi-file-document</v-icon
          >
          <v-icon
            v-on="on"
            v-else-if="getObjectPath(value, item) === 'signature'"
            color="green lighten-2"
            >mdi-draw</v-icon
          >
          <v-icon
            v-on="on"
            v-else-if="getObjectPath(value, item) === 'photo'"
            color="green lighten-2"
            >mdi-camera-image</v-icon
          >
        </template>
        <span>
          {{
            getObjectPath(value, item) === 'timesheet'
              ? 'TIMESHEET'
              : getObjectPath(value, item).toUpperCase()
          }}
        </span>
      </v-tooltip>
    </span>
    <span v-else>-</span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
