<template>
  <v-col :cols="cols" :sm="sm" :lg="lg" class="text-truncate">
    <v-tooltip bottom v-if="tooltip">
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <v-icon :color="color" class="mr-2">{{ icon }}</v-icon>
          <span :class="`${color}--text`">{{ text }}</span>
        </span>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
    <span v-else>
      <v-icon :color="color" class="mr-2">{{ icon }}</v-icon>
      <span :class="`${color}--text darken-5`">{{ text }}</span>
    </span>
  </v-col>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false
    },
    cols: {
      type: String,
      required: false,
      default: '12'
    },
    sm: {
      type: String,
      required: false,
      default: '6'
    },
    lg: {
      type: String,
      required: false,
      default: '6'
    }
  }
}
</script>
