import Store from '@/store/computed/storeHelpers'
import { getUserAuthClasses } from '../../requests/users'
export default {
  mounted() {
    this.getUserAuthClasses()
  },
  data() {
    return {
      loadedUserAuthClasses: false
    }
  },
  computed: {
    userAuthClasses: Store.getSet({ store: 'users', prop: 'userAuthClasses' }),
    loadingUserAuthClasses: Store.getSet({
      store: 'users',
      prop: 'loadingUserAuthClasses'
    })
  },
  methods: {
    async getUserAuthClasses() {
      try {
        this.loadingUserAuthClasses = true
        const result = await getUserAuthClasses()
        if (result) this.userAuthClasses = result.data.result
        this.loadedUserAuthClasses = true
        this.loadingUserAuthClasses = false
      } catch (err) {
        console.log(err)
        this.loadingUserAuthClasses = false
      }
    }
  }
}
