import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import FormatDates from '@/services/mixins/formatDates'

export default {
  mixins: [FormatDates],
  methods: {
    async makePdf(pdfData) {
      const doc = new jsPDF(pdfData.orientation, 'cm', pdfData.pageSize)
      const pageWidth = doc.internal.pageSize.width
      const pageHeight = doc.internal.pageSize.height
      const docHeading = pdfData.type.toUpperCase()
      const docSubheading = pdfData.type == 'rcti' ? '' : ''
      const columns = Object.keys(pdfData.lines[0]).map(str => {
        return { title: str, dataKey: str }
      })
      // const totalsOffset = pdfData.total && pdfData.costTotal ? 4 : 2
      const totalsOffset = 2
      const foot = []
      // const foot = new Array(columns.length - totalsOffset).fill('')
      // if (pdfData.costTotal) foot.push('Cost total', '$' + pdfData.costTotal)
      // if (pdfData.total) foot.push(['Total', '$' + pdfData.total])
      const offset = columns.length
        ? new Array(columns.length - totalsOffset).fill('')
        : []
      if (pdfData.costTotal)
        foot.push([...offset, 'Cost Total', '$' + pdfData.costTotal])
      if (pdfData.total) foot.push([...offset, 'Total', '$' + pdfData.total])

      //HEADER
      //HEADER
      const img = new Image()
      const imageWidth = 5
      const imageHeight = 2.5
      img.src = require('../../../assets/logo.png')
      doc.addImage(img, 'png', 0.5, 0.2, imageWidth, imageHeight)

      doc.setFont('helvetica', 'bold')
      doc.setFontSize(16).text(pdfData.title, imageWidth + 0.75, 1)
      doc.setFont('helvetica', 'normal').setFontSize(10)

      doc.setFontSize(20).setFont('helvetica', 'bold')
      doc.text(docHeading, pageWidth - 0.5, 1, {
        align: 'right'
      })
      doc.setFontSize(10).setFont('helvetica', 'normal')
      doc.text(docSubheading, pageWidth - 0.5, 1.5, { align: 'right' })

      // LINES
      // LINES
      doc.autoTableSetDefaults({
        headStyles: { fillColor: [180, 180, 180], halign: 'center' },
        styles: { halign: 'left', fontSize: 8 },
        margin: { left: 0.5, top: 1.25, right: 0.5 },
        showFoot: 'lastPage'
      })

      autoTable(doc, {
        startY: 4,
        columns,
        body: pdfData.lines,
        columnStyles: pdfData.columnStyles,
        foot: foot,
        footStyles: {
          fontSize: 12,
          textColor: [10, 10, 10],
          fillColor: [255, 255, 255],
          halign: 'right'
        }
      })

      // FOOTER
      // FOOTER
      doc.setFontSize(8).text(pdfData.footerText, 0.5, pageHeight - 1)

      var pageCount = doc.internal.getNumberOfPages() //Total Page Number
      for (let i = 0; i < pageCount; i++) {
        doc.setPage(i)
        let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber //Current Page
        doc
          .setFontSize(8)
          .text(
            `Report created by ${this.$store.state.user.user.name}`,
            0.5,
            pageHeight - 0.5
          )
        doc
          .setFontSize(8)
          .text(
            `Page: ${pageCurrent} / ${pageCount}`,
            pageWidth - 0.5,
            pageHeight - 1,
            { align: 'right' }
          )
        doc.text(
          this.makeShortDateAndTime(new Date()),
          pageWidth - 0.5,
          pageHeight - 0.5,
          { align: 'right' }
        )
      }
      doc.save(pdfData.filename)
    }
  }
}
