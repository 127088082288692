import DocketLineHeaders from '../../dockets/mixins/docketLineAllHeaders'
import DocketHeaders from '../../dockets/mixins/docketAllHeaders'
import AllocationHeaders from '../../allocations/mixins/allocationAllHeaders'
import AllocationLineHeaders from '../../allocations/mixins/allocationLineAllHeaders'
import AllocationStagingLineHeaders from '../../allocations/mixins/allocationStagingLineAllHeaders'
import RctiHeaders from '../../rctis/mixins/rctiAllHeaders'
import RctiLineHeaders from '../../rctis/mixins/rctiLineAllHeaders'
import BillHeaders from '../../bills/mixins/billAllHeaders'
import BillLineHeaders from '../../bills/mixins/billLineAllHeaders'
import PodHeaders from '../../pods/mixins/podAllHeaders'
import RateHeaders from '../../rates/mixins/rateAllHeaders'

export default {
  mixins: [
    DocketLineHeaders,
    DocketHeaders,
    AllocationHeaders,
    AllocationLineHeaders,
    AllocationStagingLineHeaders,
    RctiHeaders,
    RctiLineHeaders,
    BillHeaders,
    BillLineHeaders,
    PodHeaders,
    RateHeaders
  ],
  computed: {
    allocationHeaderKeys() {
      const keys = [
        'id',
        'date',
        'source.project.name',
        'source.name',
        'vehicle.contractor.name',
        'lineCount',
        'isDay',
        'driver.name',
        'vehicle.name',
        'notes',
        'createdAt',
        'updatedAt'
      ]
      return [...this.allAllocationHeaders].filter(({ value }) =>
        keys.includes(value)
      )
    },
    allocationLineKeys() {
      const keys = [
        'id',
        'destination.name',
        'allocation.source.name',
        'allocation.driver.name',
        'itemType.name',
        'notes',
        'lineCount',
        'status',
        '_qty',
        'deliveryStatus',
        'addressStreet1',
        'addressStreet2',
        'addressCity',
        'addressState',
        'createdBy',
        'updatedBy',
        'createdAt',
        'updatedAt'
      ]
      return [...this.allAllocationLineHeaders].filter(({ value }) =>
        keys.includes(value)
      )
    },
    allocationStagingLineKeys() {
      const keys = [
        'id',
        'destination.name',
        'allocation.source.name',
        'allocation.driver.name',
        'itemType.name',
        'notes',
        'lineCount',
        'status',
        '_qty',
        'deliveryStatus',
        'addressStreet1',
        'addressStreet2',
        'addressCity',
        'addressState',
        'createdBy',
        'updatedBy',
        'createdAt',
        'updatedAt'
      ]
      return [...this.allAllocationStagingLineHeaders].filter(({ value }) =>
        keys.includes(value)
      )
    },
    docketHeaderKeys() {
      const keys = [
        'id',
        'billingPeriod',
        '_date',
        'notes',
        'externalRef',
        'isClosed',
        '_qty',
        '_lineHours',
        'lineMass',
        '_costTotal',
        '_sellTotal',
        'totalOutstanding',
        'docketLineCount',
        'billLineCount',
        'source.project.name',
        'source.name',
        'vehicle.name',
        'driver.name',
        'isAppCreated',
        '_createdAt',
        '_updatedAt',
        'deletedAt',
        'createdBy',
        'updatedBy'
      ]
      return [...this.allDocketHeaders].filter(({ value }) =>
        keys.includes(value)
      )
    },
    docketLineKeys() {
      const keys = [
        'id',
        'docket.id',
        'docket.billingPeriod',
        'docket._date',
        'docket.source.project.name',
        'docket.source.name',
        'docket.vehicle.name',
        'docket.vehicle.vehicleType.name',
        'docket.driver.name',
        'destination.name',
        'itemType.name',
        'destinationWeight',
        'notes',
        '_start',
        '_end',
        '_hours',
        'minutes',
        '_costQty',
        '_costPrice',
        '_costTotal',
        '_qty',
        '_sellPrice',
        '_sellTotal',
        'rctiId',
        'billId',
        'exportBillId',
        '_exportTotal',
        '_createdAt',
        '_updatedAt',
        'deletedAt',
        'createdById',
        'createdBy',
        'updatedBy'
      ]
      return [...this.allDocketLineHeaders].filter(({ value }) =>
        keys.includes(value)
      )
    },
    rctiDocketLineKeys() {
      const keys = [
        'rctiId',
        'id',
        'docket.id',
        'docket.billingPeriod',
        'docket._date',
        'docket.source.project.name',
        'docket.source.name',
        'docket.vehicle.name',
        'docket.vehicle.vehicleType.name',
        'docket.driver.name',
        'destination.name',
        'itemType.name',
        'destinationWeight',
        'notes',
        '_hours',
        '_costQty',
        '_costPrice',
        '_costTotal'
      ]
      return [...this.allDocketLineHeaders].filter(({ value }) =>
        keys.includes(value)
      )
    },
    billDocketLineKeys() {
      const keys = [
        'id',
        'docket.id',
        'docket.billingPeriod',
        'docket._date',
        'docket.source.project.name',
        'docket.source.name',
        'docket.vehicle.name',
        'docket.vehicle.vehicleType.name',
        'docket.vehicle.vehicleType.class',
        'docket.driver.name',
        'destination.name',
        'itemType.name',
        'destinationWeight',
        'notes',
        '_hours',
        '_qty',
        '_sellPrice',
        '_sellTotal',
        'billId'
      ]
      return [...this.allDocketLineHeaders].filter(({ value }) =>
        keys.includes(value)
      )
    },
    rctiHeaderKeys() {
      const keys = [
        'id',
        'billingPeriod',
        '_date',
        'externalRef',
        'project.name',
        'contractor.name',
        'abn',
        'rctiLineCount',
        'docketLineCount',
        'hoursTotal',
        'externalRef',
        'sourceWeightTotal',
        'vehicleWeightTotal',
        'destinationWeightTotal',
        '_total',
        'notes',
        'status',
        'type',
        'statDecConfirmed',
        'statDecNotes',
        'internalNotes',
        'invoiceNotes',
        'createdBy.name',
        'updatedBy.name',
        '_createdAt',
        '_updatedAt',
        'deletedAt'
      ]
      return [...this.allRctiHeaders].filter(({ value }) =>
        keys.includes(value)
      )
    },
    rctiLineKeys() {
      const keys = [
        'id',
        'rctiId',
        'rcti.billingPeriod',
        'rcti.date',
        'rcti.externalRef',
        'rcti.project.name',
        'rcti.contractor.name',
        'rcti.projectId',
        'rcti.contractorId',
        'rcti.abn',
        'description',
        '_costTotal',
        'notes',
        'taxType',
        'accountCode',
        'rcti.status',
        'rcti.type',
        'rcti.statDecConfirmed',
        'rcti.statDecNotes',
        'rcti.internalNotes',
        'rcti.invoiceNotes',
        'createdBy',
        'updatedBy',
        'createdById',
        'createdAt',
        'updatedAt',
        'deletedAt'
      ]
      return [...this.allRctiLineHeaders].filter(({ value }) =>
        keys.includes(value)
      )
    },
    billHeaderKeys() {
      const keys = [
        'id',
        'billingPeriod',
        '_date',
        'notes',
        'billLineCount',
        'docketLineCount',
        'source.name',
        'project.name',
        'status',
        'statDecConfirmed',
        '_sellTotal',
        'createdBy.name',
        'updatedBy.name',
        '_createdAt',
        '_updatedAt'
      ]
      return [...this.allBillHeaders].filter(({ value }) =>
        keys.includes(value)
      )
    },
    billLineKeys() {
      const keys = [
        'id',
        'billId',
        'bill._date',
        'description',
        '_sellTotal',
        'bill.project.name',
        'bill.source.name',
        'bill.destination.name',
        'bill.abn',
        'docketLineCount',
        '_hours',
        'sourceWeightTotal',
        'scaleWeightTotal',
        'destinationWeightTotal',
        'notes',
        'status',
        'type',
        'statDecConfirmed',
        'statDecNotes',
        'internalNotes',
        'invoiceNotes',
        'createdBy',
        'updatedBy',
        'createdById',
        '_createdAt',
        '_updatedAt'
      ]
      return [...this.allBillLineHeaders].filter(({ value }) =>
        keys.includes(value)
      )
    },
    podKeys() {
      const keys = [
        'id',
        'notes',
        // 'name',
        '_createdAt',
        'type',
        'allocationLine.destination.name',
        'allocationLine.itemType.name',
        'allocationLine.allocation.driver.name',
        'allocationLine.allocation.docketId',
        'allocationLine.allocation.vehicle.contractor.name',
        'deliveredAt',
        'qty',
        'addressStreet1',
        'addressStreet2',
        'addressCity',
        'addressState'
      ]
      return [...this.allPodHeaders].filter(({ value }) => keys.includes(value))
    },
    rateKeys() {
      const keys = [
        'id',
        'active',
        '_costPrice',
        'costUnit',
        '_sellPrice',
        'sellUnit',
        'costPriceAlt',
        'costUnitAlt',
        'sellPriceAlt',
        'sellUnitAlt',
        'costPriceSunday',
        'costUnitSunday',
        'sellPriceSunday',
        'sellUnitSunday',
        'costPriceAltSunday',
        'sellPriceAltSunday',
        'truncatedVehicleTypes',
        'truncatedItemTypes',
        'notes',
        'project.name',
        'source.name',
        'destination.name',
        '_exportAmount',
        'createdBy',
        'updatedBy',
        '_createdAt',
        '_updatedAt'
      ]
      return [...this.allRateHeaders].filter(({ value }) =>
        keys.includes(value)
      )
    }
  }
}
