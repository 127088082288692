<template>
  <div>
    <v-autocomplete
      :id="id"
      prepend-icon="mdi-map-marker"
      :search-input.sync="autocompleteText"
      label="Enter a location"
      ref="autocomplete"
      v-model="selectedAddress"
      :items="predictions"
      item-text="description"
    ></v-autocomplete>
    <div id="map"></div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    onChanged: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      autocompleteText: '',
      predictions: [],
      placesService: null,
      autoService: null,
      selectedAddress: null
    }
  },
  watch: {
    autocompleteText: debounce(function() {
      this.getPredictions()
    }, 1000),
    selectedAddress() {
      this.selectPrediction()
    }
  },
  mounted: function() {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -33.866, lng: 151.196 },
      zoom: 15
    })
    this.autoService = new window.google.maps.places.AutocompleteService()
    this.placesService = new window.google.maps.places.PlacesService(map)
  },
  methods: {
    selectPrediction() {
      if (this.selectedAddress && this.predictions.length > 0) {
        const selected = this.predictions.find(
          p => p.description === this.selectedAddress
        )
        if (selected) {
          const request = {
            placeId: selected.place_id,
            fields: ['place_id', 'geometry', 'address_components']
          }
          this.placesService.getDetails(request, (place, status) => {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
              return
            }
            const selectedPlace = {
              address_components: place.address_components,
              long: place.geometry.location.lng(),
              lat: place.geometry.location.lat()
            }
            this.onChanged(selectedPlace)
          })
          return
        }
      }
    },
    getPredictions() {
      if (
        this.autocompleteText?.length > 4 &&
        this.autocompleteText !== this.selectedAddress
      ) {
        this.autoService.getPlacePredictions(
          {
            input: this.autocompleteText,
            componentRestrictions: { country: ['au', 'nz'] }
          },
          (predictions, status) => {
            this.predictions = status == 'OK' ? [...predictions] : []
          }
        )
      }
    }
  }
}
</script>
