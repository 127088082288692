<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-1">
        <span class="text-truncate">
          View {{ $store.state.global.pages.rate.titleSingular }} -
          <span class="pl-1 subtitle-1">
            ID: <span v-if="rate" class="font-weight-bold">{{ rate.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="userAuthClass && openEditDialog"
        icon
        @click="showEditDialog"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row v-if="!rate">
          <v-col cols="12">
            <v-progress-circular></v-progress-circular>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-card-title
              class="pa-0 font-weight-regular justify-space-between"
            >
              <span> </span>
              <v-switch
                class="text-right"
                color="primary"
                label="Active rate"
                v-model="rate.active"
                disabled
                inset
              ></v-switch>
            </v-card-title>
          </v-col>

          <!-- <v-col cols="12">
            <v-flex
              xs12
              class="vertical-center-container mb-3 d-flex justify-space-between"
            >
              <span class="headline"
                >Rate - <span class="subtitle-1">ID: {{ rate.id }}</span>
              </span>
              <v-btn v-if="openEditDialog" icon @click="showEditDialog">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-flex>
          </v-col> -->
          <v-col cols="12">
            <v-row>
              <v-col>
                <h4>
                  <v-icon color="primary" class="mr-2">
                    {{ $store.state.global.pages.project.icon }}
                  </v-icon>
                  Project
                </h4>
              </v-col>
              <v-col class="font-weight-bold">
                {{ rate.projectId ? rate.project.name : 'All' }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h4>
                  <v-icon color="primary" class="mr-2">
                    {{ $store.state.global.pages.source.icon }}
                  </v-icon>
                  {{ $store.state.global.pages.source.titleSingular }}
                </h4>
              </v-col>
              <v-col class="font-weight-bold">
                {{ rate.sourceId ? rate.source.name : 'All' }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h4>
                  <v-icon color="primary" class="mr-2">
                    {{ $store.state.global.pages.vehicle.icon }}
                  </v-icon>
                  {{
                    `${$store.state.global.pages.vehicle.titleSingular} types`
                  }}
                </h4>
              </v-col>
              <v-col class="font-weight-bold">
                {{ rate.vehicleTypeNames }}
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h4>
                  <v-icon color="primary" class="mr-2">
                    {{ $store.state.global.pages.destination.icon }}
                  </v-icon>
                  {{ $store.state.global.pages.destination.titleSingular }}
                </h4>
              </v-col>
              <v-col class="font-weight-bold">
                {{ rate.destinationId ? rate.destination.name : 'All' }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h4>
                  <v-icon color="primary" class="mr-2">
                    {{ $store.state.global.pages.itemType.icon }}
                  </v-icon>
                  {{ $store.state.global.pages.itemType.titleSingular }}
                </h4>
              </v-col>
              <v-col class="font-weight-bold">
                {{ rate.itemTypeNames }}
              </v-col>
            </v-row>
            <v-row v-if="dupRates.length">
              <v-col cols="12" class="red--text">
                WARNING! Possible duplicate rate(s) found with IDs:
                {{ dupRates.map(({ id }) => id).join(', ') }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <div><v-divider></v-divider></div>
          </v-col>

          <v-col
            :cols="item.type == 'heading' ? 12 : 6"
            v-for="item in fields"
            :key="item.label"
          >
            <h4 v-if="item.type == 'heading'">{{ item.value }}</h4>
            <span v-else>
              <span v-if="item.type == 'number'">
                {{
                  `${item.label}: $${rate[item.value].toFixed(2)} per ${
                    rate[item.unitvalue]
                  }`
                }}
              </span>
              <span v-else>
                {{ `${item.label}: ${rate[item.value]}` }}
              </span>
            </span>
          </v-col>
          <v-col v-if="!rate">
            <h3>No rate found</h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <ShowAudits :auditData="auditData" />
      <v-spacer></v-spacer>
      <v-btn color="grey darken-2" text @click="close">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Rate from '../../services/models/rate'
import { getRate, getRates } from '../../services/requests/rates'
import ShowAudits from '../misc/shared/ShowAudits'
export default {
  data() {
    return {
      rate: null,
      loadingRate: false,
      dupRates: []
    }
  },
  mounted() {
    this.getRate()
  },
  props: {
    dialog: Boolean,
    close: Function,
    rateId: Number,
    openEditDialog: {
      type: Function,
      required: false
    },
    vehicleTypeId: {
      type: Number,
      required: false
    },
    itemTypeId: {
      type: Number,
      required: false
    }
  },
  components: {
    ShowAudits
  },
  computed: {
    auditData() {
      return { id: this.rateId, model: 'rate' }
    },
    fields() {
      return [
        { type: 'heading', value: 'Standard' },
        {
          type: 'number',
          label: 'Cost',
          value: 'costPrice',
          unitvalue: 'costUnit'
        },
        {
          type: 'number',
          label: 'Sell',
          value: 'sellPrice',
          unitvalue: 'sellUnit'
        },
        {
          type: 'number',
          label: 'Export Amount',
          value: 'exportAmount',
          unitvalue: 'sellUnit'
        },
        { type: 'heading', value: 'Alternate' },
        {
          type: 'number',
          label: 'Cost (Alt.)',
          value: 'costPriceAlt',
          unitvalue: 'costUnitAlt'
        },
        {
          type: 'number',
          label: 'Sell (Alt.)',
          value: 'sellPriceAlt',
          unitvalue: 'sellUnitAlt'
        },
        {
          type: 'number',
          label: 'Cost (Sunday Alt.)',
          value: 'costPriceAltSunday',
          unitvalue: 'costUnitAlt'
        },
        {
          type: 'number',
          label: 'Sell (Sunday Alt.)',
          value: 'sellPriceAltSunday',
          unitvalue: 'sellUnitAlt'
        },
        { type: 'heading', value: 'Night' },
        {
          type: 'number',
          label: 'Cost (Night)',
          value: 'costPriceNight',
          unitvalue: 'costUnitNight'
        },
        {
          type: 'number',
          label: 'Sell (Night)',
          value: 'sellPriceNight',
          unitvalue: 'sellUnitNight'
        },
        { type: 'heading', value: 'Sunday' },
        {
          type: 'number',
          label: 'Cost (Sunday)',
          value: 'costPriceSunday',
          unitvalue: 'costUnitSunday'
        },
        {
          type: 'number',
          label: 'Sell (Sunday)',
          value: 'sellPriceSunday',
          unitvalue: 'sellUnitSunday'
        },
        {
          type: 'text',
          label: 'Notes',
          value: 'notes'
        }
      ]
    }
  },
  methods: {
    async getRate() {
      try {
        this.loadingRate = true
        const result = await getRate(this.rateId)
        if (result) {
          this.rate = new Rate(result.data.result)
        }
        this.getDuplicatedRates()
        this.loadingRate = false
      } catch (err) {
        this.loadingRate = false
        console.log(err)
      }
    },
    async getDuplicatedRates() {
      try {
        const payload = {
          checkForDuplicates: true,
          filterBy: null,
          showClosed: false,
          projectId: this.rate.projectId,
          sourceId: this.rate.sourceId,
          destinationId: this.rate.destinationId,
          vehicleTypeIds: this.vehicleTypeId
            ? [this.vehicleTypeId]
            : this.rate.vehicleTypeIds,
          itemTypeIds: this.itemTypeId
            ? [this.itemTypeId]
            : this.rate.itemTypeIds
        }
        const result = await getRates(payload)
        if (result) {
          this.dupRates = result.data.result.filter(
            rate => rate.id !== this.rate.id
          )
        }
      } catch (err) {
        console.log(err)
      }
    },
    showEditDialog() {
      this.openEditDialog()
      this.close()
    }
  },
  watch: {
    rateId() {
      this.getRate()
    }
  }
}
</script>
