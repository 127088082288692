import Store from '@/store/computed/storeHelpers'
import { getVehicleTypes } from '../../requests/vehicles'
export default {
  mounted() {
    if (this.pagination) this.initPagination()
    this.getVehicleTypes()
  },
  data() {
    return {
      loadedVehicleTypes: false,
      vehicleTypeCount: 0
    }
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        this.getVehicleTypes()
      }
    },
    sortDesc(val) {
      this.pagination.sortDesc = [val]
    }
  },
  computed: {
    sortDesc: Store.getSet({ store: 'masterfiles', prop: 'sortDesc' }),
    vehicleTypes: Store.getSet({ store: 'vehicleTypes', prop: 'vehicleTypes' }),
    loadingVehicleTypes: Store.getSet({
      store: 'vehicleTypes',
      prop: 'loadingVehicleTypes'
    })
  },
  methods: {
    async getVehicleTypes() {
      try {
        this.loadingVehicleTypes = true
        const payload =
          this.masterFile == 'vehicleType'
            ? { pagination: this.pagination, filterBy: this.filterBy }
            : {}
        const result = await getVehicleTypes(payload)
        if (result) {
          this.vehicleTypes = result.data.result
          this.vehicleTypeCount = result.data.count
        }
        this.loadedVehicleTypes = true
        this.loadingVehicleTypes = false
      } catch (err) {
        console.log(err)
        this.loadingVehicleTypes = false
      }
    }
  }
}
