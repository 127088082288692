import Store from '@/store/computed/storeHelpers'

export default {
  computed: {
    isFilter() {
      return this.$options._componentTag == 'FilterDrawer'
    },
    model: Store.getSet({ store: 'compliances', prop: 'filterCol' }),
    showClosed: Store.getSet({ store: 'global', prop: 'showClosed' }),
    showVoid: Store.getSet({ store: 'global', prop: 'showVoid' }),
    isStaged: Store.getSet({ store: 'allocations', prop: 'isStaged' }),
    selectedBillingPeriod: Store.getSet({
      store: 'global',
      prop: 'selectedBillingPeriod'
    }),
    selectedUserId: Store.getSet({ store: 'global', prop: 'selectedUserId' }),
    selectedDeliveryStatus: Store.getSet({
      store: 'global',
      prop: 'selectedDeliveryStatus'
    }),
    dateRangeStart: Store.getSet({ store: 'global', prop: 'dateRangeStart' }),
    dateRangeEnd: Store.getSet({ store: 'global', prop: 'dateRangeEnd' }),
    selectedProjectId: Store.getSet({
      store: 'global',
      prop: 'selectedProjectId'
    }),
    selectedSourceId: Store.getSet({
      store: 'global',
      prop: 'selectedSourceId'
    }),
    selectedDestinationId: Store.getSet({
      store: 'global',
      prop: 'selectedDestinationId'
    }),
    selectedContractorId: Store.getSet({
      store: 'global',
      prop: 'selectedContractorId'
    }),
    selectedDriverId: Store.getSet({
      store: 'global',
      prop: 'selectedDriverId'
    }),
    selectedVehicleId: Store.getSet({
      store: 'global',
      prop: 'selectedVehicleId'
    }),

    selectedItemTypeId: Store.getSet({
      store: 'global',
      prop: 'selectedItemTypeId'
    }),
    selectedVehicleTypeId: Store.getSet({
      store: 'global',
      prop: 'selectedVehicleTypeId'
    }),
    selectedPodType: Store.getSet({
      store: 'global',
      prop: 'selectedPodType'
    }),
    selectedReportId: Store.getSet({
      store: 'global',
      prop: 'selectedReportId'
    }),
    showTargets: Store.getSet({ store: 'map', prop: 'showTargets' }),
    showBillingPeriod() {
      const condition = this.isFilter ? true : !!this.selectedBillingPeriod
      return (
        condition &&
        (this.$route.name === 'rcti' ||
          this.$route.name === 'docket' ||
          this.$route.name === 'bill' ||
          this.$route.name === 'approvedBillLine')
      )
    },
    showShowClosed() {
      const condition = this.isFilter ? true : this.showClosed
      return (
        condition && this.$route.name !== 'pod' && this.$route.name !== 'map'
      )
    },
    showShowVoid() {
      const condition = this.isFilter ? true : this.showVoid
      return condition && this.$route.name === 'allocation' && !this.isStaged
    },
    showProjects() {
      const condition = this.isFilter ? !!this.projects : !!this.projectName
      return (
        (this.$route.name === 'docket' ||
          this.$route.name === 'plan' ||
          this.$route.name === 'allocation' ||
          this.$route.name === 'pod' ||
          this.$route.name === 'rate' ||
          this.$route.name === 'rcti' ||
          this.$route.name === 'bill' ||
          this.$route.name === 'approvedBillLine') &&
        condition
      )
    },
    showSources() {
      const condition = this.isFilter ? !!this.sources : !!this.sourceName
      return (
        (this.$route.name === 'docket' ||
          this.$route.name === 'plan' ||
          this.$route.name === 'allocation' ||
          this.$route.name === 'pod' ||
          this.$route.name === 'rate' ||
          this.$route.name === 'bill' ||
          this.$route.name === 'approvedBillLine' ||
          this.$route.name === 'map') &&
        condition
      )
    },
    showUsers() {
      const condition = this.isFilter ? this.users : !!this.userName
      return (
        (this.$route.name === 'docket' ||
          this.$route.name === 'rate' ||
          this.$route.name === 'plan' ||
          this.$route.name === 'allocation' ||
          this.$route.name === 'rcti' ||
          this.$route.name === 'bill' ||
          this.$route.name === 'approvedBillLine') &&
        condition
      )
    },
    showContractors() {
      const condition = this.isFilter ? true : !!this.selectedContractorId
      return (
        this.$route.name !== 'plan' &&
        this.$route.name !== 'bill' &&
        this.$route.name !== 'approvedBillLine' &&
        this.$route.name !== 'rate' &&
        condition
      )
    },
    showVehicles() {
      const condition = this.isFilter
        ? this.vehicles
        : !!this.vehicleName &&
          this.$route.name != 'plan' &&
          this.$route.name != 'rate' &&
          this.$route.name != 'rcti' &&
          this.$route.name != 'bill' &&
          this.$route.name != 'approvedBillLine'
      if (condition) {
        if (this.$route.name === 'compliance') {
          return this.model === null || this.model === 'vehicle'
        }
        return true
      } else {
        return false
      }
    },
    showDrivers() {
      const condition = this.isFilter
        ? this.drivers
        : !!this.driverName &&
          this.$route.name != 'plan' &&
          this.$route.name != 'rate' &&
          this.$route.name != 'rcti' &&
          this.$route.name != 'bill' &&
          this.$route.name != 'approvedBillLine'
      if (condition) {
        if (this.$route.name === 'compliance') {
          return this.model === null || this.model === 'driver'
        }
        return true
      } else {
        return false
      }
    },
    showItemTypes() {
      const condition = this.isFilter ? !!this.itemTypes : !!this.itemTypeName
      if (condition && this.$route.name === 'rate') {
        return true
      } else {
        return false
      }
    },
    showVehicleTypes() {
      const condition = this.isFilter
        ? !!this.vehicleTypes
        : !!this.vehicleTypeName
      if (condition && this.$route.name === 'rate') {
        return true
      } else {
        return false
      }
    },
    showDateRange() {
      const condition = this.isFilter
        ? true
        : !!this.dateRangeStart || !!this.dateRangeEnd
      return (
        (this.$route.name === 'allocation' ||
          this.$route.name === 'plan' ||
          this.$route.name === 'docket' ||
          this.$route.name === 'compliance' ||
          this.$route.name === 'pod' ||
          this.$route.name === 'rcti' ||
          this.$route.name === 'approvedBillLine' ||
          this.$route.name === 'bill') &&
        condition
      )
    },
    showDeliveryStatus() {
      const condition = this.isFilter ? true : !!this.selectedDeliveryStatus
      return this.$route.name === 'allocation' && this.lineView && condition
    },
    showDestinations() {
      const condition = this.isFilter
        ? this.destinations
        : !!this.destinationName
      return (
        ((this.$route.name === 'docket' && this.lineView) ||
          (this.$route.name === 'allocation' && this.lineView) ||
          this.$route.name === 'pod' ||
          this.$route.name === 'bill' ||
          this.$route.name === 'rate') &&
        condition
      )
    },
    showShowTargets() {
      const condition = this.$route.name === 'map'
      return condition
    },
    showPodType() {
      const condition =
        this.$route.name === 'pod' &&
        this.selectedPodType &&
        !!this.selectedPodType.length
      return condition
    },
    showReportId() {
      const condition = this.$route.name === 'pod' && !!this.selectedReportId
      return condition
    }
  }
}
