<template>
  <v-container fill-height>
    <v-row class="fill-height">
      <v-col cols="12"> </v-col>
      <v-col cols="12" class="d-flex justify-space-around align-center">
        <v-container>
          <v-row>
            <v-col cols="12" class="d-flex justify-center align-center">
              <v-img
                src="@/assets/payloader-large1.png"
                max-width="70"
                contain
              ></v-img>
              <div class="text-h4 grey--text text--lighten-1 text-center">
                ayloader
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  flat
                  class="card-outlined"
                  width="350"
                  :elevation="hover ? 3 : 0"
                  :class="{ 'on-hover': hover }"
                >
                  <v-card-text>
                    <v-window
                      class="text-center"
                      v-model="step"
                      :touchless="true"
                    >
                      <LandingForm
                        :onSubmit="login"
                        :toggleStep="() => step++"
                        title="Login"
                        toggleText="Sign Up!"
                        :step="1"
                        refName="loginForm"
                        :loading="loading"
                        :fields="loginFields"
                        :allowRegister="allowRegister"
                      />
                      <LandingForm
                        v-if="allowRegister"
                        :onSubmit="register"
                        :toggleStep="() => step--"
                        title="Register"
                        toggleText="Have an account?"
                        :step="2"
                        refName="registerForm"
                        :loading="loading"
                        :fields="registerFields"
                        :allowRegister="allowRegister"
                      />
                      <LandingForm
                        :onSubmit="login"
                        :toggleStep="() => (step = 1)"
                        title="Two-factor authentication"
                        toggleText="Back"
                        :step="3"
                        refName="codeForm"
                        :loading="loading"
                        :fields="codeFields"
                        submitText="Authenticate"
                      />
                    </v-window>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12"></v-col>
    </v-row>
  </v-container>
  <!-- </v-img> -->
</template>

<script>
import { login, signUp } from '@/services/authentication'
import AuthValidation from '@/services/validation/authValidation'
import LandingForm from '@/components/misc/LandingForm'
export default {
  mixins: [AuthValidation],
  components: { LandingForm },
  data() {
    return {
      step: 1,
      email: '',
      name: '',
      password: '',
      confirmedPassword: '',
      loading: false,
      showSnackbar: false,
      snackbarText: null,
      snackbarColor: null,
      email2FA: null,
      password2FA: null,
      allowRegister: process.env.VUE_APP_ALLOW_REGISTER === 'true'
    }
  },
  computed: {
    loginFields() {
      return {
        email: { label: 'Email', value: '', rules: this.emailRules },
        password: {
          label: 'Password',
          value: '',
          rules: this.passwordRules
        }
      }
    },
    registerFields() {
      return {
        email: { label: 'Email', value: '', rules: this.emailRules },
        name: { label: 'Name', value: '' },
        password: {
          label: 'Password',
          value: '',
          rules: this.passwordRules
        },
        confirmedPassword: {
          label: 'Confirm Password',
          value: '',
          rules: this.passwordRules
        }
      }
    },
    codeFields() {
      return {
        code: {
          label: '2FA Code',
          value: ''
        }
      }
    }
  },
  methods: {
    async login(form, fields) {
      if (!form.validate()) {
        this.snackFormError()
        return
      }

      try {
        this.loading = true
        const payload =
          this.step == 3
            ? {
                email: this.email2FA,
                password: this.password2FA,
                code: fields.code.value
              }
            : {
                email: fields.email.value,
                password: fields.password.value
              }

        const result = await login(payload)
        if (result.require2FA) {
          this.step = 3
          this.email2FA = fields.email.value
          this.password2FA = fields.password.value
          this.loading = false
          return
        }
        this.$router.push({
          name: 'dashboard'
        })
      } catch (err) {
        this.snack({
          text: `${err.response.data.message} (${err.response.status}) `,
          color: 'amber'
        })
      } finally {
        this.loading = false
      }
    },
    async register(form, fields) {
      if (!form.validate()) {
        this.snackFormError()
        return
      }
      if (fields.password.value !== fields.confirmedPassword.value) {
        this.snack({ text: 'Passwords do not match', color: 'red' })
        return
      }

      try {
        this.loading = true
        await signUp({
          email: fields.email.value,
          name: fields.name.value,
          password: fields.password.value,
          userAuthClassId: 1
        })
        this.$router.push({
          name: 'dashboard'
        })
      } catch (err) {
        this.snack({
          text: `${err.response.data.message} (${err.response.status}) `,
          color: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
