import Store from '@/store/computed/storeHelpers'
import RctiLineAllHeaders from './rctiLineAllHeaders'
export default {
  mixins: [RctiLineAllHeaders],
  computed: {
    defaultHeaders: Store.getSet({
      store: 'rctis',
      prop: 'rctiLineDefaultHeaders'
    }),
    allPossibleHeaders() {
      return this.allRctiLineHeaders
    }
  },
  data() {
    return {
      mobileHeaders: [
        {
          text: 'Description',
          align: 'left',
          value: 'description',
          type: 'text'
        },
        {
          text: 'Total',
          align: 'left',
          value: '_costTotal',
          type: 'curr'
        }
      ]
    }
  }
}
