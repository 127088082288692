import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    itemTypes: [],
    itemTypesLite: [],
    adding: false,
    loadingItemTypes: false,
    loadingItemTypesLite: false
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
