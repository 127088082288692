export default {
  computed: {
    allRctiHeaders() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        { text: 'Billing Period', value: 'billingPeriod' },
        { text: 'Date', value: '_date' },
        { text: 'Reference', value: 'externalRef' },
        {
          text: this.$store.state.global.pages.project.titleSingular,
          value: 'project.name'
        },
        {
          text: this.$store.state.global.pages.contractor.titleSingular,
          value: 'contractor.name'
        },
        { text: 'ABN', value: 'abn' },
        {
          text: `${this.$store.state.global.pages.docket.titleSingular} lines`,
          align: 'right',
          value: 'docketLineCount',
          type: 'decimal',
          noOfDecimals: 0
        },
        {
          text: `${this.$store.state.global.pages.rcti.titleSingular} lines`,
          align: 'right',
          value: 'rctiLineCount',
          type: 'decimal',
          noOfDecimals: 0
        },
        {
          text: 'Hours',
          value: 'hoursTotal',
          type: 'decimal',
          noOfDecimals: 1
        },
        {
          text: `${this.$store.state.global.pages.source.titleSingular} weight`,
          value: 'sourceWeightTotal',
          type: 'decimal',
          noOfDecimals: 1
        },
        {
          text: `${this.$store.state.global.pages.vehicle.titleSingular} weight`,
          value: 'vehicleWeightTotal',
          type: 'decimal',
          noOfDecimals: 1
        },
        {
          text: `${this.$store.state.global.pages.destination.titleSingular} weight`,
          value: 'destinationWeightTotal',
          type: 'decimal',
          noOfDecimals: 1
        },
        {
          text: 'Total',
          value: '_total',
          type: 'curr',
          align: 'right',
          noOfDecimals: 2
        },
        {
          text: 'Internal Notes',
          align: 'left',
          value: 'internalNotes',
          type: 'text'
        },
        {
          text: 'Invoice Notes',
          align: 'left',
          value: 'invoiceNotes',
          type: 'text'
        },
        { text: 'Status', value: 'status' },
        {
          text: 'Stat. Dec.',
          value: 'statDecConfirmed',
          type: 'confirmedIcon',
          icon: 'mdi-note',
          align: 'center'
        },
        { text: 'Stat. Dec. Notes', value: 'statDecNotes' },
        { text: 'Type', value: 'type' },
        {
          text: `${this.$store.state.settings.settings.accountingSystem} Inv#`,
          align: 'left',
          value: 'transactionNumber',
          type: 'text',
          show: false
        },
        {
          text: `${this.$store.state.settings.settings.accountingSystem} ID`,
          align: 'left',
          value: 'transactionId',
          type: 'text',
          show: false
        },
        {
          text: `${this.$store.state.settings.settings.accountingSystem}`,
          align: 'center',
          value: 'hasXero',
          sortable: false,
          type: 'boolean',
          show: false
        },
        {
          text: `${this.$store.state.settings.settings.accountingSystem} contact ID`,
          align: 'left',
          value: 'contractor.accountingContactId'
        },
        { text: 'Entered By', value: 'createdBy.name' },
        { text: 'Updated By', value: 'updatedBy.name' },
        { text: 'Created At', value: '_createdAt' },
        { text: 'Updated At', value: '_updatedAt' }
      ]
    }
  }
}
