import Store from '@/store/computed/storeHelpers'
export default {
  computed: {
    defaultHeaders: Store.getSet({ store: 'policies', prop: 'defaultHeaders' })
  },
  data() {
    return {
      allPossibleHeaders: [
        {
          text: 'Name',
          align: 'center',
          value: 'name',
          type: 'text',
          show: false
        }
      ]
    }
  }
}
