import Api from '@/services/api.js'

export const addItemType = payload => {
  return Api.post('itemTypes/', payload)
}
export const getItemTypes = payload => {
  return Api.post('itemTypes/get', payload)
}
export const getItemTypesLite = () => {
  return Api.get('itemTypes/')
}
export const updateItemType = (id, payload) => {
  return Api.put(`itemTypes/${id}`, payload)
}
export const deleteItemType = id => {
  return Api.delete(`itemTypes/${id}`)
}
