<template>
  <v-card flat class="card-outlined">
    <v-data-table
      :headers="headers"
      :items="audits"
      :loading="loadingAudits"
      mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [10, 20, 40, 50],
        showFirstLastPage: true
      }"
    >
      <template v-slot:no-data>
        No matching records found
      </template>
      <template v-slot:loading>
        Loading records...
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getAudits } from '../../services/requests/audits'
import getUsers from '../../services/mixins/getMasterFiles/getUsers'
import FormatDates from '@/services/mixins/formatDates'

export default {
  mixins: [FormatDates, getUsers],

  props: {
    model: {
      type: String,
      required: false
    },
    modelId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      loadingAudits: false,
      headers: [
        {
          text: 'Date',
          value: 'createdAt',
          sortable: false
        },
        {
          text: 'Record ID',
          value: 'modelId',
          sortable: false
        },
        {
          text: 'User',
          value: 'username',
          sortable: false
        },
        {
          text: 'Field',
          value: 'fieldName',
          sortable: false
        },
        {
          text: 'Details',
          value: 'text',
          sortable: false
        }
      ],
      audits: []
    }
  },
  watch: {
    modelId() {
      if (this.model && this.modelId) this.getAudits()
    }
  },

  methods: {
    async getAudits() {
      try {
        this.loadingAudits = true
        const payload = { model: this.model, modelId: Number(this.modelId) }
        const result = await getAudits({
          ...payload
        })
        if (result) {
          this.audits = result.data.result.map(item => {
            const detailsArray = item.text.split(' ')
            const userId = detailsArray[2]
            const fieldName = detailsArray[4]
            const user = this.users.find(({ id }) => id == userId)

            return {
              modelId: item.modelId,
              username: user.name,
              fieldName,
              createdAt: this.makeShortDateAndTime(new Date(item.createdAt)),
              text: item.text
            }
          })
          this.count = result.data.count
        }
        this.loadingAudits = false
      } catch (err) {
        console.log(err)
        this.loadingAudits = false
      }
    }
  }
}
</script>
