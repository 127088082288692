import { getAllocationByDocketId } from '../../../services/requests/allocations'
import { getLatestGpsLine } from '../../../services/requests/gpsLines'

export const getGpsData = async id => {
  const result = await getAllocationByDocketId(id)
  const data = result.data.result
  return {
    allocation: data.allocation,
    gpsLines: data.gpsLines,
    lines: data.lines,
    pods: data.pods
  }
}

export const getLatestGpsData = async (
  docketId,
  gpsLineId,
  allocationLineId
) => {
  const payload = {
    gpsLineId,
    allocationLineId
  }
  const result = await getLatestGpsLine(docketId, payload)
  const data = result.data.result
  return {
    gpsLines: data.gpsLines,
    lines: data.lines,
    pods: data.pods
  }
}
