<template>
  <v-scale-transition>
    <v-chip
      color="primary"
      outlined
      close
      @click:close="close"
      @click="onClick"
      v-show="condition"
      class="ma-1"
    >
      <v-icon left>
        {{ icon }}
      </v-icon>
      {{ name }}
    </v-chip>
  </v-scale-transition>
</template>

<script>
export default {
  props: {
    condition: Boolean,
    name: String,
    icon: String,
    close: { type: Function, required: false },
    onClick: { type: Function, required: false }
  }
}
</script>
