import { countMasterFiles, countTransactionFiles } from '../requests/statistics'
export default {
  mounted() {
    this.getMasterCount()
    this.getTransactionCount()
  },
  data() {
    return {
      loadingMasterCount: false,
      loadingTransactionCount: false,
      masterCount: {},
      transactionCount: {}
    }
  },
  methods: {
    async getMasterCount() {
      try {
        this.loadingMasterCount = true
        const result = await countMasterFiles()
        this.loadingMasterCount = false
        this.masterCount = result.data.result
      } catch (err) {
        console.log(err)
        this.loadingMasterCount = false
      }
    },
    async getTransactionCount() {
      try {
        this.loadingTransactionCount = true
        const result = await countTransactionFiles()
        this.loadingTransactionCount = false
        this.transactionCount = result.data.result
      } catch (err) {
        console.log(err)
        this.loadingTransactionCount = false
      }
    }
  }
}
