<template>
  <span
    v-if="header.value == 'id'"
    class="d-flex align-center justify-space-between"
  >
    {{ getObjectPath(header.value, item) }}
    <ComplianceExpiryIcon
      v-if="showComplianceExpiry"
      :daysTillExpired="Number(item.daysTillExpired)"
      :warningMessage="item.warningMessage"
    />
  </span>

  <span v-else-if="checkTextType(header)">
    {{
      getObjectPathRootExists(header.value, item) &&
      getObjectPath(header.value, item)
        ? getObjectPath(header.value, item)
        : '-' || '-'
    }}
  </span>
  <span v-else-if="header.type == 'dayBoolean'">
    <div v-if="getObjectPathRootExists(header.value, item)">
      <v-icon v-if="getObjectPath(header.value, item)" color="amber darken-1"
        >mdi-white-balance-sunny</v-icon
      >
      <v-icon v-else color="blue darken-1">mdi-weather-night</v-icon>
    </div>
  </span>

  <span v-else-if="header.type == 'boolean'">
    <div v-if="getObjectPathRootExists(header.value, item)">
      <v-icon v-if="getObjectPath(header.value, item)" color="green"
        >mdi-check</v-icon
      >
      <span v-else>-</span>
    </div>
  </span>

  <span v-else-if="header.type == 'decimal'">
    <div v-if="getObjectPathRootExists(header.value, item)">
      <div v-if="getObjectPath(header.value, item)">
        {{
          Number(getObjectPath(header.value, item)).toFixed(header.noOfDecimals)
        }}
      </div>
      <span v-else>-</span>
    </div>
  </span>

  <span v-else-if="header.type == 'curr'">
    <div v-if="getObjectPathRootExists(header.value, item)">
      <div v-if="getObjectPath(header.value, item)">
        ${{ getObjectPath(header.value, item) }}
      </div>
      <span v-else>
        <span v-if="getObjectPath(header.value, item) == null">-</span>
        <span v-else>$0.00</span>
      </span>
    </div>
  </span>

  <DeliveryStatusColumn
    v-else-if="header.type == 'deliveryStatus'"
    :value="header.value"
    :item="item"
  />

  <!-- <span v-else-if="header.type == 'date' || !header.type">
    {{ makeShortDate(getObjectPath(header.value, item)) || '-' }}
  </span> -->
  <!-- <span v-else-if="header.type == 'time'">{{
    getUTCTime(getObjectPath(header.value, item)) || '-'
  }}</span> -->
  <span v-else-if="header.type == 'compliance'">
    {{ getComplianceTypeName(getObjectPath(header.value, item)) || '-' }}
  </span>

  <PodColumn
    v-else-if="header.type == 'podType'"
    :value="header.value"
    :item="item"
  />

  <ConfirmedIcon
    v-else-if="header.type === 'confirmedIcon'"
    :value="getObjectPath(header.value, item)"
    :icon="header.icon"
  />

  <ActionsColumn
    v-else-if="header.type == 'action'"
    @click.stop
    :actionKeys="actionKeys"
    :actions="actions"
  />

  <span v-else>
    -
  </span>
</template>

<script>
import FormatDates from '@/services/mixins/formatDates'
import models from '../../../compliances/mixins/models'

import ComplianceExpiryIcon from '../columns/complianceExpiryIcon'
import DeliveryStatusColumn from '../columns/deliveryStatusColumn'
import PodColumn from '../columns/podColumn'
import ConfirmedIcon from '../columns/confirmedIcon'
import ActionsColumn from '../columns/actionsColumn'
export default {
  mixins: [FormatDates, models],
  components: {
    ComplianceExpiryIcon,
    DeliveryStatusColumn,
    PodColumn,
    ConfirmedIcon,
    ActionsColumn
  },
  props: {
    header: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    actions: {
      type: Object,
      required: true
    },
    actionKeys: {
      type: Array,
      required: true
    }
  },
  computed: {
    showComplianceExpiry() {
      return (
        this.item.type !== 'soa' &&
        this.$route.name === 'compliance' &&
        this.item.daysTillExpired <= 31
      )
    }
  },
  methods: {
    checkTextType(header) {
      const types = ['text', 'date', 'time']
      const isText = types.includes(header.type)
      return (isText || !header.type) && header.value != 'id'
    }
  }
}
</script>
