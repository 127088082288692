import Store from '@/store/computed/storeHelpers'
import RctiAllHeaders from './rctiAllHeaders'
export default {
  mixins: [RctiAllHeaders],
  computed: {
    defaultHeaders: Store.getSet({
      store: 'rctis',
      prop: 'rctiDefaultHeaders'
    }),
    allPossibleHeaders() {
      return this.allRctiHeaders
    }
  },
  data() {
    return {
      mobileHeaders: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$store.state.global.pages.contractor.titleSingular,
          value: 'contractor.name'
        }
      ]
    }
  }
}
