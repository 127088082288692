<template>
  <v-card flat class="card-outlined">
    <v-card-text class="pb-0 d-flex justify-space-between align-center">
      <v-row>
        <v-col cols="12" sm="10" class="py-0">
          <MultiActions
            page="allocations"
            isLines
            :selectedCount="selectedCount"
            :fieldsToChange="actionFields"
            :exportOptions="exportOptions"
          />
        </v-col>
        <v-col cols="12" sm="2" class="py-0 d-flex justify-end align-center">
          <ColumnSelector
            :defaultHeaders="defaultHeaders"
            :possibleHeaders="possibleHeaders"
            toolTipText="Column selector"
            :onChanged="val => (defaultHeaders = val)"
          />
        </v-col>
        <SelectAlert
          :selected="selected"
          :selectedAll="selectedAll"
          :totalRecordCount="count"
          :selectAll="() => (selectedAll = true)"
          :unselectAll="() => ((selectedAll = false), (selected = []))"
        />
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="allocationLines"
      :loading="loadingLines"
      :options.sync="pagination"
      :server-items-length="count"
      mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [10, 20, 40, 50],
        showFirstLastPage: true
      }"
      v-model="selected"
      show-select
    >
      <template v-slot:no-data>
        No matching records found
      </template>
      <template v-slot:loading>
        Loading records...
      </template>
      <template v-slot:item="{ item, isSelected }">
        <Row
          :headers="headers"
          :item="item"
          :key="item.id"
          showEdit
          showDeliveryDetails
          :value="isSelected"
          @input="toggleSelected"
          select
          defaultDialog="detailsDialog"
          editDialogFullscreen
          detailsDialogFullscreen
          :createDocketFromAllocation="createDocketFromAllocation"
        >
          <template v-slot:details="{ dialog, close, openEditDialog }">
            <AllocationDetails
              v-if="dialog"
              :dialog="dialog"
              :item="item.allocation"
              :loading="loading"
              :close="close"
              :openEditDialog="openEditDialog"
              :selectedId="item.id"
            />
          </template>
          <template v-slot:podDetails="{ dialog, close }">
            <AllocationLineDetails
              v-if="dialog"
              :item="item"
              :dialog="dialog"
              :close="close"
            />
          </template>
          <template v-slot:edit="{ dialog, close }">
            <AllocationEdit
              v-if="dialog"
              :dialog="dialog"
              :item="item.allocation"
              :loading="loading"
              :action="editAllocation"
              :close="close"
            />
          </template>
          <template v-slot:delete="{ dialog, close }">
            <DeleteDialog
              :dialog="dialog"
              :page="`${titleSingular} Line`"
              :subject="item"
              :action="deleteAllocationLine"
              :close="close"
            />
          </template>
        </Row>
      </template>
      <template
        v-slot:footer[`page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        <div class="text-center mt-1">
          <span v-if="itemsLength">
            {{ `${pageStart}-${pageStop} of ${itemsLength}` }}
          </span>
          <span v-else> - </span>
          <v-progress-linear
            v-if="loadingLines && itemsLength"
            size="15"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <div v-else style="height:5px"></div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Store from '@/store/computed/storeHelpers'
import getAllocationLinesMixin from '@/services/mixins/getTransactionFiles/getAllocationLines'
import Headers from '@/components/allocations/mixins/allocationLineHeaders'
import HeaderFilters from '@/services/mixins/headerFilters'
import MultipleRecords from '@/services/mixins/multipleRecords'
import Common from '@/components/allocations/mixins/allocationCommon'
import AllocationLineExport from '@/components/allocations/mixins/allocationLineExport'

import Row from '@/components/misc/row/Row'
import AllocationDetails from '@/components/allocations/AllocationDetails'
import AllocationLineDetails from '@/components/allocations/lines/AllocationLineDetails'
import AllocationEdit from '@/components/allocations/AllocationEdit'
import DeleteDialog from '@/components/misc/DeleteDialog'
import ColumnSelector from '@/components/misc/shared/ColumnSelector'
import MultiActions from '@/components/misc/shared/MultiActions'
import SelectAlert from '@/components/misc/shared/SelectAlert'

import { debounce } from 'lodash'
export default {
  mixins: [
    getAllocationLinesMixin,
    Headers,
    HeaderFilters,
    MultipleRecords,
    Common,
    AllocationLineExport
  ],
  components: {
    Row,
    AllocationDetails,
    AllocationLineDetails,
    AllocationEdit,
    DeleteDialog,
    ColumnSelector,
    MultiActions,
    SelectAlert
  },
  props: {
    createDocketFromAllocation: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    actionFields() {
      const authLimitedActions = [
        {
          text: 'Delete selected',
          icon: 'mdi-delete',
          color: 'red',
          value: { value: null, text: 'Delete Selected', label: 'delete' },
          action: async () => {
            await this.deleteMultipleRecords({
              ids: this.selected.map(({ id }) => id),
              table: 'allocations',
              isLine: true,
              query: this.selectedAll ? this.buildQuery() : null
            })
          }
        }
      ]
      let actions = [
        {
          text: 'Export selected',
          icon: 'mdi-file-export',
          color: 'grey',
          value: { value: null, text: 'Export Selected', label: 'export' }
        },
        {
          text: 'Print selected',
          icon: 'mdi-file-pdf-box',
          color: 'grey',
          value: { value: null, text: 'Print selected', label: 'print' }
        }
      ]
      if (this.userAuthClass) actions = [...actions, ...authLimitedActions]
      return actions
    },
    showClosed: Store.getSet({ store: 'global', prop: 'showClosed' }),
    showVoid: Store.getSet({ store: 'global', prop: 'showVoid' }),
    selectedUserId: Store.getSet({ store: 'global', prop: 'selectedUserId' }),
    selectedProjectId: Store.getSet({
      store: 'global',
      prop: 'selectedProjectId'
    }),
    selectedSourceId: Store.getSet({
      store: 'global',
      prop: 'selectedSourceId'
    }),
    selectedDestinationId: Store.getSet({
      store: 'global',
      prop: 'selectedDestinationId'
    }),
    selectedDeliveryStatus: Store.getSet({
      store: 'global',
      prop: 'selectedDeliveryStatus'
    }),
    selectedContractorId: Store.getSet({
      store: 'global',
      prop: 'selectedContractorId'
    }),
    selectedDriverId: Store.getSet({
      store: 'global',
      prop: 'selectedDriverId'
    }),
    selectedVehicleId: Store.getSet({
      store: 'global',
      prop: 'selectedVehicleId'
    }),
    dateRangeStart: Store.getSet({ store: 'global', prop: 'dateRangeStart' }),
    dateRangeEnd: Store.getSet({ store: 'global', prop: 'dateRangeEnd' }),
    filterCol: Store.getSet({ store: 'allocations', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'allocations', prop: 'filterBy' }),
    selected: Store.getSet({ store: 'allocations', prop: 'selectedLines' }),
    selectedAll: Store.getSet({
      store: 'allocations',
      prop: 'selectedAllLines'
    }),
    selectedCount() {
      return this.selectedAll ? this.count : this.selected.length
    }
  },
  watch: {
    allocationLines() {
      this.selected = []
      this.selectedAll = false
    },
    showClosed() {
      this.getLinesFromPage1()
    },
    showVoid() {
      this.getLinesFromPage1()
    },
    selectedUserId() {
      this.getLinesFromPage1()
    },
    selectedProjectId() {
      this.getLinesFromPage1()
    },
    selectedSourceId() {
      this.getLinesFromPage1()
    },
    selectedDestinationId() {
      this.getLinesFromPage1()
    },
    selectedDeliveryStatus() {
      this.getLinesFromPage1()
    },
    selectedContractorId() {
      this.getLinesFromPage1()
    },
    selectedDriverId() {
      this.getLinesFromPage1()
    },
    selectedVehicleId() {
      this.getLinesFromPage1()
    },
    dateRangeStart() {
      this.getLinesFromPage1()
    },
    dateRangeEnd() {
      this.getLinesFromPage1()
    },
    filterCol() {
      this.getLinesFromPage1()
    },
    filterBy: debounce(function() {
      this.getLinesFromPage1()
    }, 300)
  },
  methods: {
    async getLinesFromPage1() {
      this.pagination.page = 1
      await this.getAllocationLines()
    }
  }
}
</script>
