<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        :color="color ? color : 'primary'"
        text
        :icon="(!!icon && !text) || !$vuetify.breakpoint.smAndUp"
        @click="action"
        :disabled="disabled"
        class="ml-1"
      >
        <v-icon v-if="icon" :left="$vuetify.breakpoint.smAndUp && !!text">
          {{ icon }}
        </v-icon>
        <span v-if="text">
          {{ $vuetify.breakpoint.smAndUp && text ? text : '' }}
        </span>
      </v-btn>
    </template>
    <span v-if="toolTipText">{{ toolTipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    action: {
      type: Function,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false
    },
    toolTipText: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  }
}
</script>
