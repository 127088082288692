<template>
  <v-form ref="form" @submit.prevent="submit()">
    <v-card>
      <v-card-text class="pb-0">
        <v-card-title class="pa-0 font-weight-regular">
          {{ title }}
        </v-card-title>

        <v-row>
          <input
            type="file"
            ref="fileUpload"
            style="display:none"
            @change="onFileChange"
          />
          <v-col cols="12" class="py-0">
            <v-text-field
              label="Select File"
              color="primary"
              prepend-icon="mdi-paperclip"
              readonly
              :value="typeof file == 'object' ? file.name : ''"
              v-model="file.name"
              @click="openFileDialog"
              :loading="loading"
            ></v-text-field>
            <span>{{ importHelp }}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="close()">Close</v-btn>
        <v-btn
          text
          color="green"
          :disabled="!file"
          :loading="loading"
          type="submit"
        >
          <v-icon left>mdi-cloud-upload</v-icon>Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
export default {
  props: {
    title: String,
    close: Function,
    importLines: Function,
    loading: Boolean
  },
  data() {
    return {
      file: ''
    }
  },
  methods: {
    openFileDialog() {
      this.$refs.fileUpload.click()
    },
    onFileChange() {
      this.file = this.$refs.fileUpload.files[0]
    },
    submit() {
      if (!this.file) {
        this.snack({ text: 'Please select a file', color: 'amber' })
        return
      }
      this.importLines(this.file)
    }
  },
  computed: {
    route() {
      return this.$route.name
    },
    importHelp() {
      const importCols = ['destination', 'itemType', 'qty']
      let str = 'Column order: '
      for (const col of importCols)
        str += ` ${
          this.$store.state.global.pages[col]
            ? this.$store.state.global.pages[col].titleSingular
            : col.charAt(0).toUpperCase() + col.slice(1)
        },`
      return str.slice(0, -1)
    }
  }
}
</script>
