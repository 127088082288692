export default {
  computed: {
    contractorId() {
      return this.contractor?.id
    },
    source() {
      const sourceId = this.editedItem
        ? this.editedItem.sourceId
        : this.sourceId
      if (!sourceId) return null
      return this.sources.find(({ id }) => id === sourceId) || null
    },
    vehicle() {
      const vehicleId = this.editedItem
        ? this.editedItem.vehicleId
        : this.vehicleId
      return this.vehicles.find(({ id }) => id === vehicleId) || null
    },
    contractor() {
      const driverId = this.editedItem
        ? this.editedItem.driverId
        : this.driverId
      const vehicleId = this.editedItem
        ? this.editedItem.vehicleId
        : this.vehicleId
      const driver = this.drivers?.find(({ id }) => driverId === id)
      const vehicle = this.vehicles?.find(({ id }) => vehicleId === id)
      if (driver) return driver.contractor
      else if (vehicle) return vehicle.contractor
      else return null
    },
    filteredVehicles() {
      return this.filterSubjects('vehicles', this.vehicles)
    },
    filteredDrivers() {
      return this.filterSubjects('drivers', this.drivers)
    }
  },
  methods: {
    filterSubjects(listName, allSubjects) {
      const subjects = this.contractorId
        ? this.filterByContractor(listName, this.contractorId)
        : allSubjects
      if (!this.globalSettings.useInductions) return allSubjects
      if (!this.source?.projectId) return []
      return subjects.filter(subject => this.isSubjectAllowed(subject))
    },
    isSubjectAllowed(subject) {
      const isProjectAllowed = allowedProjects =>
        allowedProjects.includes(this.source.projectId)
      return (
        isProjectAllowed(subject.allowedProjects) &&
        isProjectAllowed(subject.allowedContractorProjects)
      )
    },

    filterByContractor(listName, contractorId) {
      return this[listName].filter(record => {
        return record.contractorId == contractorId
      })
    }
  }
}
