import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    compliances: [],
    loadingCompliances: false,
    filterCol: null,
    filterBy: '',
    selectedAll: false,
    selected: [],
    complianceDefaultHeaders: [
      'id',
      'expiryDate',
      'belongsTo',
      'notes',
      'createdBy.name',
      'updatedBy.name'
    ]
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
