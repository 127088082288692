import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    contractorId: null,
    driver: null,
    driverIds: [],
    lockPosition: false,
    showTargets: false,
    sourceId: null
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
