<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" v-if="value === 'CONFIRMED'" color="green">
        {{ icon }}
      </v-icon>
      <v-icon v-on="on" v-else-if="value === 'REVIEW'" color="amber">
        {{ icon }}
      </v-icon>
      <v-icon v-on="on" v-else-if="value === 'REQUIRED'" color="amber">
        {{ icon }}
      </v-icon>
      <v-icon v-on="on" v-else-if="value === 'NOT SET'" color="grey">
        {{ icon }}
      </v-icon>
    </template>
    <span> {{ value }} </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  }
}
</script>
