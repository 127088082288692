import {
  makeShortDate,
  makeTime,
  makeComputerDate
} from '../../utils/FormatDates'
class Allocation {
  constructor(allocation) {
    Object.keys(allocation).forEach(key => {
      this[key] = allocation[key]
      this[`_${key}`] = allocation[key]
      const formats = [
        {
          condition: ['date', 'createdAt', 'updatedAt', 'endDate'].includes(
            key
          ),
          format: val => makeShortDate(val)
        },
        {
          condition: ['start', 'end'].includes(key),
          format: val => makeTime(new Date(val))
        },
        {
          condition: ['qty', 'lineHours'].includes(key),
          format: val => Number(val).toFixed(2)
        }
      ]

      formats.forEach(item => {
        if (item.condition && allocation[key])
          this[`_${key}`] = item.format(allocation[key])
      })
    })
  }

  get createdByName() {
    return this.createdBy ? this.createdBy.name : this.driver.name
  }
  get isWithDriver() {
    return !!this.docketId && !this.docket?.isClosed
  }
  get closed() {
    return (
      (!!this.docketId && this.docket?.isClosed) ||
      this.endDate < makeComputerDate(new Date())
    )
  }
}

export default Allocation
