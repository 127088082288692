<template>
  <v-alert
    v-if="selected.length >= 10"
    color="primary"
    outlined
    border="left"
    style="width: 100%;"
  >
    <template v-slot:prepend>
      <v-icon color="primary">mdi-bell</v-icon>
    </template>
    <div class="d-flex justify-center align-center">
      <span class="grey--text text--darken-2"
        >{{ selectedAll ? totalRecordCount : selected.length }} items are
        selected.</span
      >
      <v-btn v-if="selectedAll" @click="unselectAll" text color="grey"
        >Unselect all
        {{
          $vuetify.breakpoint.smAndUp
            ? `${selectedAll ? totalRecordCount : selected.length} items`
            : ''
        }}</v-btn
      >
      <v-btn v-else @click="selectAll" text color="primary"
        >Select all
        {{
          $vuetify.breakpoint.smAndUp ? `${totalRecordCount} items` : ''
        }}</v-btn
      >
    </div>
  </v-alert>
</template>

<script>
export default {
  props: {
    selected: {
      type: Array,
      required: true
    },
    selectedAll: {
      type: Boolean,
      required: false
    },
    selectAll: {
      type: Function,
      required: true
    },
    unselectAll: {
      type: Function,
      required: true
    },
    totalRecordCount: {
      type: Number,
      required: true
    }
  }
}
</script>
