import Rules from '@/services/validation/rules/rules'
export default {
  data() {
    return {
      billingPeriodRules: [v => Rules.isRequired(v)],
      dateRules: [v => Rules.isRequired(v)],
      sourceRules: [v => Rules.isRequired(v)],
      vehicleRules: [v => Rules.isRequired(v)],
      sellUnitRules: [v => Rules.isRequired(v)],
      costUnitRules: [v => Rules.isRequired(v)],
      driverRules: [v => Rules.isRequired(v)],
      notesRules: [v => Rules.minLength(v, 1), v => Rules.maxLength(v, 255)],
      externalRefRules: [
        v => Rules.minLength(v, 1),
        v => Rules.maxLength(v, 255)
      ],
      qtyRules: [v => Rules.isRequiredWithZero(v)],
      sellPriceRules: [v => Rules.isRequiredWithZero(v)],
      destinationRules: [v => Rules.isRequired(v)],
      itemTypeRules: [v => Rules.isRequired(v)],
      typeRules: [v => Rules.isRequired(v)],
      destinationWeightRules: [v => Rules.isRequiredWithZero(v)],
      sourceWeightRules: [v => Rules.isRequiredWithZero(v)],
      vehicleWeightRules: [v => Rules.isRequiredWithZero(v)],
      breaksRules: [v => Rules.isRequiredWithZero(v)],
      travelRules: [v => Rules.isRequiredWithZero(v)],
      wbDocketNumberRules: [
        v => Rules.minLength(v, 1),
        v => Rules.maxLength(v, 255)
      ],
      timeRules: [v => Rules.isRequired(v)]
    }
  }
}
