<template>
  <v-card height="100%">
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-1">
        <span class="text-truncate">
          View
          {{ $store.state.global.pages.docket.titleSingular.toLowerCase() }}
          line -
          <span class="pl-1 subtitle-1">
            ID: <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-card flat class="card-outlined mb-3">
            <v-card-text class="pb-0 pt-1">
              <v-container fluid class="px-0">
                <v-row>
                  <Detail
                    v-if="item.destination"
                    :icon="$store.state.global.pages.destination.icon"
                    :text="item.destination.name"
                    :tooltip="
                      $store.state.global.pages.destination.titleSingular
                    "
                    cols="6"
                    lg="4"
                  />
                  <Detail
                    v-if="item.itemType"
                    :icon="$store.state.global.pages.itemType.icon"
                    :text="item.itemType.name"
                    :tooltip="$store.state.global.pages.itemType.titleSingular"
                    cols="6"
                    lg="4"
                  />
                  <Detail
                    v-if="item.rctiId"
                    :icon="$store.state.global.pages.rcti.icon"
                    :text="item.rctiId.toString()"
                    :tooltip="$store.state.global.pages.rcti.titleSingular"
                    cols="6"
                    lg="4"
                  />
                  <Detail
                    v-if="item.wbDocketNumber"
                    icon="mdi-alpha-w-box"
                    :text="item.wbDocketNumber"
                    tooltip="Weighbridge docket number"
                    cols="6"
                    lg="4"
                  />
                  <Detail
                    v-if="item.docket"
                    :icon="item.closed ? 'mdi-door-closed' : 'mdi-door-open'"
                    :text="item.closed ? 'Closed' : 'Open'"
                    :tooltip="
                      `${$store.state.global.pages.docket.titleSingular} line status`
                    "
                    cols="6"
                    lg="4"
                  />
                  <Detail
                    v-if="item.notes"
                    icon="mdi-note"
                    :text="item.notes"
                    tooltip="Line notes"
                    lg="4"
                  />
                  <Detail
                    v-if="item.start"
                    icon="mdi-clock"
                    :text="
                      `${item._start} ${item.end ? ' - ' + item._end : ''} (${
                        item.hours
                          ? item.hours.toFixed(2)
                          : item.currentOpenHours
                          ? Number(item.currentOpenHours).toFixed(2)
                          : '0.00'
                      } hours)`
                    "
                    :color="!item.end ? 'green' : ''"
                    lg="4"
                    :tooltip="!item.docket.isClosed ? 'Is active' : ''"
                  />
                </v-row>
                <v-divider
                  class="my-5"
                  v-if="globalSettings.useConfirmations"
                ></v-divider>
                <v-row v-if="globalSettings.useConfirmations">
                  <Detail
                    v-if="item.GPSConfirmed != 'NOT SET'"
                    icon="mdi-crosshairs-gps"
                    :text="`GPS ${item.GPSConfirmed.toLowerCase()}`"
                    :tooltip="
                      item.GPSNotes
                        ? item.GPSNotes
                        : `GPS ${item.GPSConfirmed.toLowerCase()}`
                    "
                    :color="
                      item.GPSConfirmed == 'CONFIRMED' ? 'green' : 'amber'
                    "
                    lg="4"
                  />
                  <Detail
                    v-if="item.tippingDocketConfirmed != 'NOT SET'"
                    icon="mdi-delete-sweep"
                    :text="
                      `Tipping ${item.tippingDocketConfirmed.toLowerCase()}`
                    "
                    :tooltip="
                      item.tippingDocketNotes
                        ? item.tippingDocketNotes
                        : `Tipping ${item.tippingDocketConfirmed.toLowerCase()}`
                    "
                    :color="
                      item.tippingDocketConfirmed == 'CONFIRMED'
                        ? 'green'
                        : 'amber'
                    "
                    lg="4"
                  />
                  <Detail
                    v-if="item.massDeclarationConfirmed != 'NOT SET'"
                    icon="mdi-basket"
                    :text="
                      `Mass ${item.massDeclarationConfirmed.toLowerCase()}`
                    "
                    :tooltip="
                      item.massDeclarationNotes
                        ? item.massDeclarationNotes
                        : `Mass ${item.massDeclarationConfirmed.toLowerCase()}`
                    "
                    :color="
                      item.massDeclarationConfirmed == 'CONFIRMED'
                        ? 'green'
                        : 'amber'
                    "
                    lg="4"
                  />
                </v-row>
                <v-divider
                  class="my-5"
                  v-if="
                    item.massDeclarationConfirmed != 'NOT SET' ||
                      item.GPSConfirmed != 'NOT SET' ||
                      item.tippingDocketConfirmed != 'NOT SET'
                  "
                ></v-divider>

                <v-row class="mt-0">
                  <v-col cols="6" sm="3">
                    <v-text-field
                      label="Sell Units"
                      :prepend-icon="$store.state.global.pages.destination.icon"
                      v-model="item.sellUnit"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      label="Qty"
                      prepend-icon="mdi-chevron-right"
                      v-model.number="item.qty"
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-if="showSell"
                      label="Sell Price"
                      prepend-icon="mdi-chevron-right"
                      v-model.number="item.sellPrice"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-if="showSell"
                      label="Sell Total"
                      prepend-icon="mdi-chevron-right"
                      :value="item._sellTotal ? item._sellTotal : 0.0"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="pt-0" cols="6" sm="3">
                    <v-text-field
                      label="Cost Units"
                      :prepend-icon="$store.state.global.pages.destination.icon"
                      v-model="item.costUnit"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col class="pt-0" cols="6" sm="3">
                    <v-text-field
                      label="Cost Quantity"
                      prepend-icon="mdi-chevron-right"
                      v-model.number="item.costQty"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col class="pt-0" cols="6" sm="3">
                    <v-text-field
                      v-if="showCost"
                      label="Cost Price"
                      prepend-icon="mdi-chevron-right"
                      v-model.number="item.costPrice"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col class="pt-0" cols="6" sm="3">
                    <v-text-field
                      v-if="showCost"
                      label="Cost Total"
                      prepend-icon="mdi-chevron-right"
                      :value="item._costTotal ? item._costTotal : 0.0"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-expansion-panels
                  multiple
                  flat
                  class="card-outlined"
                  v-if="globalSettings.useApprovedBilling && showBilling"
                >
                  <v-expansion-panel active-class="mt-0">
                    <v-expansion-panel-header>
                      <template v-slot:actions>
                        <v-chip
                          v-if="item.outstandingBillingAmount"
                          color="primary"
                          class="mr-2"
                          label
                          >Outstanding: ${{
                            item.outstandingBillingAmount
                          }}</v-chip
                        >
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                      <h4 class="grey--text">
                        Billing information
                      </h4>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            label="Approved Amount"
                            prepend-icon="mdi-shopping_basket"
                            v-model.number="item.approvedBillingAmount"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            label="Waived Amount"
                            disabled
                            prepend-icon="mdi-shopping_basket"
                            v-model.number="item.waivedAmount"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            label="Outstanding Amount"
                            prepend-icon="mdi-shopping_basket"
                            v-model.number="item.outstandingBillingAmount"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-row>
                  <v-col class="pt-5" cols="12">
                    <v-text-field
                      label="Export Total"
                      prepend-icon="mdi-chevron-right"
                      :value="item._exportTotal ? item._exportTotal : 0.0"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <ShowAudits :auditData="auditData" />
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="close">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import FormatDates from '@/services/mixins/formatDates'
import Detail from '../../misc/Detail'
import ShowAudits from '../../misc/shared/ShowAudits'

export default {
  mixins: [FormatDates],
  components: {
    Detail,
    ShowAudits
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    openEditDialog: {
      type: Function,
      required: false
    },
    showBilling: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    showLocation(pod) {
      const gpsString = `${(pod.long, pod.lat)}`
      const url = `https://www.google.com/maps/dir/${gpsString}`
      window.open(url)
    }
  },
  computed: {
    showSell() {
      return this.$store.state.user.userAuthClass.showSell
    },
    showCost() {
      return this.$store.state.user.userAuthClass.showCost
    },
    auditData() {
      return { id: this.item.id, model: 'docketLine' }
    }
  }
}
</script>

<style>
.show-route:hover {
  cursor: pointer;
}
</style>
