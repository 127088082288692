import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    allocations: [],
    lines: [],
    pods: [],
    loadingAllocations: false,
    loadingLines: false,
    lineView: false,
    showCalendar: false,
    filterCol: null,
    isStaged: false,
    filterBy: null,
    selectedAll: false,
    selectedAllLines: false,
    selected: [],
    selectedLines: [],
    calDate: null,
    calNewAdded: false,
    allocationDefaultHeaders: [
      'id',
      'date',
      'source.name',
      'vehicle.name',
      'driver.name',
      'notes',
      'lineCount',
      'createdBy.name',
      'updatedBy.name'
    ],
    allocationLineDefaultHeaders: [
      'id',
      'destination.name',
      'itemType.name',
      'notes'
    ],
    allocationStagingLineDefaultHeaders: [
      'id',
      'destination.name',
      'itemType.name',
      'notes'
    ]
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
