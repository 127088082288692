<template>
  <v-col>
    <v-card flat class="card-outlined">
      <v-row>
        <v-col class="d-flex justify-end pb-0 pt-5">
          <v-menu
            bottom
            left
            transition="scale-transition"
            max-height="600px"
            origin="top right"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on }">
              <v-btn icon color="grey" v-on="on" class="mr-5">
                <v-icon>mdi-view-column</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="header of possibleHeaders"
                :key="header.value"
              >
                <v-list-item-action>
                  <v-checkbox
                    color="secondary"
                    v-model="defaultHeadersCopy"
                    :label="header.text"
                    :value="header.value"
                  ></v-checkbox>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-data-table :headers="headers" :items="subjects">
        <template v-slot:item="{ item }">
          <Row
            :headers="headers"
            :item="item"
            :key="item.id"
            :masterFile="masterFile"
            :fields="fields"
            :onEdit="onEdit"
            :onDelete="onDelete"
            :loading="loading"
          ></Row>
        </template>
        <template v-slot:loading>
          Loading records...
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>

<script>
import headerFilters from '../../services/mixins/headerFilters'

import Row from './MasterFileRow'
export default {
  name: 'masterFileTable',
  mixins: [headerFilters],
  components: {
    Row
  },
  props: {
    masterFile: {
      type: String,
      required: true
    },
    subjects: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    onEdit: {
      type: Function,
      required: true
    },
    onDelete: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    defaultHeaders: {
      type: Array,
      required: true
    },
    allPossibleHeaders: {
      type: Array,
      required: true
    },
    updateHeaders: {
      type: Function,
      required: true
    }
  },
  watch: {
    defaultHeadersCopy(val) {
      this.updateHeaders(val)
    }
  },
  data() {
    return {
      defaultHeadersCopy: [...this.defaultHeaders]
    }
  }
}
</script>

<style></style>
