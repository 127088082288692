import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    sources: [],
    sourcesLite: [],
    sourcesLiteActive: [],
    adding: false,
    loadingSources: false,
    loadingSourcesLite: false,
    parentId: null
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
