<template>
  <MasterFilePage>
    <template v-slot:control>
      <MasterFileControl
        :masterFile="masterFile"
        :addDialog="addDialog"
        :toggleAdd="toggleAdd"
      />
    </template>
    <template v-slot:view>
      <MasterFileTable
        :masterFile="masterFile"
        :subjects="policies"
        :fields="fields"
        :loading="loading"
        :onEdit="editPolicy"
        :onDelete="deletePolicy"
        :defaultHeaders="defaultHeaders"
        :allPossibleHeaders="allPossibleHeaders"
        :updateHeaders="updateHeaders"
      />
    </template>
    <template v-slot:form>
      <v-dialog
        v-model="addDialog"
        width="700"
        @click:outside="() => (addDialog = false)"
        @keydown.esc="() => (addDialog = false)"
      >
        <MasterFileForm
          :masterFile="masterFile"
          :fields="fields"
          :dialog="addDialog"
          :close="toggleAdd"
          :onSubmit="createPolicy"
          :loading="loading"
        />
      </v-dialog>
    </template>
  </MasterFilePage>
</template>

<script>
import {
  addPolicy,
  updatePolicy,
  deletePolicy
} from '../../../services/requests/policies'
import getPolicies from '../../../services/mixins/getMasterFiles/getPolicies'
import policyFields from '../../../services/mixins/fields/policyFields'
import MasterFileCrudFunctions from '../../../services/mixins/masterFileCrudFunctions'
import policyHeaders from '../../../services/mixins/headers/policyHeaders'

import MasterFilePage from '../../../components/masterFiles/MasterFilePage'
import MasterFileControl from '../../../components/masterFiles/MasterFileControl'
import MasterFileForm from '../../../components/masterFiles/MasterFileForm'
import MasterFileTable from '../../../components/masterFiles/MasterFileTable'
export default {
  mixins: [getPolicies, policyFields, MasterFileCrudFunctions, policyHeaders],
  components: {
    MasterFilePage,
    MasterFileControl,
    MasterFileForm,
    MasterFileTable
  },
  data() {
    return {
      masterFile: 'policy',
      prop: 'policies',
      addDialog: false,
      sortBy: null,
      filterBy: '',
      loading: false
    }
  },
  methods: {
    async createPolicy(policy) {
      await this.createMasterFile({
        payload: policy,
        action: addPolicy,
        store: 'policies'
      })
    },
    async editPolicy(policy) {
      const payload = {
        name: policy.name,
        key: policy.key
      }
      await this.editMasterFile({
        id: policy.id,
        payload: payload,
        action: updatePolicy,
        store: 'policies'
      })
    },
    async deletePolicy(policy) {
      await this.deleteMasterFile({
        payload: policy,
        action: deletePolicy,
        store: 'policies'
      })
    },
    toggleAdd() {
      this.addDialog = !this.addDialog
    },
    updateHeaders(val) {
      this.defaultHeaders = val
    }
  }
}
</script>
