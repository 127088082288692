import { makeShortDate } from '../../utils/FormatDates'
class Compliance {
  constructor(compliance) {
    Object.keys(compliance).forEach(key => {
      this[key] = compliance[key]
      this[`_${key}`] = compliance[key]
      const formats = [
        {
          condition: ['expiryDate', 'createdAt', 'updatedAt'].includes(key),
          format: val => makeShortDate(val)
        }
      ]

      formats.forEach(item => {
        if (item.condition && compliance[key])
          this[`_${key}`] = item.format(compliance[key])
      })
    })
  }

  get belongsTo() {
    return this[this.model].longName || this[this.model].name
  }
  get active() {
    return this[this.model].active
  }
  get daysTillExpired() {
    const expiryDateObj = new Date(this.expiryDate)
    const today = new Date()
    const days = Math.ceil((expiryDateObj - today) / 1000 / 60 / 60 / 24)
    return days
  }

  get warningMessage() {
    var days = this.daysTillExpired

    if (days < 1) {
      return `${this.type.toUpperCase()} EXPIRED ON ${makeShortDate(
        this.expiryDate
      )}!`
    } else {
      return `${convertCamel(this.type)} expires in ${days} day${
        days == 1 ? '' : 's'
      }!`
    }
  }
  get updatedByName() {
    return this.updatedById
      ? this.updatedBy.name
      : `DRIVER: ${this.driver.name}`
  }

  get attachmentType() {
    if (this.key) {
      const arr = this.key.split('.')
      return arr[arr.length - 1]
    } else {
      return null
    }
  }
}

function convertCamel(text) {
  const result = text.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export default Compliance
