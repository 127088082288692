<template>
  <v-btn color="primary" text @click="makePdf">PDF</v-btn>
</template>

<script>
import { makePdfDoc } from '@/services/mixins/makePdfDoc'
export default {
  props: {
    pdfData: {
      type: Object,
      required: true
    }
  },
  methods: {
    makePdf() {
      makePdfDoc(this.pdfData)
    }
  }
}
</script>
