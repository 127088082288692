<template>
  <v-card flat>
    <v-card-subtitle>
      Image
    </v-card-subtitle>
    <v-img
      v-if="item.url"
      :src="item.url"
      contain
      max-height="300"
      max-width="300"
      class="mx-auto"
    ></v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
