import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { colors } from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#00a999',
        secondary: '#8c9efe',
        // secondary: "#464fcf",
        accent: '#8c9eff',
        error: '#b71c1c',
        bar: '#434343',
        background: colors.grey.lighten4
      },
      dark: {
        primary: '#00a999',
        secondary: '#8c9efe',
        // secondary: "#464fcf",
        accent: '#8c9eff',
        error: '#b71c1c',
        bar: colors.grey.darken4,
        background: '#121212'
      }
    }
  }
})
