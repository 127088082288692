<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-1">
        <span class="text-truncate">
          View {{ $store.state.global.pages.plan.titleSingular }} line -
          <span class="pl-1 subtitle-1">
            ID:
            <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pb-3 pt-3">
      <v-row class="subtitle-1">
        <Detail
          :icon="$store.state.global.pages.destination.icon"
          :text="item.destination.name"
          cols="12"
          sm="6"
          :tooltip="destinationTooltip"
        />
        <v-col
          cols="12"
          sm="6"
          :class="$vuetify.breakpoint.smAndUp ? 'd-flex justify-end' : null"
        >
          <span>
            <v-icon class="pr-1">
              {{ $store.state.global.pages.itemType.icon }}
            </v-icon>
            {{ item.itemType.name }} Qty: {{ item.qty }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="pb-0 pt-3">
      <v-row>
        <v-col cols="12" sm="6" v-if="item.driver">
          <span class="text-truncate">
            <v-icon class="pr-1">
              {{ $store.state.global.pages.driver.icon }}
            </v-icon>
            {{ item.driver.name }}
          </span>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          v-if="item.vehicle"
          :class="$vuetify.breakpoint.smAndUp ? 'd-flex justify-end' : null"
        >
          <span>
            <v-icon class="pr-1">
              {{ $store.state.global.pages.vehicle.icon }}
            </v-icon>
            {{ item.vehicle.name }} - {{ item.vehicle.vehicleType.name }} ({{
              item.vehicle.contractor.name
            }})
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="mt-4"></v-divider>
  </v-card>
</template>

<script>
import Detail from '../../misc/Detail'
export default {
  components: { Detail },
  props: {
    item: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    },
    close: {
      type: Function,
      required: true
    }
  },
  computed: {
    destinationTooltip() {
      const text = `${this.item.destination.addressStreet1} ${this.item.destination.addressCity}`
      const waypointText = this.item.waypointIndex
        ? `Waypoint: ${this.item.waypointIndex}`
        : null
      return waypointText ? `${text} - ${waypointText}` : text
    }
  }
}
</script>
