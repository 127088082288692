import Api from '@/services/api.js'

export const addCompliance = payload => {
  return Api.post('compliances/', payload)
}
export const getCompliances = payload => {
  return Api.post('compliances/get', payload)
}
export const getCompliancesBySubjectId = (id, model) => {
  return Api.get(`compliances/${model}/${id}`)
}
export const updateCompliance = (id, payload) => {
  return Api.put(`compliances/${id}`, payload)
}
export const deleteCompliance = id => {
  return Api.delete(`compliances/${id}`)
}
