<template>
  <span>
    <v-menu bottom left v-model="menu">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon text color="grey">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <BasicListItem
          v-for="key in actionKeys"
          :key="key"
          :title="actions[key].title"
          :color="actions[key].color"
          :icon="actions[key].icon"
          :onClick="actions[key].onClick"
        />
      </v-list>
    </v-menu>
  </span>
</template>

<script>
export default {
  props: {
    actionKeys: {
      type: Array,
      required: true
    },
    actions: {
      type: Object,
      required: true
    },
    confirmUpdateDocketLineField: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      menu: false
    }
  }
}
</script>
