<template>
  <v-card flat class="card-outlined">
    <v-card-text class="pb-0 d-flex justify-space-between align-center">
      <v-row>
        <v-col cols="12" sm="10" class="py-0">
          <MultiActions
            page="approvedBillLines"
            :selectedCount="selectedCount"
            :fieldsToChange="actionFields"
            :exportOptions="exportOptions"
          />
        </v-col>
        <v-col cols="12" sm="2" class="py-0 d-flex justify-end align-center">
          <v-menu
            v-if="userAuthClass"
            bottom
            left
            transition="scale-transition"
            max-height="600px"
            origin="top right"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on }">
              <v-btn text icon color="grey" v-on="on" class="mr-2">
                <v-icon>mdi-check-circle</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="openAddBillsDialog">
                <v-list-item-icon>
                  <v-icon>{{ $store.state.global.pages.bill.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    >Create
                    {{
                      $store.state.global.pages.bill.title
                    }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <ColumnSelector
            :defaultHeaders="defaultHeaders"
            :possibleHeaders="possibleHeaders"
            toolTipText="Column selector"
            :onChanged="val => (defaultHeaders = val)"
          />
        </v-col>
        <SelectAlert
          :selected="selected"
          :selectedAll="selectedAll"
          :totalRecordCount="count"
          :selectAll="() => (selectedAll = true)"
          :unselectAll="() => ((selectedAll = false), (selected = []))"
        />
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="approvedBillLines"
      :loading="loadingApprovedBillLines"
      :options.sync="pagination"
      :server-items-length="count"
      mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [10, 20, 40, 50],
        showFirstLastPage: true
      }"
      v-model="selected"
      show-select
    >
      <template v-slot:no-data>
        No matching records found
      </template>
      <template v-slot:loading>
        Loading records...
      </template>
      <template v-slot:item="{ item, isSelected }">
        <Row
          :headers="headers"
          :item="item"
          :key="item.id"
          showEdit
          :value="isSelected"
          @input="toggleSelected"
          select
          defaultDialog="detailsDialog"
        >
          <template v-slot:delete="{ dialog, close }">
            <DeleteDialog
              :dialog="dialog"
              :page="titleSingular"
              :subject="item"
              :action="deleteApprovedBillLine"
              :close="close"
            />
          </template>
        </Row>
      </template>
      <template
        v-slot:footer[`page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        <div class="text-center mt-1">
          <span v-if="itemsLength">
            {{ `${pageStart}-${pageStop} of ${itemsLength}` }}
          </span>
          <span v-else> - </span>
          <v-progress-linear
            v-if="loadingApprovedBillLines && itemsLength"
            size="15"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <div v-else style="height:5px"></div>
        </div>
      </template>
    </v-data-table>

    <v-card-text
      class="pb-0 d-flex justify-end align-center"
      v-if="approvedBillLines.length"
    >
      <v-row>
        <v-col cols="12" sm="4" md="3">
          <div>
            <div
              class="text-xs-right flex-container-space-between mb-2"
              v-if="totals.docketLineTotal && !!userShowCost"
            >
              <div>
                <span class="text-xs-left grey--text text--darken-1 body-2"
                  >Trip Total:</span
                >
              </div>
              <div class="ml-2">
                <span class="text-xs-right"
                  >${{
                    addCommas(parseFloat(totals.docketLineTotal).toFixed(4))
                  }}</span
                >
              </div>
            </div>
            <div
              class="text-xs-right flex-container-space-between mb-2"
              v-if="totals.approvedTotal && !!userShowCost"
            >
              <div>
                <span class="text-xs-left grey--text text--darken-1 body-2"
                  >Approved:</span
                >
              </div>
              <div class="ml-2">
                <span class="text-xs-right"
                  >${{
                    addCommas(Number(totals.approvedTotal).toFixed(4))
                  }}</span
                >
              </div>
            </div>

            <div
              class="text-xs-right flex-container-space-between"
              v-if="!!totals.outstandingTotal && !!userShowSell"
            >
              <div>
                <span class="text-xs-left grey--text text--darken-1 body-2"
                  >Outstanding:</span
                >
              </div>
              <div class="ml-2">
                <span class="text-xs-right"
                  >${{
                    addCommas(parseFloat(totals.outstandingTotal).toFixed(4))
                  }}</span
                >
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="addBillsDialog" max-width="900px">
      <AddBills
        :dialogOpen="addBillsDialog"
        @close="addBillsDialog = false"
      ></AddBills>
    </v-dialog>
  </v-card>
</template>

<script>
import Store from '@/store/computed/storeHelpers'
import { deleteApprovedBillLine } from '@/services/requests/approvedBillLines'
import getApprovedBillLinesMixin from '../../services/mixins/getTransactionFiles/getApprovedBillLines'
import Headers from './mixins/approvedBillLineHeaders'
import HeaderFilters from '@/services/mixins/headerFilters'
import MultipleRecords from '../../services/mixins/multipleRecords'
import ApprovedBillLineExport from './mixins/approvedBillLineExport'

import Row from '../misc/row/Row'
import AddBills from './AddBills'
import DeleteDialog from '../misc/DeleteDialog'
import ColumnSelector from '../misc/shared/ColumnSelector'
import MultiActions from '../misc/shared/MultiActions'
import SelectAlert from '../misc/shared/SelectAlert'

import { debounce } from 'lodash'

export default {
  mixins: [
    getApprovedBillLinesMixin,
    Headers,
    HeaderFilters,
    MultipleRecords,
    ApprovedBillLineExport
  ],
  components: {
    Row,
    ColumnSelector,
    MultiActions,
    SelectAlert,
    AddBills,
    DeleteDialog
  },
  props: {
    page: String
  },
  data() {
    return {
      color: 'blue',
      searchTerm: '',
      loading: false,
      fieldChooserMenu: false,
      toolsMenu: false,
      addBillsDialog: false,
      totals: {},
      count: 0
    }
  },
  mounted() {
    this.getApprovedBillLines()
  },
  beforeDestroy() {
    this.approvedBillLines = []
    this.selected = []
  },
  computed: {
    actionFields() {
      const authLimitedActions = [
        {
          text: 'Delete Selected',
          icon: 'mdi-delete',
          color: 'red',
          value: { value: null, text: 'Delete Selected', label: 'delete' },
          action: async () => {
            await this.deleteMultipleRecords({
              ids: this.selected.map(({ id }) => id),
              table: 'approvedBillLines',
              query: this.selectedAll ? this.buildQuery() : null
            })
          }
        }
      ]
      let actions = [
        {
          text: 'Export Selected',
          icon: 'mdi-file-export',
          color: 'grey',
          value: { value: null, text: 'Export Selected', label: 'export' }
        }
      ]
      if (this.userAuthClass) actions = [...actions, ...authLimitedActions]
      return actions
    },

    approvedBillLines: Store.getSet({
      store: 'approvedBillLines',
      prop: 'approvedBillLines'
    }),
    filterBy: Store.getSet({ store: 'approvedBillLines', prop: 'filterBy' }),
    filterCol: Store.getSet({ store: 'approvedBillLines', prop: 'filterCol' }),
    dates: Store.getSet({ store: 'approvedBillLines', prop: 'dates' }),
    selected: Store.getSet({ store: 'approvedBillLines', prop: 'selected' }),
    billingPeriod: Store.getSet({
      store: 'global',
      prop: 'selectedBillingPeriod'
    }),
    billingStatus: Store.getSet({
      store: 'global',
      prop: 'selectedBillingStatus'
    }),
    showClosed: Store.getSet({ store: 'global', prop: 'showClosed' }),
    projectId: Store.getSet({ store: 'global', prop: 'selectedProjectId' }),
    sourceId: Store.getSet({ store: 'global', prop: 'selectedSourceId' }),
    selectedUserId: Store.getSet({ store: 'global', prop: 'selectedUserId' }),
    dateRangeStart: Store.getSet({ store: 'global', prop: 'dateRangeStart' }),
    dateRangeEnd: Store.getSet({ store: 'global', prop: 'dateRangeEnd' }),
    showCreated: Store.getSet({ store: 'global', prop: 'showCreated' }),
    selectedAll: Store.getSet({
      store: 'approvedBillLines',
      prop: 'selectedAll'
    }),
    selectedCount() {
      if (this.selectedAll) {
        return this.pagination.totalItems
      } else {
        return this.selected.length
      }
    },
    headers() {
      var actions = {
        text: 'Actions',
        align: 'center',
        value: '',
        sortable: false,
        colData: '',
        type: 'action'
      }

      var tempArray = this.filterHeaders()
      tempArray.push(actions)
      return tempArray
    }
  },
  watch: {
    approvedBillLines() {
      this.selected = []
      this.selectedAll = false
    },
    billingPeriod() {
      this.getLinesFromPage1()
    },
    billingStatus() {
      this.getLinesFromPage1()
    },
    showClosed() {
      this.getLinesFromPage1()
    },
    showCreated() {
      this.getLinesFromPage1()
    },
    projectId() {
      this.getLinesFromPage1()
    },
    siteId() {
      this.getLinesFromPage1()
    },
    selectedUserId() {
      this.getLinesFromPage1()
    },
    dateRangeStart() {
      this.getLinesFromPage1()
    },
    dateRangeEnd() {
      this.getLinesFromPage1()
    },
    filterBy: debounce(function() {
      this.getLinesFromPage1()
    }, 500)
  },
  methods: {
    async getLinesFromPage1() {
      this.pagination.page = 1
      this.loadingApprovedBillLines = true
      await this.getApprovedBillLines()
      this.loadingApprovedBillLines = false
    },
    openAddBillsDialog() {
      const selectedPeriodIsOpen = !!this.$store.state.billingPeriods.openBillingPeriods.find(
        billingPeriod => billingPeriod === this.billingPeriod
      )
      const messageText =
        !!this.billingPeriod && !selectedPeriodIsOpen
          ? `Billing period ${this.billingPeriod} is closed - please select an open billing period`
          : 'You must select an open billing period and a project!'
      if (!this.projectId || !this.billingPeriod || !selectedPeriodIsOpen) {
        this.snack({
          color: 'amber',
          text: messageText
        })
        return
      } else {
        this.addBillsDialog = true
      }
    },
    //ADDS OR REMOVES OBJECTS FROM THE SELECTED ARRAY, DEPENDING ON WHETHER TRUE OR FALSE WAS EMMITED FROM TRIPROW COMPONENT
    toggleSelected(val) {
      if (val.value) {
        this.selected.push(val.object)
      } else {
        var index = this.selected.indexOf(val.object)
        this.selected.splice(index, 1)
      }
    },
    async deleteApprovedBillLine(approvedBillLine) {
      try {
        this.loading = true
        const result = await deleteApprovedBillLine(approvedBillLine.id)
        if (result) {
          this.$store.commit('approvedBillLines/deleteFromArrayStateByValue', {
            prop: 'approvedBillLines',
            value: approvedBillLine
          })
          this.snackDeleted()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    }
  }
}
</script>
