export default {
  computed: {
    filteredItemTypes() {
      if (!this.globalSettings.limitItemTypes) return this.itemTypes
      if (this.destinationId) {
        return this.itemTypes.filter(record =>
          record.allowedDestinations.some(
            destinationId => destinationId == this.destinationId
          )
        )
      }
      return []
    },
    filteredDestinations() {
      if (!this.globalSettings.limitDestinations) return this.destinations
      if (!this.source?.projectId) return []
      return this.destinations.filter(record => {
        return record.allowedProjects.some(
          projectId => projectId == this.source.projectId
        )
      })
    }
  }
}
