import Rules from '@/services/validation/rules/rules'
export default {
  data() {
    return {
      rules: {
        projectRules: [v => Rules.isRequired(v)],
        siteRules: [],
        tipRules: [],
        spoilRules: [],
        vehicleTypeRules: []
      },
      altRateTripsRules: [v => Rules.isRequiredWithZero(v)],
      priceRules: [v => Rules.isRequiredWithZero(v)],
      adminFeePercentageRules: [
        v => Rules.isRequiredWithZero(v),
        v => Rules.isNumber(v),
        v => Rules.maxLength(v, 5)
      ],
      notesRules: [v => Rules.maxLength(v, 254)]
    }
  }
}
