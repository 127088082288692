<template>
  <v-container
    fluid
    v-if="$store.state.user.userAuthClass[`${$route.name}Read`]"
  >
    <v-row>
      <v-col cols="12" class="py-0">
        <ControlCard
          :addDialog="addDialog"
          :toggleAdd="toggleAdd"
          :toggleDrawer="() => (drawer = !drawer)"
          :filterCols="filterCols"
          :filterCol="filterCol"
          :filterBy="filterBy"
          :lineView="lineView"
          :onFilterColChange="val => (filterCol = val)"
          :onFilterByChange="val => (filterBy = val)"
        >
          <template v-slot:header>
            <CardHeader
              :title="title"
              :icon="$store.state.global.pages[$route.name].icon"
              :actions="controlActions"
            />
          </template>
        </ControlCard>
      </v-col>
      <v-col cols="12" v-if="loadedUserSettings">
        <DocketView
          v-if="!lineView && componentLoaded"
          :openCredit="openCredit"
          :projects="projectsLite"
        />
        <DocketLineView
          v-if="lineView && componentLoaded"
          :openCredit="openCredit"
          :projects="projectsLite"
        />
      </v-col>
    </v-row>

    <FilterDrawer
      v-model="drawer"
      :lineView="lineView"
      :users="usersLite"
      :contractors="contractorsLite"
      :sources="sourcesLite"
      :vehicles="vehiclesLite"
      :drivers="driversLite"
      :destinations="destinationsLite"
      :projects="projectsLite"
    />

    <v-dialog v-model="addDialog" fullscreen>
      <DocketAdd
        :toggleAdd="toggleAdd"
        :clearCredit="clearCredit"
        :sources="sourcesLiteActive"
        :vehicles="vehiclesLiteActive"
        :drivers="driversLiteActive"
        :destinations="destinationsLite"
        :itemTypes="itemTypesLite"
        :docketToCredit="docketToCredit"
        :isCredit="isCredit"
      />
    </v-dialog>
    <v-dialog v-model="importDialog" width="900px">
      <ImportCsv
        :title="`Import ${$store.state.global.pages.docket.title}`"
        :close="() => (importDialog = false)"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Store from '../../store/computed/storeHelpers'
import getUsersLite from '../../services/mixins/getMasterFiles/lite/getUsersLite'
import getContractorsLite from '../../services/mixins/getMasterFiles/lite/getContractorsLite'
import getProjectsLite from '../../services/mixins/getMasterFiles/lite/getProjectsLite'
import getSourcesLite from '../../services/mixins/getMasterFiles/lite/getSourcesLite'
import getVehiclesLite from '../../services/mixins/getMasterFiles/lite/getVehiclesLite'
import getDriversLite from '../../services/mixins/getMasterFiles/lite/getDriversLite'
import getDestinationsLite from '../../services/mixins/getMasterFiles/lite/getDestinationsLite'
import getItemTypesLite from '../../services/mixins/getMasterFiles/lite/getItemTypesLite'
import getBillingPeriods from '../../services/mixins/getMasterFiles/getBillingPeriods'

import ControlCard from '../../components/misc/shared/ControlCard'
import CardHeader from '../../components/misc/shared/CardHeader'
import DocketView from '@/components/dockets/DocketView'
import DocketLineView from '../../components/dockets/DocketLineView'
import DocketAdd from '@/components/dockets/DocketAdd'
import FilterDrawer from '../../components/misc/shared/FilterDrawer'
import ImportCsv from '../../components/misc/shared/importCsv'

export default {
  mixins: [
    getUsersLite,
    getContractorsLite,
    getSourcesLite,
    getVehiclesLite,
    getDriversLite,
    getDestinationsLite,
    getItemTypesLite,
    getProjectsLite,
    getBillingPeriods
  ],
  components: {
    ControlCard,
    CardHeader,
    DocketView,
    DocketLineView,
    DocketAdd,
    FilterDrawer,
    ImportCsv
  },
  data() {
    return {
      drawer: false,
      addDialog: false,
      importDialog: false,
      docketToCredit: null,
      isCredit: false,
      componentLoaded: false
    }
  },
  mounted() {
    this.setDocId()
  },
  watch: {
    addDialog() {
      if (!this.addDialog) this.clearCredit()
    }
  },
  computed: {
    loadedUserSettings: Store.getSet({
      store: 'global',
      prop: 'loadedUserSettings'
    }),
    lineView: Store.getSet({ store: 'dockets', prop: 'lineView' }),
    filterCol: Store.getSet({ store: 'dockets', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'dockets', prop: 'filterBy' }),
    lineViewIcon() {
      return this.lineView ? 'mdi-view-list' : 'mdi-view-list-outline'
    },
    title() {
      if (this.lineView) {
        return `${this.titleSingular} Lines`
      } else {
        return this.$store.state.global.pages[this.$route.name].title
      }
    },
    lineViewTooltipText() {
      if (this.lineView) {
        return `${this.titleSingular}s`
      } else {
        return `${this.titleSingular} Lines`
      }
    },
    controlActions() {
      const authLimitActions = [
        {
          action: () => (this.importDialog = !this.importDialog),
          text: 'Import CSV',
          icon: 'mdi-paperclip',
          toolTipText: 'Import CSV',
          color: 'primary'
        }
      ]
      let actions = [
        {
          action: () => (this.lineView = !this.lineView),
          icon: this.lineViewIcon,
          toolTipText: this.lineViewTooltipText
        },
        {
          action: () => (this.drawer = !this.drawer),
          icon: 'mdi-filter-variant-plus',
          toolTipText: 'Filters'
        }
      ]
      if (this.userAuthClass) actions = [...authLimitActions, ...actions]
      return actions
    },
    filterCols() {
      const cols = this.lineView
        ? [
            {
              value: 'docket.source.name',
              text: this.$store.state.global.pages.source.titleSingular
            },
            {
              value: 'docket.vehicle.contractor.name',
              text: this.$store.state.global.pages.contractor.titleSingular
            },
            {
              value: 'docket.driver.name',
              text: this.$store.state.global.pages.driver.titleSingular
            },
            {
              value: 'docket.vehicle.name',
              text: this.$store.state.global.pages.vehicle.titleSingular
            },
            {
              value: 'docket.vehicle.vehicleType.name',
              text: this.$store.state.global.pages.vehicleType.titleSingular
            },
            {
              value: 'docket.id',
              text: `${this.$store.state.global.pages.docket.titleSingular} ID`
            },
            {
              value: 'allocationLine.destination.runCode',
              text: 'Run Number'
            }
          ]
        : [
            {
              value: 'source.name',
              text: this.$store.state.global.pages.source.titleSingular
            },
            {
              value: 'vehicle.contractor.name',
              text: this.$store.state.global.pages.contractor.titleSingular
            },
            {
              value: 'driver.name',
              text: this.$store.state.global.pages.driver.titleSingular
            },
            {
              value: 'vehicle.name',
              text: this.$store.state.global.pages.vehicle.titleSingular
            },
            {
              value: 'vehicle.vehicleType.name',
              text: this.$store.state.global.pages.vehicleType.titleSingular
            },
            {
              value: 'docket.id',
              text: `${this.$store.state.global.pages.docket.titleSingular} ID`
            }
          ]
      return cols
    }
  },
  methods: {
    toggleAdd() {
      if (this.licenceExpired) {
        this.snack({
          text: `Your account is on hold. This feature has been disabled`,
          color: 'red'
        })
        return
      }
      this.addDialog = !this.addDialog
    },
    clearCredit() {
      this.docketToCredit = null
      this.isCredit = false
    },
    setDocId() {
      if (this.$route.query.id) {
        this.filterCol = 'docket.id'
        this.filterBy = this.$route.query.id.toString()
      }
      this.componentLoaded = true
    },
    openCredit(docket) {
      this.docketToCredit = docket
      this.isCredit = true
      this.addDialog = !this.addDialog
    }
  }
}
</script>
