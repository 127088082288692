<template>
  <MglPopup :closeButton="false">
    <v-list dense class="py-0">
      <v-list-item>
        <v-list-item-icon class="mr-4">
          <v-avatar
            :color="item.driver.color"
            class="mt-0"
            :style="`border: 2px solid ${item.driver.color};`"
          >
            <span class="white--text headline">{{
              item.driver.name.charAt(0)
            }}</span>
          </v-avatar>
          <!-- <v-icon :color="item.color">mdi-account</v-icon> -->
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            >{{ item.driver.name }} <br />
            {{ item.vehicle.name }}</v-list-item-title
          >
          <v-list-item-subtitle>
            <div v-if="item.allocation">
              {{
                `${$store.state.global.pages.allocation.title.slice(
                  0,
                  -1
                )} ID: ${item.allocation.id}`
              }}
            </div>
            <div>
              {{
                `${$store.state.global.pages.docket.title.slice(0, -1)} ID: ${
                  item.id
                }`
              }}
            </div>
            <div class="pt-2" v-if="item.speed">
              Speed:
              {{ (item.speed >= 0 ? item.speed * 3.6 : 0).toFixed(0) }}
              km/h
            </div>
            <div v-if="item.direction">Heading: {{ item.direction }}</div>
            <div v-if="item.distance">
              {{ item.distance.toFixed(2) }}km travelled
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <MapPopupButton text="ROUTE" :action="action" :loading="loading" />
    </v-list>
  </MglPopup>
</template>

<script>
import { MglPopup } from 'vue-mapbox'
import MapPopupButton from './MapPopupButton'

export default {
  components: {
    MglPopup,
    MapPopupButton
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style></style>
