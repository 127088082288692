<template>
  <v-container fluid v-if="$store.state.user.userAuthClass[`billRead`]">
    <v-row>
      <v-col cols="12" class="py-0">
        <ControlCard
          :addDialog="importDialog"
          :toggleAdd="toggleImport"
          :toggleDrawer="() => (drawer = !drawer)"
          :filterCols="filterCols"
          :filterCol="filterCol"
          :filterBy="filterBy"
          :onFilterColChange="val => (filterCol = val)"
          :onFilterByChange="val => (filterBy = val)"
        >
          <template v-slot:header>
            <CardHeader
              :title="$store.state.global.pages[$route.name].title"
              :icon="$store.state.global.pages[$route.name].icon"
              :actions="controlActions"
              :actionBtn="{
                text: $store.state.global.pages.bill.title,
                onClick: () => $router.push({ name: 'bill' })
              }"
            />
          </template>
        </ControlCard>
      </v-col>
      <v-col cols="12" v-if="loadedUserSettings">
        <template>
          <ApprovedBillLineView />
        </template>
      </v-col>
    </v-row>

    <FilterDrawer
      v-model="drawer"
      :users="usersLite"
      :projects="projectsLite"
      :sources="sourcesLite"
    />

    <v-dialog v-model="importDialog" width="900px">
      <ImportApprovedBillLines
        :title="`Import ${$store.state.global.pages[$route.name].title} Lines`"
        :close="() => (importDialog = false)"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Store from '../../store/computed/storeHelpers'
import getUsersLite from '../../services/mixins/getMasterFiles/lite/getUsersLite'
import getProjectsLite from '../../services/mixins/getMasterFiles/lite/getProjectsLite'
import getSourcesLite from '../../services/mixins/getMasterFiles/lite/getSourcesLite'
import getBillingPeriods from '../../services/mixins/getMasterFiles/getBillingPeriods'

import ApprovedBillLineView from '../../components/approvedBillLines/ApprovedBillLineView'
import ImportApprovedBillLines from '../../components/approvedBillLines/ImportApprovedBillLines'
import ControlCard from '../../components/misc/shared/ControlCard'
import CardHeader from '../../components/misc/shared/CardHeader'
import FilterDrawer from '../../components/misc/shared/FilterDrawer'
export default {
  mixins: [getProjectsLite, getUsersLite, getSourcesLite, getBillingPeriods],
  components: {
    ApprovedBillLineView,
    ImportApprovedBillLines,
    ControlCard,
    CardHeader,
    FilterDrawer
  },
  data() {
    return {
      drawer: false,
      addDialog: false,
      importDialog: false,
      addMoreDialog: false
    }
  },
  mounted() {
    this.setDocId()
  },
  computed: {
    loadedUserSettings: Store.getSet({
      store: 'global',
      prop: 'loadedUserSettings'
    }),
    filterCol: Store.getSet({ store: 'approvedBillLines', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'approvedBillLines', prop: 'filterBy' }),
    controlActions() {
      return [
        {
          action: () => (this.drawer = !this.drawer),
          icon: 'mdi-filter-variant-plus',
          toolTipText: 'Filter and Settings',
          color: 'grey'
        }
      ]
    },
    filterCols() {
      const cols = [
        {
          value: 'docketLine.docket.source.project.name',
          text: this.$store.state.global.pages.project.titleSingular
        },
        {
          value: 'createdBy.name',
          text: 'Entered By'
        },
        {
          value: 'customerClaimRef',
          text: 'Customer claim reference'
        },
        {
          value: 'notes',
          text: 'Notes'
        }
      ]
      return cols
    }
  },
  methods: {
    toggleImport() {
      this.importDialog = !this.importDialog
    },
    setDocId() {
      if (this.$route.query.id) {
        this.filterCol = 'approvedBillLine.id'
        this.filterBy = this.$route.query.id.toString()
      }
    }
  }
}
</script>
