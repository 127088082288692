import Store from '@/store/computed/storeHelpers'
import PlanAllHeaders from './planAllHeaders'
export default {
  mixins: [PlanAllHeaders],
  computed: {
    defaultHeaders: Store.getSet({
      store: 'plans',
      prop: 'planDefaultHeaders'
    }),
    allPossibleHeaders() {
      return this.allPlanHeaders
    }
  },
  data() {
    return {
      mobileHeaders: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$store.state.global.pages.source.titleSingular,
          value: 'source.name'
        }
      ]
    }
  }
}
