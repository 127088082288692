import Store from '@/store/computed/storeHelpers'
import { getDockets } from '../../requests/dockets'
import Docket from '@/services/models/docket'
export default {
  mounted() {
    this.getDockets()
  },
  data() {
    return {
      pagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: []
      },
      count: 0,
      initPagination: false
    }
  },
  computed: {
    dockets: Store.getSet({ store: 'dockets', prop: 'dockets' }),
    loadingDockets: Store.getSet({
      store: 'dockets',
      prop: 'loadingDockets'
    })
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        if (this.initPagination) {
          this.getDockets()
        } else {
          this.initPagination = true
        }
      }
    }
  },
  methods: {
    async getDockets() {
      try {
        this.loadingDockets = true
        const payload = this.buildQuery()
        const result = await getDockets({
          ...payload,
          pagination: this.pagination
        })
        if (result) {
          const items = result.data.result
          this.dockets = items.map(item => new Docket(item))
          this.count = result.data.count
        }
        this.loadingDockets = false
      } catch (err) {
        console.log(err)
        this.loadingDockets = false
      }
    },
    buildQuery() {
      return {
        userId: this.$store.state.global.selectedUserId,
        showClosed: this.$store.state.global.showClosed,
        showAutoCreated: this.$store.state.global.showAutoCreated,
        billingPeriod: this.$store.state.global.selectedBillingPeriod,
        projectId: this.$store.state.global.selectedProjectId,
        sourceId: this.$store.state.global.selectedSourceId,
        contractorId: this.$store.state.global.selectedContractorId,
        driverId: this.$store.state.global.selectedDriverId,
        vehicleId: this.$store.state.global.selectedVehicleId,
        dateRangeStart: this.$store.state.global.dateRangeStart,
        dateRangeEnd: this.$store.state.global.dateRangeEnd,
        filterCol: this.$store.state.dockets.filterCol,
        filterBy: this.$store.state.dockets.filterBy
      }
    }
  }
}
