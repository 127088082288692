import Store from '@/store/computed/storeHelpers'
import { getDriversLite } from '../../../requests/drivers'
export default {
  mounted() {
    this.getDriversLite()
  },
  data() {
    return {
      loadedDriversLite: false
    }
  },
  computed: {
    driversLite: Store.getSet({ store: 'drivers', prop: 'driversLite' }),
    driversLiteActive: Store.getSet({
      store: 'drivers',
      prop: 'driversLiteActive'
    }),
    loadingDriversLite: Store.getSet({
      store: 'drivers',
      prop: 'loadingDriversLite'
    })
  },
  methods: {
    async getDriversLite() {
      try {
        this.loadingDriversLite = true
        const result = await getDriversLite()
        if (result) {
          this.driversLite = result.data.result
          this.driversLiteActive = result.data.result.filter(
            i => i.active && i.contractor.active
          )
        }
        this.loadedDriversLite = true
        this.loadingDriversLite = false
      } catch (err) {
        console.log(err)
        this.loadingDriversLite = false
      }
    }
  }
}
