import Api from '@/services/api.js'

// * USERS
export const addUser = payload => {
  return Api.post('users/register', payload)
}
export const getUsers = payload => {
  return Api.post('users/get', payload)
}
export const getUsersLite = () => {
  return Api.get('users/')
}
export const checkLoginExists = login => {
  return Api.get(`users/check/${login}`)
}
export const updateUser = (id, payload) => {
  return Api.put(`users/${id}`, payload)
}
export const updateUserPassword = (id, payload) => {
  return Api.put(`users/password/${id}`, payload)
}
export const deleteUser = id => {
  return Api.delete(`users/${id}`)
}

// * USER AUTH CLASSES
export const addUserAuthClass = payload => {
  return Api.post('users/userAuthClass', payload)
}
export const getUserAuthClasses = () => {
  return Api.get('users/userAuthClass')
}
export const updateUserAuthClass = (id, payload) => {
  return Api.put(`users/userAuthClass/${id}`, payload)
}
export const deleteUserAuthClass = id => {
  return Api.delete(`users/userAuthClass/${id}`)
}
