<template>
  <v-card>
    <v-card-text class="pb-0">
      <v-card-title class="px-0 pb-1 font-weight-regular">
        VOID {{ text }}
      </v-card-title>
    </v-card-text>
    <v-card-text>
      <v-text-field label="VOID notes" v-model="voidNotes"></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text color="grey darken-1" @click="close">Cancel</v-btn>
      <v-btn
        text
        color="red darken-1"
        :loading="loading"
        @click="voidSubject(subject)"
      >
        VOID
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'voidDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    page: {
      type: String,
      required: true
    },
    subject: {
      type: Object,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      voidNotes: null
    }
  },
  computed: {
    text() {
      let text
      if (this.subject.name) {
        text = this.subject.name
      } else if (this.subject.id) {
        text = `${this.page} (${this.subject.id})`
      } else {
        text = this.page
      }
      return text
    }
  },
  methods: {
    async voidSubject(subject) {
      console.log(subject)
      await this.action(subject.id, this.voidNotes)
      this.close()
    }
  }
}
</script>
