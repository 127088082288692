<template>
  <tr
    style="cursor: pointer;"
    :class="rowClasses"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="rowClick"
    loading
  >
    <td @click.stop v-if="select">
      <v-checkbox
        v-model="selected"
        color="primary"
        hide-details
        class="mt-0 pt-0"
      ></v-checkbox>
    </td>

    <td v-for="(header, i) in headers" :key="i" :class="`text-${header.align}`">
      <Column
        :header="header"
        :item="item"
        :actions="actions"
        :actionKeys="actionKeys"
      />
    </td>

    <v-dialog
      v-model="detailsDialog"
      @click:outside="closeDetails"
      @keydown.esc="closeDetails"
      :max-width="!detailsDialogFullscreen ? '900px' : ''"
      :fullscreen="detailsDialogFullscreen"
    >
      <slot
        name="details"
        :dialog="detailsDialog"
        :close="closeDetails"
        :open="() => (detailsDialog = true)"
        :openEditDialog="() => (editDialog = true)"
      ></slot>
    </v-dialog>

    <v-dialog
      v-model="podDetailsDialog"
      @click:outside="closeDetails"
      @keydown.esc="closeDetails"
      :max-width="$vuetify.breakpoint.smAndUp ? '900px' : null"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <slot
        name="podDetails"
        :dialog="podDetailsDialog"
        :close="closeDetails"
        :open="() => (podDetailsDialog = true)"
        :openEditDialog="() => (editDialog = true)"
      ></slot>
    </v-dialog>

    <v-dialog
      v-model="editDialog"
      @click:outside="() => (editDialog = false)"
      @keydown.esc="() => (editDialog = false)"
      :max-width="!editDialogFullscreen ? '1000px' : ''"
      :fullscreen="editDialogFullscreen"
    >
      <slot
        name="edit"
        :dialog="editDialog"
        :close="() => (editDialog = false)"
      ></slot>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      @click:outside="() => (deleteDialog = false)"
      @keydown.esc="() => (deleteDialog = false)"
      max-width="500px"
    >
      <slot
        name="delete"
        :dialog="deleteDialog"
        :close="() => (deleteDialog = false)"
      ></slot>
    </v-dialog>

    <v-dialog
      v-model="voidDialog"
      @click:outside="() => (voidDialog = false)"
      @keydown.esc="() => (voidDialog = false)"
      max-width="500px"
    >
      <slot
        name="void"
        :dialog="voidDialog"
        :close="() => (voidDialog = false)"
      ></slot>
    </v-dialog>
  </tr>
</template>

<script>
import CreatedUpdatedRows from '../../../services/mixins/createdUpdatedRows'
import models from '../../compliances/mixins/models'
import RowActions from './mixins/rowActions'

import Column from './columns/column'

export default {
  mixins: [CreatedUpdatedRows, models, RowActions],
  components: {
    Column
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: false
    },
    headers: {
      type: Array,
      required: true
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    showDeliveryDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    select: {
      type: Boolean,
      required: false,
      default: false
    },
    closeParent: {
      type: Function,
      required: false
    },
    selectedId: {
      type: Number,
      required: false
    },
    defaultDialog: {
      type: String,
      required: false,
      validator: val =>
        ['detailsDialog', 'podDetailsDialog', 'editDialog'].includes(val)
    },
    editDialogFullscreen: {
      type: Boolean,
      required: false,
      default: false
    },
    detailsDialogFullscreen: {
      type: Boolean,
      required: false,
      default: false
    },
    createDocketFromAllocation: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      prefix: 'item',
      detailsDialog: false,
      podDetailsDialog: false,
      addDialog: false,
      editDialog: false,
      deleteDialog: false,
      voidDialog: false
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', { value: val, object: this.item })
      }
    }
  },
  methods: {
    showDocument(route, id) {
      this.$router.push({ name: route, query: { id: id } })
    },
    showLocation(item) {
      const addressString = `${item.addressStreet1} ${item.addressCity} ${item.addressState}`
        .trim()
        .replace(/\s/g, '+')
      const url = `https://www.google.com/maps/search/?api=1&query=${addressString}`
      window.open(url)
    },
    closeDetails() {
      this.detailsDialog = false
      this.podDetailsDialog = false
    },
    rowClick() {
      if (this.defaultDialog) this[this.defaultDialog] = true
    }
  }
}
</script>
