import Api from '@/services/api.js'

export const addDriver = payload => {
  return Api.post('drivers/', payload)
}
export const getDrivers = payload => {
  return Api.post('drivers/get', payload)
}
export const getDriversLite = () => {
  return Api.get('drivers/')
}
export const checkLoginExists = login => {
  return Api.get(`drivers/check/${login}`)
}
export const updateDriver = (id, payload) => {
  return Api.put(`drivers/${id}`, payload)
}
export const updateDriverPassword = (id, payload) => {
  return Api.put(`drivers/password/${id}`, payload)
}
export const deleteDriver = id => {
  return Api.delete(`drivers/${id}`)
}
