import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    vehicleTypes: [],
    loadingVehicleTypes: false
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
