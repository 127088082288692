<template>
  <v-card>
    <v-card-text class="pb-0">
      <v-card-title class="px-0 pb-1 font-weight-regular">
        Create {{ $store.state.global.pages.allocation.title }}
      </v-card-title>
      Are you sure you want to create
      {{ $store.state.global.pages.allocation.title.toLowerCase() }} from this
      {{ $store.state.global.pages.plan.titleSingular.toLowerCase() }}?
      <div>
        {{ totalAllocations }}
        {{ $store.state.global.pages.allocation.title }} will be created
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text color="grey darken-1" @click="close">Cancel</v-btn>
      <v-btn text color="primary" :loading="loading" @click="action()"
        >Create</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    close: Function,
    action: Function,
    totalAllocations: Number,
    loading: Boolean
  }
}
</script>
