<template>
  <div v-if="currentDestination">
    <div>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <div v-on="on" @click="$emit('toggleAddress')" class="text-truncate">
            <v-icon>mdi-chevron-right</v-icon>
            <span v-if="!editedAddress">
              {{ currentAddress.addressStreet1 }}
              {{ currentAddress.addressStreet2 }},
              {{ currentAddress.addressCity }}
              {{ currentAddress.addressState }}
            </span>
            <span v-else>
              {{ editedAddress.addressStreet1 }}
              {{ editedAddress.addressStreet2 }},
              {{ editedAddress.addressCity }}
              {{ editedAddress.addressState }}
            </span>
          </div>
        </template>
        <span>{{ !editAddress ? 'Edit delivery address' : 'Close' }}</span>
      </v-tooltip>
    </div>
    <div v-if="editAddress">
      <span>
        <GoogleAutocompleteCustom
          :id="`addressAutocomplete-${destinationId}`"
          :onChanged="getAddressData"
        />
      </span>
    </div>
  </div>
</template>

<script>
import GoogleAutocompleteCustom from '../misc/GoogleAutocompleteCustom'

export default {
  components: { GoogleAutocompleteCustom },
  props: {
    destinationId: {
      type: Number,
      required: false
    },
    originalDestinationId: {
      type: Number,
      required: false
    },
    currentItem: {
      type: Object,
      required: false
    },
    editAddress: {
      type: Boolean,
      required: true
    },
    destinations: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      address: {},
      editedAddress: null
    }
  },
  computed: {
    currentAddress() {
      return this.originalDestinationId == this.destinationId
        ? {
            addressStreet1: this.currentItem?.addressStreet1,
            addressStreet2: this.currentItem?.addressStreet2,
            addressCity: this.currentItem?.addressCity,
            addressState: this.currentItem?.addressState,
            addressPostCode: this.currentItem?.addressPostCode
          }
        : {
            addressStreet1: this.currentDestination.addressStreet1,
            addressStreet2: this.currentDestination.addressStreet2,
            addressCity: this.currentDestination.addressCity,
            addressState: this.currentDestination.addressState,
            addressPostCode: this.currentDestination.addressPostCode
          }
    },
    currentDestination() {
      return this.destinations.find(item => item.id == this.destinationId)
    }
  },
  methods: {
    getAddressData(place) {
      let address1 = '',
        address2,
        city,
        state,
        postCode
      for (const component of place.address_components) {
        const type = component.types[0]
        if (type === 'subpremise') address1 += `${component.long_name}/`
        if (type === 'street_number') address1 += `${component.long_name} `
        if (type === 'route') address1 += component.long_name
        if (type === 'locality') city = component.long_name
        if (type === 'administrative_area_level_1') state = component.short_name
        if (type === 'postal_code') postCode = component.long_name
      }
      this.editedAddress = {
        addressStreet1: address1,
        addressCity: city,
        addressState: state,
        addressPostCode: postCode
      }
      const currentDeliveryAddress = {
        addressStreet1: address1 || this.currentDestination.addressStreet1,
        addressStreet2: address2 || this.currentDestination.addressStreet2,
        addressCity: city || this.currentDestination.addressCity,
        addressState: state || this.currentDestination.addressState
      }
      this.$emit('newAddress', currentDeliveryAddress)
    }
  },
  watch: {
    destinationId() {
      this.editedAddress = null
    }
  }
}
</script>
