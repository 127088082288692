import { makeShortDate } from '../../utils/FormatDates'
class Plan {
  constructor(plan) {
    Object.keys(plan).forEach(key => {
      this[key] = plan[key]
      this[`_${key}`] = plan[key]
      const formats = [
        {
          condition: ['date', 'createdAt', 'updatedAt', 'endDate'].includes(
            key
          ),
          format: val => makeShortDate(val)
        },
        {
          condition: ['qty', 'lineHours'].includes(key),
          format: val => Number(val).toFixed(2)
        }
      ]

      formats.forEach(item => {
        if (item.condition && plan[key])
          this[`_${key}`] = item.format(plan[key])
      })
    })
  }

  get createdByName() {
    return this.createdBy ? this.createdBy.name : this.driver.name
  }
  get closed() {
    return this.status === 'CLOSED'
  }
}

export default Plan
