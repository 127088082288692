<template>
  <v-menu
    ref="dateMenu"
    v-model="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
    max-width="290px"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        ref="dateField"
        v-on="on"
        :color="color"
        :rules="!!rules ? rules : dateRules"
        :value="value"
        @input="fieldInput"
        :label="label"
        type="date"
        :disabled="disabled"
        :prepend-icon="!noIcon ? 'mdi-calendar-range' : null"
        clearable
      ></v-text-field>
    </template>
    <v-date-picker
      scrollable
      :color="color"
      :value="value"
      @input="pickerInput"
      full-width
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: String,
    label: String,
    color: String,
    rules: Array,
    noIcon: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      dateMenu: false,
      dateRules: [v => !!v || 'Field is required']
    }
  },
  methods: {
    pickerInput(val) {
      this.$emit('input', val)
      this.dateMenu = false
    },
    fieldInput(val) {
      this.$emit('input', val)
      this.dateMenu = false
    },
    toggleTimePicker() {
      this.dateMenu = !this.dateMenu
    }
  }
}
</script>
