<template>
  <span>
    <span v-if="getObjectPath(value, item)">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" :color="deliveryDetails.color">{{
            deliveryDetails.icon
          }}</v-icon>
        </template>
        <span>{{ getObjectPath(value, item) }}</span>
      </v-tooltip>
    </span>
    <span v-else>-</span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    deliveryDetails() {
      return this.item.deliveryStatus === 'Delivered'
        ? { color: 'green', icon: 'mdi-truck-check' }
        : this.item.deliveryStatus === 'No delivery details'
        ? { color: 'red lighten-2', icon: 'mdi-truck' }
        : this.item.deliveryStatus === 'On-board'
        ? { color: 'amber', icon: 'mdi-truck-fast' }
        : {
            color: 'grey',
            icon: this.$store.state.global.pages.allocation.icon
          }
    }
  }
}
</script>
