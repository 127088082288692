export default {
  computed: {
    actions() {
      const docketCreateTitle = `Create 
        ${this.$store.state.global.pages.docket.titleSingular.toLowerCase()} 
        from ${this.$store.state.global.pages.allocation.titleSingular.toLowerCase()}`
      return {
        createDocket: {
          title: docketCreateTitle,
          color: 'primary',
          icon: this.$store.state.global.pages.allocation.icon,
          onClick: () =>
            this.createDocketFromAllocation(
              this.item.allocation ? this.item.allocation : this.item
            ),
          condition:
            this.userAuthClass &&
            this.$route.name === 'allocation' &&
            this.createDocketFromAllocation &&
            !this.closed &&
            !this.void
        },
        details: {
          title: 'View details',
          color: 'primary',
          icon: this.$store.state.global.pages[this.$route.name].icon,
          onClick: () => {
            if (this.defaultDialog) {
              this[this.defaultDialog] = true
            } else {
              this.detailsDialog = true
            }
          }
        },
        image: {
          title: 'View image',
          color: 'primary',
          icon: 'mdi-eye',
          onClick: () => this.$emit('open-file')
        },
        location: {
          title: 'Show location',
          color: 'primary',
          icon: this.$store.state.global.pages.destination.icon,
          onClick: () => this.showLocation(this.item)
        },
        allocations: {
          title: `Go to ${this.$store.state.global.pages.allocation.titleSingular.toLowerCase()}`,
          color: 'primary',
          icon: this.$store.state.global.pages.allocation.icon,
          onClick: this.onClickAllocations,
          condition:
            (this.$route.name === 'docket' &&
              (this.item.allocation || this.item?.docket?.allocation)) ||
            (this.$route.name === 'pod' && this.item.allocationLine.allocation)
        },
        dockets: {
          title: `Go to ${this.$store.state.global.pages.docket.titleSingular.toLowerCase()}`,
          color: 'primary',
          icon: this.$store.state.global.pages.docket.icon,
          onClick: this.onClickDockets,
          condition:
            (this.$route.name === 'allocation' || this.$route.name === 'pod') &&
            (!!this.item.docketId ||
              !!this.item.allocation?.docketId ||
              !!this.item.allocationLine?.allocation?.docketId)
        },
        pods: {
          title: 'Delivery details',
          color: 'primary',
          icon: this.$store.state.global.pages.destination.icon,
          onClick: () => (this.podDetailsDialog = true),
          condition: !!this.item.lineCount && this.showDeliveryDetails
        },
        map: {
          title: 'Show GPS',
          color: 'primary',
          icon: this.$store.state.global.pages.map.icon,
          onClick: () => this.showDocument('map', this.selectedDocketId),
          condition: this.item.gpsLineCount || this.item.docket?.gpsLineCount
        },
        edit: {
          title: 'Edit',
          icon: 'mdi-pencil',
          onClick: () => (this.editDialog = true),
          condition:
            !this.credit &&
            !this.addition &&
            this.userAuthClass &&
            this.showEdit &&
            this.$route.name != 'pod' &&
            !this.item.isWithDriver &&
            !this.closed
        },
        void: {
          title: 'VOID',
          icon: 'mdi-cancel',
          onClick: () => (this.voidDialog = true),
          condition:
            this.userAuthClass &&
            this.$route.name === 'allocation' &&
            !this.item.void &&
            !this.item.allocationId &&
            !this.item.closed &&
            this.item.createdAt
        },
        delete: {
          title: 'Delete',
          color: 'red lighten-3',
          icon: 'mdi-delete',
          onClick: () => (this.deleteDialog = true),
          condition: this.userAuthClass && this.showEdit && !this.void
        }
      }
    },
    actionKeys() {
      const keys = []
      if (this.actions.createDocket.condition) keys.push('createDocket')
      if (!this.hideDetails) keys.push('details')
      if (this.item.streetAddress1) keys.push('location')
      if (this.actions.allocations.condition) keys.push('allocations')
      if (this.actions.dockets.condition) keys.push('dockets')
      if (this.actions.pods.condition) keys.push('pods')
      if (this.actions.map.condition) keys.push('map')
      if (this.actions.void.condition) keys.push('void')
      if (!this.closed) {
        if (this.actions.edit.condition) keys.push('edit')
        if (this.actions.delete.condition) keys.push('delete')
      }
      return keys
    },
    closed() {
      if (
        this.$route.name === 'allocation' ||
        this.$route.name === 'docket' ||
        this.$route.name === 'bill' ||
        this.$route.name === 'rcti' ||
        this.$route.name === 'plan'
      ) {
        return !!this.item.closed
      } else if (this.$route.name === 'approvedBillLine') {
        return !!this.item.billLineId
      } else return false
    },
    void() {
      if (this.$route.name === 'allocation') {
        return this.item.void || this.item.allocation?.void
      } else {
        return false
      }
    },
    hasDuplicate() {
      if (this.$route.name === 'allocation') {
        return this.item.allocation?.hasDuplicate
      } else {
        return false
      }
    },
    credit() {
      return this.item.type === 'CREDIT' || this.item.docket?.type === 'CREDIT'
    },
    addition() {
      return this.item.type === 'ADDITION'
    },
    selectedDocketId() {
      if (this.item.docket) return this.item.docketId
      else if (this.item.allocation) return this.item.allocation.docketId
      else if (this.item.allocationLine)
        return this.item.allocationLine.allocation.docketId
      else return this.item.id
    },
    isActive() {
      return this.item.isWithDriver
    },
    isUnderReview() {
      return (
        this.item.GPSConfirmed == 'REVIEW' ||
        this.item.tippingDocketConfirmed == 'REVIEW' ||
        this.item.massDeclarationConfirmed == 'REVIEW' ||
        this.item?.allocation?.sourceConfirmed == 'REVIEW' ||
        this.item?.allocation?.destinationConfirmed == 'REVIEW' ||
        this.item?.allocation?.amendmentsConfirmed == 'REVIEW' ||
        this.item.statDecConfirmed == 'REVIEW' ||
        this.item.status == 'REVIEW'
      )
    },
    rowClasses() {
      return {
        'green lighten-5':
          this.isActive && !this.credit && !this.$vuetify.theme.dark,
        'amber lighten-5':
          this.isUnderReview && !this.hasDuplicate && !this.$vuetify.theme.dark,
        'red lighten-5':
          (this.credit || this.hasDuplicate) && !this.$vuetify.theme.dark,
        'blue lighten-5':
          (this.updated || this.created) && !this.$vuetify.theme.dark,
        'grey lighten-4':
          (this.closed || this.void) &&
          !this.$vuetify.theme.dark &&
          this.$route.name !== 'pod',

        'green darken-4':
          this.isActive && !this.credit && this.$vuetify.theme.dark,
        'amber darken-4':
          this.isUnderReview && !this.hasDuplicate && this.$vuetify.theme.dark,
        'blue darken-4':
          (this.created || this.updated) && this.$vuetify.theme.dark,
        'grey darken-3':
          (this.closed || this.void) &&
          this.$vuetify.theme.dark &&
          this.$route.name !== 'pod',
        'red darken-4':
          (this.credit || this.hasDuplicate) && this.$vuetify.theme.dark,
        'primary--text': this.selectedId === this.item.id
      }
    }
  },
  methods: {
    onClickAllocations() {
      let id = null
      if (this.item.allocationLine) {
        id = this.item.allocationLine.allocationId
      } else {
        id = this.item.allocation
          ? this.item.allocation.id
          : this.item.docket.allocation.id
      }
      this.showDocument('allocation', id)
    },
    onClickDockets() {
      let id = null
      if (this.item.allocationLine) {
        id = this.item.allocationLine.allocation.docketId
      } else {
        id = this.item.allocation
          ? this.item.allocation.docketId
          : this.item.docketId
      }
      this.showDocument('docket', id)
    }
  }
}
