export default {
  data() {
    return {
      models: [
        {
          value: 'contractor',
          text: this.$store.state.global.pages.contractor.titleSingular,
          resourceTypes: [
            {
              value: 'compensationInsurance',
              text: 'Workers Compensation Insurance'
            },
            {
              value: 'liabilityInsurance',
              text: 'Public Liability Insurance'
            },
            {
              value: 'plantAndEquipInsurance',
              text: 'Plant & Equipment Insurance'
            }
          ]
        },
        {
          value: 'vehicle',
          text: this.$store.state.global.pages.vehicle.titleSingular,
          nameField: 'vehicleName_vehicleType_contractorName',
          resourceTypes: [
            {
              value: 'registration',
              text: 'Registration Papers'
            },
            {
              value: 'pinkSlip',
              text: '6 Monthly Pink Slip'
            }
          ]
        },
        {
          value: 'driver',
          text: this.$store.state.global.pages.driver.titleSingular,
          nameField: 'driverName_contractorName',
          resourceTypes: [
            {
              value: 'licence',
              text: 'Licence'
            },
            {
              value: 'voc',
              text: 'VOC'
            },
            {
              value: 'soa',
              text: 'Statement of Attainment'
            }
          ]
        }
      ],
      resourceTypes: []
    }
  },
  mounted() {
    this.getResourceTypes()
  },
  methods: {
    getResourceTypes() {
      this.resourceTypes = []
      for (var model of this.models) {
        if (model.value == this.model || !this.model) {
          Array.prototype.push.apply(this.resourceTypes, model.resourceTypes)
        }
      }
    },
    getComplianceTypeName(type) {
      var index = this.searchByAttr(this.resourceTypes, 'value', type)
      const typeDisp = index >= 0 ? this.resourceTypes[index].text : '-'
      return typeDisp
    }
  }
}
