<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span>
          View
          {{
            item.isStaged
              ? `(staged) ${$store.state.global.pages.allocation.titleSingular}`
              : $store.state.global.pages.allocation.titleSingular
          }}
          -
          <span class="pl-1 subtitle-1">
            ID: <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip
        bottom
        v-if="userAuthClass && !item.closed && $route.name == 'allocation'"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="showEditDialog">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span
          >Edit this
          {{
            $store.state.global.pages.allocation.titleSingular.toLowerCase()
          }}</span
        >
      </v-tooltip>
    </v-toolbar>

    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-sheet outlined :color="item.isStaged ? 'amber' : ''" rounded>
            <v-card flat class="card-outlined">
              <v-card-text class="pb-0">
                <CardHeader
                  :title="item.source.project.name"
                  :icon="$store.state.global.pages.allocation.icon"
                />
                <v-container fluid class="px-0">
                  <v-row>
                    <Detail
                      icon="mdi-calendar"
                      :text="dateString"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="$store.state.global.pages.source.icon"
                      :text="item.source.name"
                      :tooltip="$store.state.global.pages.source.titleSingular"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="$store.state.global.pages.contractor.icon"
                      :text="item.vehicle.contractor.name"
                      :tooltip="
                        $store.state.global.pages.contractor.titleSingular
                      "
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="$store.state.global.pages.vehicle.icon"
                      :text="item.vehicle.name"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="$store.state.global.pages.vehicle.icon"
                      :text="
                        `${item.vehicle.vehicleType.name}  (${item.vehicle.vehicleType.tonnage}t)`
                      "
                      :tooltip="
                        $store.state.global.pages.vehicleType.titleSingular
                      "
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="$store.state.global.pages.driver.icon"
                      :text="item.driver.name"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      icon="mdi-test-tube"
                      text="Staged"
                      lg="4"
                      cols="6"
                      v-if="item.isStaged"
                    />
                    <Detail
                      icon="mdi-note"
                      :text="item.notes"
                      v-if="item.notes"
                      lg="4"
                    />
                    <StagedWarning
                      v-if="
                        item.isStaged &&
                          stagedLines.length &&
                          (duplicateVehicleLines.length ||
                            duplicateDriverLines.length)
                      "
                      :duplicateVehicleLines="duplicateVehicleLines"
                      :duplicateDriverLines="duplicateDriverLines"
                    />
                  </v-row>
                </v-container>
              </v-card-text>
              <v-col class="pb-0">
                <v-divider></v-divider>
              </v-col>
              <v-card-actions>
                <v-row v-if="$vuetify.breakpoint.smAndUp">
                  <v-col
                    cols="12"
                    class="py-0"
                    v-if="item.updatedAt !== item.createdAt"
                  >
                    <TimeAgo
                      class="grey--text"
                      :subject="item"
                      :time="item.updatedAt"
                      needsParse
                      updated
                    >
                      <v-icon small color="grey" slot="icon" class="mr-2">
                        mdi-clock-outline
                      </v-icon>
                    </TimeAgo>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <TimeAgo
                      class="grey--text"
                      :subject="item"
                      :time="item.createdAt"
                      needsParse
                      created
                    >
                      <v-icon small color="grey" slot="icon" class="mr-2">
                        mdi-clock-outline
                      </v-icon>
                    </TimeAgo>
                  </v-col>
                </v-row>
                <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
                <span
                  class="show-route"
                  v-if="item.docketId && $route.name != 'docket'"
                >
                  <v-btn
                    color="primary"
                    text
                    @click="docketDetailsDialog = true"
                  >
                    <v-icon left>
                      {{ $store.state.global.pages.docket.icon }}
                    </v-icon>
                    <span>
                      {{ $store.state.global.pages.docket.titleSingular }}</span
                    >
                  </v-btn>
                </span>
                <span class="show-route" v-if="allocationLines">
                  <DriverRoute
                    :item="{ source: item.source }"
                    :useButton="true"
                    :lines="allocationLines"
                  />
                </span>
                <makeInvoicePdf :pdfData="pdfData" />
                <ShowAudits :auditData="auditData" />
              </v-card-actions>
            </v-card>
          </v-sheet>
        </v-col>

        <v-col cols="12">
          <AllocationLineTable
            :headers="lineHeaders"
            :items="allocationLines"
            :loading="loading"
            :loadingLines="loadingLines"
            :closeParent="close"
            :selectedId="selectedId"
          />
        </v-col>
        <v-dialog
          v-model="docketDetailsDialog"
          @click:outside="() => (docketDetailsDialog = false)"
          @keydown.esc="() => (docketDetailsDialog = false)"
          fullscreen
        >
          <DocketDetails
            v-if="docketDetailsDialog"
            :dialog="docketDetailsDialog"
            :item="{ id: this.item.docketId }"
            :loading="loading"
            :close="() => (docketDetailsDialog = false)"
            :openEditDialog="openEditDialog"
          />
        </v-dialog>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {
  getLinesByAllocationId,
  getAllocationLines
} from '../../services/requests/allocations'
import LineHeaders from './lines/mixins/lineHeaders'
import HeaderFilters from '../../services/mixins/headerFilters'

import AllocationLineTable from './lines/AllocationLineTable'
import FormatDates from '@/services/mixins/formatDates'
import Detail from '../misc/Detail'
import AllocationLine from '../../services/models/allocationLine'
import TimeAgo from '../misc/TimeAgo'
import CardHeader from '../misc/shared/CardHeader'
import DocketDetails from '../dockets/DocketDetails'
import MakeInvoicePdf from '../misc/shared/MakeInvoicePdf'
import StagedWarning from '../misc/StagedWarning'
import makePdf from './mixins/makeAllocationPdf'
import ShowAudits from '../misc/shared/ShowAudits'
import DriverRoute from '../plans/DriverRoute'

export default {
  mixins: [FormatDates, LineHeaders, HeaderFilters, makePdf],
  components: {
    AllocationLineTable,
    Detail,
    DocketDetails,
    TimeAgo,
    CardHeader,
    MakeInvoicePdf,
    StagedWarning,
    ShowAudits,
    DriverRoute
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    openEditDialog: {
      type: Function,
      required: false
    },
    loading: {
      type: Boolean,
      required: true
    },
    open: {
      type: Function,
      required: false
    },
    selectedId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      allocationLines: [],
      docketDetailsDialog: false,
      loadingLines: false,
      pdfData: {},
      stagedLines: []
    }
  },
  mounted() {
    this.getLines()
    this.getStagedLines()
  },
  computed: {
    lineHeaders() {
      return Number(this.window?.width) >= 600
        ? this.allPossibleHeaders
        : this.mobileHeaders
    },
    duplicateDriverLines() {
      return this.stagedLines.filter(
        item => item.allocation?.driverId == this.item.driverId
      )
    },
    duplicateVehicleLines() {
      return this.stagedLines.filter(
        item => item.allocation?.vehicle?.name == this.item.vehicle?.name
      )
    },
    auditData() {
      return { id: this.item.id, model: 'allocation' }
    },
    dateString() {
      let str = this.makeShortDate(this.item.date)
      if (this.item.endDate)
        str += ` (End date: ${this.makeShortDate(this.item.endDate)})`
      return str
    }
  },
  methods: {
    async getLines() {
      try {
        this.loadingLines = true
        const result = await getLinesByAllocationId(this.item.id)
        const items = result.data.result
        this.allocationLines = items.map(item => new AllocationLine(item))
        const data = this.item
        data.lines = this.allocationLines
        this.pdfData = this.makePdf([data])[0]
        this.loadingLines = false
      } catch (err) {
        console.log(err)
      }
    },
    async getStagedLines() {
      try {
        if (this.item.isStaged && this.item.date) {
          const payload = {
            dateRangeStart: this.item.date,
            dateRangeEnd: this.item.date,
            isStaged: true,
            showVoid: false,
            filterCol: null,
            filterBy: null
          }
          const result = await getAllocationLines(payload)
          if (result.data.result) {
            this.stagedLines = result.data.result
              .filter(({ allocationId }) => allocationId != this.item.id)
              .map(item => {
                return new AllocationLine(item)
              })
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    showEditDialog() {
      this.openEditDialog()
      this.close()
    }
  }
}
</script>

<style>
.show-route:hover {
  cursor: pointer;
}
</style>
