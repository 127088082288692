<template>
  <v-list-item ripple @click.stop="onClick">
    <v-list-item-action>
      <v-icon :color="color">{{ icon }}</v-icon>
    </v-list-item-action>
    <v-list-item-title>{{ title }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: {
    icon: String,
    color: String,
    title: String,
    onClick: Function
  }
}
</script>

<style></style>
