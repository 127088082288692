import Rules from '@/services/validation/rules/rules'
export default {
  data() {
    return {
      nameRules: [
        v => Rules.isRequired(v),
        v => Rules.minLength(v, 1),
        v => Rules.maxLength(v, 90)
      ],
      numberRules: [v => Rules.minLength(v, 1), v => Rules.maxLength(v, 20)],
      addressRules: [v => Rules.minLength(v, 1), v => Rules.maxLength(v, 90)],
      addressCityRules: [
        v => Rules.minLength(v, 1),
        v => Rules.maxLength(v, 90)
      ],
      addressPostCodeRules: [
        v => Rules.minLength(v, 1),
        v => Rules.maxLength(v, 10)
      ],
      contactEmailRules: [v => Rules.isEmail(v)],
      detailsRules: [v => Rules.minLength(v, 1), v => Rules.maxLength(v, 100)],
      geofenceRadiusRules: [v => Rules.isRequiredWithZero(v)],
      abnRules: [v => Rules.minLength(v, 1), v => Rules.maxLength(v, 90)],
      xeroIdRules: [v => Rules.maxLength(v, 150)]
    }
  }
}
