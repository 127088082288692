<template>
  <MasterFilePage>
    <template v-slot:control>
      <MasterFileControl
        :masterFile="masterFile"
        :addDialog="addDialog"
        :toggleAdd="toggleAdd"
      />
    </template>
    <template v-slot:view>
      <MasterFileTable
        :masterFile="masterFile"
        :subjects="billingPeriods"
        :fields="fields"
        :loading="loading"
        :onEdit="editBillingPeriod"
        :onDelete="deleteBillingPeriod"
        :defaultHeaders="defaultHeaders"
        :allPossibleHeaders="allPossibleHeaders"
        :updateHeaders="updateHeaders"
      />
    </template>
    <template v-slot:form>
      <v-dialog
        v-model="addDialog"
        width="700"
        @click:outside="() => (addDialog = false)"
        @keydown.esc="() => (addDialog = false)"
      >
        <MasterFileForm
          :masterFile="masterFile"
          :fields="fields"
          :dialog="addDialog"
          :close="toggleAdd"
          :onSubmit="createBillingPeriod"
          :loading="loading"
        />
      </v-dialog>
    </template>
  </MasterFilePage>
</template>

<script>
import {
  addBillingPeriod,
  updateBillingPeriod,
  deleteBillingPeriod
} from '../../../services/requests/billingPeriods'
import getBillingPeriods from '../../../services/mixins/getMasterFiles/getBillingPeriods'
import billingPeriodFields from '../../../services/mixins/fields/billingPeriodFields'
import MasterFileCrudFunctions from '../../../services/mixins/masterFileCrudFunctions'
import billingPeriodHeaders from '../../../services/mixins/headers/billingPeriodHeaders'

import MasterFilePage from '../../../components/masterFiles/MasterFilePage'
import MasterFileControl from '../../../components/masterFiles/MasterFileControl'
import MasterFileForm from '../../../components/masterFiles/MasterFileForm'
import MasterFileTable from '../../../components/masterFiles/MasterFileTable'
export default {
  mixins: [
    getBillingPeriods,
    billingPeriodFields,
    MasterFileCrudFunctions,
    billingPeriodHeaders
  ],
  components: {
    MasterFilePage,
    MasterFileControl,
    MasterFileForm,
    MasterFileTable
  },
  data() {
    return {
      masterFile: 'billingPeriod',
      prop: 'billingPeriods',
      addDialog: false,
      sortBy: null,
      filterBy: '',
      loading: false
    }
  },
  methods: {
    async createBillingPeriod(billingPeriod) {
      await this.createMasterFile({
        payload: billingPeriod,
        action: addBillingPeriod,
        store: 'billingPeriods'
      })
    },
    async editBillingPeriod(billingPeriod) {
      const payload = {
        name: billingPeriod.name,
        isOpen: billingPeriod.isOpen
      }
      await this.editMasterFile({
        id: billingPeriod.id,
        payload: payload,
        action: updateBillingPeriod,
        store: 'billingPeriods'
      })
    },
    async deleteBillingPeriod(billingPeriod) {
      await this.deleteMasterFile({
        payload: billingPeriod,
        action: deleteBillingPeriod,
        store: 'billingPeriods'
      })
    },
    toggleAdd() {
      this.addDialog = !this.addDialog
    },
    updateHeaders(val) {
      this.defaultHeaders = val
    }
  }
}
</script>
