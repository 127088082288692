<template>
  <v-dialog v-model="display" :width="dialogWidth" persistent>
    <template v-slot:activator="{ on }">
      <v-text-field
        v-bind="textFieldProps"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :value="hexa"
        v-on="on"
        readonly
      >
        <template v-slot:prepend>
          <v-icon :color="hexa">mdi-checkbox-blank-circle</v-icon>
        </template>
        <template v-slot:progress>
          <slot name="progress">
            <v-progress-linear
              color="primary"
              indeterminate
              absolute
              height="2"
            ></v-progress-linear>
          </slot>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="pt-5 ma-0">
        <v-color-picker show-swatches v-model="color"></v-color-picker>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn color="grey darken-1" text @click="clearHandler">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="okHandler">{{ okText }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const DEFAULT_DIALOG_WIDTH = 340
const DEFAULT_CLEAR_TEXT = 'CLEAR'
const DEFAULT_OK_TEXT = 'OK'

export default {
  model: {
    prop: 'datetime',
    event: 'input'
  },
  props: {
    value: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    label: {
      type: String,
      default: ''
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT
    },
    textFieldProps: {
      type: Object
    }
  },
  data() {
    return {
      display: false,
      color: null
    }
  },
  computed: {
    hexa() {
      if (this.color) {
        return this.color.hexa
      } else if (this.value) {
        return this.value
      } else {
        return null
      }
    }
  },
  methods: {
    okHandler() {
      this.resetPicker()
      this.$emit('input', this.color.hexa)
    },
    clearHandler() {
      this.resetPicker()
      this.color = null
      this.$emit('input', null)
    },
    resetPicker() {
      this.display = false
    }
  }
}
</script>
