<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span>
          Edit {{ titleSingular }} -
          <span class="pl-1 subtitle-1">
            ID: <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-sheet
            outlined
            :color="editedItem.type == 'CREDIT' ? 'red' : ''"
            rounded
          >
            <v-card flat class="card-outlined">
              <v-form ref="form" @submit.prevent="submit()">
                <v-card-text class="pb-0">
                  <v-container fluid class="px-0">
                    <v-row>
                      <Detail
                        :icon="$store.state.global.pages.project.icon"
                        :text="item.project.name"
                        lg="3"
                      />
                      <Detail
                        :icon="$store.state.global.pages.contractor.icon"
                        :text="item.contractor.name"
                        lg="3"
                      />
                      <Detail :icon="typeIcon" :text="editedItem.type" lg="3" />
                    </v-row>
                  </v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        disabled
                        label="* Billing Period"
                        v-model="editedItem.billingPeriod"
                        :items="$store.state.billingPeriods.allBillingPeriods"
                        color="primary"
                        prepend-icon="mdi-calendar"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        :disabled="item.closed"
                        label="* Reference No."
                        v-model="editedItem.externalRef"
                        color="primary"
                        prepend-icon="mdi-note"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <DatePickerWrapper
                        :disabled="item.closed"
                        :label="`* Date`"
                        color="primary"
                        v-model="editedItem.date"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        label="* Status"
                        v-model="editedItem.status"
                        :items="[
                          'ENTERED',
                          'REVIEW',
                          'APPROVED',
                          'SENT',
                          'CLOSED'
                        ]"
                        color="primary"
                        prepend-icon="mdi-note"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        :disabled="item.closed"
                        label="Stat Dec Status"
                        v-model="editedItem.statDecConfirmed"
                        :items="[
                          'ENTERED',
                          'REVIEW',
                          'APPROVED',
                          'SENT',
                          'NOT SET'
                        ]"
                        color="primary"
                        prepend-icon="mdi-note"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :disabled="item.closed"
                        label="Stat Dec Notes"
                        v-model="editedItem.statDecNotes"
                        color="primary"
                        prepend-icon="mdi-note"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :disabled="item.closed"
                        label="Internal Notes"
                        v-model="editedItem.internalNotes"
                        color="primary"
                        prepend-icon="mdi-note"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :disabled="item.closed"
                        label="Invoice Notes"
                        v-model="editedItem.invoiceNotes"
                        color="primary"
                        prepend-icon="mdi-note"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="grey darken-1" @click="cancel">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" type="submit" :loading="loading">
                    Update
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-sheet>
        </v-col>
        <v-col cols="12">
          <LineView
            :headers="lineHeaders"
            @showAddLineDialog="addLineDialog = !addLineDialog"
            :items="lines"
            :onEdit="onEdit"
            :onDelete="onDelete"
            :loading="loading"
            :loadingLines="loadingLines"
            showEdit
            showAddBtn
            hideDetails
          />
          <div v-if="totalForLines" class=" ma-3 text-right title">
            Total: ${{ addCommas(totalForLines.toFixed(2)) }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="addLineDialog"
      @click:outside="() => (addLineDialog = false)"
      @keydown.esc="() => (addLineDialog = false)"
      max-width="900px"
    >
      <LineAdd
        :dialog="addLineDialog"
        :action="addLine"
        :item="item"
        :lines="lines"
        :loading="loadingLines"
        :onAdd="addLine"
        :close="() => (addLineDialog = false)"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import Vue from 'vue'
import {
  getLinesByParentId,
  addRctiLine,
  updateRctiLine,
  deleteRctiLine
} from '../../services/requests/rctis'
import DatePickerWrapper from '../misc/fields/DatePickerWrapper'
import LineHeaders from './mixins/lineHeaders'
import HeaderFilters from '../../services/mixins/headerFilters'
import RctiLine from '@/services/models/rctiLine'
import Detail from '../misc/Detail'
import LineView from './lines/LineView'
import LineAdd from './lines/LineAdd'

import FormatDates from '@/services/mixins/formatDates'
import { sumProp } from '../../utils/Utils'

export default {
  mixins: [FormatDates, LineHeaders, HeaderFilters],

  components: { LineView, Detail, DatePickerWrapper, LineAdd },
  props: {
    item: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    this.getLines()
  },
  data() {
    return {
      lines: [],
      editedItem: Object.assign({}, this.item),
      statusItems: ['ENTERED', 'COMMITED', 'REVIEW', 'APPROVED', 'CLOSED'],
      typeItems: ['INVOICE', 'CREDIT'],
      loadingLines: false,
      addLineDialog: false
    }
  },
  computed: {
    lineHeaders() {
      return Number(this.window?.width) >= 600
        ? this.headers
        : this.mobileHeaders
    },
    totalForLines() {
      return this.lines.length ? sumProp(this.lines, 'costTotal') : 0
    },
    typeIcon() {
      return this.editedItem.type == 'CREDIT'
        ? 'mdi-file-minus'
        : 'mdi-file-plus'
    }
  },

  methods: {
    async getLines() {
      try {
        this.loadingLines = true
        const result = await getLinesByParentId(this.item.id)
        const items = result.data.result
        this.lines = items.map(item => new RctiLine(item))
        this.loadingLines = false
      } catch (err) {
        console.log(err)
      }
    },
    async onEdit({ item }) {
      try {
        this.loadingLines = true
        const payload = {
          rctiId: item.rctiId,
          costTotal: Number(item.costTotal),
          description: item.description,
          notes: item.notes,
          sourceWeightTotal: item.sourceWeightTotal,
          destinationWeightTotal: item.destinationWeightTotal,
          vehicleWeightTotal: item.vehicleWeightTotal,
          hoursTotal: item.hoursTotal
        }
        const result = await updateRctiLine(item.id, payload)
        if (result) {
          const i = this.lines.findIndex(line => line.id === item.id)
          if (~i) {
            const editedLine = new RctiLine(result.data.result)
            Vue.set(this.lines, i, editedLine)
          }
          this.snack({
            text: `${this.titleSingular} line updated`,
            color: 'green'
          })
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }
    },
    async addLine(line) {
      try {
        this.loadingLines = true
        const result = await addRctiLine(line)
        if (result) {
          await this.getLines()
          const newLine = new RctiLine(result.data.line)
          console.log(newLine)
          this.snack({
            text: `${this.titleSingular} line created`,
            color: 'green'
          })
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }
    },
    async onDelete(line) {
      try {
        this.loadingLines = true
        const result = await deleteRctiLine(line.id)
        if (result) {
          console.log(result)
          await this.getLines()
          this.snack({
            text: `${this.titleSingular} line deleted`,
            color: 'amber'
          })
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        this.snackFormError()
        return
      }
      await this.action(this.editedItem)
      this.close()
    },
    openFileDialog() {
      this.$refs.fileUpload.click()
    },
    onFileChange() {
      this.file = {}
      this.file.type = this.type
      this.file = this.$refs.fileUpload.files[0]
      this.$emit('fileSelected', this.file)
    },
    cancel() {
      this.close()
      this.clear()
    },
    clear() {
      this.name = null
      this.$refs.fileUpload.value = ''
    }
  }
}
</script>
