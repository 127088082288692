import Store from '@/store/computed/storeHelpers'
import PodAllHeaders from './podAllHeaders'
export default {
  mixins: [PodAllHeaders],
  computed: {
    defaultHeaders: Store.getSet({
      store: 'pods',
      prop: 'podDefaultHeaders'
    }),
    allPossibleHeaders() {
      return this.allPodHeaders
    }
  },
  data() {
    return {
      mobileHeaders: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          value: 'allocationLine.destination.name'
        }
      ]
    }
  }
}
