var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"outlined":"","color":"amber","rounded":""}},[_c('v-card',{staticClass:"card-outlined",style:('border: 1px solid amber;'),attrs:{"flat":"","outlined":""}},[_c('v-card-text',{staticClass:"pb-0 d-flex justify-space-between align-center"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"10"}},[_c('MultiActions',{attrs:{"page":"allocations","isLines":"","selectedCount":_vm.selectedCount,"fieldsToChange":_vm.actionFields,"exportOptions":_vm.exportOptions}})],1),_c('v-col',{staticClass:"py-0 d-flex justify-end align-center",attrs:{"cols":"12","sm":"2"}},[_c('ColumnSelector',{attrs:{"defaultHeaders":_vm.defaultHeaders,"possibleHeaders":_vm.possibleHeaders,"toolTipText":"Column selector","onChanged":function (val) { return (_vm.defaultHeaders = val); }}})],1),_c('SelectAlert',{attrs:{"selected":_vm.selected,"selectedAll":_vm.selectedAll,"totalRecordCount":_vm.count,"selectAll":function () { return (_vm.selectedAll = true); },"unselectAll":function () { return ((_vm.selectedAll = false), (_vm.selected = [])); }}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allocationLines,"loading":_vm.loadingLines,"options":_vm.pagination,"server-items-length":_vm.count,"mobile-breakpoint":"0","footer-props":{
        'items-per-page-options': [10, 20, 40, 50],
        showFirstLastPage: true
      },"show-select":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No matching records found ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Loading records... ")]},proxy:true},{key:"item",fn:function(ref){
      var item = ref.item;
      var isSelected = ref.isSelected;
return [_c('Row',{key:item.id,attrs:{"headers":_vm.headers,"item":item,"showEdit":"","showDeliveryDetails":"","value":isSelected,"select":"","defaultDialog":"detailsDialog","editDialogFullscreen":"","detailsDialogFullscreen":""},on:{"input":_vm.toggleSelected},scopedSlots:_vm._u([{key:"details",fn:function(ref){
      var dialog = ref.dialog;
      var close = ref.close;
      var openEditDialog = ref.openEditDialog;
return [(dialog)?_c('AllocationDetails',{attrs:{"dialog":dialog,"item":item.allocation,"loading":_vm.loading,"close":close,"openEditDialog":openEditDialog,"selectedId":item.id}}):_vm._e()]}},{key:"podDetails",fn:function(ref){
      var dialog = ref.dialog;
      var close = ref.close;
return [(dialog)?_c('AllocationLineDetails',{attrs:{"item":item,"dialog":dialog,"close":close}}):_vm._e()]}},{key:"edit",fn:function(ref){
      var dialog = ref.dialog;
      var close = ref.close;
return [_c('AllocationEdit',{attrs:{"dialog":dialog,"item":item.allocation,"loading":_vm.loading,"action":_vm.editAllocation,"close":close}})]}},{key:"delete",fn:function(ref){
      var dialog = ref.dialog;
      var close = ref.close;
return [_c('DeleteDialog',{attrs:{"dialog":dialog,"page":(_vm.titleSingular + " Line"),"subject":item,"action":_vm.deleteAllocationLine,"close":close}})]}}],null,true)})]}},{key:"footer[`page-text`]",fn:function(ref){
      var pageStart = ref.pageStart;
      var pageStop = ref.pageStop;
      var itemsLength = ref.itemsLength;
return [_c('div',{staticClass:"text-center mt-1"},[(itemsLength)?_c('span',[_vm._v(" "+_vm._s((pageStart + "-" + pageStop + " of " + itemsLength))+" ")]):_c('span',[_vm._v(" - ")]),(_vm.loadingLines && itemsLength)?_c('v-progress-linear',{attrs:{"size":"15","indeterminate":"","color":"primary"}}):_c('div',{staticStyle:{"height":"5px"}})],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }