import BillingPeriodValidation from '../../validation/billingPeriod'
export default {
  mixins: [BillingPeriodValidation],
  computed: {
    fields() {
      return [
        {
          label: '* Name',
          prop: 'name',
          value: null,
          rules: this.nameRules,
          sm: '6'
        },
        {
          label: '* Open',
          prop: 'isOpen',
          value: false,
          type: 'boolean',
          sm: '6'
        }
      ]
    }
  }
}
