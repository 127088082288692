<template>
  <v-form ref="form" @submit.prevent="handleSubmit()">
    <v-card>
      <v-card-text class="pb-0">
        <v-card-title class="pa-0 font-weight-regular">
          {{ field.text }}
        </v-card-title>
        <span>{{ text }}</span>
        <v-select
          v-if="types"
          label="Status"
          v-model="type"
          :prepend-icon="type ? type.icon : 'mdi-information'"
          :color="type ? type.color : 'primary'"
          :items="types"
          type="text"
          item-text="text"
          return-object
        ></v-select>
        <v-text-field
          v-if="types"
          label="Notes"
          prepend-icon="mdi-note"
          v-model="notes"
          :disabled="!type"
        ></v-text-field>
        <div class="mt-3" v-if="field.value.label == 'print'">
          <v-text-field label="Title" v-model="pdfInfo.title"></v-text-field>
          <v-text-field label="Type" v-model="pdfInfo.type"></v-text-field>
          <v-select
            label="Page orientation"
            v-model="pdfInfo.orientation"
            prepend-icon="mdi-information"
            :items="['Portrait', 'Landscape']"
            type="text"
            item-text="text"
          ></v-select>
          <v-select
            label="Page size"
            v-model="pdfInfo.pageSize"
            prepend-icon="mdi-information"
            :items="['A4', 'A3', 'A2']"
            type="text"
            item-text="text"
          ></v-select>
        </div>

        <v-radio-group
          v-model="exportValue"
          v-if="field.value.label === 'export' || field.value.label === 'print'"
        >
          <v-radio
            v-for="item in exportOptions"
            :key="item.name"
            :label="
              field.value.label === 'export'
                ? `Export ${item.name}`
                : `Print ${item.name}`
            "
            :value="item"
          ></v-radio>
        </v-radio-group>
        <v-checkbox
          v-if="showDetailsCheckbox"
          :label="
            `Include ${$store.state.global.pages.docket.titleSingular.toLowerCase()} lines`
          "
          hide-details
          class="mt-0 mr-4"
          v-model="includeLines"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="close()" :disabled="loading">
          Close
        </v-btn>
        <v-btn
          text
          color="green"
          type="submit"
          :loading="loading || submitLoading"
          v-if="
            field.value.label !== 'export' &&
              field.value.label !== 'print' &&
              field.value.label !== 'print-docs'
          "
        >
          <v-icon v-if="icon" left>{{ icon }}</v-icon>
          {{ actionText }}
        </v-btn>
        <slot
          :exportValue="exportValue"
          :pdfInfo="pdfInfo"
          :includeLines="includeLines"
        >
        </slot>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  props: {
    submit: {
      type: Function,
      required: true
    },
    selectedCount: {
      type: Number,
      required: true
    },
    actionText: {
      type: String,
      required: false,
      default: 'Submit'
    },
    icon: {
      type: String,
      required: false
    },
    close: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    types: {
      type: Array,
      required: false
    },
    field: {
      type: Object,
      required: true
    },
    exportOptions: {
      type: Array,
      required: false
    }
  },
  mounted() {
    if (this.types) {
      this.type = this.types.find(({ text }) => text.includes('Confirm'))
    }
  },
  watch: {
    exportValue(val) {
      if (val) this.pdfInfo.type = val.name
    },
    types(val) {
      if (val) this.type = val.find(({ text }) => text.includes('Confirm'))
    }
  },
  data() {
    return {
      type: null,
      notes: null,
      submitLoading: false,
      exportHeader: false,
      exportLines: false,
      exportXero: false,
      exportAlt: false,
      exportValue: null,
      includeLines: false,
      pdfInfo: {
        title: 'My Report',
        type: '',
        orientation: 'Landscape',
        pageSize: 'A4'
      }
    }
  },
  computed: {
    text() {
      const plural =
        this.selectedCount > 1 || this.field.selectedNotRequired ? 's' : ''
      const count = this.field.selectedNotRequired ? '' : this.selectedCount
      const label =
        this.field.value.label == 'xero' ? 'send' : this.field.value.label
      return `Do you want to ${label} ${count} document${plural}?`
    },
    showDetailsCheckbox() {
      return (
        this.field.value.label === 'print' &&
        this.exportValue &&
        (this.exportValue.name === 'rcti details' ||
          this.exportValue.name === 'bill details')
      )
    }
  },
  methods: {
    async handleSubmit() {
      this.submit(this.type ? this.type.action : null, this.notes)
    }
  }
}
</script>
