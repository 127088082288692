import Api from '@/services/api.js'

// * VEHICLES
export const addVehicle = payload => {
  return Api.post('vehicles/', payload)
}
export const getVehicles = payload => {
  return Api.post('vehicles/get', payload)
}
export const getVehiclesLite = () => {
  return Api.get('vehicles/')
}
export const getVehiclesWithDriverLite = () => {
  return Api.get('vehicles/withDriver')
}
export const getVehicle = id => {
  return Api.get(`vehicles/vehicle/${id}`)
}
export const getVehicleQrCode = id => {
  return Api.get(`vehicles/qr/${id}`)
}
export const updateVehicle = (id, payload) => {
  return Api.put(`vehicles/${id}`, payload)
}
export const deleteVehicle = id => {
  return Api.delete(`vehicles/${id}`)
}

// * VEHICLE TYPES
export const addVehicleType = payload => {
  return Api.post('vehicles/types/', payload)
}
export const getVehicleTypes = payload => {
  return Api.post('vehicles/types/get', payload)
}
export const updateVehicleType = (id, payload) => {
  return Api.put(`vehicles/types/${id}`, payload)
}
export const deleteVehicleType = id => {
  return Api.delete(`vehicles/types/${id}`)
}
