import UserValidation from '../../validation/user'
import getUserAuthClasses from '../getMasterFiles/getUserAuthClasses'
export default {
  mixins: [UserValidation, getUserAuthClasses],
  computed: {
    fields() {
      return [
        {
          label: '* Name',
          prop: 'name',
          value: null,
          sm: '6',
          rules: this.nameRules
        },
        {
          label: '* Email',
          prop: 'email',
          value: '',
          type: 'login',
          sm: '6',
          rules: this.emailRules
        },
        {
          label: '* Password',
          prop: 'password',
          value: '',
          type: 'password',
          sm: '6',
          rules: this.passwordRules
        },
        {
          label: '* Confirm Password',
          prop: 'confirmedPassword',
          value: '',
          type: 'password',
          sm: '6',
          rules: this.passwordRules
        },
        {
          label: '* User Authorization',
          prop: 'userAuthClassId',
          value: null,
          items: this.userAuthClasses,
          rules: this.userAuthClassRules
        }
      ]
    }
  }
}
