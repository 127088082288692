<template>
  <v-card flat>
    <v-container class="pt-0">
      <v-row v-if="loading" class="pt-3">
        <Loading />
      </v-row>
      <v-row v-else class="pt-3">
        <v-col v-if="!lines.length" cols="12" class="d-flex justify-center">
          No delivery details available
        </v-col>

        <v-col cols="12" class="pb-0" v-if="timesheets.length">
          Timesheets
        </v-col>
        <v-col v-for="pod in timesheets" :key="pod.id" cols="12" sm="6">
          <PodCard :pod="pod" :handleClick="changeTab" />
        </v-col>

        <v-col cols="12" class="pb-0" v-if="images.length">
          Images & Signatures
        </v-col>
        <v-col v-for="pod in images" :key="pod.id" cols="12" sm="6">
          <PodCard :pod="pod" :handleClick="changeTab" />
        </v-col>

        <v-col cols="12" class="pb-0" v-if="forms.length">Forms</v-col>
        <v-col v-for="pod in forms" :key="pod.id" cols="12" sm="6">
          <PodCard :pod="pod" :handleClick="changeTab" />
        </v-col>

        <v-col cols="12" class="pt-0">
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Loading from '../misc/Loading'
import PodCard from '../pods/PodCard'
export default {
  components: {
    Loading,
    PodCard
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    timesheets: {
      type: Array,
      required: true
    },
    images: {
      type: Array,
      required: true
    },
    forms: {
      type: Array,
      required: true
    },
    changeTab: {
      type: Function,
      required: true
    },
    lines: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
