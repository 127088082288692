<template>
  <v-card flat>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-1">
        <span class="text-truncate">
          View {{ titleSingular.toLowerCase() }} line -
          <span class="pl-1 subtitle-1">
            ID: <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pb-0">
      <v-row>
        <v-col cols="12">
          <v-container fluid class="px-0">
            <v-row class="pt-3">
              <Detail
                icon="mdi-note-outline"
                :text="item.description"
                tooltip="Description"
                lg="6"
              />
              <Detail
                icon="mdi-currency-usd"
                :text="item._sellTotal || '0.00'"
                lg="6"
              />
              <Detail
                v-if="item.notes"
                icon="mdi-note"
                :text="item.notes"
                tooltip="Line note"
                lg="12"
              />
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <ShowAudits :auditData="auditData" />
      <v-spacer></v-spacer>
      <v-btn text color="grey darken-1" @click="close">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Detail from '../../misc/Detail'
import ShowAudits from '../../misc/shared/ShowAudits'

export default {
  components: { Detail, ShowAudits },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    auditData() {
      return { id: this.item.id, model: 'billLine' }
    }
  }
}
</script>

<style></style>
