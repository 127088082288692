import Api from '@/services/api.js'

export const addDestination = payload => {
  return Api.post('destinations/', payload)
}
export const getDestinations = payload => {
  return Api.post('destinations/get', payload)
}
export const getDestinationsLite = () => {
  return Api.get('destinations/')
}
export const getDestinationsByRun = payload => {
  return Api.post('destinations/getByRun', payload)
}
export const getRuns = payload => {
  return Api.post('destinations/getRuns', payload)
}

export const updateDestination = (id, payload) => {
  return Api.put(`destinations/${id}`, payload)
}
export const deleteDestination = id => {
  return Api.delete(`destinations/${id}`)
}
