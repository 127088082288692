import Store from '@/store/computed/storeHelpers'
import { getRuns } from '../../requests/destinations'
export default {
  mounted() {
    this.getRuns()
  },
  computed: {
    runs: Store.getSet({ store: 'destinations', prop: 'runs' })
  },
  methods: {
    async getRuns() {
      try {
        const payload = {}
        const result = await getRuns(payload)
        if (result) {
          result.data.result.forEach(function(value, i) {
            value.count = result.data.count[i].count
          })
          this.runs = result.data.result
        }
      } catch (err) {
        console.log(err)
      }
    }
  }
}
