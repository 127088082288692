import Rules from '@/services/validation/rules/rules'
export default {
  data() {
    return {
      dateRules: [v => Rules.isRequired(v)],
      sourceRules: [v => Rules.isRequired(v)],
      vehicleRules: [v => Rules.isRequired(v)],
      driverRules: [v => Rules.isRequired(v)],
      notesRules: [v => Rules.minLength(v, 1), v => Rules.maxLength(v, 255)],
      referenceRules: [
        v => Rules.minLength(v, 1),
        v => Rules.maxLength(v, 255)
      ],

      qtyRules: [v => Rules.isRequiredWithZero(v)],
      destinationRules: [v => Rules.isRequired(v)],
      waypointRules: [v => Rules.isNumber(v)],
      itemTypeRules: [v => Rules.isRequired(v)],
      typeRules: [v => Rules.isRequired(v)]
    }
  }
}
