import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    billingPeriods: [],
    allBillingPeriods: [],
    openBillingPeriods: [],
    billingPeriodDefaultHeaders: [
      'id',
      'name',
      'createdBy.name',
      'updatedBy.name',
      'isOpen'
    ],
    loadingBillingPeriods: false
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
