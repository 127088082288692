import Store from '@/store/computed/storeHelpers'
export default {
  computed: {
    defaultHeaders: Store.getSet({
      store: 'allocations',
      prop: 'allocationLineDefaultHeaders'
    })
  },
  data() {
    return {
      mobileHeaders: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          align: 'left',
          value: 'destination.name',
          type: 'text'
        },
        {
          text: 'Actions',
          align: 'center',
          value: '',
          sortable: false,
          colData: '',
          type: 'action'
        }
      ],
      allPossibleHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          type: 'text'
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          align: 'left',
          value: 'destination.name',
          type: 'text'
        },
        {
          text: this.$store.state.global.pages.itemType.titleSingular,
          align: 'left',
          value: 'itemType.name',
          type: 'text'
        },
        {
          text: 'Qty',
          align: 'right',
          value: 'qty'
        },
        {
          text: 'Delivery Status',
          align: 'center',
          value: 'deliveryStatus',
          type: 'deliveryStatus',
          sortable: true
        },
        {
          text: 'POD',
          align: 'right',
          value: 'lineCount',
          type: 'text'
        },
        {
          text: 'Del. Windows',
          value: 'destination.deliveryWindow'
        },
        {
          text: 'Est. Delivery',
          value: 'delTime'
        },
        {
          text: 'Notes',
          align: 'left',
          value: 'notes',
          type: 'text'
        },
        {
          text: 'Actions',
          align: 'center',
          value: '',
          sortable: false,
          colData: '',
          type: 'action'
        }
      ]
    }
  }
}
