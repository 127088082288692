import Rules from '@/services/validation/rules/rules'
export default {
  data() {
    return {
      nameRules: [
        v => Rules.isRequired(v),
        v => Rules.minLength(v, 1),
        v => Rules.maxLength(v, 90)
      ],
      typeNameRules: [
        v => Rules.isRequired(v),
        v => Rules.minLength(v, 2),
        v => Rules.maxLength(v, 75)
      ],
      detailsRules: [v => Rules.minLength(v, 1), v => Rules.maxLength(v, 100)],
      tonnageRules: [
        v => (v === 0 ? true : !!v || 'Field is required (enter 0 to disable)'),
        v => Rules.isNumber(v)
      ],
      maxTonnageRules: [v => Rules.isNumber(v)],
      contractorRules: [v => Rules.isRequired(v)],
      vehicleTypeRules: [v => Rules.isRequired(v)],
      vinRules: [
        v => {
          if (!v || v.toString().length === 17) {
            return true
          } else {
            return 'Must be exactly 17 characters'
          }
        }
      ]
    }
  }
}
