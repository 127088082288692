var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"py-3",attrs:{"cols":"7","sm":"6","md":"4","ls":"3"}},[_c('v-select',{attrs:{"label":"Select action","items":_vm.fieldsToChange,"item-value":"value","return-object":"","prepend-icon":"mdi-format-list-bulleted","clearable":"","clear-icon-cb":_vm.clearFieldToChange,"color":_vm.$store.state.global.pages[_vm.$route.name].color},on:{"change":function () { return _vm.updateFieldToChange ? _vm.updateFieldToChange(_vm.fieldToChange) : null; }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('v-list-item-action',[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.text))])]}},{key:"selection",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(!!_vm.selectedCount ? ((item.text) + " for " + _vm.selectedCount + " " + (_vm.selectedCount > 1 ? _vm.page : _vm.pageSingular)) : ("" + (item.text)))+" ")]}}]),model:{value:(_vm.fieldToChange),callback:function ($$v) {_vm.fieldToChange=$$v},expression:"fieldToChange"}})],1),_c('v-col',{staticClass:"py-0 d-flex align-center",attrs:{"cols":"5"}},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.confirmAction()}}},[_vm._v(" Confirm ")])],1),(_vm.fieldToChange)?_c('v-dialog',{attrs:{"max-width":"400"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.dialog = false}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ActionDialog',{attrs:{"submit":_vm.submit,"close":function () { return (_vm.dialog = false); },"loading":_vm.loading,"selectedCount":_vm.selectedCount,"types":_vm.fieldToChange.types,"field":_vm.fieldToChange,"exportOptions":_vm.filteredExportOptions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var exportValue = ref.exportValue;
          var pdfInfo = ref.pdfInfo;
          var includeLines = ref.includeLines;
return [(
            _vm.fieldToChange.value.label == 'export' ||
              _vm.fieldToChange.value.label == 'print'
          )?_c('v-btn',{attrs:{"text":"","disabled":!exportValue,"loading":_vm.loading,"color":"green"},on:{"click":function () { return _vm.handleExportClick(exportValue, pdfInfo, includeLines); }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.fieldToChange.value.label == 'export' ? 'mdi-cloud-download' : 'mdi-file-pdf-box'))]),_vm._v(_vm._s(_vm.fieldToChange.value.label == 'export' ? 'Export' : 'Print (pdf)')+" ")],1):_vm._e(),(_vm.fieldToChange.value.label == 'export')?_c('download-csv',{ref:"downloadCsv",staticClass:"btn btn-default",staticStyle:{"display":"none"},attrs:{"data":_vm.exportData,"name":_vm.filename},on:{"export-finished":_vm.exported}}):_vm._e()]}}],null,false,2799153968)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }