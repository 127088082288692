import { makeShortDate, makeTime } from '../../utils/FormatDates'
class Bill {
  constructor(bill) {
    Object.keys(bill).forEach(key => {
      this[key] = bill[key]
      this[`_${key}`] = bill[key]
      const formats = [
        {
          condition: ['date', 'createdAt', 'updatedAt'].includes(key),
          format: val => makeShortDate(val)
        },
        {
          condition: ['start', 'end'].includes(key),
          format: val => makeTime(new Date(val))
        },
        {
          condition: ['sellTotal', 'costTotal', 'qty', 'lineHours'].includes(
            key
          ),
          format: val => Number(val).toFixed(2)
        }
      ]

      formats.forEach(item => {
        if (item.condition && bill[key])
          this[`_${key}`] = item.format(bill[key])
      })
    })
  }

  get subject() {
    return this.destinationId
      ? this.destination
      : this.sourceId
      ? this.source
      : this.project
  }

  get billedBy() {
    if (this.destinationId) return 'DESTINATION'
    else if (this.sourceId) return 'SOURCE'
    else return 'PROJECT'
  }

  get closed() {
    return this.status === 'CLOSED'
  }

  get hasXero() {
    return !!this.transactionId
  }
}

export default Bill
