import Store from '@/store/computed/storeHelpers'
export default {
  computed: {
    defaultHeaders: Store.getSet({
      store: 'dockets',
      prop: 'docketLineDefaultHeaders'
    }),
    headers() {
      const tempHeaders = [...this.docketLineHeaders]
      if (this.showSell) {
        tempHeaders.splice(-2, 0, {
          text: 'Sell',
          align: 'right',
          value: 'sellPrice',
          type: 'curr',
          noOfDecimals: 2
        })
        tempHeaders.splice(-2, 0, {
          text: 'Total',
          align: 'right',
          value: '_sellTotal',
          type: 'curr',
          noOfDecimals: 2
        })
      }
      return tempHeaders
    }
  },
  data() {
    return {
      mobileHeaders: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          align: 'left',
          value: 'destination.name',
          type: 'text'
        },
        {
          text: 'Actions',
          align: 'center',
          value: '',
          sortable: false,
          colData: '',
          type: 'action'
        }
      ],
      docketLineHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          type: 'text'
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          align: 'left',
          value: 'destination.name',
          type: 'text'
        },
        {
          text: this.$store.state.global.pages.itemType.titleSingular,
          align: 'left',
          value: 'itemType.name',
          type: 'text'
        },
        {
          text: 'W/B Docket',
          align: 'left',
          value: 'wbDocketNumber',
          type: 'text'
        },
        {
          text: 'Quantity',
          align: 'right',
          value: 'qty',
          type: 'decimal',
          noOfDecimals: 2
        },
        {
          text: 'Mass',
          align: 'right',
          value: 'destinationWeight',
          type: 'decimal',
          noOfDecimals: 2
        },
        {
          text: 'Hours',
          align: 'right',
          value: 'hours',
          type: 'decimal',
          noOfDecimals: 2
        },
        {
          text: 'Rate ID',
          align: 'right',
          value: 'rateId'
        },
        {
          text: 'Actions',
          align: 'center',
          value: '',
          sortable: false,
          colData: '',
          type: 'action'
        }
      ]
    }
  }
}
